// source: reportpb/trialbalance.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reportpb.ListTrialBalanceRequest', null, global);
goog.exportSymbol('proto.reportpb.ListTrialBalanceResponse', null, global);
goog.exportSymbol('proto.reportpb.TrialBalance', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.TrialBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.TrialBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.TrialBalance.displayName = 'proto.reportpb.TrialBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListTrialBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListTrialBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListTrialBalanceRequest.displayName = 'proto.reportpb.ListTrialBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListTrialBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListTrialBalanceResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListTrialBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListTrialBalanceResponse.displayName = 'proto.reportpb.ListTrialBalanceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.TrialBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.TrialBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.TrialBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.TrialBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
sdCreditCashBalance: jspb.Message.getFieldWithDefault(msg, 9, ""),
sdDebitCashBalance: jspb.Message.getFieldWithDefault(msg, 10, ""),
tdCreditCashBalance: jspb.Message.getFieldWithDefault(msg, 11, ""),
tdDebitCashBalance: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.TrialBalance}
 */
proto.reportpb.TrialBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.TrialBalance;
  return proto.reportpb.TrialBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.TrialBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.TrialBalance}
 */
proto.reportpb.TrialBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCreditCashBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdDebitCashBalance(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCreditCashBalance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdDebitCashBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.TrialBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.TrialBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.TrialBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.TrialBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSdCreditCashBalance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSdDebitCashBalance();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTdCreditCashBalance();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTdDebitCashBalance();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.TrialBalance.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.TrialBalance} returns this
*/
proto.reportpb.TrialBalance.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.TrialBalance.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string sd_credit_cash_balance = 9;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getSdCreditCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setSdCreditCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sd_debit_cash_balance = 10;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getSdDebitCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setSdDebitCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string td_credit_cash_balance = 11;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getTdCreditCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setTdCreditCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string td_debit_cash_balance = 12;
 * @return {string}
 */
proto.reportpb.TrialBalance.prototype.getTdDebitCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TrialBalance} returns this
 */
proto.reportpb.TrialBalance.prototype.setTdDebitCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListTrialBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListTrialBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListTrialBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListTrialBalanceRequest}
 */
proto.reportpb.ListTrialBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListTrialBalanceRequest;
  return proto.reportpb.ListTrialBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListTrialBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListTrialBalanceRequest}
 */
proto.reportpb.ListTrialBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListTrialBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListTrialBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListTrialBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
*/
proto.reportpb.ListTrialBalanceRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListTrialBalanceRequest} returns this
 */
proto.reportpb.ListTrialBalanceRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListTrialBalanceRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListTrialBalanceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListTrialBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListTrialBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListTrialBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListTrialBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
trialBalancesList: jspb.Message.toObjectList(msg.getTrialBalancesList(),
    proto.reportpb.TrialBalance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListTrialBalanceResponse}
 */
proto.reportpb.ListTrialBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListTrialBalanceResponse;
  return proto.reportpb.ListTrialBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListTrialBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListTrialBalanceResponse}
 */
proto.reportpb.ListTrialBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.TrialBalance;
      reader.readMessage(value,proto.reportpb.TrialBalance.deserializeBinaryFromReader);
      msg.addTrialBalances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListTrialBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListTrialBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListTrialBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListTrialBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrialBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.TrialBalance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrialBalance trial_balances = 1;
 * @return {!Array<!proto.reportpb.TrialBalance>}
 */
proto.reportpb.ListTrialBalanceResponse.prototype.getTrialBalancesList = function() {
  return /** @type{!Array<!proto.reportpb.TrialBalance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.TrialBalance, 1));
};


/**
 * @param {!Array<!proto.reportpb.TrialBalance>} value
 * @return {!proto.reportpb.ListTrialBalanceResponse} returns this
*/
proto.reportpb.ListTrialBalanceResponse.prototype.setTrialBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.TrialBalance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.TrialBalance}
 */
proto.reportpb.ListTrialBalanceResponse.prototype.addTrialBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.TrialBalance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListTrialBalanceResponse} returns this
 */
proto.reportpb.ListTrialBalanceResponse.prototype.clearTrialBalancesList = function() {
  return this.setTrialBalancesList([]);
};


goog.object.extend(exports, proto.reportpb);
