import Tracker from '@openreplay/tracker';

const isProd = window.location.hostname.includes('prod');

const openReplayTracker = new Tracker({
  projectKey: isProd ? 'wD5YP84dH1pVRNP2CGM6' : 'LjVLsoaNL1GmJ7fbpBkM',
  ingestPoint: `https://openreplay.${
    isProd ? 'prod' : 'staging'
  }.tradetalk.us/ingest`,
});

export default openReplayTracker;
