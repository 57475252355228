/**
 * @fileoverview gRPC-Web generated client stub for feepb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: feepb/interestrate.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.feepb = require('./interestrate_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.feepb.InterestRateServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.feepb.InterestRateServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.feepb.ListInterestRateRequest,
 *   !proto.feepb.ListInterestRateResponse>}
 */
const methodDescriptor_InterestRateService_ListInterestRate = new grpc.web.MethodDescriptor(
  '/feepb.InterestRateService/ListInterestRate',
  grpc.web.MethodType.UNARY,
  proto.feepb.ListInterestRateRequest,
  proto.feepb.ListInterestRateResponse,
  /**
   * @param {!proto.feepb.ListInterestRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.feepb.ListInterestRateResponse.deserializeBinary
);


/**
 * @param {!proto.feepb.ListInterestRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.feepb.ListInterestRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.feepb.ListInterestRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.feepb.InterestRateServiceClient.prototype.listInterestRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/feepb.InterestRateService/ListInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_ListInterestRate,
      callback);
};


/**
 * @param {!proto.feepb.ListInterestRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.feepb.ListInterestRateResponse>}
 *     Promise that resolves to the response
 */
proto.feepb.InterestRateServicePromiseClient.prototype.listInterestRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/feepb.InterestRateService/ListInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_ListInterestRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.feepb.InterestRate,
 *   !proto.feepb.CreateInterestRateResponse>}
 */
const methodDescriptor_InterestRateService_CreateInterestRate = new grpc.web.MethodDescriptor(
  '/feepb.InterestRateService/CreateInterestRate',
  grpc.web.MethodType.UNARY,
  proto.feepb.InterestRate,
  proto.feepb.CreateInterestRateResponse,
  /**
   * @param {!proto.feepb.InterestRate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.feepb.CreateInterestRateResponse.deserializeBinary
);


/**
 * @param {!proto.feepb.InterestRate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.feepb.CreateInterestRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.feepb.CreateInterestRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.feepb.InterestRateServiceClient.prototype.createInterestRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/feepb.InterestRateService/CreateInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_CreateInterestRate,
      callback);
};


/**
 * @param {!proto.feepb.InterestRate} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.feepb.CreateInterestRateResponse>}
 *     Promise that resolves to the response
 */
proto.feepb.InterestRateServicePromiseClient.prototype.createInterestRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/feepb.InterestRateService/CreateInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_CreateInterestRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.feepb.InterestRate,
 *   !proto.feepb.UpdateInterestRateResponse>}
 */
const methodDescriptor_InterestRateService_UpdateInterestRate = new grpc.web.MethodDescriptor(
  '/feepb.InterestRateService/UpdateInterestRate',
  grpc.web.MethodType.UNARY,
  proto.feepb.InterestRate,
  proto.feepb.UpdateInterestRateResponse,
  /**
   * @param {!proto.feepb.InterestRate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.feepb.UpdateInterestRateResponse.deserializeBinary
);


/**
 * @param {!proto.feepb.InterestRate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.feepb.UpdateInterestRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.feepb.UpdateInterestRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.feepb.InterestRateServiceClient.prototype.updateInterestRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/feepb.InterestRateService/UpdateInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_UpdateInterestRate,
      callback);
};


/**
 * @param {!proto.feepb.InterestRate} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.feepb.UpdateInterestRateResponse>}
 *     Promise that resolves to the response
 */
proto.feepb.InterestRateServicePromiseClient.prototype.updateInterestRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/feepb.InterestRateService/UpdateInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_UpdateInterestRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.feepb.DeleteInterestRateRequest,
 *   !proto.feepb.DeleteInterestRateResponse>}
 */
const methodDescriptor_InterestRateService_DeleteInterestRate = new grpc.web.MethodDescriptor(
  '/feepb.InterestRateService/DeleteInterestRate',
  grpc.web.MethodType.UNARY,
  proto.feepb.DeleteInterestRateRequest,
  proto.feepb.DeleteInterestRateResponse,
  /**
   * @param {!proto.feepb.DeleteInterestRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.feepb.DeleteInterestRateResponse.deserializeBinary
);


/**
 * @param {!proto.feepb.DeleteInterestRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.feepb.DeleteInterestRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.feepb.DeleteInterestRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.feepb.InterestRateServiceClient.prototype.deleteInterestRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/feepb.InterestRateService/DeleteInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_DeleteInterestRate,
      callback);
};


/**
 * @param {!proto.feepb.DeleteInterestRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.feepb.DeleteInterestRateResponse>}
 *     Promise that resolves to the response
 */
proto.feepb.InterestRateServicePromiseClient.prototype.deleteInterestRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/feepb.InterestRateService/DeleteInterestRate',
      request,
      metadata || {},
      methodDescriptor_InterestRateService_DeleteInterestRate);
};


module.exports = proto.feepb;

