// source: marginpb/calllog.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.marginpb.CallLog', null, global);
goog.exportSymbol('proto.marginpb.ListCallLogPendingRequest', null, global);
goog.exportSymbol('proto.marginpb.ListCallLogPendingResponse', null, global);
goog.exportSymbol('proto.marginpb.ListCallLogRequest', null, global);
goog.exportSymbol('proto.marginpb.ListCallLogResponse', null, global);
goog.exportSymbol('proto.marginpb.UpdateCallLogResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.CallLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.CallLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.CallLog.displayName = 'proto.marginpb.CallLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListCallLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.ListCallLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListCallLogRequest.displayName = 'proto.marginpb.ListCallLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListCallLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marginpb.ListCallLogResponse.repeatedFields_, null);
};
goog.inherits(proto.marginpb.ListCallLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListCallLogResponse.displayName = 'proto.marginpb.ListCallLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.UpdateCallLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.UpdateCallLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.UpdateCallLogResponse.displayName = 'proto.marginpb.UpdateCallLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListCallLogPendingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.ListCallLogPendingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListCallLogPendingRequest.displayName = 'proto.marginpb.ListCallLogPendingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListCallLogPendingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marginpb.ListCallLogPendingResponse.repeatedFields_, null);
};
goog.inherits(proto.marginpb.ListCallLogPendingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListCallLogPendingResponse.displayName = 'proto.marginpb.ListCallLogPendingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.CallLog.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.CallLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.CallLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.CallLog.toObject = function(includeInstance, msg) {
  var f, obj = {
requirementLogId: jspb.Message.getFieldWithDefault(msg, 1, ""),
tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
dueDate: (f = msg.getDueDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
correspondent: jspb.Message.getFieldWithDefault(msg, 6, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 8, ""),
callType: jspb.Message.getFieldWithDefault(msg, 9, ""),
callReq: jspb.Message.getFieldWithDefault(msg, 10, ""),
callAmt: jspb.Message.getFieldWithDefault(msg, 11, ""),
callStatus: jspb.Message.getFieldWithDefault(msg, 12, ""),
callStatusReason: jspb.Message.getFieldWithDefault(msg, 13, ""),
callStatusReasonCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
modifiedBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
modifiedDate: (f = msg.getModifiedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.CallLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.CallLog;
  return proto.marginpb.CallLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.CallLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.CallLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequirementLogId(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallReq(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallAmt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallStatusReason(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCallStatusReasonCount(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.CallLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.CallLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.CallLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.CallLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequirementLogId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCallType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCallReq();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCallAmt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCallStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCallStatusReason();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCallStatusReasonCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getModifiedDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string requirement_log_id = 1;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getRequirementLogId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setRequirementLogId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date trade_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.CallLog.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Date due_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.CallLog.prototype.getDueDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string correspondent = 6;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string master_account_no = 7;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string account_name = 8;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string call_type = 9;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string call_req = 10;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallReq = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string call_amt = 11;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string call_status = 12;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string call_status_reason = 13;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallStatusReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallStatusReason = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 call_status_reason_count = 14;
 * @return {number}
 */
proto.marginpb.CallLog.prototype.getCallStatusReasonCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallStatusReasonCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string modified_by = 15;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp modified_date = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marginpb.CallLog.prototype.getModifiedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearModifiedDate = function() {
  return this.setModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasModifiedDate = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListCallLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListCallLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListCallLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
fromModifiedDate: (f = msg.getFromModifiedDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
toModifiedDate: (f = msg.getToModifiedDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListCallLogRequest}
 */
proto.marginpb.ListCallLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListCallLogRequest;
  return proto.marginpb.ListCallLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListCallLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListCallLogRequest}
 */
proto.marginpb.ListCallLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromModifiedDate(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToModifiedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListCallLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListCallLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListCallLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFromModifiedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToModifiedDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListCallLogRequest.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
*/
proto.marginpb.ListCallLogRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListCallLogRequest.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date from_modified_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListCallLogRequest.prototype.getFromModifiedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
*/
proto.marginpb.ListCallLogRequest.prototype.setFromModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.clearFromModifiedDate = function() {
  return this.setFromModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListCallLogRequest.prototype.hasFromModifiedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.Date to_modified_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListCallLogRequest.prototype.getToModifiedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
*/
proto.marginpb.ListCallLogRequest.prototype.setToModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.clearToModifiedDate = function() {
  return this.setToModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListCallLogRequest.prototype.hasToModifiedDate = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marginpb.ListCallLogResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListCallLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListCallLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListCallLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
callLogsList: jspb.Message.toObjectList(msg.getCallLogsList(),
    proto.marginpb.CallLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListCallLogResponse}
 */
proto.marginpb.ListCallLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListCallLogResponse;
  return proto.marginpb.ListCallLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListCallLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListCallLogResponse}
 */
proto.marginpb.ListCallLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.CallLog;
      reader.readMessage(value,proto.marginpb.CallLog.deserializeBinaryFromReader);
      msg.addCallLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListCallLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListCallLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListCallLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marginpb.CallLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CallLog call_logs = 1;
 * @return {!Array<!proto.marginpb.CallLog>}
 */
proto.marginpb.ListCallLogResponse.prototype.getCallLogsList = function() {
  return /** @type{!Array<!proto.marginpb.CallLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marginpb.CallLog, 1));
};


/**
 * @param {!Array<!proto.marginpb.CallLog>} value
 * @return {!proto.marginpb.ListCallLogResponse} returns this
*/
proto.marginpb.ListCallLogResponse.prototype.setCallLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marginpb.CallLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.ListCallLogResponse.prototype.addCallLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marginpb.CallLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marginpb.ListCallLogResponse} returns this
 */
proto.marginpb.ListCallLogResponse.prototype.clearCallLogsList = function() {
  return this.setCallLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.UpdateCallLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.UpdateCallLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.UpdateCallLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.UpdateCallLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
callLog: (f = msg.getCallLog()) && proto.marginpb.CallLog.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.UpdateCallLogResponse}
 */
proto.marginpb.UpdateCallLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.UpdateCallLogResponse;
  return proto.marginpb.UpdateCallLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.UpdateCallLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.UpdateCallLogResponse}
 */
proto.marginpb.UpdateCallLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.CallLog;
      reader.readMessage(value,proto.marginpb.CallLog.deserializeBinaryFromReader);
      msg.setCallLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.UpdateCallLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.UpdateCallLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.UpdateCallLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.UpdateCallLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallLog();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marginpb.CallLog.serializeBinaryToWriter
    );
  }
};


/**
 * optional CallLog call_log = 1;
 * @return {?proto.marginpb.CallLog}
 */
proto.marginpb.UpdateCallLogResponse.prototype.getCallLog = function() {
  return /** @type{?proto.marginpb.CallLog} */ (
    jspb.Message.getWrapperField(this, proto.marginpb.CallLog, 1));
};


/**
 * @param {?proto.marginpb.CallLog|undefined} value
 * @return {!proto.marginpb.UpdateCallLogResponse} returns this
*/
proto.marginpb.UpdateCallLogResponse.prototype.setCallLog = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.UpdateCallLogResponse} returns this
 */
proto.marginpb.UpdateCallLogResponse.prototype.clearCallLog = function() {
  return this.setCallLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.UpdateCallLogResponse.prototype.hasCallLog = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListCallLogPendingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListCallLogPendingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListCallLogPendingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogPendingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListCallLogPendingRequest}
 */
proto.marginpb.ListCallLogPendingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListCallLogPendingRequest;
  return proto.marginpb.ListCallLogPendingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListCallLogPendingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListCallLogPendingRequest}
 */
proto.marginpb.ListCallLogPendingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListCallLogPendingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListCallLogPendingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListCallLogPendingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogPendingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.marginpb.ListCallLogPendingRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogPendingRequest} returns this
 */
proto.marginpb.ListCallLogPendingRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marginpb.ListCallLogPendingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListCallLogPendingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListCallLogPendingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListCallLogPendingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogPendingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
callLogsList: jspb.Message.toObjectList(msg.getCallLogsList(),
    proto.marginpb.CallLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListCallLogPendingResponse}
 */
proto.marginpb.ListCallLogPendingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListCallLogPendingResponse;
  return proto.marginpb.ListCallLogPendingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListCallLogPendingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListCallLogPendingResponse}
 */
proto.marginpb.ListCallLogPendingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.CallLog;
      reader.readMessage(value,proto.marginpb.CallLog.deserializeBinaryFromReader);
      msg.addCallLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListCallLogPendingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListCallLogPendingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListCallLogPendingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogPendingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marginpb.CallLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CallLog call_logs = 1;
 * @return {!Array<!proto.marginpb.CallLog>}
 */
proto.marginpb.ListCallLogPendingResponse.prototype.getCallLogsList = function() {
  return /** @type{!Array<!proto.marginpb.CallLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marginpb.CallLog, 1));
};


/**
 * @param {!Array<!proto.marginpb.CallLog>} value
 * @return {!proto.marginpb.ListCallLogPendingResponse} returns this
*/
proto.marginpb.ListCallLogPendingResponse.prototype.setCallLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marginpb.CallLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.ListCallLogPendingResponse.prototype.addCallLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marginpb.CallLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marginpb.ListCallLogPendingResponse} returns this
 */
proto.marginpb.ListCallLogPendingResponse.prototype.clearCallLogsList = function() {
  return this.setCallLogsList([]);
};


goog.object.extend(exports, proto.marginpb);
