import { formatPbDate } from 'lib/fmt';
import moment from 'moment';
import React, { createContext, useReducer, useContext } from 'react';

var ProfileContext = createContext();

function profileReducer(state, action) {
  switch (action.type) {
    case 'UPDATE': {
      const val = action.profile.toObject().profile;
      val.systemDate = moment(new Date(formatPbDate(val.systemDate)));
      val.tradeDate = moment(new Date(formatPbDate(val.tradeDate)));
      val.settleDate = moment(new Date(formatPbDate(val.settleDate)));
      val.previousDate = moment(new Date(formatPbDate(val.previousDate)));

      return { ...state, profile: val, pbProfile: action.profile };
    }
    case 'REMOVE':
      return { ...state, profile: null };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function ProfileProvider({ children }) {
  var [state, dispatch] = useReducer(profileReducer, {
    profile: null,
  });

  return (
    <ProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </ProfileContext.Provider>
  );
}

function useProfile() {
  const context = useContext(ProfileContext);
  return context.state.profile;
}

export { ProfileProvider, ProfileContext, useProfile };
