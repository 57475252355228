/*Service*/
import {
  SubAccount,
  DeleteSubAccountRequest,
} from '../../../proto/accountpb/subaccount_grpc_web_pb';

/*ReactJS*/
import React, { useState, useContext } from 'react';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Re-render component
===========================================================================================*/
function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}
/*=========================================================================================
End of re-render component
===========================================================================================*/

function SubAccountTable({ accountid, subaccountlist }) {
  const rows = subaccountlist;

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();

  const reRender = RenderComponent(); //Re-renders component when something change in component state
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { subAccountServiceClient: subAccountClient } = useContext(
    ServiceContext
  );

  /*=========================================================================================
  Add and Update sub account function
  ===========================================================================================*/
  function saveSubAccount(
    reRender,
    isAdd,
    closeModal,
    subAccountId,
    accountId,
    subAccountNo,
    accountName
  ) {
    if (isAdd) {
      let addReq = new SubAccount();
      addReq.setAccountId(accountId);
      addReq.setSubAccount(subAccountNo);
      addReq.setAccountName(accountName);

      subAccountClient.createSubAccount(addReq, {}, (err, res) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }
        rows.push(res.toObject().subAccount);
        notifySuccess('New sub account has been added.');
        reRender();
        closeModal();
      });
    } else {
      let updateReq = new SubAccount();
      updateReq.setSubAccountId(subAccountId);
      updateReq.setAccountId(accountId);
      updateReq.setSubAccount(subAccountNo);
      updateReq.setAccountName(accountName);

      subAccountClient.updateSubAccount(updateReq, {}, (err) => {
        if (err) {
          console.log(err);
          notifyError(err.messsage);
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].subAccountId === subAccountId) {
            rows[i].accountName = accountName;
          }
        }
        notifySuccess('Sub account has been updated.');
        reRender();
        closeModal();
      });
    }
  }
  /*=========================================================================================
  End of Add and Update sub account function
  ===========================================================================================*/

  /*=========================================================================================
  Delete sub account function: Multiple
  ===========================================================================================*/
  function removeSubAccount(reRender, subAccountIds, handleClick, confirm) {
    if (subAccountIds.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        subAccountIds.length +
        ' Sub Account(s) in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        subAccountIds.forEach(removeFunction);

        function removeFunction(item) {
          let deleteReq = new DeleteSubAccountRequest();
          deleteReq.setSubAccountId(item);

          subAccountClient.deleteSubAccount(deleteReq, {}, (err) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
            }
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].subAccountId === item) {
                rows.splice(c, 1);
              }
            }
            reRender();
          });
        }

        handleClick('', '', true);
        notifySuccess(subAccountIds.length + ' System codes has been deleted');
      })
      .catch(() => {
        /* ... */
      });
  }
  /*=========================================================================================
  End of delete sub account function: Multiple
  ===========================================================================================*/

  /*=========================================================================================
  Delete sub account function: Single
  ===========================================================================================*/
  function removeSubAccountSingle(
    reRender,
    subAccountId,
    accountName,
    confirm
  ) {
    confirm({
      description:
        "You are about to delete Sub Account '" +
        accountName +
        "' in your system. Please confirm your action.",
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeleteSubAccountRequest();
        deleteReq.setSubAccountId(subAccountId);

        subAccountClient.deleteSubAccount(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
            return;
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].subAccountId === subAccountId) {
              rows.splice(c, 1);
            }
          }
          reRender();
        });
        notifySuccess('Sub account has been deleted');
      })
      .catch(() => {
        // showToastMessage("error", "System error.");
      });
  }
  /*=========================================================================================
  End of delete sub account function: Single
  ===========================================================================================*/

  /*=========================================================================================
  Table modifications (Material UI default: includes pagination, sort, and multi select)
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const [isVisibleSubAccountNo, setIsVisibleSubAccountNo] = React.useState(
    true
  );
  const [isVisibleAccountName, setIsVisibleAccountName] = React.useState(true);

  const columns = [
    {
      id: '1',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '2',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '3',
      name: 'subAccountNo',
      label: 'Sub Account No.',
    },
    {
      id: '4',
      name: 'accountName',
      label: 'Account Name',
    },
  ];

  const handleClick = (event, subAccountId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(subAccountId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, subAccountId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
      setRowID([]);
    }
  };

  const isSelected = (subAccountId) => selected.indexOf(subAccountId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].subAccountId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rows[rowIndex].subAccountId}
        selected={isItemSelected}
      >
        {isVisibleSubAccountNo || isVisibleAccountName ? (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) =>
                handleClick(event, rows[rowIndex].subAccountId)
              }
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        ) : null}
        {isVisibleSubAccountNo || isVisibleAccountName ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="delete"
              onClick={() => {
                removeSubAccountSingle(
                  reRender,
                  rows[rowIndex].subAccountId,
                  rows[rowIndex].accountName,
                  confirm
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleSubAccountNo || isVisibleAccountName ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="edit"
              onClick={() => handleOpen(rows[rowIndex], false)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleSubAccountNo ? (
          <TableCell align="left">{rows[rowIndex].subAccount}</TableCell>
        ) : null}
        {isVisibleAccountName ? (
          <TableCell align="left">{rows[rowIndex].accountName}</TableCell>
        ) : null}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    if (column === 'subAccountNo') {
      if (action === 'remove') {
        setIsVisibleSubAccountNo(false);
      } else {
        setIsVisibleSubAccountNo(true);
      }
    }

    if (column === 'accountName') {
      if (action === 'remove') {
        setIsVisibleAccountName(false);
      } else {
        setIsVisibleAccountName(true);
      }
    }
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
  };
  /*=========================================================================================
  End of table modifications (Material UI default: includes pagination, sort, and multi select)
  ===========================================================================================*/

  /*========================================================================================= 
  Sub account modal
  ===========================================================================================*/
  const [subAccountId, setSubAccountId] = React.useState('');
  const [modalSubAccountNoValue, setModalSubAccountNoValue] = React.useState(
    ''
  );
  const [modalAccountNameValue, setModalAccountNameValue] = React.useState('');
  const [disableSubAccountNo, setDisableSubAccountNo] = React.useState(false);

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalSubAccountNoValue('');
      setModalAccountNameValue('');

      setModalAddAction(true);
      setDisableSubAccountNo(false);
      setModalTitleValue('Add New');
    } else {
      setSubAccountId(rowData.subAccountId);
      setModalSubAccountNoValue(rowData.subAccount);
      setModalAccountNameValue(rowData.accountName);

      setModalAddAction(false);
      setDisableSubAccountNo(true);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*========================================================================================= 
  End of sub account modal
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeSubAccount(reRender, rowID, handleClick, confirm);
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Sub Accounts'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Sub Account
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      type="text"
                      label="Sub Account No."
                      fullWidth={true}
                      disabled={disableSubAccountNo}
                      value={modalSubAccountNoValue}
                      onChange={(e) =>
                        setModalSubAccountNoValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      type="text"
                      label="Account Name"
                      fullWidth={true}
                      value={modalAccountNameValue}
                      onChange={(e) => setModalAccountNameValue(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveSubAccount(
                          reRender,
                          modalAddAction,
                          handleClose,
                          subAccountId,
                          accountid,
                          modalSubAccountNoValue,
                          modalAccountNameValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { SubAccountTable };
