/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListAccountManagementRequest } from '../../../proto/reportpb/accountmanagement_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { LoadPreviousDateRequest } from '../../../proto/admpb/previousdate_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

import PropTypes from 'prop-types';

/*Icons*/
import {
  Visibility as ViewIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import BalanceDetails from './BalanceDetails';
import PositionDetailsTable from './PositionDetailsTable';
import MarginRequirementDetails from './MarginRequirementDetails';
import SegregationDetailsTable from './SegregationDetailsTable';
import SearchButton from '../../../components/Button/Search';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function AccountManagementTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    accountManagementServiceClient: accountManagement,
    profileServiceClient: systemDate,
    previousDateServiceClient: previousDate,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNumberValue
        ? '&masterAccountNo=' + masterAccountNumberValue
        : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListAccountManagementRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setMasterAccountNo(masterAccountNumberValue);
    list.setAccountName(accountNameValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    accountManagement.listAccountManagement(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      let data = res.toObject().accountManagementsList;
      setRows(data);
      notifyInfo(data.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Account Management');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="view"
              onClick={() => {
                handleOpen(
                  rows[dataIndex].accountId,
                  rows[dataIndex].masterAccountNo,
                  systemDateValue
                );
              }}
            >
              <ViewIcon />
            </IconButton>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountId',
      label: 'Account ID',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const fileName =
    'AccountManagement_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 2, 9, 6, 4, 3, 7, 1, 5, 8],
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Account Management');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Account Management');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [
    masterAccountNumberValue,
    setMasterAccountNumberValue,
  ] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [tradeDateValue, setTradeDateValue] = React.useState('');
  const [settleDateValue, setSettleDateValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  const getPreviousDate = (date) => {
    let prevDateReq = new LoadPreviousDateRequest();
    if (date) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = date.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      prevDateReq.setSystemDate(gglSystemDate);
    }
    previousDate.loadPreviousDate(prevDateReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        return;
      } else {
        setTradeDateValue(
          moment(
            new Date(
              formatPbDate(res.toObject().previousDate.previousDateFunct)
            )
          ).format('yyyy-MM-DD')
        );
      }
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      systemDate: query.get('systemDate'),
      accountId: query.get('accountId'),
    };
    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNumberValue(data.masterAccountNo);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }

    let profileReq = new ReadProfileRequest();
    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        return;
      } else {
        let systemDate = moment(
          new Date(formatPbDate(res.toObject().profile.systemDate))
        ).format('yyyy-MM-DD');

        setSystemDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
        setSettleDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );

        let prevDateReq = new LoadPreviousDateRequest();
        if (systemDate) {
          let gglSystemDate = new google_date.Date();
          let [y, m, d] = systemDate.split('-');
          gglSystemDate.setYear(y);
          gglSystemDate.setMonth(m);
          gglSystemDate.setDay(d);
          prevDateReq.setSystemDate(gglSystemDate);
        }
        previousDate.loadPreviousDate(prevDateReq, {}, (err, res) => {
          if (err) {
            console.log(err);
            return;
          } else {
            setTradeDateValue(
              moment(
                new Date(
                  formatPbDate(res.toObject().previousDate.previousDateFunct)
                )
              ).format('yyyy-MM-DD')
            );
          }
        });
      }
    });

    if (data.accountId) {
      handleOpen(data.accountId, data.masterAccountNo, data.systemDate);
    }

    setCurrentPath(path);

    // eslint-disable-next-line
  }, [systemDate, previousDate, params]);

  const [value, setValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  const [selectedAccountId, setSelectedAccountId] = React.useState('');
  const [selectedMasterAccountNo, setSelectedMasterAccountNo] = React.useState(
    ''
  );
  const [open, setOpen] = React.useState(false);

  const handleOpen = (accountId, masterAccountNo, systemDate) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDate ? '&systemDate=' + systemDate : '') +
      (accountId ? '&accountId=' + accountId : '') +
      (masterAccountNo ? '&masterAccountNo=' + masterAccountNo : '');
    window.history.pushState({}, null, pathWithParams);
    console.log(systemDate);
    setSelectedAccountId(accountId);
    setSelectedMasterAccountNo(masterAccountNo);
    setSystemDateValue(systemDate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue(0);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNumberValue}
                  onChange={(e) => {
                    setMasterAccountNumberValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="account_designator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  required
                  labelId="broker"
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="type">
                  Type
                </InputLabel>
                <Select
                  required
                  labelId="type"
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => {
                    setSystemDateValue(e.target.value);
                    setSettleDateValue(e.target.value);
                    getPreviousDate(e.target.value);
                  }}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Account Management'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <div style={{ maxHeight: '90vh', overflowY: 'auto' }}>
              <div className={classes.tabModalHeader}>
                <Typography
                  id="transition-modal-title"
                  variant="h4"
                  className={classes.textBold}
                  gutterBottom
                >
                  Account Summary Report
                </Typography>
                <Typography style={{ marginTop: 13 }}>
                  System Date: {systemDateValue} | Trade Date: {tradeDateValue}{' '}
                  | Settle Date: {settleDateValue}
                </Typography>
              </div>
              <AppBar position="static" className={classes.tabHeader}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  variant="fullWidth"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab
                    style={{ padding: '18px 12px !important' }}
                    label="Balance"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ padding: '18px 12px !important' }}
                    label="Position"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ padding: '18px 12px !important' }}
                    label="Margin Requirement"
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{ padding: '18px 12px !important' }}
                    label="Segregation"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <BalanceDetails
                  systemdate={systemDateValue}
                  accountid={selectedAccountId}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <PositionDetailsTable
                  systemdate={systemDateValue}
                  accountid={selectedAccountId}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MarginRequirementDetails
                  tradedate={tradeDateValue}
                  masteraccountno={selectedMasterAccountNo}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <SegregationDetailsTable
                  settledate={settleDateValue}
                  masteraccountno={selectedMasterAccountNo}
                />
              </TabPanel>
            </div>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
