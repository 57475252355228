/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  Request,
  UpdateRequest,
  ListRequest,
} from '../../../proto/bankpb/request_grpc_web_pb.js';

import { ListBankAccountRequest } from '../../../proto/commonpb/list_grpc_web_pb';

import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

/*=========================================================================================
Add and Update ACH function
===========================================================================================*/
function saveACH(
  ach,
  isAdd,
  closeModal,
  requestId,
  bankId,
  correspondent,
  accountNo,
  externalTransferId,
  amount,
  transferType,
  status,
  requestType,
  fee,
  isInternational,
  instructionForCreditorAgent,
  rows
) {
  if (!correspondent) {
    notifyError('Correspondent is required.');
    return;
  }
  if (!accountNo) {
    notifyError('Account no. is required.');
    return;
  }
  if (!bankId) {
    notifyError('Bank is required.');
    return;
  }
  if (!amount) {
    notifyError('Amount is required.');
    return;
  }
  if (amount < 0) {
    notifyError('Amount cannot be ngative value');
    return;
  }
  if (!transferType) {
    notifyError('Transfer Type is required.');
    return;
  }

  if (isAdd) {
    let addReq = new Request();
    addReq.setBankId(bankId);
    addReq.setCorrespondent(correspondent);
    addReq.setAccountNo(accountNo);
    addReq.setExternalTransferId(externalTransferId);
    addReq.setAmount(amount);
    addReq.setTransferType(transferType);
    addReq.setStatus(status);
    addReq.setRequestType(requestType);
    addReq.setFee(fee);
    addReq.setIsInternational(isInternational);
    addReq.setInstructionForCreditorAgent(instructionForCreditorAgent);
    ach.create(addReq, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      } else {
        const data = res.toObject().requestDetail;
        const newRow = {
          accountNo: data.accountNo,
          achRoutingNo: data.achRoutingNo,
          amount: data.amount,
          fee: data.fee,
          isInternational: data.isInternational,
          bankAccountNo: data.bankAccountNo,
          bankAccountType: data.bankAccountType,
          bankId: data.bankId,
          bankName: data.bankName,
          correspondent: data.correspondent,
          createAt: data.createdAt
            ? moment(new Date(data.createdAt.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--',
          createdBy: data.createdBy,
          diffAmount: data.diffAmount,
          externalTransferId: data.externalTransferId,
          frbAmount: data.frbAmount,
          masterAccountNo: data.masterAccountNo,
          requestId: data.requestId,
          requestType: data.requestType,
          status: data.status,
          systemDate: data.systemDate ? formatPbDate(data.systemDate) : '--',
          transferType: data.transferType,
          wireRoutingNo: data.wireRoutingNo,
          instructionForCreditorAgent: data.instructionForCreditorAgent,
        };
        rows.push(newRow);
        notifySuccess('New ACH has been added.');
        closeModal();
      }
    });
  } else {
    let updateReq = new UpdateRequest();
    updateReq.setRequestId(requestId);
    updateReq.setStatus(status);

    ach.update(updateReq, {}, (err) => {
      if (err) {
        console.log(err);
        notifyError(err.message);
        return;
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].requestId === requestId) {
            rows[i].status = status;
          }
        }
        notifySuccess('ACH has been updated.');
        closeModal();
      }
    });
  }
}
/*=========================================================================================
End of Add and Update ACH function
===========================================================================================*/

/*Banks Dropdown Component*/
class BankSelect extends React.Component {
  render() {
    const listItems = this.props.items.map((item) => {
      if (item) {
        return (
          <MenuItem key={item.bankId} value={item.bankId}>
            {item.bankName + ': Bank Account # ' + item.bankAccountNo}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key="invalid" value="No Bank Available">
            No Bank Available
          </MenuItem>
        );
      }
    });

    return (
      <Select
        labelId={this.props.labelid}
        r
        fullWidth
        disabled={this.props.disabled}
        value={this.props.value}
        onOpen={this.props.onopen}
        onChange={this.props.onChange}
      >
        {listItems}
      </Select>
    );
  }
}

export default function ACHWireTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  const {
    listAccountServiceClient: bankAccounts,
    profileServiceClient: systemDate,
    requestServiceClient: ach,
  } = useContext(ServiceContext);

  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountNo:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          amount:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          bankAccountNo:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          bankName:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          correspondent:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          diffAmount:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          fee:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
          frbAmount:
            columnFilter[7].value !== undefined ? [columnFilter[7].value] : [],
          isInternational:
            columnFilter[8].value !== undefined ? [columnFilter[8].value] : [],
          requestType:
            columnFilter[9].value !== undefined ? [columnFilter[9].value] : [],
          status:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
          systemDate:
            columnFilter[11].value !== undefined
              ? [columnFilter[11].value]
              : [],
          transferType:
            columnFilter[12].value !== undefined
              ? [columnFilter[12].value]
              : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (
                key === 'amount' ||
                key === 'diffAmount' ||
                key === 'frbAmount' ||
                key === 'fee'
              ) {
                item[key] = item[key].includes('$')
                  ? item[key]
                  : formatCurrency(item[key]);
              }
              if (key === 'createdAt') {
                if (item[key] !== undefined) {
                  item[key] =
                    typeof item[key] === 'object'
                      ? moment(new Date(item[key].seconds * 1000)).format(
                          'MM/DD/YYYY hh:mm'
                        )
                      : item[key];
                } else {
                  item[key] = '--';
                }
              }
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          accountNo: data.accountNo,
          achRoutingNo: data.achRoutingNo,
          amount: data.amount.includes('$')
            ? data.amount
            : formatCurrency(data.amount),
          bankAccountNo: data.bankAccountNo,
          bankAccountType: data.bankAccountType,
          bankId: data.bankId,
          bankName: data.bankName,
          correspondent: data.correspondent,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          diffAmount: data.diffAmount.includes('$')
            ? data.diffAmount
            : formatCurrency(data.diffAmount),
          externalTransferId: data.externalTransferId,
          frbAmount: data.frbAmount.includes('$')
            ? data.frbAmount
            : formatCurrency(data.frbAmount),
          fee: data.fee.includes('$') ? data.fee : formatCurrency(data.fee),
          isInternational: data.isInternational,
          masterAccountNo: data.masterAccountNo,
          requestId: data.requestId,
          requestType: data.requestType,
          status: data.status,
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          transferType: data.transferType,
          wireRoutingNo: data.wireRoutingNo,
          instructionForCreditorAgent: data.instructionForCreditorAgent,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          accountNo: data.accountNo,
          achRoutingNo: data.achRoutingNo,
          amount: data.amount.includes('$')
            ? data.amount
            : formatCurrency(data.amount),
          bankAccountNo: data.bankAccountNo,
          bankAccountType: data.bankAccountType,
          bankId: data.bankId,
          bankName: data.bankName,
          correspondent: data.correspondent,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          diffAmount: data.diffAmount.includes('$')
            ? data.diffAmount
            : formatCurrency(data.diffAmount),
          externalTransferId: data.externalTransferId,
          frbAmount: data.frbAmount.includes('$')
            ? data.frbAmount
            : formatCurrency(data.frbAmount),
          fee: data.fee.includes('$') ? data.fee : formatCurrency(data.fee),
          isInternational: data.isInternational,
          masterAccountNo: data.masterAccountNo,
          requestId: data.requestId,
          requestType: data.requestType,
          status: data.status,
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          transferType: data.transferType,
          wireRoutingNo: data.wireRoutingNo,
          instructionForCreditorAgent: data.instructionForCreditorAgent,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**Search ACH function ==================================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (correspondentValue ? '&correspondent=' + correspondentValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (masterAccountNoValue
          ? '&masterAccountNo=' + masterAccountNoValue
          : '') +
        (transferTypeValue ? '&transferType=' + transferTypeValue : '') +
        (statusValue ? '&status=' + statusValue : '') +
        (requestTypeValue ? '&requestType=' + requestTypeValue : '') +
        (fromSystemDateValue ? '&fromSystemDate=' + fromSystemDateValue : '') +
        (toSystemDateValue ? '&toSystemDate=' + toSystemDateValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      if (!fromSystemDateValue) {
        notifyError('From System Date is required.');
        setLoading(false);
        return;
      }

      if (!toSystemDateValue) {
        notifyError('To System Date is required.');
        setLoading(false);
        return;
      }

      let list = new ListRequest();
      list.setCorrespondent(correspondentValue);
      list.setAccountNo(accountNoValue);
      list.setMasterAccountNo(masterAccountNoValue);
      list.setTransferType(transferTypeValue);
      list.setStatus(statusValue);
      list.setRequestType(requestTypeValue);
      if (fromSystemDateValue) {
        let gglFromSystemDate = new google_date.Date();
        let [y, m, d] = fromSystemDateValue.split('-');
        gglFromSystemDate.setYear(y);
        gglFromSystemDate.setMonth(m);
        gglFromSystemDate.setDay(d);
        list.setFromSystemDate(gglFromSystemDate);
      }
      if (toSystemDateValue) {
        let gglToSystemDate = new google_date.Date();
        let [y, m, d] = toSystemDateValue.split('-');
        gglToSystemDate.setYear(y);
        gglToSystemDate.setMonth(m);
        gglToSystemDate.setDay(d);
        list.setToSystemDate(gglToSystemDate);
      }

      ach.list(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }
        let fullData = res.toObject().requestDetailsList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**Search ACH function end ==============================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '2',
      name: 'amount',
      label: 'Amount',
      visibleState: useState(true),
      align: 'right',
      order: 4,
    },
    {
      id: '3',
      name: 'bankAccountNo',
      label: 'Bank Account No',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '4',
      name: 'bankName',
      label: 'Bank Name',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '5',
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '6',
      name: 'diffAmount',
      label: 'Diff Amount',
      visibleState: useState(true),
      align: 'right',
      order: 10,
    },
    {
      id: '7',
      name: 'fee',
      label: 'Fee',
      visibleState: useState(true),
      align: 'right',
      order: 11,
    },
    {
      id: '8',
      name: 'frbAmount',
      label: 'Bank Amount',
      visibleState: useState(true),
      align: 'right',
      order: 9,
    },
    {
      id: '9',
      name: 'isInternational',
      label: 'Is International',
      visibleState: useState(true),
      align: 'left',
      order: 12,
    },
    {
      id: '10',
      name: 'requestType',
      label: 'Request Type',
      visibleState: useState(true),
      align: 'left',
      order: 7,
    },
    {
      id: '11',
      name: 'status',
      label: 'Status',
      visibleState: useState(true),
      align: 'left',
      order: 6,
    },
    {
      id: '12',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(false),
      align: 'left',
      order: 8,
    },
    {
      id: '13',
      name: 'transferType',
      label: 'Transfer Type',
      visibleState: useState(false),
      align: 'center',
      order: 5,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};

  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
        <TableCell padding="checkbox">
          <IconButton
            aria-label="edit"
            onClick={() => handleOpen(rows[rowIndex], false)}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        {sortedColumns
          .filter((v) => v.options.display)
          .map((col) => {
            if (
              parseFloat(
                rows[rowIndex][col.name]
                  .toString()
                  .replace('$', '')
                  .replace(',', '')
              ) < 0
            ) {
              return (
                <TableCell
                  style={{ color: '#f44336' }}
                  align={col.align || 'left'}
                  key={col.name}
                >
                  {rows[rowIndex][col.name] === true ? (
                    <CheckCircleIcon />
                  ) : (
                    rows[rowIndex][col.name]
                  )}
                </TableCell>
              );
            } else {
              return (
                <TableCell align={col.align || 'left'} key={col.name}>
                  {rows[rowIndex][col.name] === true ? (
                    <CheckCircleIcon />
                  ) : (
                    rows[rowIndex][col.name]
                  )}
                </TableCell>
              );
            }
          })}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'AchWire_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [4, 0, 3, 2, 1, 12, 10, 9, 11, 7, 5, 6, 8],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };
  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/

  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };

  const [loading, setLoading] = React.useState(false);

  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [requestId, setRequestId] = React.useState('');
  const [bankId, setBankId] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [transferTypeValue, setTransferTypeValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');
  const [requestTypeValue, setRequestTypeValue] = React.useState('');
  const [fromSystemDateValue, setFromSystemDateValue] = React.useState('');
  const [toSystemDateValue, setToSystemDateValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [showFilter, setShowFilter] = React.useState(true);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalCorrespondentValue, setModalCorrespondentValue] = React.useState(
    ''
  );
  const [modalAccountNoValue, setModalAccountNoValue] = React.useState('');
  const [
    modalExternalTransferIdValue,
    setModalExternalTransferIdValue,
  ] = React.useState('');
  const [modalAmountValue, setModalAmountValue] = React.useState('');
  const [modalTransferTypeValue, setModalTransferTypeValue] = React.useState(
    ''
  );
  const [modalStatusValue, setModalStatusValue] = React.useState('');
  const [modalRequestTypeValue, setModalRequestTypeValue] = React.useState('');
  const [modalFeeValue, setModalFeeValue] = React.useState('');
  const [
    modalIsInternationalValue,
    setModalIsInternationalValue,
  ] = React.useState(false);
  const [
    modalInstructionForCreditorAgentVAlue,
    setModalInstructionForCreditorAgentVAlue,
  ] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setBankId('');
      setModalCorrespondentValue('');
      setModalAccountNoValue('');
      setModalExternalTransferIdValue('');
      setModalAmountValue('');
      setModalTransferTypeValue('');
      setModalStatusValue('pending');
      setModalRequestTypeValue('');
      setModalFeeValue('');
      setModalIsInternationalValue(false);
      setModalInstructionForCreditorAgentVAlue('');
      setModalAddAction(true);
      setIsDisabled(false);
      setModalTitleValue('Add New');
    } else {
      setRequestId(rowData.requestId);
      setBankId(rowData.bankId);
      setModalCorrespondentValue(rowData.correspondent);
      setModalAccountNoValue(rowData.accountNo);
      setModalExternalTransferIdValue(rowData.externalTransferId);
      setModalAmountValue(rowData.amount.replace('$', ''));
      setModalTransferTypeValue(rowData.transferType);
      setModalStatusValue(rowData.status);
      setModalRequestTypeValue(rowData.requestType);
      setModalFeeValue(rowData.fee);
      setModalIsInternationalValue(rowData.isInternational);
      setModalInstructionForCreditorAgentVAlue(
        rowData.instructionForCreditorAgent
      );
      setModalAddAction(false);
      setIsDisabled(true);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*Execute load list of client accounts*/
  const [listOfBankAccounts, setListOfBankAccounts] = React.useState('');

  useEffect(() => {
    //Get list of bank accounts
    let bankList = new ListBankAccountRequest();
    bankList.setCorrespondent(modalCorrespondentValue);
    bankList.setAccountNo(modalAccountNoValue);
    bankList.setStatus('active');

    bankAccounts.listBankAccount(bankList, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        setListOfBankAccounts(res.toObject().bankAccountsList);
      }
    });

    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      transferType: query.get('transferType'),
      accountDesignator: query.get('accountDesignator'),
      status: query.get('status'),
      requestType: query.get('requestType'),
      fromSystemDate: query.get('fromSystemDate'),
      toSystemDate: query.get('toSystemDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.transferType) {
      setTransferTypeValue(data.transferType);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.requestType) {
      setRequestTypeValue(data.requestType);
    }
    if (data.fromSystemDate) {
      setFromSystemDateValue(data.fromSystemDate);
    }
    if (data.toSystemDate) {
      setToSystemDateValue(data.toSystemDate);
    }

    //Get default system date
    if (!data.fromSystemDate) {
      let profileReq = new ReadProfileRequest();
      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          return;
        } else {
          if (!data.fromSystemDate) {
            setFromSystemDateValue(
              moment(
                new Date(formatPbDate(res.toObject().profile.systemDate))
              ).format('yyyy-MM-DD')
            );
          }
          if (!data.toSystemDate) {
            setToSystemDateValue(
              moment(
                new Date(formatPbDate(res.toObject().profile.systemDate))
              ).format('yyyy-MM-DD')
            );
          }
        }
      });
    }

    setCurrentPath(path);
  }, [
    modalCorrespondentValue,
    modalAccountNoValue,
    bankAccounts,
    systemDate,
    params,
  ]);

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink>Transfer Type</InputLabel>
                <Select
                  fullWidth
                  value={transferTypeValue}
                  onChange={(e) => setTransferTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="deposit">Deposit</MenuItem>
                  <MenuItem value="withdrawal">Withdrawal</MenuItem>
                </Select>
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="initiated">Initiated</MenuItem>
                  <MenuItem value="margin_approved">Margin Approved</MenuItem>
                  <MenuItem value="transfer_approved">
                    Transfer Approved
                  </MenuItem>
                  <MenuItem value="sent">Sent</MenuItem>
                  <MenuItem value="denied">Denied</MenuItem>
                  <MenuItem value="bank_rejected">Bank Reject</MenuItem>
                  <MenuItem value="canceled">Canceled</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Request Type</InputLabel>
                <Select
                  fullWidth
                  value={requestTypeValue}
                  onChange={(e) => setRequestTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="ach">ACH</MenuItem>
                  <MenuItem value="wire">Wire</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From System Date"
                  type="date"
                  value={fromSystemDateValue}
                  onChange={(e) => setFromSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputProps={{
                    max: toSystemDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="To System Date"
                  type="date"
                  value={toSystemDateValue}
                  onChange={(e) => setToSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputProps={{
                    min: fromSystemDateValue,
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'ACH\\Wire'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} ACH
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    {modalAddAction ? (
                      <CorrespondentSelect
                        freeSolo={false}
                        required={true}
                        name="correspondent"
                        label="Correspondent"
                        value={modalCorrespondentValue}
                        onChange={(e) => {
                          setModalCorrespondentValue(e.currentTarget.value);
                        }}
                      />
                    ) : (
                      <TextField
                        label="Correspondent"
                        required
                        fullWidth={true}
                        disabled={isDisabled}
                        value={modalCorrespondentValue}
                        onChange={(e) =>
                          setModalCorrespondentValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                  <div className={classes.grdCell1}>
                    {modalAddAction ? (
                      <AccountNoSelect
                        freeSolo={false}
                        name="accountNo"
                        label="Account No"
                        value={modalAccountNoValue}
                        onChange={(e) => {
                          setModalAccountNoValue(e.currentTarget.value);
                        }}
                        selectedCorrespondent={modalCorrespondentValue}
                      />
                    ) : (
                      <TextField
                        label="Account No"
                        required
                        fullWidth={true}
                        disabled={isDisabled}
                        value={modalAccountNoValue}
                        onChange={(e) => setModalAccountNoValue(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="bank" shrink>
                      Bank
                    </InputLabel>
                    <BankSelect
                      labelid="bank"
                      items={listOfBankAccounts}
                      disabled={isDisabled}
                      value={bankId}
                      onChange={(e) => {
                        setBankId(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="External Transfer ID"
                      required
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalExternalTransferIdValue}
                      onChange={(e) =>
                        setModalExternalTransferIdValue(e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Amount"
                      required
                      type="number"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalAmountValue}
                      onChange={(e) => setModalAmountValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Transfer Type</InputLabel>
                    <Select
                      fullWidth
                      disabled={isDisabled}
                      value={modalTransferTypeValue}
                      onChange={(e) =>
                        setModalTransferTypeValue(e.target.value)
                      }
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="deposit">Deposit</MenuItem>
                      <MenuItem value="withdrawal">Withdrawal</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Request Type *</InputLabel>
                    <Select
                      fullWidth
                      disabled={isDisabled}
                      value={modalRequestTypeValue}
                      onChange={(e) => setModalRequestTypeValue(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="ach">ACH</MenuItem>
                      <MenuItem value="wire">Wire</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Status</InputLabel>
                    <Select
                      fullWidth
                      disabled={modalStatusValue === 'sent' ? true : false}
                      value={modalStatusValue}
                      onChange={(e) => setModalStatusValue(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="initiated">Initiated</MenuItem>
                      <MenuItem value="margin_approved">
                        Margin Approved
                      </MenuItem>
                      <MenuItem value="transfer_approved">
                        Transfer Approved
                      </MenuItem>
                      <MenuItem value="sent">Sent</MenuItem>
                      <MenuItem value="denied">Denied</MenuItem>
                      <MenuItem value="bank_rejected">Bank Reject</MenuItem>
                      <MenuItem value="canceled">Canceled</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Fee"
                      required
                      type="number"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalFeeValue}
                      onChange={(e) => setModalFeeValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    {/* <InputLabel shrink>Is International</InputLabel> */}
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="Is International"
                      checked={modalIsInternationalValue}
                      onChange={(e) =>
                        setModalIsInternationalValue(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Instruction For Creditor Agent"
                      fullWidth={true}
                      type="text"
                      disabled={isDisabled}
                      value={modalInstructionForCreditorAgentVAlue}
                      onChange={(e) =>
                        setModalInstructionForCreditorAgentVAlue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}></div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveACH(
                          ach,
                          modalAddAction,
                          handleClose,
                          requestId,
                          bankId,
                          modalCorrespondentValue,
                          modalAccountNoValue,
                          modalExternalTransferIdValue,
                          modalAmountValue,
                          modalTransferTypeValue,
                          modalStatusValue,
                          modalRequestTypeValue,
                          modalFeeValue,
                          modalIsInternationalValue,
                          modalInstructionForCreditorAgentVAlue,
                          rows
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
