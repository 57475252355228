/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListReserveDetailRequest } from '../../../proto/compliancepb/reserve_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

/*Material UI Components*/
import { TableCell, TableRow } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import useStyles from '../../../styles';
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ReserveCalcDetailsTable({ settledate, type, code }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { reserveServiceClient: reserve } = useContext(ServiceContext);

  useEffect(() => {
    let list = new ListReserveDetailRequest();
    list.setType(type);
    list.setCode(code);

    if (settledate) {
      let gglSettleDate = new google_date.Date();
      let [y, m, d] = moment(new Date(settledate))
        .format('yyyy-MM-DD')
        .split('-');
      gglSettleDate.setYear(y);
      gglSettleDate.setMonth(m);
      gglSettleDate.setDay(d);
      list.setSettleDate(gglSettleDate);
    }

    reserve.listReserveDetail(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err);
        return;
      }

      const rows = res.toObject().reserveDetailsList.map((data) => ({
        accountNo: data.accountNo,
        accountName: data.accountName,
        amount: formatCurrency(data.amount),
        code: data.code,
        settleDate: formatPbDate(data.settleDate),
        type: data.type,
      }));
      setRows(rows);
    });
  }, [reserve, settledate, type, code]);

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'settleDate',
      label: 'Settle Date',
      visibleState: useState(true),
    },
    {
      id: '2',
      name: 'type',
      label: 'Type',
      visibleState: useState(true),
    },
    {
      id: '3',
      name: 'code',
      label: 'Code',
      visibleState: useState(true),
    },
    {
      id: '4',
      name: 'account_no',
      label: 'Account No',
      visibleState: useState(true),
    },
    {
      id: '5',
      name: 'account_name',
      label: 'Account Name',
      visibleState: useState(true),
    },
    {
      id: '6',
      name: 'amount',
      label: 'Amount',
      visibleState: useState(true),
    },
  ];

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
        <TableCell padding="checkbox"></TableCell>
        {columns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell
              className={
                parseFloat(
                  rows[rowIndex][col.name]
                    .toString()
                    .replace('$', '')
                    .replace(',', '')
                ) < 0
                  ? classes.textColorRed
                  : ''
              }
              align={col.align || 'left'}
              key={col.name}
            >
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'ReserveCalcDetails_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    downloadOptions: {
      filename: fileName,
    },
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    selectableRowsHeader: false,
    onColumnSortChange: function(changedColumn, direction) {
      function dynamicsort(property, order) {
        var sort_order = 1;
        if (order === 'descending') {
          sort_order = -1;
        }
        return function(a, b) {
          if (a[property].toUpperCase() < b[property].toUpperCase()) {
            return -1 * sort_order;
          } else if (a[property].toUpperCase() > b[property].toUpperCase()) {
            return 1 * sort_order;
          } else {
            return 0 * sort_order;
          }
        };
      }
      setRows(rows.sort(dynamicsort(changedColumn, direction)));
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={'Reserve Calculation Details'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
