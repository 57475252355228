/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  ListProfileRequest,
  ExecuteProfileRequest,
} from '../../../proto/admpb/profile_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { TableCell, TableRow, Button, Box } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';

const google_date = require('../../../google/type/date_pb.js');

function process(profile, action, rollbackDate) {
  let req = new ExecuteProfileRequest();
  req.setProcess(action);

  if (rollbackDate) {
    let gglRollbackDate = new google_date.Date();
    let [y, m, d] = rollbackDate.split('-');
    gglRollbackDate.setYear(y);
    gglRollbackDate.setMonth(m);
    gglRollbackDate.setDay(d);
    req.setRollbackDate(gglRollbackDate);
  }

  profile.executeProfile(req, {}, (err, res) => {
    if (err) {
      console.error(err);
      notifyError(err.message);
      return;
    }
    notifySuccess(action.toUpperCase() + ' process completed');
  });
}

export default function SystemProfileTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { profileServiceClient: profile } = useContext(ServiceContext);

  /*START OF PAGING LOGIC ===================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          baseCurrency:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          previousDate:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          settleDate:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          systemDate:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          tradeDate:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          versionNo:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          baseCurrency: data.baseCurrency,
          versionNo: data.versionNo,
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          tradeDate:
            typeof data.tradeDate === 'object'
              ? formatPbDate(data.tradeDate)
              : data.tradeDate,
          settleDate:
            typeof data.settleDate === 'object'
              ? formatPbDate(data.settleDate)
              : data.settleDate,
          previousDate:
            typeof data.previousDate === 'object'
              ? formatPbDate(data.previousDate)
              : data.previousDate,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          baseCurrency: data.baseCurrency,
          versionNo: data.versionNo,
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          tradeDate:
            typeof data.tradeDate === 'object'
              ? formatPbDate(data.tradeDate)
              : data.tradeDate,
          settleDate:
            typeof data.settleDate === 'object'
              ? formatPbDate(data.settleDate)
              : data.settleDate,
          previousDate:
            typeof data.previousDate === 'object'
              ? formatPbDate(data.previousDate)
              : data.previousDate,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**search function to get SLLs ========================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams = currentPath + '?search=true';
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let list = new ListProfileRequest();

      profile.listProfile(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }
        let fullData = res.toObject().profilesList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**search function end */

  /**PAGING LOGIC END ======================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'baseCurrency',
      label: 'Base Currency',
      visibleState: useState(true),
      align: 'left',
      order: 4,
    },
    {
      id: '2',
      name: 'previousDate',
      label: 'Previous Date',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '3',
      name: 'settleDate',
      label: 'Settle Date',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '4',
      name: 'systemDate',
      label: 'SystemDate',
      visibleState: useState(false),
      align: 'left',
      order: 0,
    },
    {
      id: '5',
      name: 'tradeDate',
      label: 'Trade Date',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '6',
      name: 'versionNo',
      label: 'Version No',
      visibleState: useState(true),
      align: 'right',
      order: 5,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={rows[rowIndex].calendarId}
      >
        <TableCell padding="checkbox"></TableCell>
        {sortedColumns
          .filter((v) => (v.options.empty ? false : v.options.display))
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'SystemProfile_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [3, 4, 2, 1, 0, 5],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const path = params.truepath;

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              hideFilterButton={true}
            />
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              //startIcon={<AddIcon />}
              onClick={() => {
                process(profile, 'eod', null);
              }}
            >
              Process EOD
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              //startIcon={<AddIcon />}
              onClick={() => {
                process(profile, 'bod', null);
              }}
            >
              Process BOD
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'System Profile'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
