/*ReactJS*/
import React, { useEffect } from 'react';

/*Material UI Components*/
import {
  Typography,
  Breadcrumbs,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

/*Styles*/
import useStyles from '../../styles';

/*Table Components*/
import SegregationTable from './Components/SegregationTable';
import SegregationBalanceTable from './Components/SegregationBalanceTable';

export default function Segregation(props) {
  const classes = useStyles();

  const [segregationType, setSegregationType] = React.useState('per_account');

  useEffect(() => {
    const data = props.match.params;

    if (data.segregationType && data.segregationType !== 'none') {
      setSegregationType(data.segregationType);
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 30 }}>
            <Typography
              variant="h2"
              className={classes.textBold}
              align="left"
              gutterBottom={true}
            >
              Segregation
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <span color="inherit">Compliance</span>
              <span color="inherit">Segregation</span>
              <Typography color="primary">Segregation</Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.grdCell1} style={{ alignSelf: 'flex-end' }}>
            <InputLabel shrink id="segregation">
              Segregation
            </InputLabel>
            <Select
              labelId="segregation"
              displayEmpty
              fullWidth
              value={segregationType}
              onChange={(e) => setSegregationType(e.target.value)}
            >
              <MenuItem value="per_account">Per Account</MenuItem>
              <MenuItem value="on_the_firm_level">
                On the Firm Level/Symbol
              </MenuItem>
            </Select>
          </div>
        </div>
        <Box mt={2}>
          {segregationType === 'per_account' ? (
            <SegregationBalanceTable params={props} segtype={segregationType} />
          ) : (
            <SegregationTable params={props} segtype={segregationType} />
          )}
        </Box>
      </div>
    </React.Fragment>
  );
}
