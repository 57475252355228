import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createSelector } from 'reselect'; //memoization
import { LazyLoadRequest } from '../../proto/commonpb/list_grpc_web_pb';
import { ServiceContext } from 'context/ServiceContext';

const alpacaYellow = '#FCD72B';

//memoization -  cached result when the same inputs occur again
const lazyLoadAccount = createSelector(
  (
    listClient,
    value,
    selectedCorrespondent,
    type,
    showAccountName,
    showInactive
  ) =>
    (async () =>
      await lazyLoadAccountPromise(
        listClient,
        value,
        selectedCorrespondent,
        type,
        showAccountName,
        showInactive
      ))(),
  (returnValue) => returnValue
);

async function lazyLoadAccountPromise(
  listClient,
  value,
  selectedCorrespondent,
  type,
  showAccountName,
  showInactive
) {
  return new Promise((resolve, reject) => {
    let listAccountReq = new LazyLoadRequest();
    if (type === 'correspondent') {
      listAccountReq.setLimit(10000);
      listAccountReq.setKey('');
    } else {
      listAccountReq.setLimit(100);
      listAccountReq.setKey(value);
      listAccountReq.setShowInactive(showInactive);
    }

    listClient.lazyLoadAccount(listAccountReq, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const data = response
          .toObject()
          .accountsList.filter((v) => {
            if (selectedCorrespondent) {
              return v.correspondent === selectedCorrespondent && v[type];
            }

            return v[type];
          })
          .map((v) => {
            if (!showAccountName) {
              return {
                [type]: v[type],
              };
            }

            return {
              [type]: v[type],
              accountName: v.accountName,
              baseCurrency: v.baseCurrency,
            };
          });
        resolve(removeDuplicates(data, (item) => item[type]));
      }
    });
  });
}

function removeDuplicates(data, key) {
  return [...new Map(data.map((item) => [key(item), item])).values()];
}

export default function SelectAccount({
  value,
  onChange,
  name,
  freeSolo,
  selectedCorrespondent,
  clear,
  disabled,
  required,
  label,
  type,
  showAccountName,
  showInactive,
}) {
  const { listAccountServiceClient: listClient } = useContext(ServiceContext);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(value);

  const setPropsValue = (v) => {
    onChange({
      currentTarget: {
        name: name,
        value: v,
      },
      target: {
        name: name,
        value: v,
      },
    });
  };

  const handleOnBlur = async (v) => {
    if (freeSolo) {
      setPropsValue(v);
      return;
    }

    if (options.find((o) => o[type] === v)) {
      setPropsValue(v);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, v) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && v) return;

    setInputValue(v);
    setPropsValue(v);
    getOptions(v);
  };

  //get list from backend
  const getOptions = async (v, editInputValue) => {
    const list = await lazyLoadAccount(
      listClient,
      v,
      selectedCorrespondent,
      type,
      showAccountName,
      showInactive
    );

    setOptions(list.filter((acct) => acct[type]));

    //editInputValue to setup the visible value of autocomplete for query string
    if (
      editInputValue &&
      list.some((e) => {
        return e[type] === v;
      })
    ) {
      setInputValue(value);
    }
  };

  React.useEffect(() => {
    if (open) {
      getOptions(value);
    } else if (value && !options.length && !inputValue) {
      const editInputValue = true;
      getOptions(value, editInputValue);
    }
    // eslint-disable-next-line
  }, [open, value]);

  return (
    <Autocomplete
      key={clear}
      disabled={disabled}
      freeSolo={freeSolo}
      id={name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const newValue = v ? v[type] : '';
        setPropsValue(newValue);
        setInputValue(newValue);
      }}
      getOptionSelected={(option, v) => option[type] === v[type]}
      getOptionLabel={(option) =>
        option.accountName && type !== 'accountName'
          ? option[type] + ' (' + option.accountName + ')'
          : option[type]
      }
      renderOption={(option) => {
        let renderText =
          option.accountName && type !== 'accountName'
            ? option[type] + ' (' + option.accountName + ')'
            : option[type];

        // add "LCT" badge to account no selection
        if (
          name === 'accountNo' &&
          option.baseCurrency &&
          option.baseCurrency !== 'USD'
        ) {
          return (
            <div>
              {renderText}{' '}
              <Chip
                label="LCT"
                size="small"
                style={{ backgroundColor: alpacaYellow }}
              />
            </div>
          );
        }

        return renderText;
      }}
      inputValue={inputValue}
      options={options}
      name={name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => {
        let InputProps = { ...params.InputProps };

        // Add "LCT" badge to account no field if it's LCT account (base-currency != USD)
        if (name === 'accountNo') {
          let value = params?.inputProps?.value;
          let opt = options.find((x) => x.accountNo === value);
          if (opt && opt.baseCurrency && opt.baseCurrency !== 'USD') {
            InputProps.endAdornment = (
              <InputAdornment position="start">
                <Chip
                  label="LCT"
                  size="small"
                  style={{ backgroundColor: alpacaYellow }}
                />
              </InputAdornment>
            );
          }
        }

        return (
          <TextField
            {...params}
            name={name}
            required={required}
            disabled={disabled}
            label={label}
            InputLabelProps={{ shrink: true }}
            InputProps={InputProps}
          />
        );
      }}
    />
  );
}
