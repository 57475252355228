/*Service*/
import {
  ReportSetup,
  DeleteReportSetupRequest,
} from '../../../proto/accountpb/reportsetup_grpc_web_pb';

/*ReactJS*/
import React, { useState, useContext } from 'react';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Re-render component
===========================================================================================*/
function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}
/*=========================================================================================
End of re-render component
===========================================================================================*/

function SetupTable({ accountid, reportsetuplist }) {
  const rows = reportsetuplist;

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();

  const reRender = RenderComponent(); //Re-renders component when something change in component state
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { reportSetupServiceClient: reportSetup } = useContext(ServiceContext);

  /*=========================================================================================
Add and Update sub account function
===========================================================================================*/
  function saveReportSetup(
    reRender,
    isAdd,
    closeModal,
    setupId,
    accountId,
    setupType,
    access,
    deliveryType
  ) {
    if (isAdd) {
      let addReq = new ReportSetup();
      addReq.setAccountId(accountId);
      addReq.setSetupType(setupType);
      addReq.setAccess(access);
      addReq.setDeliveryType(deliveryType);

      reportSetup.createReportSetup(addReq, {}, (err, res) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }
        rows.push(res.toObject().reportSetup);
        notifySuccess('New report setup has been added.');
        reRender();
        closeModal();
      });
    } else {
      let updateReq = new ReportSetup();
      updateReq.setSetupId(setupId);
      updateReq.setAccountId(accountId);
      updateReq.setSetupType(setupType);
      updateReq.setAccess(access);
      updateReq.setDeliveryType(deliveryType);

      reportSetup.updateReportSetup(updateReq, {}, (err) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].setupId === setupId) {
            rows[i].setupType = setupType;
            rows[i].deliveryType = deliveryType;
            rows[i].access = access;
          }
        }
        notifySuccess('Report setup has been updated.');
        reRender();
        closeModal();
      });
    }
  }
  /*=========================================================================================
End of Add and Update sub account function
===========================================================================================*/

  /*=========================================================================================
Delete report setup function: Multiple
===========================================================================================*/
  function removeReportSetup(reRender, setupIds, handleClick, confirm) {
    if (setupIds.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        setupIds.length +
        ' Report Setup(s) in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        setupIds.forEach(removeFunction);

        function removeFunction(item) {
          let deleteReq = new DeleteReportSetupRequest();
          deleteReq.setSetupId(item);

          reportSetup.deleteReportSetup(deleteReq, {}, (err) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
            }
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].setupId === item) {
                rows.splice(c, 1);
              }
            }
            reRender();
          });
        }

        handleClick('', '', true);
        notifySuccess(setupIds.length + ' Report setup has been deleted');
      })
      .catch(() => {
        /* ... */
      });
  }
  /*=========================================================================================
End of delete report setup function: Multiple
===========================================================================================*/

  /*=========================================================================================
Delete report setup function: Single
===========================================================================================*/
  function removeReportSetupSingle(reRender, setupId, setupType, confirm) {
    confirm({
      description:
        "You are about to delete Report Setup '" +
        setupType +
        "' in your system. Please confirm your action.",
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeleteReportSetupRequest();
        deleteReq.setSetupId(setupId);

        reportSetup.deleteReportSetup(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
            return;
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].setupId === setupId) {
              rows.splice(c, 1);
            }
          }
          reRender();
        });
        notifySuccess('Setup report has been deleted');
      })
      .catch(() => {
        // showToastMessage("error", "System error.");
      });
  }
  /*=========================================================================================
End of delete report setup function: Single
===========================================================================================*/

  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const [isVisibleSetupType, setIsVisibleSetupType] = React.useState(true);
  const [isVisibleAccess, setIsVisibleAccess] = React.useState(true);
  const [isVisibleDeliveryType, setIsVisibleDeliveryType] = React.useState(
    true
  );

  const columns = [
    {
      id: '1',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '2',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '3',
      name: 'setupType',
      label: 'Setup Type',
    },
    {
      id: '4',
      name: 'deliveryType',
      label: 'Delivery Type',
    },
    {
      id: '5',
      name: 'access',
      label: 'Access',
    },
  ];

  const handleClick = (event, setupId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(setupId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, setupId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
      setRowID([]);
    }
  };

  const isSelected = (setupId) => selected.indexOf(setupId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].setupId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rows[rowIndex].setupId}
        selected={isItemSelected}
      >
        {isVisibleSetupType || isVisibleAccess || isVisibleDeliveryType ? (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => handleClick(event, rows[rowIndex].setupId)}
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        ) : null}
        {isVisibleSetupType || isVisibleAccess || isVisibleDeliveryType ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="delete"
              onClick={() => {
                removeReportSetupSingle(
                  reRender,
                  rows[rowIndex].setupId,
                  rows[rowIndex].setupType,
                  confirm
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleSetupType || isVisibleAccess || isVisibleDeliveryType ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="edit"
              onClick={() => handleOpen(rows[rowIndex], false)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleSetupType ? (
          <TableCell align="left">{rows[rowIndex].setupType}</TableCell>
        ) : null}
        {isVisibleDeliveryType ? (
          <TableCell align="left">{rows[rowIndex].deliveryType}</TableCell>
        ) : null}
        {isVisibleAccess ? (
          <TableCell align="left">
            {rows[rowIndex].access ? <CheckCircleIcon /> : null}
          </TableCell>
        ) : null}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    if (column === 'setupType') {
      if (action === 'remove') {
        setIsVisibleSetupType(false);
      } else {
        setIsVisibleSetupType(true);
      }
    }

    if (column === 'access') {
      if (action === 'remove') {
        setIsVisibleAccess(false);
      } else {
        setIsVisibleAccess(true);
      }
    }

    if (column === 'deliveryType') {
      if (action === 'remove') {
        setIsVisibleDeliveryType(false);
      } else {
        setIsVisibleDeliveryType(true);
      }
    }
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/

  /*========================================================================================= 
  Sub account modal
  ===========================================================================================*/
  const [setupId, setSetupId] = React.useState('');
  const [modalSetupTypeValue, setModalSetupTypeValue] = React.useState('');
  const [modalDeliveryTypeValue, setModalDeliveryTypeValue] = React.useState(
    'Online'
  );
  const [modalAccessValue, setModalAccessValue] = React.useState(true);

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalSetupTypeValue('');
      setModalDeliveryTypeValue('online');
      setModalAccessValue(true);

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setSetupId(rowData.setupId);
      setModalSetupTypeValue(rowData.setupType);
      setModalDeliveryTypeValue(rowData.deliveryType);
      setModalAccessValue(rowData.access);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*========================================================================================= 
  End of sub account modal
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<DeleteIcon />}
              onClick={() => {
                removeReportSetup(reRender, rowID, handleClick, confirm);
              }}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Report Types'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Report Setup
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="setupType" shrink>
                      Setup Type
                    </InputLabel>
                    <Select
                      displayEmpty
                      labelId="setupType"
                      fullWidth
                      value={modalSetupTypeValue}
                      onChange={(e) => setModalSetupTypeValue(e.target.value)}
                    >
                      <MenuItem value="monthly_statement">
                        Monthly Statement
                      </MenuItem>
                      <MenuItem value="trade_confirmation">
                        Trade Confirmation
                      </MenuItem>
                      <MenuItem value="trade_records">Trade Records</MenuItem>
                      <MenuItem value="position">Position</MenuItem>
                      <MenuItem value="balance">Balance</MenuItem>
                      <MenuItem value="margin_requirements">
                        Margin Requirements
                      </MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="Access"
                      checked={modalAccessValue}
                      onChange={(e) => setModalAccessValue(e.target.checked)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Delivery Type</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalDeliveryTypeValue}
                      onChange={(e) =>
                        setModalDeliveryTypeValue(e.target.value)
                      }
                    >
                      <MenuItem value="online">Online</MenuItem>
                      <MenuItem value="mail">Mail</MenuItem>
                      <MenuItem value="email">eMail</MenuItem>
                      <MenuItem value="3rd_party">3rd party</MenuItem>
                      <MenuItem value="ftp">FTP</MenuItem>
                      <MenuItem value="api">API</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveReportSetup(
                          reRender,
                          modalAddAction,
                          handleClose,
                          setupId,
                          accountid,
                          modalSetupTypeValue,
                          modalAccessValue,
                          modalDeliveryTypeValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { SetupTable };
