/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListAccountManagementBalanceDetailRequest } from '../../../proto/reportpb/accountmanagement_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

import { Typography, Box } from '@material-ui/core';

/*Styles*/
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function BalanceDetails({ systemdate, accountid }) {
  const classes = useStyles();

  const { accountManagementServiceClient: accountManagement } = useContext(
    ServiceContext
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    let list = new ListAccountManagementBalanceDetailRequest();

    let gglSystemDate = new google_date.Date();
    let [y, m, d] = moment(new Date(systemdate))
      .format('yyyy-MM-DD')
      .split('-');
    gglSystemDate.setYear(y);
    gglSystemDate.setMonth(m);
    gglSystemDate.setDay(d);
    list.setSystemDate(gglSystemDate);
    list.setAccountId(accountid);

    accountManagement.listAccountManagementBalanceDetail(
      list,
      {},
      (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err);
          return;
        }
        setData(res.toObject().balanceDetailsList);
      }
    );
  }, [accountManagement, systemdate, accountid]);

  return (
    <div className={classes.root}>
      {data.length > 0 ? (
        <div style={{ padding: 30 }}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
            Balance Details
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 18 }}>
            {data[0].correspondent ? (
              <label style={{ marginBottom: 3, display: 'block' }}>
                Correspondent: <b>{data[0].correspondent}</b>
              </label>
            ) : null}
            <label>
              Account No: <b>{data[0].accountNo}</b>
            </label>
          </Typography>
          <Box component="div" mt={5}>
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCell1}>
                <label className={classes.detailsValue}>
                  TD Cash Balance:{' '}
                  <b>{formatCurrency(data[0].tdCashBalance)}</b>
                </label>
                <label className={classes.detailsValue}>
                  TD Long Market Value:{' '}
                  <b>{formatCurrency(data[0].tdLongMarketValue)}</b>
                </label>
                <label className={classes.detailsValue}>
                  TD Short Market Value:{' '}
                  <b>{formatCurrency(data[0].tdShortMarketValue)}</b>
                </label>
                <label className={classes.detailsValue}>
                  TD Equity: <b>{formatCurrency(data[0].tdEquity)}</b>
                </label>
                <label className={classes.detailsValue}>
                  TD Adjusted Balance:{' '}
                  <b>{formatCurrency(data[0].tdAdjustedBalance)}</b>
                </label>
              </div>
              <div className={classes.grdCell1}>
                <label className={classes.detailsValue}>
                  SD Cash Balance:{' '}
                  <b>{formatCurrency(data[0].sdCashBalance)}</b>
                </label>
                <label className={classes.detailsValue}>
                  SD Long Market Value:{' '}
                  <b>{formatCurrency(data[0].sdLongMarketValue)}</b>
                </label>
                <label className={classes.detailsValue}>
                  SD Short Market Value:{' '}
                  <b>{formatCurrency(data[0].sdShortMarketValue)}</b>
                </label>
                <label className={classes.detailsValue}>
                  SD Equity: <b>{formatCurrency(data[0].sdEquity)}</b>
                </label>
                <label className={classes.detailsValue}>
                  SD Adjusted Balance:{' '}
                  <b>{formatCurrency(data[0].sdAdjustedBalance)}</b>
                </label>
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <div
          style={{
            textAlign: 'center',
            padding: '50px 30px',
            fontSize: '1rem',
          }}
        >
          <span>Sorry, no matching records found.</span>
        </div>
      )}
    </div>
  );
}
