import React, { useState, useContext } from 'react';

import useStyles from '../../../styles';
import tableTheme from '../../../components/Table/TableStyle';
import CsvDownload from '../../../components/Table/CsvDownload';
import MUIDataTable from 'mui-datatables';
import SaveTable from 'components/Table/SaveTable';

import {
  Box,
  Button,
  IconButton,
  TextField,
  Modal,
  Fade,
  Typography,
  Backdrop,
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useUserState } from 'context/UserContext';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { notifySuccess } from 'components/Notification/Notification';
import { ServiceContext } from 'context/ServiceContext';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

export default function PermissionsTable() {
  const {
    root,
    textBold,
    textField,
    modalBackdrop,
    centerModal,
    modalCloseIcon,
    textError,
  } = useStyles();
  const { googleAccount } = useUserState();
  const { tableSettingsServiceClient: tableSettings } = useContext(
    ServiceContext
  );
  const [openCsvDownload, setOpenCsvDownload] = useState(false);
  const [tableSetting] = useState({
    load: true,
    viewColumns: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
  });

  const onSubmit = (values) => {
    reset();
    setOpenModal(false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Price');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2, 3, 4],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: () => (
      <SaveTable updateTableSettings={updateTableSettings} />
    ),
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
  };

  const columns = [
    {
      name: 'user',
      label: 'User',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'permission',
      label: 'Permission',
    },
    {
      name: 'resource',
      label: 'Resource',
    },
  ];

  const optionsModal = {
    filterType: 'select',
    download: false,
    filter: false,
    search: false,
    print: false,
    sort: false,
    viewColumns: false,
    columnOrder: [0, 1],
    selectableRowsHeader: true,
    selectableRows: 'multiple',
    rowsPerPageOptions: [],
  };

  const columnsModal = [
    {
      name: 'permission',
      label: 'Permission',
    },
    {
      name: 'resource',
      label: 'Resource',
    },
  ];
  return (
    <div className={root}>
      <Box component="div" mt={5}>
        <TextField
          fullWidth
          label="User Email"
          type="text"
          className={textField}
          placeholder="Enter an @alpaca.markets email"
          InputLabelProps={{
            shrink: true,
          }}
          {...register('email', {
            required: { value: true, message: 'This field is required' },
            pattern: {
              value: /\S+@alpaca.markets/,
              message: 'Email must end with @alpaca.markets',
            },
          })}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginBottom: 20, marginTop: 20 }}
          type="submit"
          onClick={() => {
            setOpenModal(true);
            //TODO: fetch user's permissions
          }}
        >
          Edit Permissions
        </Button>
        {openCsvDownload && (
          <CsvDownload
            dataKey="list"
            breaks={false}
            open={openCsvDownload}
            onClose={() => {
              setOpenCsvDownload(false);
            }}
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Permissions'}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={modalBackdrop}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={centerModal}>
              <Typography
                id="match-modal-title"
                variant="h4"
                className={textBold}
                gutterBottom
              >
                Edit Permissions
              </Typography>
              <TextField
                fullWidth
                label="User's Email"
                type="text"
                className={textField}
                style={errors.email ? {} : { marginBottom: '20px' }}
                placeholder={getValues('email')}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.email}
              />
              {errors.email && (
                <p className={textError}>{errors.email.message}</p>
              )}
              <MuiThemeProvider theme={tableTheme()}>
                <MUIDataTable columns={columnsModal} options={optionsModal} />
              </MuiThemeProvider>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: 20 }}
                type="submit"
              >
                Edit Permissions
              </Button>
              <div className={modalCloseIcon}>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenModal(false)}
                >
                  <CloseIcon style={{ color: '#f1f1f1' }} />
                </IconButton>
              </div>
            </Box>
          </form>
        </Fade>
      </Modal>
    </div>
  );
}
