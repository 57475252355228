const google_date = require('../../google/type/date_pb.js');

export function dateToPBDate(date) {
  let pbDate = new google_date.Date();
  let [y, m, d] = date.split('-');

  pbDate.setYear(y);
  pbDate.setMonth(m);
  pbDate.setDay(d);
  return pbDate;
}
