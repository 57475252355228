/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListBalanceReconRequest } from '../../../proto/reconpb/balancerecon_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { Pagination } from 'proto/utilspb/pagination_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Visibility as ViewIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency, formatDollar } from 'lib/fmt';

/*Custom components*/
import CsvDownload from '../../../components/Table/CsvDownload';
import SelectAccount from '../../../components/AutoComplete/SelectAccount';
import ContraSourceSelect from '../../../components/Dropdown/ContraSource';
import BalanceReconDetailsTable from './BalanceReconDetailsTable';
import SearchButton from '../../../components/Button/Search';
import ServerSideTableBodyFooter from '../../../components/Table/ServerSideTableBodyFooter';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';
import { useProfile } from 'context/ProfileContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function BalanceReconTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    balanceReconServiceClient: balancerecon,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: 50,
    pageNo: 0,
    reload: false,
    sortName: '',
    sortDirection: '',
  });

  const [footerData, setFooterData] = useState({});
  const [filterList, setFilterList] = useState({});

  const search = async (dontNotifySuccess, breaksOnly) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
    };

    paginationCopy.pageNo = dontNotifySuccess ? paginationCopy.pageNo : 0;

    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (dateTypeValue ? '&dateType=' + dateTypeValue : '') +
      (accountValue ? '&account=' + accountValue : '') +
      (contraAccountValue ? '&contraAccount=' + contraAccountValue : '') +
      (contraSourceValue ? '&contraSource=' + contraSourceValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);
    if (!breaksOnly) {
      setIsBreakOnly(!breaksOnly);
    }

    let paging = new Pagination();
    paging.setPageNo(paginationCopy.pageNo);
    paging.setPageSize(paginationCopy.rowsPerPage);
    paging.setSortName(paginationCopy.sortName);
    paging.setSortDirection(paginationCopy.sortDirection);

    try {
      const allData = await getData(paging, breaksOnly);
      paginationCopy.count = allData.summary.totalRows;
      setPagination(paginationCopy);
      setFooterData({
        amt: formatDollar(allData.summary.amt),
        contraAmt: formatDollar(allData.summary.contraAmt),
        amtDiff: formatDollar(allData.summary.amtDiff),
        pendingAmt: formatDollar(allData.summary.pendingAmt),
      });

      const rows = allData.list;

      setRows(rows);
      if (!dontNotifySuccess) {
        notifyInfo(allData.summary.totalRows + ' search results.');
      }
    } catch (error) {
      notifyError(error.message);
    }

    setLoading(false);
  };

  const getData = async (paging, breaksOnly) => {
    let list = new ListBalanceReconRequest();
    list.setAccount(accountValue);
    list.setContraAccount(contraAccountValue);
    list.setContraSource(contraSourceValue);
    list.setDateType(dateTypeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setBreaksOnly(breaksOnly);
    if (paging) {
      list.setPagination(paging);
    }

    return new Promise((resolve, reject) => {
      balancerecon.listBalanceRecon(list, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          const d = res.toObject();

          resolve({
            summary: d.summary,
            list: d.balanceReconsList.map((data) => ({
              systemDate: formatPbDate(data.systemDate),
              account: data.account,
              contraAccount: data.contraAccount,
              contraSource: data.contraSource,
              amt: data.amt,
              contraAmt: data.contraAmt,
              amtDiff: data.amtDiff,
              pendingAmt: data.pendingAmt,
            })),
          });
        }
      });
    });
  };

  const showBreaksOnly = (breaksOnly) => {
    search(true, breaksOnly);
    setIsBreakOnly(!isBreakOnly ? true : false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Balance Reconciliation');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  handleOpen(
                    rows[dataIndex].systemDate,
                    rows[dataIndex].account
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'account',
      label: 'Account',
    },
    {
      name: 'amt',
      label: 'Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].amt);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'amtDiff',
      label: 'Amt Diff',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].amtDiff);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'contraAccount',
      label: 'Contra Account',
    },
    {
      name: 'contraAmt',
      label: 'Contra Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].contraAmt);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'contraSource',
      label: 'Contra Source',
    },
    {
      name: 'pendingAmt',
      label: 'Pending Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].pendingAmt);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'systemDate',
      label: 'Date',
    },
  ];

  const fileName =
    'BalanceRecon_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    serverSide: true,
    count: pagination.count,
    page: pagination.pageNo,
    rowsPerPageOptions: [50, 100, 500, 1000, 5000, 15000, 30000, 100000],
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 8, 1, 4, 6, 2, 5, 3, 7],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: pagination.rowsPerPage,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      setOpenCsvDownload(true);
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Balance Reconciliation');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Balance Reconciliation');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;

      switch (action) {
        case 'changePage':
          setPagination({
            ...pagination,
            pageNo: tableState.page,
            reload: true,
          });
          break;
        case 'changeRowsPerPage':
          setPagination({
            ...pagination,
            rowsPerPage: tableState.rowsPerPage,
            reload: true,
          });
          break;
        case 'sort':
          setPagination({
            ...pagination,
            sortDirection: tableState.sortOrder.direction,
            sortName: tableState.sortOrder.name,
            reload: true,
          });
          break;
        case 'filterChange':
          {
            let filter = {};
            tableState.filterList.forEach((item, index) => {
              if (item.length) {
                filter[columns[index].name] = item[0];
              }
            });
            setFilterList(filter);
          }
          break;
        default:
      }
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <ServerSideTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          data={footerData}
        ></ServerSideTableBodyFooter>
      );
    },
  };

  const profile = useProfile();
  const [openCsvDownload, setOpenCsvDownload] = React.useState(false);
  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [dateTypeValue, setDateTypeValue] = React.useState('trade_date');
  const [accountValue, setAccountValue] = React.useState('');
  const [contraAccountValue, setContraAccountValue] = React.useState('');
  const [contraSourceValue, setContraSourceValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isBreakOnly, setIsBreakOnly] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      account: query.get('account'),
      systemDate: query.get('systemDate'),
      dateType: query.get('dateType'),
      contraAccount: query.get('contraAccount'),
      contraSource: query.get('contraSource'),
    };

    if (data.account) {
      setAccountValue(data.account);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.dateType) {
      setDateTypeValue(data.dateType);
    }
    if (data.contraAccount) {
      setContraAccountValue(data.contraAccount);
    }
    if (data.contraSource) {
      setContraSourceValue(data.contraSource);
    }

    setCurrentPath(path);
  }, [params]);

  useEffect(() => {
    if (pagination.reload) {
      search(true, !isBreakOnly);
    }
    // eslint-disable-next-line
  }, [pagination.pageNo,
    pagination.rowsPerPage,
    pagination.reload,
    pagination.sortName,
    pagination.sortDirection,
  ]);

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return;
        }
      });

      return ok;
    });

    return filtered;
  };

  const [selectedSystemDate, setSelectedSystemDate] = React.useState('');
  const [selectedAccountNo, setSelectedAccountNo] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (systemDate, accountNo) => {
    setSelectedSystemDate(systemDate);
    setSelectedAccountNo(accountNo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => setDateTypeValue(e.target.value)}
                >
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountNo"
                  name="accountNo"
                  label="Account No"
                  value={accountValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setAccountValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountNo"
                  name="contraAccountNo"
                  label="Contra Account No"
                  value={contraAccountValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setContraAccountValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="contra_source">
                  Contra Source
                </InputLabel>
                <ContraSourceSelect
                  required={false}
                  labelId="contraSource"
                  fullWidth
                  value={contraSourceValue}
                  onChange={(e) => setContraSourceValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search(false, !isBreakOnly)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ViewIcon />}
              onClick={() => {
                showBreaksOnly(isBreakOnly ? true : false);
              }}
            >
              {isBreakOnly ? 'Show Breaks Only' : 'Show All'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {openCsvDownload && (
          <CsvDownload
            getData={getData}
            filename={fileName}
            dataKey="list"
            breaks={!isBreakOnly}
            open={openCsvDownload}
            onClose={() => {
              setOpenCsvDownload(false);
            }}
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Balance Reconciliations'}
            data={filterRows()}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <BalanceReconDetailsTable
                systemdate={selectedSystemDate}
                accountno={selectedAccountNo}
                datetype={dateTypeValue}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
