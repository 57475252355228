import React, { useState, useEffect } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';
import {
  SupervisorAccount as SupervisorAccountIcon,
  ArrowBack as ArrowBackIcon,
  HowToVote as HowToVoteIcon,
  Iso as IsoIcon,
  TrendingUp as SecurityIcon,
  VerifiedUser as VerifiedUserIcon,
  BarChart as BarChartIcon,
  TrackChanges as TrackChangesIcon,
  ExpandMore as ExpandMoreIcon,
  AccountBalance,
} from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { useTheme, withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import Dot from './components/Dot';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: 'none',
    minHeight: 50,
    '&$expanded': {
      minHeight: 50,
    },
    padding: '3px 20px',
    background: 'none',
    color: '#595a5e',
    '&:hover': {
      background: '#f5f7ff',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0px 0px 0px 49px',
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

const structure = [
  {
    id: 0,
    label: 'Admin',
    link: '/app/home',
    icon: <SupervisorAccountIcon />,
    children: [
      {
        type: 'sub label',
        label: 'User Management',
        link: '/app/user-management',
      },
      {
        type: 'link',
        label: 'Administrator',
        link: '/app/user-management/administrator',
      },
      {
        type: 'link',
        label: 'Role',
        link: '/app/user-management/role',
      },
      { type: 'sub label', label: 'Account Setup', link: '/app/account-setup' },
      {
        type: 'link',
        label: 'Client Account',
        link: '/app/account-setup/client-account',
      },
      {
        type: 'link',
        label: 'GL Account',
        link: '/app/account-setup/gl-account',
      },
      {
        type: 'link',
        label: 'Bank Account',
        link: '/app/account-setup/bank-account',
      },
      {
        type: 'link',
        label: 'Bank Address',
        link: '/app/account-setup/bank-address',
      },
      { type: 'link', label: 'ACH\\Wire', link: '/app/account-setup/ach-wire' },
      {
        type: 'link',
        label: 'Recon Mapping',
        link: '/app/account-setup/recon-mapping',
      },
      {
        type: 'sub label',
        label: 'System Profile',
        link: '/app/system-profile',
      },
      {
        type: 'link',
        label: 'System Profile',
        link: '/app/system-profile/system-profile',
      },
      {
        type: 'link',
        label: 'System Code',
        link: '/app/system-profile/system-code',
      },
      {
        type: 'link',
        label: 'System Number',
        link: '/app/system-profile/system-number',
      },
      { type: 'link', label: 'Calendar', link: '/app/system-profile/calendar' },
      {
        type: 'link',
        label: 'User Guide',
        link: '/app/system-profile/user-guide',
      },
    ],
  },
  {
    id: 1,
    label: 'Balance',
    link: '/app/home',
    icon: <IsoIcon />,
    children: [
      { type: 'sub label', label: 'Cash', link: '/app/cash' },
      { type: 'link', label: 'Balance', link: '/app/cash/balance' },
      {
        type: 'link',
        label: 'Balance Reconciliation',
        link: '/app/cash/reconciliation-balance',
      },
      {
        type: 'link',
        label: 'Trial Balance',
        link: '/app/cash/trial-balance',
      },
      { type: 'link', label: 'GL Report', link: '/app/cash/gl-report' },
      {
        type: 'link',
        label: 'Settlement Funding',
        link: '/app/cash/settlement-funding',
      },
      {
        type: 'sub label',
        label: 'Margin Interest',
        link: '/app/margin-interest',
      },
      {
        type: 'link',
        label: 'Margin Rate',
        link: '/app/margin-interest/margin-rate',
      },
      {
        type: 'link',
        label: 'Margin Requirement',
        link: '/app/margin-interest/margin-requirement',
      },
      {
        type: 'link',
        label: 'Margin Interest Calc',
        link: '/app/margin-interest/margin-interest-calc',
      },
      {
        type: 'link',
        label: 'Margin Interest Rate',
        link: '/app/margin-interest/margin-interest-rate',
      },
      {
        type: 'link',
        label: 'Call Logs',
        link: '/app/margin-interest/call-log',
      },
    ],
  },
  {
    id: 2,
    label: 'Compliance',
    link: '/app/home',
    icon: <VerifiedUserIcon />,
    children: [
      { type: 'sub label', label: 'Segregation', link: '/app/segregation' },
      {
        type: 'link',
        label: 'Segregation',
        link: '/app/segregation/segregation',
      },
      {
        type: 'link',
        label: 'Segregation Priority',
        link: '/app/segregation/priority',
      },
      { type: 'sub label', label: 'Calculation', link: '/app/calculation' },
      {
        type: 'link',
        label: 'Reserve Calc',
        link: '/app/calculation/reserve-calc',
      },
    ],
  },
  {
    id: 3,
    label: 'Transaction',
    link: '/app/home',
    icon: <HowToVoteIcon />,
    children: [
      { type: 'link', label: 'Enter TRNS', link: '/app/trns/enter-trns' },
      { type: 'link', label: 'Activity', link: '/app/trns/activity' },
      { type: 'link', label: 'Cancel TRNS', link: '/app/trns/cancel-trns' },
      { type: 'link', label: 'Pending TRNS', link: '/app/trns/pending-trns' },
      {
        type: 'link',
        label: 'Execution Reconciliation',
        link: '/app/trns/reconciliation-execution',
      },
      { type: 'link', label: 'Fee', link: '/app/trns/fee' },
      {
        type: 'link',
        label: 'Fee Management',
        link: '/app/trns/fee-management',
      },
    ],
  },
  {
    id: 4,
    label: 'Position',
    link: '/app/home',
    icon: <TrackChangesIcon />,
    children: [
      { type: 'link', label: 'Position', link: '/app/position/position' },
      {
        type: 'link',
        label: 'Position Reconciliation',
        link: '/app/position/reconciliation-position',
      },
      {
        type: 'link',
        label: 'Stock Borrow',
        link: '/app/position/stock-borrow',
      },
      {
        type: 'link',
        label: 'Fractional Share Recon...',
        link: '/app/position/fractional-share-recon',
      },
      {
        type: 'link',
        label: 'Fractional Share PL',
        link: '/app/position/fractional-share-pl',
      },
      {
        type: 'link',
        label: 'Inventory Report',
        link: '/app/position/inventory-report',
      },
    ],
  },
  {
    id: 5,
    label: 'Security',
    link: '/app/home',
    icon: <SecurityIcon />,
    children: [
      {
        type: 'sub label',
        label: 'Security Details',
        link: '/app/security-details',
      },
      { type: 'link', label: 'Price', link: '/app/security-details/price' },
      {
        type: 'link',
        label: 'Security Master',
        link: '/app/security-details/security-master',
      },
      {
        type: 'link',
        label: 'ETF',
        link: '/app/security-details/etf',
      },
      {
        type: 'link',
        label: 'SLL',
        link: '/app/security-details/sll',
      },
      {
        type: 'sub label',
        label: 'Corp Action',
        link: '/app/security/corp-action',
      },
      {
        type: 'link',
        label: 'Announcement',
        link: '/app/corp-action/announcement',
      },
    ],
  },
  {
    id: 6,
    label: 'Report',
    link: '/app/home',
    icon: <BarChartIcon />,
    children: [
      {
        type: 'sub label',
        label: 'Report',
        link: '/app/report',
      },
      {
        type: 'link',
        label: 'Account Management',
        link: '/app/report/account-management',
      },
      {
        type: 'link',
        label: 'Profit and Loss',
        link: '/app/report/profit-and-loss',
      },
      {
        type: 'link',
        label: 'Monthly Statement',
        link: '/app/report/monthly-statement',
      },
      {
        type: 'link',
        label: 'Monthly Statement Disc...',
        link: '/app/report/statement-disclosure',
      },
      {
        type: 'link',
        label: 'Report Template',
        link: '/app/report/report-template',
      },
      {
        type: 'link',
        label: 'Daily Trade Confirm',
        link: '/app/report/daily-trade-confirm',
      },
      {
        type: 'link',
        label: 'Self Service Reporting',
        link: '/app/report/self-service-reporting',
      },
    ],
  },
  {
    id: 7,
    label: 'Tax',
    link: '/app/home',
    icon: <MonetizationOnIcon />,
    children: [
      {
        type: 'sub label',
        label: 'Rate',
        link: '/app/tax-rate',
      },
      {
        type: 'link',
        label: 'Tax Rate',
        link: '/app/tax-rate/tax-rate',
      },
      {
        type: 'sub label',
        label: 'Withholding',
        link: '/app/withholding',
      },
      {
        type: 'link',
        label: 'Dividend',
        link: '/app/withholding/dividend',
      },
    ],
  },
  {
    id: 8,
    label: 'Clearing',
    link: '/app/home',
    icon: <AccountBalance />,
    children: [
      {
        type: 'link',
        label: 'Trades',
        link: '/app/clearing/trades',
      },

      {
        type: 'link',
        label: 'Settlement',
        link: '/app/clearing/settlement',
      },
      {
        type: 'link',
        label: 'Permissions',
        link: '/app/clearing/permissions',
      },
    ],
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <div className={classes.sidebarList}>
        {structure.map((link) => (
          <Accordion
            key={link.id}
            expanded={expanded === link.label}
            onChange={handleChange(link.label)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={'panel' + link.id + 'bh-content'}
              id={'panel' + link.id + 'bh-header'}
            >
              {link.icon}{' '}
              <Typography
                className={classes.heading}
                style={{ marginLeft: 20 }}
              >
                {link.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {link.children.map((cs, key) => {
                var isLinkActive =
                  cs.link &&
                  (location.pathname === cs.link ||
                    location.pathname.indexOf(link) !== -1);

                return (
                  <List key={key} component="div" disablePadding>
                    <ListItem
                      button
                      onClick={(e) => {
                        if (cs.type !== 'link') {
                          e.preventDefault();
                        }
                      }}
                      component={cs.link && Link}
                      to={cs.link}
                      className={classes.link}
                      disableRipple
                      style={{ backgroundColor: 'transparent' }}
                    >
                      <ListItemIcon style={{ minWidth: 37 }}>
                        {cs.type === 'link' ? (
                          <Dot color={isLinkActive && 'primary'} />
                        ) : (
                          <Typography
                            variant="h6"
                            align="left"
                            className={classes.sublabel}
                          >
                            {cs.label}
                          </Typography>
                        )}
                      </ListItemIcon>
                      {cs.type === 'link' ? (
                        <ListItemText
                          className={classes.listItemLink}
                          primary={cs.label}
                        />
                      ) : null}
                    </ListItem>
                  </List>
                );
              })}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
