/*Service*/
import {
  ListFeeManagementRequest,
  CreateFeeManagementRequest,
  UpdateFeeManagementRequest,
  FeeManagement,
} from '../../../proto/feepb/management_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Checkbox,
  IconButton,
  Button,
  Box,
  TextField,
  Modal,
  Backdrop,
  Fade,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  CheckCircle as CheckCircleIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom component*/
import SearchButton from '../../../components/Button/Search';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import ContraAccountSelect from '../../../components/AutoComplete/ContraAccount';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Time Stamp Protobuf*/
const google_date = require('../../../google/type/date_pb.js');

function saveFeeManagement(
  feeManagement,
  rows,
  isAdd,
  closeModal,
  feeManagementId,
  correspondent,
  accountNo,
  masterAccountNo,
  fromDate,
  toDate,
  buy,
  sell,
  shortSell,
  calcType,
  feeRate,
  status,
  contraAccount,
  feeType
) {
  if (!correspondent && !accountNo && !masterAccountNo) {
    notifyError(
      'Enter either correspondent, account no., or master account no.'
    );
    return;
  }

  if (isAdd) {
    let req = new CreateFeeManagementRequest();
    req.setCorrespondent(correspondent);
    req.setAccountNo(accountNo);
    req.setMasterAccountNo(masterAccountNo);
    req.setBuy(buy);
    req.setSell(sell);
    req.setShortSell(shortSell);
    req.setCalcType(calcType);
    req.setFeeRate(feeRate);
    req.setStatus(status);
    req.setContraAccount(contraAccount);
    req.setFeeType(feeType);

    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      req.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      req.setToDate(gglToDate);
    }

    feeManagement.createFeeManagement(req, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      let data = res.toObject().feeManagement;

      let newData = {
        accountNo: data.accountNo,
        buy: data.buy,
        calcType: data.calcType,
        correspondent: data.correspondent,
        feeManagementId: data.feeManagementId,
        feeRate: formatCurrency(data.feeRate),
        fromDate: formatPbDate(data.fromDate),
        masterAccountNo: data.masterAccountNo,
        previousId: data.previousId,
        sell: data.sell,
        shortSell: data.shortSell,
        status: data.status,
        toDate: formatPbDate(data.toDate),
        contraAccount: data.contraAccount,
        feeType: data.feeType,
      };
      rows.push(newData);
      notifySuccess('Fee management has been added.');
      closeModal();
    });
  } else {
    //update
    let updatedFee = new FeeManagement();
    updatedFee.setFeeManagementId(feeManagementId);
    updatedFee.setCorrespondent(correspondent);
    updatedFee.setAccountNo(accountNo);
    updatedFee.setMasterAccountNo(masterAccountNo);
    updatedFee.setBuy(buy);
    updatedFee.setSell(sell);
    updatedFee.setShortSell(shortSell);
    updatedFee.setCalcType(calcType);
    updatedFee.setFeeRate(feeRate);
    updatedFee.setStatus(true);
    updatedFee.setContraAccount(contraAccount);
    updatedFee.setFeeType(feeType);

    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      updatedFee.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      updatedFee.setToDate(gglToDate);
    }

    let updateReq = new UpdateFeeManagementRequest();
    updateReq.setFeeManagement(updatedFee);
    feeManagement.updateFeeManagement(updateReq, {}, (err) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].feeManagementId === feeManagementId) {
          rows[i].correspondent = correspondent;
          rows[i].accountNo = accountNo;
          rows[i].masterAccountNo = masterAccountNo;
          rows[i].fromDate = moment(fromDate, 'YYYY-MM-DD').format(
            'MM/DD/YYYY'
          );
          rows[i].toDate = moment(toDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
          rows[i].buy = buy;
          rows[i].sell = sell;
          rows[i].shortSell = shortSell;
          rows[i].calcType = calcType;
          rows[i].feeRate = formatCurrency(feeRate);
          rows[i].status = status;
          rows[i].contraAccount = contraAccount;
          rows[i].feeType = feeType;
        }
      }
      notifySuccess('Fee management has been updated.');
      closeModal();
    });
  }
}

export default function FeeManagementTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    feeManagementServiceClient: feeManagement,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '') +
      (contraAccountValue ? '&contraAccount=' + contraAccountValue : '') +
      (statusValue ? '&status=' + statusValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListFeeManagementRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setMasterAccountNo(masterAccountNoValue);
    list.setStatus(statusValue);
    list.setContraAccount(contraAccountValue);

    feeManagement.listFeeManagement(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        return;
      }

      const rows = res.toObject().feeManagementsList.map((data) => ({
        fromDate: data.fromDate ? formatPbDate(data.fromDate) : '--',
        feeRate: formatCurrency(data.feeRate),
        toDate: data.toDate ? formatPbDate(data.toDate) : '--',
        feeManagementId: data.feeManagementId,
        previousId: data.previousId,
        correspondent: data.correspondent,
        accountNo: data.accountNo,
        masterAccountNo: data.masterAccountNo,
        buy: data.buy,
        sell: data.sell,
        shortSell: data.shortSell,
        status: data.status,
        calcType: data.calcType,
        contraAccount: data.contraAccount,
        feeType: data.feeType,
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Fee Management');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="Edit" arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[dataIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'buy',
      label: 'Buy',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div align="center">
              {rows[dataIndex].buy === true ? <CheckCircleIcon /> : null}
            </div>
          );
        },
      },
    },
    {
      name: 'calcType',
      label: 'Calc Type',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'feeRate',
      label: 'Fee Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'fromDate',
      label: 'From Date',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'sell',
      label: 'Sell',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div align="center">
              {rows[dataIndex].sell === true ? <CheckCircleIcon /> : null}
            </div>
          );
        },
      },
    },
    {
      name: 'shortSell',
      label: 'Short Sell',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div align="center">
              {rows[dataIndex].shortSell === true ? <CheckCircleIcon /> : null}
            </div>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div align="center">
              {rows[dataIndex].status === true ? <CheckCircleIcon /> : null}
            </div>
          );
        },
      },
    },
    {
      name: 'toDate',
      label: 'To Date',
    },
    {
      name: 'contraAccount',
      label: 'Contra Account',
    },
    {
      name: 'feeType',
      label: 'Fee Type',
    },
  ];

  const fileName =
    'FeeManagement_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 4, 1, 7, 6, 11, 2, 8, 9, 3, 5, 10, 12, 13],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Fee Management');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Fee Management');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState(true);
  const [contraAccountValue, setcontraAccountValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      contraAccount: query.get('contraAccount'),
      status: query.get('status'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.contraAccount) {
      setcontraAccountValue(data.contraAccount);
    }
    if (data.status) {
      setStatusValue(Boolean(data.status));
    }

    setCurrentPath(path);
  }, [params]);

  const [
    modalFeeManagementIDValue,
    setModalFeeManagementIDValue,
  ] = React.useState('');
  const [modalCorrespondentValue, setModalCorrespondentValue] = React.useState(
    ''
  );
  const [modalAccountNoValue, setModalAccountNoValue] = React.useState('');
  const [
    modalMasterAccountNoValue,
    setModalMasterAccountNoValue,
  ] = React.useState('');
  const [modalFromDateValue, setModalFromDateValue] = React.useState('');
  const [modalToDateValue, setModalToDateValue] = React.useState('');
  const [modalBuyValue, setModalBuyValue] = React.useState(false);
  const [modalSellValue, setModalSellValue] = React.useState(false);
  const [modalShortSellValue, setModalShortSellValue] = React.useState(false);
  const [modalFeeRateValue, setModalFeeRateValue] = React.useState('');
  const [modalCalcTypeValue, setModalCalcTypeValue] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState(true);
  const [modalContraAccountValue, setModalContraAccountValue] = React.useState(
    ''
  );
  const [modalFeeTypeValue, setModalFeeTypeValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalCorrespondentValue('');
      setModalAccountNoValue('');
      setModalMasterAccountNoValue('');
      setModalFromDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalToDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalBuyValue(false);
      setModalSellValue(false);
      setModalShortSellValue(false);
      setModalFeeRateValue('');
      setModalCalcTypeValue('');
      setModalStatusValue(true);
      setModalContraAccountValue('');
      setModalFeeTypeValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setModalFeeManagementIDValue(rowData.feeManagementId);
      setModalCorrespondentValue(rowData.correspondent);
      setModalAccountNoValue(rowData.accountNo);
      setModalMasterAccountNoValue(rowData.masterAccountNo);
      setModalFromDateValue(
        rowData.fromDate
          ? moment(new Date(rowData.fromDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalToDateValue(
        rowData.toDate
          ? moment(new Date(rowData.toDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalBuyValue(rowData.buy);
      setModalSellValue(rowData.sell);
      setModalShortSellValue(rowData.shortSell);
      setModalFeeRateValue(rowData.feeRate.replace('$', '').replace(',', ''));
      setModalCalcTypeValue(rowData.calcType);
      setModalStatusValue(rowData.status);
      setModalContraAccountValue(rowData.contraAccount);
      setModalFeeTypeValue(rowData.feeType);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <ContraAccountSelect
                  freeSolo={true}
                  name="contraAccount"
                  label="Contra Account"
                  value={contraAccountValue}
                  onChange={(e) => {
                    setcontraAccountValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={statusValue}
                      onChange={(e) => setStatusValue(e.target.checked)}
                    />
                  }
                  label="Status"
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Fee Managements'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        className={classes.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              className={classes.textBold}
              variant="h4"
              gutterBottom
            >
              {modalTitleValue} Fee Management
            </Typography>
            <Box mt={5}>
              <form className={classes.modalForm} noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <CorrespondentSelect
                      freeSolo={true}
                      required={true}
                      name="correspondent"
                      label="Correspondent"
                      value={modalCorrespondentValue}
                      onChange={(e) => {
                        setModalCorrespondentValue(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <AccountNoSelect
                      freeSolo={false}
                      name="accountNo"
                      label="Account No"
                      value={modalAccountNoValue}
                      onChange={(e) => {
                        setModalAccountNoValue(e.currentTarget.value);
                      }}
                      selectedCorrespondent={modalCorrespondentValue}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <MasterAccountNoSelect
                      freeSolo={false}
                      name="masterAccountNo"
                      label="Master Account Number"
                      value={modalMasterAccountNoValue}
                      onChange={(e) => {
                        setModalMasterAccountNoValue(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    {modalAddAction === true ? (
                      <TextField
                        fullWidth
                        label="From Date"
                        type="date"
                        value={modalFromDateValue}
                        onChange={(e) => setModalFromDateValue(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label="From Date"
                        type="date"
                        value={modalFromDateValue}
                        onChange={(e) => setModalFromDateValue(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      label="To Date"
                      type="date"
                      value={modalToDateValue}
                      onChange={(e) => setModalToDateValue(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: modalFromDateValue,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Calc Type</InputLabel>
                    <Select
                      fullWidth
                      value={modalCalcTypeValue}
                      onChange={(e) => setModalCalcTypeValue(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="amount">Amount</MenuItem>
                      <MenuItem value="flat_fee">Flat Fee</MenuItem>
                      <MenuItem value="qty">Qty</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Fee Type"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modalFeeTypeValue}
                      onChange={(e) => setModalFeeTypeValue(e.target.value)}
                    />
                  </div>

                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Fee Rate"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modalFeeRateValue}
                      onChange={(e) => setModalFeeRateValue(e.target.value)}
                    />
                  </div>

                  <div className={classes.grdCell1}>
                    <ContraAccountSelect
                      freeSolo={false}
                      name="contraAccount"
                      label="Contra Account"
                      value={modalContraAccountValue}
                      onChange={(e) => {
                        setModalContraAccountValue(e.currentTarget.value);
                      }}
                      selectedCorrespondent={''}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={modalBuyValue}
                          onChange={(e) => setModalBuyValue(e.target.checked)}
                        />
                      }
                      label="Buy"
                    />
                  </div>

                  <div className={classes.grdCell1} style={{ marginLeft: 30 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={modalSellValue}
                          onChange={(e) => setModalSellValue(e.target.checked)}
                        />
                      }
                      label="Sell"
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginLeft: 30 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={modalShortSellValue}
                          onChange={(e) =>
                            setModalShortSellValue(e.target.checked)
                          }
                        />
                      }
                      label="Short Sell"
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginLeft: 30 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={modalStatusValue}
                          onChange={(e) =>
                            setModalStatusValue(e.target.checked)
                          }
                        />
                      }
                      label="Active"
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveFeeManagement(
                          feeManagement,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalFeeManagementIDValue,
                          modalCorrespondentValue,
                          modalAccountNoValue,
                          modalMasterAccountNoValue,
                          modalFromDateValue,
                          modalToDateValue,
                          modalBuyValue,
                          modalSellValue,
                          modalShortSellValue,
                          modalCalcTypeValue,
                          modalFeeRateValue,
                          modalStatusValue,
                          modalContraAccountValue,
                          modalFeeTypeValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
