/**
 * @fileoverview gRPC-Web generated client stub for reorgpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: reorgpb/announcement.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.reorgpb = require('./announcement_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reorgpb.AnnouncementServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reorgpb.AnnouncementServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.GetAnnoucementRequest,
 *   !proto.reorgpb.GetAnnoucementResponse>}
 */
const methodDescriptor_AnnouncementService_GetAnnouncement = new grpc.web.MethodDescriptor(
  '/reorgpb.AnnouncementService/GetAnnouncement',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.GetAnnoucementRequest,
  proto.reorgpb.GetAnnoucementResponse,
  /**
   * @param {!proto.reorgpb.GetAnnoucementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.GetAnnoucementResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.GetAnnoucementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reorgpb.GetAnnoucementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.GetAnnoucementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.AnnouncementServiceClient.prototype.getAnnouncement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.AnnouncementService/GetAnnouncement',
      request,
      metadata || {},
      methodDescriptor_AnnouncementService_GetAnnouncement,
      callback);
};


/**
 * @param {!proto.reorgpb.GetAnnoucementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.GetAnnoucementResponse>}
 *     Promise that resolves to the response
 */
proto.reorgpb.AnnouncementServicePromiseClient.prototype.getAnnouncement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.AnnouncementService/GetAnnouncement',
      request,
      metadata || {},
      methodDescriptor_AnnouncementService_GetAnnouncement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.ListAnnouncementRequest,
 *   !proto.reorgpb.ListAnnouncementResponse>}
 */
const methodDescriptor_AnnouncementService_ListAnnouncement = new grpc.web.MethodDescriptor(
  '/reorgpb.AnnouncementService/ListAnnouncement',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.ListAnnouncementRequest,
  proto.reorgpb.ListAnnouncementResponse,
  /**
   * @param {!proto.reorgpb.ListAnnouncementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.ListAnnouncementResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.ListAnnouncementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reorgpb.ListAnnouncementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.ListAnnouncementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.AnnouncementServiceClient.prototype.listAnnouncement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.AnnouncementService/ListAnnouncement',
      request,
      metadata || {},
      methodDescriptor_AnnouncementService_ListAnnouncement,
      callback);
};


/**
 * @param {!proto.reorgpb.ListAnnouncementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.ListAnnouncementResponse>}
 *     Promise that resolves to the response
 */
proto.reorgpb.AnnouncementServicePromiseClient.prototype.listAnnouncement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.AnnouncementService/ListAnnouncement',
      request,
      metadata || {},
      methodDescriptor_AnnouncementService_ListAnnouncement);
};


module.exports = proto.reorgpb;

