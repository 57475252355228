/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListRequirementDetailRequest } from '../../proto/marginpb/requirement_grpc_web_pb';
import { TableSettings } from '../../proto/admpb/tablesettings_grpc_web_pb';

/*Styles*/
import tableTheme from '../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../styles';

/*Material UI Components*/
import { Box, Tooltip, IconButton } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Toast Notification*/
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from 'components/Notification/Notification';

/*Formatter*/
/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency, formatPercentage } from 'lib/fmt';

import CustomTableBodyFooter from '../../components/Table/CustomTableBodyFooter';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../google/type/date_pb.js');

export default function MarginRequirementDetails(props) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const {
    requirementServiceClient: marginRequirement,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const tradeDate = props.match.params.tradeDate;
    const masterAccountNo = props.match.params.masterAccountNo;

    let list = new ListRequirementDetailRequest();

    let gglTradeDate = new google_date.Date();
    let [y, m, d] = tradeDate.split('-');
    gglTradeDate.setYear(y);
    gglTradeDate.setMonth(m);
    gglTradeDate.setDay(d);

    list.setTradeDate(gglTradeDate);
    list.setMasterAccountNo(masterAccountNo);

    marginRequirement.listRequirementDetail(list, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }
      const rows = res.toObject().requirementDetailsList.map((data) => ({
        accountId: data.accountId,
        accountName: data.accountName,
        closingPrice: formatCurrency(data.closingPrice),
        correspondent: data.correspondent,
        cusip: data.cusip,
        exchangeRate: formatPercentage(Number(data.exchangeRate).toFixed(2)),
        exchangeReq: formatCurrency(data.exchangeReq),
        fedRate: formatPercentage(Number(data.fedRate).toFixed(2)),
        fedReq: formatCurrency(data.fedReq),
        houseRate: formatPercentage(Number(data.houseRate).toFixed(2)),
        houseReq: formatCurrency(data.houseReq),
        leveragedFactor: data.leveragedFactor,
        longMarketValue: formatCurrency(data.longMarketValue),
        longQty: data.longQty,
        marginType: data.marginType,
        marginable: data.marginable,
        marketValue: formatCurrency(data.marketValue),
        masterAccountNo: data.masterAccountNo,
        originalCusip: data.originalCusip,
        previousMarketValue: formatCurrency(data.previousMarketValue),
        previousQty: data.previousQty,
        qty: data.qty,
        shortMarketValue: formatCurrency(data.shortMarketValue),
        shortQty: data.shortQty,
        symbol: data.symbol,
        symbolDescription: data.symbolDescription,
        tradeDate: formatPbDate(data.tradeDate),
        tdCostBasis: formatCurrency(data.tdCostBasis),
      }));
      console.log(rows);
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
    });
  }, [
    marginRequirement,
    props.match.params.tradeDate,
    props.match.params.masterAccountNo,
  ]);

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Margin Requirement Details');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'marginType',
      label: 'Margin Type',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'marginable',
      label: 'Marginable',
    },
    {
      name: 'leveragedFactor',
      label: 'Leveraged Factor',
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'longQty',
      label: 'Long Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'shortQty',
      label: 'Short Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'previousQty',
      label: 'Previous Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'longMarketValue',
      label: 'Long Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'shortMarketValue',
      label: 'Short Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'previousMarketValue',
      label: 'Previous Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'exchangeReq',
      label: 'Exchange Req',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'exchangeRate',
      label: 'Exchange Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'houseReq',
      label: 'House Req',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'houseRate',
      label: 'House Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'fedReq',
      label: 'Fed Req',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'fedRate',
      label: 'Fed Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdCostBasis',
      label: 'TD Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
  ];

  const fileName =
    'MarginRequirementDetails_' +
    moment().format('MMMM Do YYYY, h:mm:ss a') +
    '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    selectableRows: 'none',
    columnOrder: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
    ],
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Margin Requirement Details');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Margin Requirement Details');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={[
            'longMarketValue',
            'shortMarketValue',
            'marketValue',
            'previousMarketValue',
            'exchangeReq',
            'fedReq',
            'houseReq',
            'tdCostBasis',
          ]}
          columnsWithQty={['qyt', 'previousQty']}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <Box component="div">
          {rows && (
            <CSVLink
              id="csvDL"
              data={rows}
              filename={fileName}
              target="_blank"
            />
          )}
          <MuiThemeProvider theme={tableTheme()}>
            <MUIDataTable
              title={'Margin Requirement Details'}
              data={rows}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Box>
      </div>
    </React.Fragment>
  );
}
