// source: feepb/interest.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.feepb.Interest', null, global);
goog.exportSymbol('proto.feepb.ListInterestRequest', null, global);
goog.exportSymbol('proto.feepb.ListInterestResponse', null, global);
goog.exportSymbol('proto.feepb.StreamInterestRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.Interest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.Interest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.Interest.displayName = 'proto.feepb.Interest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListInterestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.ListInterestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListInterestRequest.displayName = 'proto.feepb.ListInterestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.StreamInterestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.StreamInterestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.StreamInterestRequest.displayName = 'proto.feepb.StreamInterestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListInterestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.feepb.ListInterestResponse.repeatedFields_, null);
};
goog.inherits(proto.feepb.ListInterestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListInterestResponse.displayName = 'proto.feepb.ListInterestResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.Interest.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.Interest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.Interest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.Interest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
accountType: jspb.Message.getFieldWithDefault(msg, 7, ""),
settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
sdCashBalance: jspb.Message.getFieldWithDefault(msg, 9, ""),
rate: jspb.Message.getFieldWithDefault(msg, 10, ""),
interest: jspb.Message.getFieldWithDefault(msg, 11, ""),
status: jspb.Message.getFieldWithDefault(msg, 12, ""),
markUpdnRate: jspb.Message.getFieldWithDefault(msg, 13, ""),
markUpdnValue: jspb.Message.getFieldWithDefault(msg, 14, ""),
accruedInterest: jspb.Message.getFieldWithDefault(msg, 15, ""),
interestType: jspb.Message.getFieldWithDefault(msg, 16, ""),
sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.Interest}
 */
proto.feepb.Interest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.Interest;
  return proto.feepb.Interest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.Interest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.Interest}
 */
proto.feepb.Interest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterest(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkUpdnRate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkUpdnValue(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccruedInterest(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.Interest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.Interest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.Interest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.Interest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInterest();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMarkUpdnRate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMarkUpdnValue();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAccruedInterest();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getInterestType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.feepb.Interest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.feepb.Interest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.feepb.Interest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.feepb.Interest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.feepb.Interest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.feepb.Interest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_type = 7;
 * @return {string}
 */
proto.feepb.Interest.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date settle_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.feepb.Interest.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.Interest} returns this
*/
proto.feepb.Interest.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.Interest.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string sd_cash_balance = 9;
 * @return {string}
 */
proto.feepb.Interest.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string rate = 10;
 * @return {string}
 */
proto.feepb.Interest.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string interest = 11;
 * @return {string}
 */
proto.feepb.Interest.prototype.getInterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setInterest = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.feepb.Interest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string mark_updn_rate = 13;
 * @return {string}
 */
proto.feepb.Interest.prototype.getMarkUpdnRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setMarkUpdnRate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string mark_updn_value = 14;
 * @return {string}
 */
proto.feepb.Interest.prototype.getMarkUpdnValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setMarkUpdnValue = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string accrued_interest = 15;
 * @return {string}
 */
proto.feepb.Interest.prototype.getAccruedInterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setAccruedInterest = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string interest_type = 16;
 * @return {string}
 */
proto.feepb.Interest.prototype.getInterestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setInterestType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string sd_adjusted_balance = 17;
 * @return {string}
 */
proto.feepb.Interest.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.Interest} returns this
 */
proto.feepb.Interest.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.ListInterestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.ListInterestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.ListInterestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
interestType: jspb.Message.getFieldWithDefault(msg, 5, ""),
since: (f = msg.getSince()) && google_type_date_pb.Date.toObject(includeInstance, f),
until: (f = msg.getUntil()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListInterestRequest}
 */
proto.feepb.ListInterestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListInterestRequest;
  return proto.feepb.ListInterestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListInterestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListInterestRequest}
 */
proto.feepb.ListInterestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestType(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSince(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListInterestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListInterestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListInterestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterestType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSince();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.feepb.ListInterestRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRequest} returns this
 */
proto.feepb.ListInterestRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.feepb.ListInterestRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRequest} returns this
 */
proto.feepb.ListInterestRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.feepb.ListInterestRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRequest} returns this
 */
proto.feepb.ListInterestRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string interest_type = 5;
 * @return {string}
 */
proto.feepb.ListInterestRequest.prototype.getInterestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRequest} returns this
 */
proto.feepb.ListInterestRequest.prototype.setInterestType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.type.Date since = 6;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListInterestRequest.prototype.getSince = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListInterestRequest} returns this
*/
proto.feepb.ListInterestRequest.prototype.setSince = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListInterestRequest} returns this
 */
proto.feepb.ListInterestRequest.prototype.clearSince = function() {
  return this.setSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListInterestRequest.prototype.hasSince = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.type.Date until = 7;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListInterestRequest.prototype.getUntil = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListInterestRequest} returns this
*/
proto.feepb.ListInterestRequest.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListInterestRequest} returns this
 */
proto.feepb.ListInterestRequest.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListInterestRequest.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.StreamInterestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.StreamInterestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.StreamInterestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.StreamInterestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
interestType: jspb.Message.getFieldWithDefault(msg, 5, ""),
since: (f = msg.getSince()) && google_type_date_pb.Date.toObject(includeInstance, f),
until: (f = msg.getUntil()) && google_type_date_pb.Date.toObject(includeInstance, f),
distinct: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.StreamInterestRequest}
 */
proto.feepb.StreamInterestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.StreamInterestRequest;
  return proto.feepb.StreamInterestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.StreamInterestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.StreamInterestRequest}
 */
proto.feepb.StreamInterestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestType(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSince(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDistinct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.StreamInterestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.StreamInterestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.StreamInterestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.StreamInterestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterestType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSince();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDistinct();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.feepb.StreamInterestRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.feepb.StreamInterestRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.feepb.StreamInterestRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string interest_type = 5;
 * @return {string}
 */
proto.feepb.StreamInterestRequest.prototype.getInterestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.setInterestType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.type.Date since = 6;
 * @return {?proto.google.type.Date}
 */
proto.feepb.StreamInterestRequest.prototype.getSince = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
*/
proto.feepb.StreamInterestRequest.prototype.setSince = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.clearSince = function() {
  return this.setSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.StreamInterestRequest.prototype.hasSince = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.type.Date until = 7;
 * @return {?proto.google.type.Date}
 */
proto.feepb.StreamInterestRequest.prototype.getUntil = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
*/
proto.feepb.StreamInterestRequest.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.StreamInterestRequest.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool distinct = 8;
 * @return {boolean}
 */
proto.feepb.StreamInterestRequest.prototype.getDistinct = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.feepb.StreamInterestRequest} returns this
 */
proto.feepb.StreamInterestRequest.prototype.setDistinct = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.feepb.ListInterestResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.ListInterestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.ListInterestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.ListInterestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
interestsList: jspb.Message.toObjectList(msg.getInterestsList(),
    proto.feepb.Interest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListInterestResponse}
 */
proto.feepb.ListInterestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListInterestResponse;
  return proto.feepb.ListInterestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListInterestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListInterestResponse}
 */
proto.feepb.ListInterestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.feepb.Interest;
      reader.readMessage(value,proto.feepb.Interest.deserializeBinaryFromReader);
      msg.addInterests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListInterestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListInterestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListInterestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.feepb.Interest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Interest interests = 1;
 * @return {!Array<!proto.feepb.Interest>}
 */
proto.feepb.ListInterestResponse.prototype.getInterestsList = function() {
  return /** @type{!Array<!proto.feepb.Interest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.feepb.Interest, 1));
};


/**
 * @param {!Array<!proto.feepb.Interest>} value
 * @return {!proto.feepb.ListInterestResponse} returns this
*/
proto.feepb.ListInterestResponse.prototype.setInterestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.feepb.Interest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.feepb.Interest}
 */
proto.feepb.ListInterestResponse.prototype.addInterests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.feepb.Interest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.feepb.ListInterestResponse} returns this
 */
proto.feepb.ListInterestResponse.prototype.clearInterestsList = function() {
  return this.setInterestsList([]);
};


goog.object.extend(exports, proto.feepb);
