/*ReactJS*/
import React, { useState, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { OutputPosition } from '../../../proto/acatspb/outputposition_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import { Box, Tooltip, IconButton } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

export default function OutputPositionTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    tableSettingsServiceClient: tableSettings,
    outputPositionServiceClient: positionClient,
  } = useContext(ServiceContext);

  const search = () => {
    setLoading(true);

    let req = new OutputPosition();

    positionClient.listOutputPosition(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().outputPositionList.map((data) => ({
        recordType: data.recordType,
        subrecordType: data.subrecordType,
        distributionSide: data.distributionSide,
        acatsControlNumber: data.acatsControlNumber,
        assetSequenceNumber: data.assetSequenceNumber,
        processingDate: data.processingDate,
        distributionParticipant: data.distributionParticipant,
        status: data.status,
        daysInStatus: data.daysInStatus,
        registrationIndicator: data.registrationIndicator,
        settlementDate: formatPbDate(data.settlementDate),
        settlementTiming: data.settlementTiming,
        valueFreeIndicator: data.valueFreeIndicator,
        debitCreditIndicator: data.debitCreditIndicator,
        atAcatsControlNumber: data.atAcatsControlNumber,
        tiAcatsControlNumber: data.tiAcatsControlNumber,
        atTransferType: data.atTransferType,
        tiTransferType: data.tiTransferType,
        tiOriginalReceiverNumber: data.tiOriginalReceiverNumber,
        atOriginalReceiverNumber: data.atOriginalReceiverNumber,
        tiOriginalDelivererNumber: data.tiOriginalDelivererNumber,
        atOriginalDelivererNumber: data.atOriginalDelivererNumber,
        originalReceiverCustomerAccountNumber:
          data.originalReceiverCustomerAccountNumber,
        originalReceiverCustomerAccountName:
          data.originalReceiverCustomerAccountName,
        originalReceiverTaxIdPrimary: data.originalReceiverTaxIdPrimary,
        originalReceiverTaxIdSecondary: data.originalReceiverTaxIdSecondary,
        originalReceiverCustomerAccountType:
          data.originalReceiverCustomerAccountType,
        giftDonationIndicator: data.giftDonationIndicator,
        originalReceiverOccBrokerNumber: data.originalReceiverOccBrokerNumber,
        originalReceiverCorrespondent: data.originalReceiverCorrespondent,
        contraParticipantType: data.contraParticipantType,
        originalDelivererCustomerAccountNumber:
          data.originalDelivererCustomerAccountNumber,
        transferTypeReject: data.transferTypeReject,
        associatedAcatsControlNumber: data.associatedAcatsControlNumber,
        associatedSettlementDate: data.associatedSettlementDate,
        tiTransactionReferenceId: data.tiTransactionReferenceId,
        atTransactionReferenceId: data.atTransactionReferenceId,
        atAssetSequenceNumber: data.atAssetSequenceNumber,
        assetCategory: data.assetCategory,
        optionCategory: data.optionCategory,
        settlingLocation: data.settlingLocation,
        settlingLocationReason: data.settlingLocationReason,
        isinCountryCode: data.isinCountryCode,
        isinSecurityIssueId: data.isinSecurityIssueId,
        isinSecurityCheckDigit: data.isinSecurityCheckDigit,
        dtcSubIssueType: data.dtcSubIssueType,
        foreignSecurityTradeDate: data.foreignSecurityTradeDate,
        assetDescription1: data.assetDescription1,
        assetDescription2: data.assetDescription2,
        assetQuantityRequestIndicator: data.assetQuantityRequestIndicator,
        assetRequestTransactionCode: data.assetRequestTransactionCode,
        assetQuantity: data.assetQuantity,
        assetPercent: data.assetPercent,
        positionCode: data.positionCode,
        isoCurrencyCode: data.isoCurrencyCode,
        assetAmount: data.assetAmount,
        bearerBond: data.bearerBond,
        cashMarginShortCode: data.cashMarginShortCode,
        whenIssuedIndicator: data.whenIssuedIndicator,
        transferTypeReason: data.transferTypeReason,
        mbsSerialNote: data.mbsSerialNote,
        assetComment1: data.assetComment1,
        assetComment2: data.assetComment2,
        comments1: data.comments1,
        comments2: data.comments2,
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Output Position');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'recordType',
      label: 'Record Type',
    },
    {
      name: 'subrecordType',
      label: 'Subrecord Type',
    },
    {
      name: 'distributionSide',
      label: 'Distribution Side',
    },
    {
      name: 'acatsControlNumber',
      label: 'ACATS Control Number',
    },
    {
      name: 'assetSequenceNumber',
      label: 'Asset Seequence Number',
    },
    {
      name: 'processingDate',
      label: 'Processing Date',
    },
    {
      name: 'distributionParticipant',
      label: 'Distribution Participant',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'daysInStatus',
      label: 'Days In Status',
    },
    {
      name: 'registrationIndicator',
      label: 'Registration Indicator',
    },
    {
      name: 'settlementDate',
      label: 'Settlement Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settlementDate
            ? rows[dataIndex].settlementDate
            : '--';
        },
      },
    },
    {
      name: 'settlementTiming',
      label: 'Settlement Timing',
    },
    {
      name: 'valueFreeIndicator',
      label: 'Value/Free Indicator',
    },
    {
      name: 'debitCreditIndicator',
      label: 'Debit/Credit Indicator',
    },
    {
      name: 'tiAcatsControlNumber',
      label: 'TI ACATS Control Number',
    },
    {
      name: 'atAcatsControlNumber',
      label: 'AT ACATS Control Number',
    },
    {
      name: 'tiTransferType',
      label: 'TI Transfer Type',
    },
    {
      name: 'atTransferType',
      label: 'AT Transfer Type',
    },
    {
      name: 'tiOriginalReceiverNumber',
      label: 'TI Original Receiver Number',
    },
    {
      name: 'atOriginalReceiverNumber',
      label: 'AT Original Receiver Number',
    },
    {
      name: 'tiOriginalDelivererNumber',
      label: 'TI Original Deliverer Number',
    },
    {
      name: 'atOriginalDelivererNumber',
      label: 'AT Original Deliverer Number',
    },
    {
      name: 'originalReceiverCustomerAccountNumber',
      label: 'Original Receiver Customer Account Number',
    },
    {
      name: 'originalReceiverCustomerAccountName',
      label: 'Original Receiver Customer Account Name',
    },
    {
      name: 'originalReceiverTaxIdPrimary',
      label: 'Original Receiver Tax Id Primary',
    },
    {
      name: 'originalReceiverTaxIdSecondary',
      label: 'Original Receiver Tax Id Secondary',
    },
    {
      name: 'originalReceiverCustomerAccountType',
      label: 'Original Receiver Customer Account Type',
    },
    {
      name: 'giftDonationIndicator',
      label: 'Gift Donation Indicator',
    },
    {
      name: 'originalReceiverOccBrokerNumber',
      label: 'Original Receiver Occ Broker Number',
    },
    {
      name: 'originalReceiverCorrespondent',
      label: 'Original Receiver Correspondent',
    },
    {
      name: 'contraParticipantType',
      label: 'Contra Participant Type',
    },
    {
      name: 'originalDelivererCustomerAccountNumber',
      label: 'Original Deliverer Customer Account Number',
    },
    {
      name: 'transferTypeReject',
      label: 'Transfer Type Reject',
    },
    {
      name: 'associatedAcatsControlNumber',
      label: 'Associated Acats Control Number',
    },
    {
      name: 'associatedSettlementDate',
      label: 'Associated Settlement Date',
    },
    {
      name: 'tiTransactionReferenceId',
      label: 'TI Transaction Reference Id',
    },
    {
      name: 'atTransactionReferenceId',
      label: 'AT Transaction Reference Id',
    },
    {
      name: 'atAssetSequenceNumber',
      label: 'AT Asset Sequence Number',
    },
    {
      name: 'assetCategory',
      label: 'Asset Category',
    },
    {
      name: 'optionCategory',
      label: 'Option Category',
    },
    {
      name: 'settlingLocation',
      label: 'Settling Location',
    },
    {
      name: 'settlingLocationReason',
      label: 'Settling Location Reason',
    },
    {
      name: 'isinCountryCode',
      label: 'ISIN Country Code',
    },
    {
      name: 'isinSecurityIssueId',
      label: 'ISIN Security Issue Id',
    },
    {
      name: 'isinSecurityCheckDigit',
      label: 'ISIN Security Check Digit',
    },
    {
      name: 'dtcSubIssueType',
      label: 'DTC Sub Issue Type',
    },
    {
      name: 'foreignSecurityTradeDate',
      label: 'Foreign Security Trade Date',
    },
    {
      name: 'assetDescription1',
      label: 'assetDescription1',
    },
    {
      name: 'assetDescription2',
      label: 'assetDescription2',
    },
    {
      name: 'assetQuantityRequestIndicator',
      label: 'Asset Quantity Request Indicator',
    },
    {
      name: 'assetRequestTransactionCode',
      label: 'Asset Request Transaction Code',
    },
    {
      name: 'assetQuantity',
      label: 'Asset Quantity',
    },
    {
      name: 'assetPercent',
      label: 'Asset Percent',
    },
    {
      name: 'positionCode',
      label: 'Position Code',
    },
    {
      name: 'isoCurrencyCode',
      label: 'ISO Currency Code',
    },
    {
      name: 'assetAmount',
      label: 'Asset Amount',
    },
    {
      name: 'bearerBond',
      label: 'Bearer Bond',
    },
    {
      name: 'cashMarginShortCode',
      label: 'Cash Margin Short Code',
    },
    {
      name: 'whenIssuedIndicator',
      label: 'When Issued Indicator',
    },
    {
      name: 'transferTypeReason',
      label: 'Transfer Type Reason',
    },
    {
      name: 'mbsSerialNote',
      label: 'MBS Serial Note',
    },
    {
      name: 'assetComment1',
      label: 'Asset Comment1',
    },
    {
      name: 'assetComment2',
      label: 'Asset Comment2',
    },
    {
      name: 'comments1',
      label: 'Comments1',
    },
    {
      name: 'comments2',
      label: 'Comments2',
    },
  ];

  const fileName =
    'OutputPosition_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
      64,
    ],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Output Position');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Output Position');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [, setShowFilter] = React.useState(true);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Output Position'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
