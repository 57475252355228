import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import Themes from './themes';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';
import { ConfirmProvider } from 'material-ui-confirm';
import { ServiceProvider } from './context/ServiceContext';
import { ProfileProvider } from 'context/ProfileContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { init as initSentry } from './sentry';

initSentry();

const queryClient = new QueryClient();

ReactDOM.render(
  <LayoutProvider>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ServiceProvider>
          <ConfirmProvider>
            <ProfileProvider>
              <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </ProfileProvider>
          </ConfirmProvider>
        </ServiceProvider>
      </UserProvider>
    </QueryClientProvider>
  </LayoutProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
