/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListGLBalanceRequest } from '../../../proto/reportpb/glbalance_grpc_web_pb';

/*Toast Notification*/
import { notifyInfo, notifyError } from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import useStyles from '../../../styles';

/*Icons*/
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useProfile } from 'context/ProfileContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

/*=========================================================================================
Show/hide zero value function
===========================================================================================*/
function showHideZeroValues(rows, origRows, setRows, show, setIconState) {
  if (show) {
    setIconState(false);

    const filteredRows = rows.filter((data) => {
      return (
        data.beginningBalance !== '$0.00' ||
        data.activityAmount !== '$0.00' ||
        data.endingBalance !== '$0.00'
      );
    });
    setRows(filteredRows);
  } else {
    setIconState(true);

    setRows(origRows);
  }
}
/*=========================================================================================
End of show/hide zero value function
===========================================================================================*/

export default function GLReportTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { gLBalanceServiceClient: glBalance } = useContext(ServiceContext);

  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountNo:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          accountName:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          systemDate:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          beginningBalance:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          activityAmount:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          endingBalance:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (
                key === 'activityAmount' ||
                key === 'beginningBalance' ||
                key === 'endingBalance'
              ) {
                item[key] = item[key].includes('$')
                  ? item[key]
                  : formatCurrency(item[key]);
              }
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      const rows = pageData.map((data) => ({
        accountId: data.accountId,
        accountName: data.accountName,
        accountNo: data.accountNo,
        activityAmount: data.activityAmount.includes('$')
          ? data.activityAmount
          : formatCurrency(data.activityAmount),
        beginningBalance: data.beginningBalance.includes('$')
          ? data.beginningBalance
          : formatCurrency(data.beginningBalance),
        endingBalance: data.endingBalance.includes('$')
          ? data.endingBalance
          : formatCurrency(data.endingBalance),
        systemDate:
          typeof data.systemDate === 'object'
            ? formatPbDate(data.systemDate)
            : data.systemDate,
        glActivitiesList: data.glActivitiesList,
      }));

      setRows(rows);
      setOrigRows(rows);

      setDownloadableRows(
        fullData.map((data) => ({
          accountId: data.accountId,
          accountName: data.accountName,
          accountNo: data.accountNo,
          activityAmount: data.activityAmount.includes('$')
            ? data.activityAmount
            : formatCurrency(data.activityAmount),
          beginningBalance: data.beginningBalance.includes('$')
            ? data.beginningBalance
            : formatCurrency(data.beginningBalance),
          endingBalance: data.endingBalance.includes('$')
            ? data.endingBalance
            : formatCurrency(data.endingBalance),
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          glActivitiesList: data.glActivitiesList,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**Search GL Report function ==================================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (fromDateValue ? '&fromDate=' + fromDateValue : '') +
        (toDateValue ? '&toDate=' + toDateValue : '') +
        (correspondentValue ? '&correspondent=' + correspondentValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (masterAccountNoValue
          ? '&masterAccountNo=' + masterAccountNoValue
          : '') +
        (accountNameValue ? '&accountName=' + accountNameValue : '') +
        (accountDesignatorValue
          ? '&accountDesignator=' + accountDesignatorValue
          : '') +
        (typeValue ? '&type=' + typeValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      if (!fromDateValue) {
        notifyError('From date is required.');
        setLoading(false);
        return;
      }

      if (!toDateValue) {
        notifyError('To date is required.');
        setLoading(false);
        return;
      }

      let list = new ListGLBalanceRequest();
      list.setCorrespondent(correspondentValue);
      list.setAccountNo(accountNoValue);
      list.setAccountName(accountNameValue);
      list.setMasterAccountNo(masterAccountNoValue);
      list.setAccountDesignator(accountDesignatorValue);
      list.setType(typeValue);
      list.setDateType(dateTypeValue);

      if (fromDateValue) {
        let gglFromDate = new google_date.Date();
        let [y, m, d] = fromDateValue.split('-');
        gglFromDate.setYear(y);
        gglFromDate.setMonth(m);
        gglFromDate.setDay(d);

        list.setFromDate(gglFromDate);
      }
      if (toDateValue) {
        let gglToDate = new google_date.Date();
        let [y, m, d] = toDateValue.split('-');
        gglToDate.setYear(y);
        gglToDate.setMonth(m);
        gglToDate.setDay(d);

        list.setToDate(gglToDate);
      }

      glBalance.listGLBalance(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().glBalancesList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**Search GL Report function end ==============================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '2',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
    },
    {
      id: '3',
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
    },
    {
      id: '4',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(true),
    },
    {
      id: '5',
      name: 'beginningBalance',
      label: 'Beginning Balance',
      visibleState: useState(true),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return parseFloat(value.replace('$', '').replace(',', '')) < 0 ? (
            <TableCell style={{ color: '#f44336' }}>{value}</TableCell>
          ) : (
            <TableCell>{value}</TableCell>
          );
        },
      },
    },
    {
      id: '6',
      name: 'activityAmount',
      label: 'Activity Amount',
      visibleState: useState(true),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return parseFloat(value.replace('$', '').replace(',', '')) < 0 ? (
            <TableCell style={{ color: '#f44336' }}>{value}</TableCell>
          ) : (
            <TableCell>{value}</TableCell>
          );
        },
      },
    },
    {
      id: '7',
      name: 'endingBalance',
      label: 'Ending Balance',
      visibleState: useState(true),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return parseFloat(value.replace('$', '').replace(',', '')) < 0 ? (
            <TableCell style={{ color: '#f44336' }}>{value}</TableCell>
          ) : (
            <TableCell>{value}</TableCell>
          );
        },
      },
    },
  ];

  const subColumns = [
    {
      id: '2',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
    },
    {
      id: '3',
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
    },
    {
      id: '4',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(true),
    },
    {
      id: '5',
      name: 'entryType',
      label: 'Entry Type',
      visibleState: useState(true),
    },
    {
      id: '6',
      name: 'externalId',
      label: 'External ID',
      visibleState: useState(true),
    },
    {
      id: '7',
      name: 'vendor',
      label: 'Vendor',
      visibleState: useState(true),
    },
    {
      id: '8',
      name: 'description',
      label: 'Description',
      visibleState: useState(true),
    },
    {
      id: '9',
      name: 'contraAccountName',
      label: 'Contra Account Name',
      visibleState: useState(true),
    },
    {
      id: '10',
      name: 'debit',
      label: 'Debit',
      visibleState: useState(true),
    },
    {
      id: '11',
      name: 'credit',
      label: 'Credit',
      visibleState: useState(true),
    },
    {
      id: '12',
      name: 'amount',
      label: 'Amount',
      visibleState: useState(true),
    },
    {
      id: '13',
      name: 'balance',
      label: 'Balance',
      visibleState: useState(true),
    },
  ];

  const fileName =
    'GLReport_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    downloadOptions: {
      filename: fileName,
    },
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    selectableRows: 'none',
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const subRows = rows[rowMeta.rowIndex].glActivitiesList.map((data) => ({
        accountId: data.accountId,
        accountName: data.accountName,
        accountNo: data.accountNo,
        amount: data.amount,
        balance: data.balance,
        contraAccountName: data.contraAccountName,
        credit: data.credit.replace('-', ''),
        debit: data.debit.replace('-', ''),
        description: data.description,
        entryType: data.entryType,
        externalId: data.externalId,
        systemDate: formatPbDate(data.systemDate),
        vendor: data.vendor,
      }));

      return (
        <TableRow key={rowMeta.rowIndex}>
          <TableCell colSpan={7} style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow style={{ background: '#8698ff' }}>
                  {subColumns.map((col) => (
                    <TableCell
                      style={{
                        height: 20,
                        borderBottom: 'none',
                        fontWeight: 'bold',
                        color: '#ffffff',
                      }}
                      align={col.align || 'left'}
                      key={col.name}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
                {subRows.map((row, index) => (
                  <TableRow key={index} style={{ background: '#f3f5f6' }}>
                    <TableCell>{row.accountNo}</TableCell>
                    <TableCell>{row.accountName}</TableCell>
                    <TableCell>{row.systemDate}</TableCell>
                    <TableCell>{row.entryType}</TableCell>
                    <TableCell>{row.externalId}</TableCell>
                    <TableCell>{row.vendor}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.contraAccountName}</TableCell>
                    {parseFloat(row.credit) < 0 ? (
                      <TableCell style={{ color: '#f44336' }}>
                        {formatCurrency(row.credit)}
                      </TableCell>
                    ) : (
                      <TableCell>{formatCurrency(row.credit)}</TableCell>
                    )}
                    {parseFloat(row.debit) < 0 ? (
                      <TableCell style={{ color: '#f44336' }}>
                        {formatCurrency(row.debit)}
                      </TableCell>
                    ) : (
                      <TableCell>{formatCurrency(row.debit)}</TableCell>
                    )}
                    {parseFloat(row.amount) < 0 ? (
                      <TableCell style={{ color: '#f44336' }}>
                        {formatCurrency(row.amount)}
                      </TableCell>
                    ) : (
                      <TableCell>{formatCurrency(row.amount)}</TableCell>
                    )}
                    {parseFloat(row.balance) < 0 ? (
                      <TableCell style={{ color: '#f44336' }}>
                        {formatCurrency(row.balance)}
                      </TableCell>
                    ) : (
                      <TableCell>{formatCurrency(row.balance)}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    },
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const profile = useProfile();
  const [origRows, setOrigRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [dateTypeValue, setDateTypeValue] = useState('settle_date');
  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [typeValue, setTypeValue] = React.useState('');
  const [iconState, setIconState] = React.useState(true);
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      type: query.get('type'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    } else {
      setFromDateValue(profile.systemDate.format('yyyy-MM-DD'));
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    } else {
      setToDateValue(profile.systemDate.format('yyyy-MM-DD'));
    }

    setCurrentPath(path);
  }, [profile.systemDate, params]);

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => {
                    setDateTypeValue(e.target.value);
                  }}
                >
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Account Designator</InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!rows.length ? true : false}
              startIcon={iconState ? <VisibilityIcon /> : <VisibilityOffIcon />}
              onClick={() => {
                showHideZeroValues(
                  rows,
                  origRows,
                  setRows,
                  iconState,
                  setIconState
                );
              }}
            >
              {iconState ? 'Hide' : 'Show'} Zero Values
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={tableTheme()}>
            <MUIDataTable
              title={'GL Report'}
              data={rows}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Paper>
      </Box>
    </div>
  );
}
