/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListFractionalSharePLRequest } from '../../../proto/reportpb/fractionalsharepl_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { Box, TextField, IconButton, Tooltip } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function FractionalSharePLTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    fractionalSharePLServiceClient: fractionalSharePL,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (fromDateValue ? '&fromSystemDate=' + fromDateValue : '') +
      (toDateValue ? '&toSystemDate=' + toDateValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListFractionalSharePLRequest();
    list.setSymbol(symbolValue);
    list.setAccountNo(accountNoValue);
    if (fromDateValue) {
      let gglFromSystemDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromSystemDate.setYear(y);
      gglFromSystemDate.setMonth(m);
      gglFromSystemDate.setDay(d);
      list.setFromSystemDate(gglFromSystemDate);
    }
    if (toDateValue) {
      let gglToSystemDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToSystemDate.setYear(y);
      gglToSystemDate.setMonth(m);
      gglToSystemDate.setDay(d);
      list.setToSystemDate(gglToSystemDate);
    }

    fractionalSharePL.listFractionalSharePL(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      const rows = res.toObject().fractionalSharesList.map((data) => ({
        accountNo: data.accountNo,
        buyAmt: formatCurrency(data.buyAmt),
        buyPrice: formatCurrency(data.buyPrice),
        buyQty: data.buyQty,
        closeQty: data.closeQty,
        costBasis: formatCurrency(data.costBasis),
        originalCusip: data.originalCusip,
        qty: data.qty,
        realized: formatCurrency(data.realized),
        sellAmt: formatCurrency(data.sellAmt),
        sellPrice: formatCurrency(data.sellPrice),
        sellQty: data.sellQty,
        symbol: data.symbol,
        systemDate: formatPbDate(data.systemDate),
        tradeDate: formatPbDate(data.tradeDate),
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Fractional Share PL');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'buyAmt',
      label: 'Buy Amt',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'buyPrice',
      label: 'Buy Price',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'buyQty',
      label: 'Buy Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'closeQty',
      label: 'Close Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'costBasis',
      label: 'Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'costPrice',
      label: 'Cost Price',
      options: {
        // display: false,
        // visible: false,
        // viewColumns: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
      options: {
        // display: false,
        // visible: false,
      },
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'realized',
      label: 'Realized',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sellAmt',
      label: 'Sell Amt',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sellPrice',
      label: 'Sell Price',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sellQty',
      label: 'Sell Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate ? rows[dataIndex].systemDate : '--';
        },
      },
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
      options: {
        // display: false,
        // visible: false,
        // viewColumns: false,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeDate ? rows[dataIndex].tradeDate : '--';
        },
      },
    },
  ];

  const fileName =
    'FractionalSharePL_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [14, 0, 13, 7, 8, 5, 9, 3, 12, 4, 2, 11, 1, 10, 6, 15],
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Fractional Share PL');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Fractional Share PL');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['realized']}
          columnsWithQty={[]}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      fromDate: query.get('fromSystemDate'),
      toDate: query.get('toSystemDate'),
      accountNo: query.get('accountNo'),
      symbol: query.get('symbol'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    if (!data.fromDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          return;
        }
        setFromDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
        setToDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
      });
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Trade Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Trade Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Fractional Share PL'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
