/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  CreateRateRequest,
  UpdateRateRequest,
  ListRateRequest,
  DeleteRateRequest,
} from '../../../proto/marginpb/rate_grpc_web_pb.js';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatPercentage } from 'lib/fmt';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

function saveMarginRate(
  marginRate,
  rows,
  isAdd,
  closeModal,
  symbol,
  fromDate,
  toDate,
  requirementType,
  longRate,
  shortRate,
  status,
  rateId
) {
  if (!symbol) {
    notifyError('Symbol is required.');
    return;
  }
  if (!fromDate) {
    notifyError('From Date is required.');
    return;
  }
  if (!toDate) {
    notifyError('To Date required.');
    return;
  }
  if (!requirementType) {
    notifyError('Requirement Type is required.');
    return;
  }
  if (!longRate) {
    notifyError('Long Rate is required.');
    return;
  }
  if (!shortRate) {
    notifyError('Short Rate is required.');
    return;
  }

  if (isAdd) {
    let addReq = new CreateRateRequest();
    addReq.setSymbol(symbol);
    addReq.setRequirementType(requirementType);
    addReq.setLongRate(longRate);
    addReq.setShortRate(shortRate);
    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      addReq.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      addReq.setToDate(gglToDate);
    }

    marginRate.createRate(addReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
      } else {
        let obj = res.toObject().rate;
        let newMarginRate = {
          rateId: obj.rateId,
          originalCusip: obj.originalCusip,
          symbol: obj.symbol,
          cusip: obj.cusip,
          symbolDescription: obj.symbolDescription,
          status: obj.status,
          requirementType: obj.requirementType,
          longRate: formatPercentage(100 * obj.longRate),
          shortRate: formatPercentage(100 * obj.shortRate),
          fromDate: formatPbDate(obj.fromDate), //moment(new Date(fromDate)).format('yyyy-MM-DD'),
          toDate: formatPbDate(obj.toDate), //moment(new Date(toDate)).format('yyyy-MM-DD'),
        };
        rows.push(newMarginRate);
        notifySuccess('New Margin Rate has been added.');
        closeModal();
      }
    });
  } else {
    let updateReq = new UpdateRateRequest();
    updateReq.setRateId(rateId);
    updateReq.setRequirementType(requirementType);
    updateReq.setSymbol(symbol);
    updateReq.setLongRate(longRate);
    updateReq.setShortRate(shortRate);

    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      updateReq.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      updateReq.setToDate(gglToDate);
    }

    marginRate.updateRate(updateReq, {}, (err) => {
      if (err) {
        notifyError(err.message);
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].rateId === rateId) {
            rows[i].status = status;
          }
        }
        notifySuccess('Margin Rate has been updated.');
        closeModal();
      }
    });
  }
}

function removeMarginRateSingle(
  marginRate,
  rows,
  rateId,
  requirementType,
  confirm
) {
  confirm({
    description:
      "You are about to delete Requirement Type '" +
      requirementType +
      "' in your system. Please confirm your action.",
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let deleteReq = new DeleteRateRequest();
      deleteReq.setRateId(rateId);

      marginRate.deleteRate(deleteReq, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.messsage);
          return;
        }
        for (let c = 0; c < rows.length; c++) {
          if (rows[c].rateId === rateId) {
            rows[c].status = 'inactive';
          }
        }
      });
      notifySuccess('Margin Rate has been deleted');
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}

function removeMarginRate(marginRate, rows, rateIDs, handleClick, confirm) {
  if (rateIDs.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      rateIDs.length +
      ' Rate ID(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      rateIDs.forEach(removeFunction);

      function removeFunction(item) {
        let deleteReq = new DeleteRateRequest();
        deleteReq.setRateId(item);

        marginRate.deleteRate(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].rateId === item) {
              rows[c].status = 'inactive';
            }
          }
        });
      }
      handleClick('', '', true);
      notifySuccess(rateIDs.length + ' Margin Rate has bee deactivated');
    })
    .catch(() => {
      /* ... */
    });
}

export default function MarginRateTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const confirm = useConfirm();

  const {
    rateServiceClient: marginRate,
    profileServiceClient: systemDate,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (dateValue ? '&date=' + dateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (requirementTypeValue ? '&requirementType=' + requirementTypeValue : '') +
      (statusValue ? '&status=' + statusValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListRateRequest();
    list.setSymbol(symbolValue);
    list.setRequirementType(requirementTypeValue);
    list.setStatus(statusValue);

    if (dateValue) {
      let gglDate = new google_date.Date();
      let [y, m, d] = dateValue.split('-');
      gglDate.setYear(y);
      gglDate.setMonth(m);
      gglDate.setDay(d);
      list.setDate(gglDate);
    }

    marginRate.listRate(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().ratesList.map((data) => ({
        fromDate: formatPbDate(data.fromDate),
        toDate: formatPbDate(data.toDate),
        symbol: data.symbol,
        symbolDescription: data.symbolDescription,
        cusip: data.cusip,
        requirementType: data.requirementType,
        longRate: data.longRate ? formatPercentage(100 * data.longRate) : '',
        shortRate: data.shortRate ? formatPercentage(100 * data.shortRate) : '',
        status: data.status,
        rateId: data.rateId,
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Margin Rate');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          const isItemSelected = isSelected(rows[dataIndex].rateId);
          const labelId = `enhanced-table-checkbox-${dataIndex}`;

          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCellNone}>
                <Checkbox
                  onClick={(event) =>
                    handleClick(event, rows[dataIndex].rateId)
                  }
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </div>
              <div className={classes.grdCellNone}>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      removeMarginRateSingle(
                        marginRate,
                        rows,
                        rows[dataIndex].rateId,
                        rows[dataIndex].requirementType,
                        confirm
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.grdCellNone}>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleOpen(rows[dataIndex], false)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'fromDate',
      label: 'From Date',
    },
    {
      name: 'longRate',
      label: 'Long Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'requirementType',
      label: 'Requirement Type',
    },
    {
      name: 'shortRate',
      label: 'Short Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'toDate',
      label: 'To Date',
    },
  ];

  const handleClick = (event, rateId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(rateId);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, rateId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };
  const isSelected = (rateId) => selected.indexOf(rateId) !== -1;

  const fileName =
    'MarginRate_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 7, 1, 8, 2, 9, 4, 3, 5, 6],
    selectableRowsHeader: false,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Margin Rate');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Margin Rate');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [symbolValue, setSymbolValue] = React.useState('');
  const [dateValue, setDateValue] = React.useState('');
  const [requirementTypeValue, setRequirementTypeValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      date: query.get('date'),
      symbol: query.get('symbol'),
      requirementType: query.get('requirementType'),
      status: query.get('status'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.requirementType) {
      setRequirementTypeValue(data.requirementType);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.date) {
      setDateValue(data.date);
    }

    //Get system date
    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.date) {
          setDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  const [modalSymbolValue, setModalSymbolValue] = React.useState('');
  const [modalFromDateValue, setModalFromDateValue] = React.useState('');
  const [modalToDateValue, setModalToDateValue] = React.useState('');
  const [
    modalRequirementTypeValue,
    setModalRequirementTypeValue,
  ] = React.useState('');
  const [modalLongRateValue, setModalLongRateValue] = React.useState('');
  const [modalShortRateValue, setModalShortRateValue] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState('');

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const [rateId, setRateId] = React.useState('');
  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalFromDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalToDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalRequirementTypeValue('');
      setModalSymbolValue('');
      setModalLongRateValue('');
      setModalShortRateValue('');
      setModalStatusValue('active');

      setModalAddAction(true);
      setIsDisabled(false);
      setModalTitleValue('Add New');
    } else {
      setModalFromDateValue(
        rowData.fromDate
          ? moment(new Date(rowData.fromDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalToDateValue(
        rowData.toDate
          ? moment(new Date(rowData.toDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalRequirementTypeValue(rowData.requirementType);
      setModalSymbolValue(rowData.symbol);
      setModalLongRateValue(rowData.longRate.replace('%', ''));
      setModalShortRateValue(rowData.shortRate.replace('%', ''));
      setModalStatusValue(rowData.status);

      setModalAddAction(false);
      setIsDisabled(true);
      setModalTitleValue('Edit');
      setRateId(rowData.rateId);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, [modalSymbolValue]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  value={dateValue}
                  onChange={(e) => setDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Requirement Type</InputLabel>
                <Select
                  fullWidth
                  value={requirementTypeValue}
                  onChange={(e) => setRequirementTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="exchange">Exchange</MenuItem>
                  <MenuItem value="fed">Fed</MenuItem>
                  <MenuItem value="house">House</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeMarginRate(marginRate, rows, rowID, handleClick, confirm);
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Margin Rate'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Margin Rate
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Symbol"
                      fullWidth
                      disabled={isDisabled}
                      InputLabelProps={{ shrink: true }}
                      value={modalSymbolValue}
                      onChange={(e) => setModalSymbolValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      label="From Date"
                      type="date"
                      disabled={isDisabled}
                      value={modalFromDateValue}
                      onChange={(e) => setModalFromDateValue(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      label="To Date"
                      type="date"
                      disabled={isDisabled}
                      value={modalToDateValue}
                      onChange={(e) => setModalToDateValue(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Requirement Type</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      disabled={isDisabled}
                      value={modalRequirementTypeValue}
                      onChange={(e) =>
                        setModalRequirementTypeValue(e.target.value)
                      }
                    >
                      <MenuItem value="exchange">Exchange</MenuItem>
                      <MenuItem value="fed">Fed</MenuItem>
                      <MenuItem value="house">House</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Long Rate (%)"
                      required
                      type="number"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalLongRateValue}
                      onChange={(e) => setModalLongRateValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Short Rate (%)"
                      required
                      type="number"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalShortRateValue}
                      onChange={(e) => setModalShortRateValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                {!modalAddAction && (
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <InputLabel shrink>Status</InputLabel>
                      <Select
                        displayEmpty
                        fullWidth
                        value={modalStatusValue}
                        onChange={(e) => setModalStatusValue(e.target.value)}
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </Select>
                    </div>
                  </div>
                )}
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveMarginRate(
                          marginRate,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalSymbolValue,
                          modalFromDateValue,
                          modalToDateValue,
                          modalRequirementTypeValue,
                          String(Number(modalLongRateValue) / 100),
                          String(Number(modalShortRateValue) / 100),
                          modalStatusValue,
                          rateId
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
