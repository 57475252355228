/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListInterestRequest } from 'proto/feepb/interest_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { Box, TextField, Button, IconButton, Tooltip } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Icons*/
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function MarginInterestCalcTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    interestServiceClient: interest,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = (isSearch, type) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (settleDateValue ? '&settleDate=' + settleDateValue : '') +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '');
    window.history.pushState({}, null, pathWithParams);

    if (isSearch) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    let list = new ListInterestRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setInterestType('margin_interest');
    if (settleDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = settleDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSince(gglSystemDate);
      list.setUntil(gglSystemDate);
    }

    interest.listInterest(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      let rows = [];

      if (type === 'show breaks only') {
        rows = res.toObject().interestsList.filter(function(data) {
          return data.interest > 0;
        });
      } else {
        rows = res.toObject().interestsList;
      }
      setRows(rows);
      if (isSearch) {
        notifyInfo(rows.length + ' search results.');
        setLoading(false);
      }
    });
  };

  const showBreaksOnly = (type) => {
    search(false, type);
    setIsBreakOnly(!isBreakOnly ? true : false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Margin Interest Calc');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'marginInterest',
      label: 'Margin Interest',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const roundedInterest = Number(rows[dataIndex].interest)
            .toFixed(2)
            .toString();
          return <div align="right">{roundedInterest}</div>;
        },
      },
    },
    {
      name: 'markUpdnRate',
      label: 'Mark Updn Rate',
    },
    {
      name: 'markUpdnValue',
      label: 'Mark Updn Value',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'rate',
      label: 'Rate',
    },
    {
      name: 'sdCashBalance',
      label: 'Sd Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].sdCashBalance;
          const f = formatCurrency(rows[dataIndex].sdCashBalance);
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settleDate
            ? formatPbDate(rows[dataIndex].settleDate)
            : '--';
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'accruedMarginInterest',
      label: 'Accrued Margin Interest',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const roundedAccruedInterest = Number(rows[dataIndex].accruedInterest)
            .toFixed(2)
            .toString();
          return <div align="right">{roundedAccruedInterest}</div>;
        },
      },
    },
  ];

  const fileName =
    'MarginInterest_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [4, 2, 1, 8, 0, 3, 13, 11, 10, 9, 5, 14, 12, 6, 7],
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Margin Interest Calc');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Margin Interest Calc');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [settleDateValue, setSettleDateValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isBreakOnly, setIsBreakOnly] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      settleDate: query.get('settleDate'),
      accountName: query.get('accountName'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.settleDate) {
      setSettleDateValue(data.settleDate);
    }
    if (!data.settleDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSettleDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Settle Date"
                  type="date"
                  value={settleDateValue}
                  onChange={(e) => setSettleDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search(true, '')}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!rows.length ? true : false}
              startIcon={
                isBreakOnly ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              onClick={() => {
                showBreaksOnly(isBreakOnly ? 'show breaks only' : 'show all');
              }}
            >
              {isBreakOnly ? 'Hide Zero Values' : 'Show Zero Values'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Margin Interest Calc'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
