import React, { useState, useContext } from 'react';
import { Grid, Typography, Fade } from '@material-ui/core';
import { GoogleLogin } from '@react-oauth/google';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';
import Logo from '../../images/alpaca.png';

// logo
import googleGraphics from '../../images/enter-to-google.svg';

// context
import { useUserDispatch, loginUser } from '../../context/UserContext';

import { ServiceContext } from 'context/ServiceContext';
import GetTitle from 'components/title';

function Login(props) {
  const title = GetTitle();

  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  const { authError } = useContext(ServiceContext);

  var googleLoginClientId = window.env.GOOGLE_LOGIN_CLIENT_ID;

  const successGoogleResponse = (response) => {
    loginUser(userDispatch, response);
  };

  const failedGoogleResponse = (response) => {
    setIsLoading(false);
    setError(true);
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img
          alt="google-sign-in"
          className={classes.signinVecotr}
          src={googleGraphics}
        ></img>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid container>
              <Grid item>
                <img
                  className={classes.logotypeImage}
                  alt="alpaca"
                  src={Logo}
                ></img>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.logotypeText}
                  variant="h3"
                  align="left"
                  gutterBottom={false}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ marginTop: 50 }}>
              <Typography
                style={{ fontWeight: 700 }}
                variant="h4"
                align="left"
                gutterBottom={true}
              >
                Sign In
              </Typography>
              <Typography variant="subtitle1" align="left" gutterBottom>
                Sign in using your Google account.
              </Typography>
            </div>

            <GoogleLogin
              clientId={googleLoginClientId}
              isSignedIn={!authError}
              onSuccess={(r) => successGoogleResponse(r)}
              onError={failedGoogleResponse}
              useOneTap
            />

            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :(
              </Typography>
            </Fade>
            <Fade in={authError}>
              <Typography color="secondary" className={classes.errorMessage}>
                {authError}
              </Typography>
            </Fade>
          </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 Alpaca Clearing System. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
