/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: reportpb/activity.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')

var trnspb_transaction_pb = require('../trnspb/transaction_pb.js')

var utilspb_pagination_pb = require('../utilspb/pagination_pb.js')
const proto = {};
proto.reportpb = require('./activity_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.ActivityServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.ActivityServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListActivityRequest,
 *   !proto.reportpb.ListActivityResponse>}
 */
const methodDescriptor_ActivityService_ListActivity = new grpc.web.MethodDescriptor(
  '/reportpb.ActivityService/ListActivity',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListActivityRequest,
  proto.reportpb.ListActivityResponse,
  /**
   * @param {!proto.reportpb.ListActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListActivityResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ActivityServiceClient.prototype.listActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.ActivityService/ListActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_ListActivity,
      callback);
};


/**
 * @param {!proto.reportpb.ListActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListActivityResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.ActivityServicePromiseClient.prototype.listActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.ActivityService/ListActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_ListActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.StreamActivityRequest,
 *   !proto.reportpb.Activity>}
 */
const methodDescriptor_ActivityService_StreamActivity = new grpc.web.MethodDescriptor(
  '/reportpb.ActivityService/StreamActivity',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.reportpb.StreamActivityRequest,
  proto.reportpb.Activity,
  /**
   * @param {!proto.reportpb.StreamActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.Activity.deserializeBinary
);


/**
 * @param {!proto.reportpb.StreamActivityRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.Activity>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ActivityServiceClient.prototype.streamActivity =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.ActivityService/StreamActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_StreamActivity);
};


/**
 * @param {!proto.reportpb.StreamActivityRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.Activity>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ActivityServicePromiseClient.prototype.streamActivity =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.ActivityService/StreamActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_StreamActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListSuspenseAccountTransactionsRequest,
 *   !proto.reportpb.ListSuspenseAccountTransactionsResponse>}
 */
const methodDescriptor_ActivityService_ListSuspenseAccountTransactions = new grpc.web.MethodDescriptor(
  '/reportpb.ActivityService/ListSuspenseAccountTransactions',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListSuspenseAccountTransactionsRequest,
  proto.reportpb.ListSuspenseAccountTransactionsResponse,
  /**
   * @param {!proto.reportpb.ListSuspenseAccountTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListSuspenseAccountTransactionsResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListSuspenseAccountTransactionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListSuspenseAccountTransactionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListSuspenseAccountTransactionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ActivityServiceClient.prototype.listSuspenseAccountTransactions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.ActivityService/ListSuspenseAccountTransactions',
      request,
      metadata || {},
      methodDescriptor_ActivityService_ListSuspenseAccountTransactions,
      callback);
};


/**
 * @param {!proto.reportpb.ListSuspenseAccountTransactionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListSuspenseAccountTransactionsResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.ActivityServicePromiseClient.prototype.listSuspenseAccountTransactions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.ActivityService/ListSuspenseAccountTransactions',
      request,
      metadata || {},
      methodDescriptor_ActivityService_ListSuspenseAccountTransactions);
};


module.exports = proto.reportpb;

