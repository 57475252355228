/*Service*/
import {
  AccountType,
  DeleteAccountTypeRequest,
} from '../../../proto/accountpb/accounttype_grpc_web_pb';

/*ReactJS*/
import React, { useState, useContext } from 'react';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Re-render component
===========================================================================================*/
function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}
/*=========================================================================================
End of re-render component
===========================================================================================*/

function ClientTypeTable({ accountid, accounttypelist }) {
  const rows = accounttypelist;

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();

  const reRender = RenderComponent(); //Re-renders component when something change in component state
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { accountTypeServiceClient: accountType } = useContext(ServiceContext);

  /*=========================================================================================
Add and Update account type function
===========================================================================================*/
  function saveAccountType(
    reRender,
    isAdd,
    closeModal,
    typeId,
    accountId,
    type,
    typeCode
  ) {
    if (isAdd) {
      let addReq = new AccountType();
      addReq.setAccountId(accountId);
      addReq.setType(type);
      addReq.setTypeCode(typeCode);

      accountType.createAccountType(addReq, {}, (err, res) => {
        if (err) {
          notifyError(err.message);
          return;
        }
        rows.push(res.toObject().accountType);
        notifySuccess('New account type has been added.');
        reRender();
        closeModal();
      });
    } else {
      let updateReq = new AccountType();
      updateReq.setTypeId(typeId);
      updateReq.setAccountId(accountId);
      updateReq.setType(type);
      updateReq.setTypeCode(typeCode);

      accountType.updateAccountType(updateReq, {}, (err) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].typeId === typeId) {
            rows[i].type = type;
            rows[i].typeCode = typeCode;
          }
        }
        notifySuccess('Account type has been updated.');
        reRender();
        closeModal();
      });
    }
  }
  /*=========================================================================================
End of Add and Update account type function
===========================================================================================*/

  /*=========================================================================================
Delete account type function: Multiple
===========================================================================================*/
  function removeAccountType(reRender, typeIds, handleClick, confirm) {
    if (typeIds.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        typeIds.length +
        ' Account Type(s) in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        typeIds.forEach(removeFunction);

        function removeFunction(item) {
          let deleteReq = new DeleteAccountTypeRequest();
          deleteReq.setTypeId(item);

          accountType.deleteAccountType(deleteReq, {}, (err) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
              return;
            }
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].typeId === item) {
                rows.splice(c, 1);
              }
            }
            reRender();
          });
        }

        handleClick('', '', true);
        notifySuccess(typeIds.length + ' Account type has been deleted');
      })
      .catch(() => {
        /* ... */
      });
  }
  /*=========================================================================================
End of delete account type function: Multiple
===========================================================================================*/

  /*=========================================================================================
Delete account type function: Single
===========================================================================================*/
  function removeAccountTypeSingle(reRender, typeId, type, confirm) {
    confirm({
      description:
        "You are about to delete Account Type '" +
        type +
        "' in your system. Please confirm your action.",
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeleteAccountTypeRequest();
        deleteReq.setTypeId(typeId);

        accountType.deleteAccountType(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.messsage);
            return;
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].typeId === typeId) {
              rows.splice(c, 1);
            }
          }
          reRender();
        });
        notifySuccess('Account type has been deleted');
      })
      .catch(() => {
        // showToastMessage("error", "System error.");
      });
  }
  /*=========================================================================================
End of delete account type function: Single
===========================================================================================*/

  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const [isVisibleType, setIsVisibleType] = React.useState(true);
  const [isVisibleTypeCode, setIsVisibleTypeCode] = React.useState(true);

  const columns = [
    {
      id: '1',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '2',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '3',
      name: 'type',
      label: 'Type',
    },
    {
      id: '4',
      name: 'typeCode',
      label: 'Type Code',
    },
  ];

  const handleClick = (event, typeId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(typeId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, typeId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
      setRowID([]);
    }
  };

  const isSelected = (typeId) => selected.indexOf(typeId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].typeId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    // Format type for readability, e.g. 'donor_advised' => 'Donor Advised'
    const type = rows[rowIndex].type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rows[rowIndex].typeId}
        selected={isItemSelected}
      >
        {isVisibleType || isVisibleTypeCode ? (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => handleClick(event, rows[rowIndex].typeId)}
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        ) : null}
        {isVisibleType || isVisibleTypeCode ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="delete"
              onClick={() => {
                removeAccountTypeSingle(
                  reRender,
                  rows[rowIndex].typeId,
                  rows[rowIndex].type,
                  confirm
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleType || isVisibleTypeCode ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="edit"
              onClick={() => handleOpen(rows[rowIndex], false)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleType && <TableCell align="left">{type}</TableCell>}
        {isVisibleTypeCode ? (
          <TableCell align="left">{rows[rowIndex].typeCode}</TableCell>
        ) : null}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    if (column === 'type') {
      if (action === 'remove') {
        setIsVisibleType(false);
      } else {
        setIsVisibleType(true);
      }
    }

    if (column === 'typeCode') {
      if (action === 'remove') {
        setIsVisibleTypeCode(false);
      } else {
        setIsVisibleTypeCode(true);
      }
    }
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
  };
  /*=========================================================================================
    End of table configuration
  ===========================================================================================*/

  /*========================================================================================= 
  account type modal
  ===========================================================================================*/
  const [typeId, setTypeId] = React.useState('');
  const [modalTypeValue, setModalTypeValue] = React.useState('');
  const [modalTypeCodeValue, setModalTypeCodeValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const setTypeCode = (type, typeCode) => {
    setModalTypeValue(type);
    setModalTypeCodeValue(type === 'custodial' ? typeCode : '');
  };

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalTypeValue('');
      setModalTypeCodeValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setTypeId(rowData.typeId);
      setTypeCode(rowData.type, rowData.typeCode);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*========================================================================================= 
  End of account type modal
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<DeleteIcon />}
              onClick={() => {
                removeAccountType(reRender, rowID, handleClick, confirm);
              }}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Account Types'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Account Type
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="type" shrink>
                      Type
                    </InputLabel>
                    <Select
                      displayEmpty
                      labelId="type"
                      fullWidth
                      value={modalTypeValue}
                      onChange={(e) => setTypeCode(e.target.value, 'UGMA')}
                    >
                      <MenuItem value="custodial">Custodial</MenuItem>
                      <MenuItem value="donor_advised">Donor Advised</MenuItem>
                      <MenuItem value="trust">Trust</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel id="typeCode" shrink>
                      Type Code
                    </InputLabel>
                    <Select
                      displayEmpty
                      disabled={modalTypeValue !== 'custodial'}
                      labelId="typeCode"
                      fullWidth
                      value={modalTypeCodeValue}
                      onChange={(e) =>
                        setTypeCode(modalTypeValue, e.target.value)
                      }
                    >
                      {modalTypeValue === 'custodial' && (
                        <MenuItem value="UGMA">UGMA</MenuItem>
                      )}
                      {modalTypeValue === 'custodial' && (
                        <MenuItem value="UTMA">UTMA</MenuItem>
                      )}
                    </Select>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveAccountType(
                          reRender,
                          modalAddAction,
                          handleClose,
                          typeId,
                          accountid,
                          modalTypeValue,
                          modalTypeCodeValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { ClientTypeTable };
