import React from 'react';

import { useListSsrReport } from '../Hooks/useSsrService';
import { useAdministrator, convertIdToName } from '../Hooks/useAdministrator';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DataTable } from './Table';
import { useSsrRouter } from '../Hooks/useRouter';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

// cut text with "..." if it beyonds max width
function withMaxWidth(text, maxWidth) {
  if (text.length > maxWidth) {
    return text.substring(0, maxWidth) + ' ...';
  } else {
    return text;
  }
}

export default function Overview() {
  const admins = useAdministrator();
  const reports = useListSsrReport();
  const classes = useStyles();
  const ssrRouter = useSsrRouter();

  const columns = [
    'Name',
    'Description',
    'Owner',
    'Schedule',
    'Pending Reviews',
    '',
  ];

  let rows = [];

  if (reports.isFetched) {
    rows = reports.data.map((report) => {
      const handleButtonClick = () => {
        ssrRouter.goToViewReportPage(report.id);
      };

      const row = [
        withMaxWidth(report.name, 50),
        withMaxWidth(report.description, 50),
        convertIdToName(admins, report.ownerId),
        report.scheduleDescription,
        report.pendingReviewCount,
        <Button
          key="details"
          color="primary"
          variant="contained"
          onClick={handleButtonClick}
        >
          Details
        </Button>,
      ];

      return row;
    });
  }

  const handleCreateNewReportButton = () => {
    ssrRouter.goToCreateReportPage();
  };

  return (
    <Box component="div" mt={2} className={classes.root}>
      <Box display="flex" flexDirection="row-reverse">
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateNewReportButton}
        >
          Create New Report
        </Button>
      </Box>

      <DataTable
        title="Overview"
        data={rows}
        columns={columns}
        rowsPerPage={30}
      />
    </Box>
  );
}
