/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListAccountManagementMarginReqDetailRequest } from '../../../proto/reportpb/accountmanagement_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

import { Typography, Box } from '@material-ui/core';

/*Styles*/
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function BalanceDetails({ tradedate, masteraccountno }) {
  const classes = useStyles();

  const { accountManagementServiceClient: accountManagement } = useContext(
    ServiceContext
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    let list = new ListAccountManagementMarginReqDetailRequest();

    let gglTradeDate = new google_date.Date();
    let [y, m, d] = moment(new Date(tradedate))
      .format('yyyy-MM-DD')
      .split('-');
    gglTradeDate.setYear(y);
    gglTradeDate.setMonth(m);
    gglTradeDate.setDay(d);
    list.setTradeDate(gglTradeDate);
    list.setMasterAccountNo(masteraccountno);

    accountManagement.listAccountManagementMarginReqDetail(
      list,
      {},
      (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err);
          return;
        }
        setData(res.toObject().marginReqDetailsList);
      }
    );
  }, [accountManagement, tradedate, masteraccountno]);

  return (
    <div className={classes.root}>
      {data.length > 0 ? (
        <div style={{ padding: 30 }}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
            Margin Requirement Details
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 18 }}>
            <label>
              Master Account No: <b>{data[0].masterAccountNo}</b>
            </label>
          </Typography>
          <Box component="div" mt={5}>
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCell1}>
                <label className={classes.detailsValue}>
                  Cash Balance: <b>{formatCurrency(data[0].cashBalance)}</b>
                </label>
                <label className={classes.detailsValue}>
                  Market Value: <b>{formatCurrency(data[0].marketValue)}</b>
                </label>
                <label className={classes.detailsValue}>
                  Equity: <b>{formatCurrency(data[0].equity)}</b>
                </label>
                <label className={classes.detailsValue}>
                  Multiplier: <b>{data[0].multiplier}</b>
                </label>
                <label className={classes.detailsValue}>
                  Opening BP: <b>{formatCurrency(data[0].openingBp)}</b>
                </label>
                <label className={classes.detailsValue}>
                  BP Call: <b>{formatCurrency(data[0].bpCall)}</b>
                </label>
              </div>
              <div className={classes.grdCell1}>
                <label className={classes.detailsValue}>
                  Exchange Req: <b>{formatCurrency(data[0].exchangeReq)}</b>
                </label>
                <label className={classes.detailsValue}>
                  Exchange Call: <b>{formatCurrency(data[0].exchangeCall)}</b>
                </label>
                <label className={classes.detailsValue}>
                  House Req: <b>{formatCurrency(data[0].houseReq)}</b>
                </label>
                <label className={classes.detailsValue}>
                  House Call: <b>{formatCurrency(data[0].houseCall)}</b>
                </label>
                <label className={classes.detailsValue}>
                  Fed Req: <b>{formatCurrency(data[0].fedReq)}</b>
                </label>
                <label className={classes.detailsValue}>
                  Fed Call: <b>{formatCurrency(data[0].fedCall)}</b>
                </label>
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <div
          style={{
            textAlign: 'center',
            padding: '50px 30px',
            fontSize: '1rem',
          }}
        >
          <span>Sorry, no matching records found.</span>
        </div>
      )}
    </div>
  );
}
