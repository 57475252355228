/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListSegregationRequest } from '../../../proto/reportpb/segregation_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { Box, TextField, IconButton, Tooltip } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Visibility as ViewIcon, Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Custom component*/
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function SegregationTable({ params, segtype }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    segregationServiceClient: segregation,
    profileServiceClient: systemDate,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (segtype ? '&segtype=' + segtype : '') +
      (settleDateValue ? '&settleDate=' + settleDateValue : '') +
      (symbolValue ? '&origCusip=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let listReq = new ListSegregationRequest();

    if (settleDateValue) {
      let gglSettleDate = new google_date.Date();
      let [y, m, d] = settleDateValue.split('-');
      gglSettleDate.setYear(y);
      gglSettleDate.setMonth(m);
      gglSettleDate.setDay(d);
      listReq.setSettleDate(gglSettleDate);
    }

    listReq.setSymbol(symbolValue);

    segregation.listSegregation(listReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().segregationsList.map((data) => ({
        settleDate: formatPbDate(data.settleDate),
        originalCusip: data.originalCusip,
        cusip: data.cusip,
        symbol: data.symbol,
        symbolDescription: data.symbolDescription,
        previousSegQty: data.previousSegQty,
        segQty: data.segQty,
        segAmt: formatCurrency(data.segAmt),
        excessMarginQty: data.excessMarginQty,
        excessMarginAmt: formatCurrency(data.excessMarginAmt),
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Segregation');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          const openNewWindow = (settleDate, originalCusip) => {
            const paramPath =
              '/segregation/segregation-lockup/' +
              moment(new Date(settleDate)).format('YYYY-MM-DD') +
              '/' +
              originalCusip +
              '/' +
              'Symbol';

            window.open(paramPath, '_blank');
          };

          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  openNewWindow(
                    rows[dataIndex].settleDate,
                    rows[dataIndex].originalCusip
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'excessMarginAmt',
      label: 'Excess Margin Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].excessMarginAmt.replace('$', '');
          const f = rows[dataIndex].excessMarginAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'excessMarginQty',
      label: 'Excess Margin Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].excessMarginQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'previousSegQty',
      label: 'Previous Seg Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].previousSegQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'segAmt',
      label: 'Seg Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].segAmt.replace('$', '');
          const f = rows[dataIndex].segAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'segQty',
      label: 'Seg Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].segQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
  ];

  const fileName =
    'Segregation_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 8, 4, 1, 9, 10, 5, 7, 6, 3, 2],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Segregation');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Segregation');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['segAmt', 'excessMarginAmt']}
          columnsWithQty={['segQty', 'excessMarginQty', 'previousSegQty']}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [settleDateValue, setSettleDateValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      settleDate: query.get('settleDate'),
      origCusip: query.get('origCusip'),
    };

    if (data.origCusip) {
      setSymbolValue(data.origCusip);
    }
    if (data.settleDate) {
      setSettleDateValue(data.settleDate);
    }

    if (!data.settleDate) {
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSettleDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
          console.log(formatPbDate(res.toObject().profile.previousDate));
        }
      });
    }

    setCurrentPath(path);
  }, [params, systemDate]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Settle Date"
                  type="date"
                  value={settleDateValue}
                  onChange={(e) => setSettleDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  label="Original Cusip"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={symbolValue}
                  onChange={(e) => setSymbolValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer} style={{ marginTop: 26 }}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Segregation'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
