/**
 * @fileoverview gRPC-Web generated client stub for accountpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: accountpb/owner.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.accountpb = require('./owner_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.OwnerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.OwnerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Owner,
 *   !proto.accountpb.CreateOwnerResponse>}
 */
const methodDescriptor_OwnerService_CreateOwner = new grpc.web.MethodDescriptor(
  '/accountpb.OwnerService/CreateOwner',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Owner,
  proto.accountpb.CreateOwnerResponse,
  /**
   * @param {!proto.accountpb.Owner} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.CreateOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Owner} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.CreateOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.CreateOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.OwnerServiceClient.prototype.createOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.OwnerService/CreateOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_CreateOwner,
      callback);
};


/**
 * @param {!proto.accountpb.Owner} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.CreateOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.OwnerServicePromiseClient.prototype.createOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.OwnerService/CreateOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_CreateOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Owner,
 *   !proto.accountpb.UpdateOwnerResponse>}
 */
const methodDescriptor_OwnerService_UpdateOwner = new grpc.web.MethodDescriptor(
  '/accountpb.OwnerService/UpdateOwner',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Owner,
  proto.accountpb.UpdateOwnerResponse,
  /**
   * @param {!proto.accountpb.Owner} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.UpdateOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Owner} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.UpdateOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.UpdateOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.OwnerServiceClient.prototype.updateOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.OwnerService/UpdateOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_UpdateOwner,
      callback);
};


/**
 * @param {!proto.accountpb.Owner} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.UpdateOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.OwnerServicePromiseClient.prototype.updateOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.OwnerService/UpdateOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_UpdateOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ReadOwnerRequest,
 *   !proto.accountpb.ReadOwnerResponse>}
 */
const methodDescriptor_OwnerService_ReadOwner = new grpc.web.MethodDescriptor(
  '/accountpb.OwnerService/ReadOwner',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ReadOwnerRequest,
  proto.accountpb.ReadOwnerResponse,
  /**
   * @param {!proto.accountpb.ReadOwnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ReadOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.ReadOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.OwnerServiceClient.prototype.readOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.OwnerService/ReadOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_ReadOwner,
      callback);
};


/**
 * @param {!proto.accountpb.ReadOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.OwnerServicePromiseClient.prototype.readOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.OwnerService/ReadOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_ReadOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.DeleteOwnerRequest,
 *   !proto.accountpb.DeleteOwnerResponse>}
 */
const methodDescriptor_OwnerService_DeleteOwner = new grpc.web.MethodDescriptor(
  '/accountpb.OwnerService/DeleteOwner',
  grpc.web.MethodType.UNARY,
  proto.accountpb.DeleteOwnerRequest,
  proto.accountpb.DeleteOwnerResponse,
  /**
   * @param {!proto.accountpb.DeleteOwnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.DeleteOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.DeleteOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.DeleteOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.DeleteOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.OwnerServiceClient.prototype.deleteOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.OwnerService/DeleteOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_DeleteOwner,
      callback);
};


/**
 * @param {!proto.accountpb.DeleteOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.DeleteOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.OwnerServicePromiseClient.prototype.deleteOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.OwnerService/DeleteOwner',
      request,
      metadata || {},
      methodDescriptor_OwnerService_DeleteOwner);
};


module.exports = proto.accountpb;

