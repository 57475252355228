import { signOut } from '../../context/UserContext';
const InterceptedStream = function(stream, authError, setAuthError, dispatch) {
  this.stream = stream;
  this.authError = authError;
  this.setAuthError = setAuthError;
  this.dispatch = dispatch;
};

/** @override */
InterceptedStream.prototype.on = function(eventType, callback) {
  if (eventType === 'error') {
    const errorCallback = async (response) => {
      if (response.code === 16) {
        this.setAuthError(response.message);
        signOut(this.dispatch);
      }
      callback(response);
    };
    this.stream.on('error', errorCallback);
  } else {
    if (this.authError) this.setAuthError(null);
    this.stream.on(eventType, callback);
  }

  return this;
};

/** @override */
InterceptedStream.prototype.cancel = function() {
  this.stream.cancel();
  return this;
};

const AuthenticationInterceptor = function(
  googleAccount,
  authError,
  setAuthError,
  dispatch
) {
  this.googleAccount = googleAccount;
  this.authError = authError;
  this.setAuthError = setAuthError;
  this.dispatch = dispatch;
};

/** @override */
AuthenticationInterceptor.prototype.intercept = function(request, invoker) {
  const md = request.getMetadata();
  request.c = {
    Authorization: 'Bearer ' + this.googleAccount.credential,
    ...md,
  }; //TODO This is a workaround because 'request.withMetadata' not working
  return new InterceptedStream(
    invoker(request),
    this.authError,
    this.setAuthError,
    this.dispatch
  );
};

export const auth = (googleAccount, authError, setAuthError, dispatch) => ({
  streamInterceptors: [
    new AuthenticationInterceptor(
      googleAccount,
      authError,
      setAuthError,
      dispatch
    ),
  ],
});
