/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

//Service
import { ListDailyTradeConfirmRequest } from '../../../proto/reportpb/dailytradeconfirm_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { downloadDailyTradeConfirmation } from '../../../services/DailyTradeConfirmService';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Notification/Notification';

/*Material UI Table Components*/
import { Box, TextField, Tooltip, IconButton } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Icons*/
import { GetAppRounded as DownloadIcon } from '@material-ui/icons';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import SearchButton from '../../../components/Button/Search';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function DailyTradeConfirmTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    dailyTradeConfirmServiceClient: dailyTrade,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (fromDateValue ? '&tradeDateFrom=' + fromDateValue : '') +
      (toDateValue ? '&tradeDateTo=' + toDateValue : '') +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListDailyTradeConfirmRequest();
    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);

      list.setFromDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);

      list.setToDate(gglToDate);
    }
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setMasterAccountNo(masterAccountNoValue);

    dailyTrade.listDailyTradeConfirm(list, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().dailyTradeConfirmationsList.map((data) => ({
        accountId: data.accountId,
        accountNo: data.accountNo,
        accountName: data.accountName,
        correspondent: data.correspondent,
        entryType: data.entryType,
        masterAccountNo: data.masterAccountNo,
        netAmt: formatCurrency(data.netAmt),
        tradeConfirmCount: data.tradeConfirmCount,
        tradeDate: formatPbDate(data.tradeDate),
        trnsId: data.trnsId,
        type: data.type,
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Daily Trade Confirm');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="download"
              onClick={() => {
                downloadDailyTradeConfirmation(
                  dailyTrade,
                  rows[dataIndex].tradeDate,
                  rows[dataIndex].accountId
                );
              }}
            >
              <DownloadIcon />
            </IconButton>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'correspondent',
      label: 'Correspondent ',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'netAmt',
      label: 'Net Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].netAmt.replace('$', '');
          const f = rows[dataIndex].netAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'tradeConfirmCount',
      label: 'Trade Confirm Count',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].tradeConfirmCount;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
    },
  ];

  const fileName =
    'DailyTradeConfirm' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 3, 2, 1, 4, 7, 5, 6],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Daily Trade Confirm');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Daily Trade Confirm');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      tradeDateFrom: query.get('tradeDateFrom'),
      tradeDateTo: query.get('tradeDateTo'),
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      accountName: query.get('accountName'),
      masterAccountNo: query.get('masterAccountNo'),
    };

    if (data.tradeDateFrom) {
      setFromDateValue(data.tradeDateFrom);
    }
    if (data.tradeDateTo) {
      setToDateValue(data.tradeDateTo);
    }
    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }

    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.tradeDateFrom) {
          setFromDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
        }
        if (!data.tradeDateTo) {
          setToDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Trade Date From"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Trade Date To"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Daily Trade Confirm'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
