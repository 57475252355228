/*ReactJS*/
import React, { useState, useContext } from 'react';
import useStyles from '../../../styles';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  CircularProgress,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

import SelectAccountNo from '../../../components/AutoComplete/SelectAccountNo';
import { ServiceContext } from 'context/ServiceContext';
import {
  MovePositionData,
  BatchMovePositionRequest,
} from '../../../proto/trnspb/transaction_grpc_web_pb';

export default function PositionModal({
  onClose: handleClose,
  open: isOpen,
  value: selectedRows,
}) {
  const { transactionServiceClient: transactionClient } = useContext(
    ServiceContext
  );

  const classes = useStyles();
  const [modalData, setModalData] = useState({
    contraAccountNo: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  async function batchMovePosition(selectedRows, contraAccountNo) {
    let batch = [];

    selectedRows.forEach(function(row) {
      let req = new MovePositionData();

      req.setFromAccountNo(row.accountNo);
      req.setToAccountNo(contraAccountNo);
      req.setSymbol(row.symbol);
      req.setQty(row.tdLongQty === '0' ? row.tdShortQty : row.tdLongQty);
      req.setFromContraAccountDesignator(row.contraAccountDesignator);
      batch.push(req);
    });

    let batchReq = new BatchMovePositionRequest();
    batchReq.setMovePositionsList(batch);

    return new Promise((resolve, reject) => {
      transactionClient.batchMovePosition(batchReq, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res.toObject());
        }
      });
    });
  }

  const handleMove = async () => {
    if (!modalData.contraAccountNo) {
      notifyError('Contra Account No is required.');
      return;
    }

    try {
      setLoading(true);
      const { transactionResponsesList } = await batchMovePosition(
        selectedRows,
        modalData.contraAccountNo
      );

      console.log(transactionResponsesList);
      transactionResponsesList.forEach(function(message) {
        const msg =
          message.status +
          ' TRNSID: ' +
          message.trnsId +
          ' ' +
          message.errorMessage;

        if (message.status === 'executed') {
          notifySuccess(msg);
        } else {
          notifyError(msg);
        }
      });

      setLoading(false);
      handleClose(true);
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        if (!loading) {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Move Position
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <SelectAccountNo
                    freeSolo={true}
                    name="contraAccountNo"
                    label="Contra Account No"
                    value={modalData.contraAccountNo}
                    onChange={handleChange}
                    allowInputChange={true}
                  />
                </div>
              </div>

              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={loading}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress
                          style={{ color: '#ffffff', height: 20, width: 20 }}
                        />
                      ) : (
                        <UpdateIcon />
                      )
                    }
                    onClick={() => {
                      handleMove(modalData, selectedRows);
                    }}
                  >
                    Move
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
