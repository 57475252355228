/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { Etf, ListEtfRequest } from '../../../proto/assetpb/etf_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Add as AddIcon, Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Moment JS*/
import moment from 'moment-timezone';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

function saveEtf(
  etf,
  rows,
  isAdd,
  closeModal,
  symbol,
  cusip,
  symbolDescription,
  inverse,
  leveragedFactor
) {
  if (!symbol) {
    notifyError('Symbol is required.');
    return;
  }
  if (!leveragedFactor) {
    notifyError('Leveraged Factor is required.');
    return;
  }

  let addReq = new Etf();
  addReq.setSymbol(symbol);
  addReq.setCusip(cusip);
  addReq.setSymbolDescription(symbolDescription);
  addReq.setInverse(inverse);
  addReq.setLeveragedFactor(leveragedFactor);

  etf.createEtf(addReq, {}, (err, res) => {
    if (err) {
      notifyError(err.message);
      return;
    } else {
      let obj = res.toObject().etf;
      let newEtf = {
        symbol: obj.symbol,
        cusip: obj.cusip,
        symbolDescription: obj.symbolDescription,
        inverse: obj.inverse,
        leveragedFactor: obj.leveragedFactor,
      };
      rows.push(newEtf);
      notifySuccess('New Margin Rate has been added.');
      closeModal();
    }
  });
}

export default function EtfTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    etfServiceClient: etf,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListEtfRequest();
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);

    etf.listEtf(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      const rows = res.toObject().etfsList;
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('ETF');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'inverse',
      label: 'Inverse',
    },
    {
      name: 'leveragedFactor',
      label: 'Leveraged Factor',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
  ];

  const fileName = 'EFT_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [3, 0, 4, 1, 2],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('ETF');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('ETF');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [symbolValue, setSymbolValue] = React.useState('');
  const [cusipValue, setCusipValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.cusip) {
      setCusipValue(data.cusip);
    }

    setCurrentPath(path);
  }, [params]);

  const [modalSymbolValue, setModalSymbolValue] = React.useState('');
  const [modalCusipValue, setModalCusipValue] = React.useState('');
  const [
    modalSymbolDescriptionValue,
    setModalSymbolDescriptionValue,
  ] = React.useState('');
  const [modalInverseValue, setModalInverseValue] = React.useState('');
  const [
    modalLeveragedFactorValue,
    setModalLeveragedFactorValue,
  ] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    setModalSymbolValue('');
    setModalCusipValue('');
    setModalSymbolDescriptionValue('');
    setModalInverseValue('');
    setModalLeveragedFactorValue('');

    setModalAddAction(true);
    setModalTitleValue('Add New');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  label="Cusip"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'ETF'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} ETF
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required
                      label="Symbol"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalSymbolValue}
                      onChange={(e) => setModalSymbolValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Cusip"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalCusipValue}
                      onChange={(e) => setModalCusipValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Symbol Description"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalSymbolDescriptionValue}
                      onChange={(e) =>
                        setModalSymbolDescriptionValue(e.target.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink id="Inverse">
                      Inverse
                    </InputLabel>
                    <Select
                      name="Inverse"
                      displayEmpty
                      fullWidth
                      value={modalInverseValue}
                      onChange={(e) => setModalInverseValue(e.target.value)}
                      inputlabelprops={{ shrink: true }}
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="YES">YES</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required
                      label="Leveraged Factor"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalLeveragedFactorValue}
                      onChange={(e) =>
                        setModalLeveragedFactorValue(e.target.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1}></div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveEtf(
                          etf,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalSymbolValue,
                          modalCusipValue,
                          modalSymbolDescriptionValue,
                          modalInverseValue,
                          modalLeveragedFactorValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
