/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  ListSettlementFundingRequest,
  SettlementFunding,
} from '../../../proto/reportpb/settlementfunding_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
  Button,
  Typography,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

function updateSettlementFunding(
  settlement,
  rows,
  closeModal,
  ledgieAccount,
  restingBalance,
  pendingWireAch
) {
  if (!restingBalance) {
    notifyError('Resting Balance is required.');
    return;
  }

  let req = new SettlementFunding();
  req.setLedgieAccount(ledgieAccount);
  req.setRestingBalance(restingBalance);
  req.setPendingWireAch(pendingWireAch);

  settlement.updateSettlementFunding(req, {}, (err, res) => {
    if (err) {
      notifyError(err.message);
    } else {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].ledgieAccount === ledgieAccount) {
          let lb = parseFloat(
            rows[i].ledgieBalance.replace('$', '').replaceAll(',', '')
          );
          let sa = parseFloat(
            rows[i].settlementAmount.replace('$', '').replaceAll(',', '')
          );
          let sum = lb + sa;
          let ab = sum + parseFloat(pendingWireAch);

          rows[i].restingBalance = formatCurrency(restingBalance);
          rows[i].pendingWireAch = formatCurrency(pendingWireAch);
          rows[i].adjBalance = formatCurrency(ab);
        }
      }
      notifySuccess('Resting Balance has been updated.');
      closeModal();
    }
  });
}

export default function SettlementFundingTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    settlementFundingServiceClient: settlementFunding,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListSettlementFundingRequest();
    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      list.setFromDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      list.setToDate(gglToDate);
    }

    settlementFunding.listSettlementFunding(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().settlementFundingsList.map((data) => ({
        veloxAccount: data.veloxAccount,
        ledgieAccount: data.ledgieAccount,
        frbAccount: data.frbAccount,
        ledgieBalance: formatCurrency(data.ledgieBalance),
        settlementAmount: formatCurrency(data.settlementAmount),
        adjBalance: formatCurrency(data.adjBalance),
        restingBalance: formatCurrency(data.restingBalance),
        fundingAmount: formatCurrency(data.fundingAmount),
        fundingStatus: data.fundingStatus,
        frbBalance: formatCurrency(data.frbBalance),
        balanceAfterFunding: formatCurrency(data.balanceAfterFunding),
        pendingWireAch: formatCurrency(data.pendingWireAch),
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Settlement Funding');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          //const isItemSelected = isSelected(rows[dataIndex].marginRateId);
          //const labelId = `enhanced-table-checkbox-${dataIndex}`;

          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              {/* <div className={classes.grdCellNone}>
                <Checkbox
                  onClick={(event) =>
                    handleClick(event, rows[dataIndex].marginRateId)
                  }
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </div> */}
              <div className={classes.grdCellNone}>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    disabled={
                      rows[dataIndex].ledgieAccount === '' ? true : false
                    }
                    aria-label="edit"
                    onClick={() => handleOpen(rows[dataIndex], false)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'veloxAccount',
      label: 'Velox Account',
    },
    {
      name: 'ledgieAccount',
      label: 'Ledgie Account',
    },
    {
      name: 'frbAccount',
      label: 'Bank Account',
    },
    {
      name: 'ledgieBalance',
      label: 'Ledgie Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].ledgieBalance.replace('$', '');
          const f = rows[dataIndex].ledgieBalance;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'settlementAmount',
      label: 'Settlement Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].settlementAmount.replace('$', '');
          const f = rows[dataIndex].settlementAmount;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'adjBalance',
      label: 'Adj Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].adjBalance.replace('$', '');
          const f = rows[dataIndex].adjBalance;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'restingBalance',
      label: 'Resting Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].restingBalance.replace('$', '');
          const f = rows[dataIndex].restingBalance;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'fundingAmount',
      label: 'Funding Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].fundingAmount.replace('$', '');
          const f = rows[dataIndex].fundingAmount;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'fundingStatus',
      label: 'Funding Status',
    },
    {
      name: 'pendingWireAch',
      label: 'Pending Wire/ACH',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].pendingWireAch.replace('$', '');
          const f = rows[dataIndex].pendingWireAch;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'frbBalance',
      label: 'Bank Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].frbBalance.replace('$', '');
          const f = rows[dataIndex].frbBalance;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'balanceAfterFunding',
      label: 'Balance After Funding',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].balanceAfterFunding.replace('$', '');
          const f = rows[dataIndex].balanceAfterFunding;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
  ];

  const fileName =
    'SettlementFunding_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Settlement Funding');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Settlement Funding');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  //const [accountValue, setAccountValue] = React.useState('');
  //const [contraAccountValue, setContraAccountValue] = React.useState('');

  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  const [modalLedgieAccount, setModalLedgieAccount] = useState('');
  const [modalRestingBalance, setModalRestingBalance] = useState('');
  const [modalPendingWireAch, setModalPendingWireAch] = useState('');

  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    setModalLedgieAccount(rowData.ledgieAccount);
    setModalRestingBalance(rowData.restingBalance.replace('$', ''));
    setModalPendingWireAch(rowData.pendingWireAch.replace('$', ''));

    setModalTitleValue('Edit');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      //account: query.get('account'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
      //contraAccount: query.get('contraAccount'),
    };

    // if (data.account) {
    //   setAccountValue(data.account);
    // }
    // if (data.contraAccount) {
    //   setContraAccountValue(data.contraAccount);
    // }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    //Get system date
    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.fromDate) {
          setFromDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
        if (!data.toDate) {
          setToDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="SD From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="SD To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Settlement Funding'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Settlement Funding
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      disabled
                      label="Ledgie Account"
                      value={modalLedgieAccount}
                      //onChange={(e) => setModalLedgieAccount(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      label="Resting Balance"
                      value={modalRestingBalance}
                      onChange={(e) => setModalRestingBalance(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      label="Pending Wire/ACH"
                      value={modalPendingWireAch}
                      onChange={(e) => setModalPendingWireAch(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        updateSettlementFunding(
                          settlementFunding,
                          rows,
                          handleClose,
                          modalLedgieAccount,
                          modalRestingBalance,
                          modalPendingWireAch
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
