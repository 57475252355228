// source: feepb/interestrate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.feepb.CreateInterestRateResponse', null, global);
goog.exportSymbol('proto.feepb.DeleteInterestRateRequest', null, global);
goog.exportSymbol('proto.feepb.DeleteInterestRateResponse', null, global);
goog.exportSymbol('proto.feepb.InterestRate', null, global);
goog.exportSymbol('proto.feepb.ListInterestRateRequest', null, global);
goog.exportSymbol('proto.feepb.ListInterestRateResponse', null, global);
goog.exportSymbol('proto.feepb.UpdateInterestRateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.InterestRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.InterestRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.InterestRate.displayName = 'proto.feepb.InterestRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListInterestRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.ListInterestRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListInterestRateRequest.displayName = 'proto.feepb.ListInterestRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListInterestRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.feepb.ListInterestRateResponse.repeatedFields_, null);
};
goog.inherits(proto.feepb.ListInterestRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListInterestRateResponse.displayName = 'proto.feepb.ListInterestRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.CreateInterestRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.CreateInterestRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.CreateInterestRateResponse.displayName = 'proto.feepb.CreateInterestRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.UpdateInterestRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.UpdateInterestRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.UpdateInterestRateResponse.displayName = 'proto.feepb.UpdateInterestRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.DeleteInterestRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.DeleteInterestRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.DeleteInterestRateRequest.displayName = 'proto.feepb.DeleteInterestRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.DeleteInterestRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.DeleteInterestRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.DeleteInterestRateResponse.displayName = 'proto.feepb.DeleteInterestRateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.InterestRate.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.InterestRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.InterestRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.InterestRate.toObject = function(includeInstance, msg) {
  var f, obj = {
interestRateId: jspb.Message.getFieldWithDefault(msg, 1, 0),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 6, ""),
broker: jspb.Message.getFieldWithDefault(msg, 7, ""),
type: jspb.Message.getFieldWithDefault(msg, 8, ""),
fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
federalRate: jspb.Message.getFieldWithDefault(msg, 11, ""),
creditRateAdj: jspb.Message.getFieldWithDefault(msg, 12, ""),
creditRate: jspb.Message.getFieldWithDefault(msg, 13, ""),
debitRateAdj: jspb.Message.getFieldWithDefault(msg, 14, ""),
debitRate: jspb.Message.getFieldWithDefault(msg, 15, ""),
markupRate: jspb.Message.getFieldWithDefault(msg, 16, ""),
markdownRate: jspb.Message.getFieldWithDefault(msg, 17, ""),
note: jspb.Message.getFieldWithDefault(msg, 18, ""),
isStatus: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.InterestRate}
 */
proto.feepb.InterestRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.InterestRate;
  return proto.feepb.InterestRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.InterestRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.InterestRate}
 */
proto.feepb.InterestRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterestRateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFederalRate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditRateAdj(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditRate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebitRateAdj(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebitRate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkupRate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkdownRate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.InterestRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.InterestRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.InterestRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.InterestRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestRateId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFederalRate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreditRateAdj();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreditRate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDebitRateAdj();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDebitRate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMarkupRate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMarkdownRate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getIsStatus();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional int32 interest_rate_id = 1;
 * @return {number}
 */
proto.feepb.InterestRate.prototype.getInterestRateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setInterestRateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_designator = 6;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.type.Date from_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.feepb.InterestRate.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.InterestRate} returns this
*/
proto.feepb.InterestRate.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.InterestRate.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.type.Date to_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.feepb.InterestRate.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.InterestRate} returns this
*/
proto.feepb.InterestRate.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.InterestRate.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string federal_rate = 11;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getFederalRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setFederalRate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string credit_rate_adj = 12;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getCreditRateAdj = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setCreditRateAdj = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string credit_rate = 13;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getCreditRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setCreditRate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string debit_rate_adj = 14;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getDebitRateAdj = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setDebitRateAdj = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string debit_rate = 15;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getDebitRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setDebitRate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string markup_rate = 16;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getMarkupRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setMarkupRate = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string markdown_rate = 17;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getMarkdownRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setMarkdownRate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string note = 18;
 * @return {string}
 */
proto.feepb.InterestRate.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool is_status = 19;
 * @return {boolean}
 */
proto.feepb.InterestRate.prototype.getIsStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.feepb.InterestRate} returns this
 */
proto.feepb.InterestRate.prototype.setIsStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.ListInterestRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.ListInterestRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.ListInterestRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
isStatus: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListInterestRateRequest}
 */
proto.feepb.ListInterestRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListInterestRateRequest;
  return proto.feepb.ListInterestRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListInterestRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListInterestRateRequest}
 */
proto.feepb.ListInterestRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListInterestRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListInterestRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListInterestRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getIsStatus();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.feepb.ListInterestRateRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRateRequest} returns this
 */
proto.feepb.ListInterestRateRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.feepb.ListInterestRateRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRateRequest} returns this
 */
proto.feepb.ListInterestRateRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.feepb.ListInterestRateRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.feepb.ListInterestRateRequest} returns this
 */
proto.feepb.ListInterestRateRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.type.Date from_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListInterestRateRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListInterestRateRequest} returns this
*/
proto.feepb.ListInterestRateRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListInterestRateRequest} returns this
 */
proto.feepb.ListInterestRateRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListInterestRateRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date to_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListInterestRateRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListInterestRateRequest} returns this
*/
proto.feepb.ListInterestRateRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListInterestRateRequest} returns this
 */
proto.feepb.ListInterestRateRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListInterestRateRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_status = 6;
 * @return {boolean}
 */
proto.feepb.ListInterestRateRequest.prototype.getIsStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.feepb.ListInterestRateRequest} returns this
 */
proto.feepb.ListInterestRateRequest.prototype.setIsStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.feepb.ListInterestRateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.ListInterestRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.ListInterestRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.ListInterestRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
interestRatesList: jspb.Message.toObjectList(msg.getInterestRatesList(),
    proto.feepb.InterestRate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListInterestRateResponse}
 */
proto.feepb.ListInterestRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListInterestRateResponse;
  return proto.feepb.ListInterestRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListInterestRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListInterestRateResponse}
 */
proto.feepb.ListInterestRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.feepb.InterestRate;
      reader.readMessage(value,proto.feepb.InterestRate.deserializeBinaryFromReader);
      msg.addInterestRates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListInterestRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListInterestRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListInterestRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListInterestRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestRatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.feepb.InterestRate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InterestRate interest_rates = 1;
 * @return {!Array<!proto.feepb.InterestRate>}
 */
proto.feepb.ListInterestRateResponse.prototype.getInterestRatesList = function() {
  return /** @type{!Array<!proto.feepb.InterestRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.feepb.InterestRate, 1));
};


/**
 * @param {!Array<!proto.feepb.InterestRate>} value
 * @return {!proto.feepb.ListInterestRateResponse} returns this
*/
proto.feepb.ListInterestRateResponse.prototype.setInterestRatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.feepb.InterestRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.feepb.InterestRate}
 */
proto.feepb.ListInterestRateResponse.prototype.addInterestRates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.feepb.InterestRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.feepb.ListInterestRateResponse} returns this
 */
proto.feepb.ListInterestRateResponse.prototype.clearInterestRatesList = function() {
  return this.setInterestRatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.CreateInterestRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.CreateInterestRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.CreateInterestRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.CreateInterestRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
interestRate: (f = msg.getInterestRate()) && proto.feepb.InterestRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.CreateInterestRateResponse}
 */
proto.feepb.CreateInterestRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.CreateInterestRateResponse;
  return proto.feepb.CreateInterestRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.CreateInterestRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.CreateInterestRateResponse}
 */
proto.feepb.CreateInterestRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.feepb.InterestRate;
      reader.readMessage(value,proto.feepb.InterestRate.deserializeBinaryFromReader);
      msg.setInterestRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.CreateInterestRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.CreateInterestRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.CreateInterestRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.CreateInterestRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.feepb.InterestRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional InterestRate interest_rate = 1;
 * @return {?proto.feepb.InterestRate}
 */
proto.feepb.CreateInterestRateResponse.prototype.getInterestRate = function() {
  return /** @type{?proto.feepb.InterestRate} */ (
    jspb.Message.getWrapperField(this, proto.feepb.InterestRate, 1));
};


/**
 * @param {?proto.feepb.InterestRate|undefined} value
 * @return {!proto.feepb.CreateInterestRateResponse} returns this
*/
proto.feepb.CreateInterestRateResponse.prototype.setInterestRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.CreateInterestRateResponse} returns this
 */
proto.feepb.CreateInterestRateResponse.prototype.clearInterestRate = function() {
  return this.setInterestRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.CreateInterestRateResponse.prototype.hasInterestRate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.UpdateInterestRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.UpdateInterestRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.UpdateInterestRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.UpdateInterestRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
interestRate: (f = msg.getInterestRate()) && proto.feepb.InterestRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.UpdateInterestRateResponse}
 */
proto.feepb.UpdateInterestRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.UpdateInterestRateResponse;
  return proto.feepb.UpdateInterestRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.UpdateInterestRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.UpdateInterestRateResponse}
 */
proto.feepb.UpdateInterestRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.feepb.InterestRate;
      reader.readMessage(value,proto.feepb.InterestRate.deserializeBinaryFromReader);
      msg.setInterestRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.UpdateInterestRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.UpdateInterestRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.UpdateInterestRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.UpdateInterestRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.feepb.InterestRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional InterestRate interest_rate = 1;
 * @return {?proto.feepb.InterestRate}
 */
proto.feepb.UpdateInterestRateResponse.prototype.getInterestRate = function() {
  return /** @type{?proto.feepb.InterestRate} */ (
    jspb.Message.getWrapperField(this, proto.feepb.InterestRate, 1));
};


/**
 * @param {?proto.feepb.InterestRate|undefined} value
 * @return {!proto.feepb.UpdateInterestRateResponse} returns this
*/
proto.feepb.UpdateInterestRateResponse.prototype.setInterestRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.UpdateInterestRateResponse} returns this
 */
proto.feepb.UpdateInterestRateResponse.prototype.clearInterestRate = function() {
  return this.setInterestRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.UpdateInterestRateResponse.prototype.hasInterestRate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.DeleteInterestRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.DeleteInterestRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.DeleteInterestRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.DeleteInterestRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
interestRateId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.DeleteInterestRateRequest}
 */
proto.feepb.DeleteInterestRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.DeleteInterestRateRequest;
  return proto.feepb.DeleteInterestRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.DeleteInterestRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.DeleteInterestRateRequest}
 */
proto.feepb.DeleteInterestRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterestRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.DeleteInterestRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.DeleteInterestRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.DeleteInterestRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.DeleteInterestRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestRateId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 interest_rate_id = 1;
 * @return {number}
 */
proto.feepb.DeleteInterestRateRequest.prototype.getInterestRateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.feepb.DeleteInterestRateRequest} returns this
 */
proto.feepb.DeleteInterestRateRequest.prototype.setInterestRateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.feepb.DeleteInterestRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.feepb.DeleteInterestRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.feepb.DeleteInterestRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.DeleteInterestRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
interestRateId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.DeleteInterestRateResponse}
 */
proto.feepb.DeleteInterestRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.DeleteInterestRateResponse;
  return proto.feepb.DeleteInterestRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.DeleteInterestRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.DeleteInterestRateResponse}
 */
proto.feepb.DeleteInterestRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterestRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.DeleteInterestRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.feepb.DeleteInterestRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.DeleteInterestRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.DeleteInterestRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterestRateId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 interest_rate_id = 1;
 * @return {number}
 */
proto.feepb.DeleteInterestRateResponse.prototype.getInterestRateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.feepb.DeleteInterestRateResponse} returns this
 */
proto.feepb.DeleteInterestRateResponse.prototype.setInterestRateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.feepb);
