/*ReactJS*/
import React, { useEffect, useContext } from 'react';

/*Material UI Components*/
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ServiceContext } from 'context/ServiceContext';

import { LazyLoadRequest } from '../../proto/commonpb/list_grpc_web_pb';

export default function ClientAccountNameSelect(props) {
  const [accounts, setAccounts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loadingInit = open && options.length === 0;
  const [loading, setLoading] = React.useState(loadingInit);
  const { listAccountServiceClient: listClient } = useContext(ServiceContext);

  const accountNameOnChange = async (key) => {
    setLoading(true);

    let listAccountReq = new LazyLoadRequest();
    listAccountReq.setLimit(100);
    listAccountReq.setKey(key);

    await listClient.lazyLoadClientAccount(listAccountReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        return;
      } else {
        let data = res.toObject().accountsList;

        const filteredValues = data.reduce((dup, current) => {
          const x = dup.find(
            (item) => item.accountName === current.accountName
          );
          if (!x) {
            return dup.concat([current]);
          } else {
            return dup;
          }
        }, []);
        setOptions(filteredValues);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
      const loadAccounts = async () => {
        let listAccountReq = new LazyLoadRequest();
        listAccountReq.setLimit(100);
        listAccountReq.setKey(props.value);

        await listClient.lazyLoadClientAccount(
          listAccountReq,
          {},
          (err, res) => {
            if (err) {
              console.log(err);
              return;
            } else {
              let data = res.toObject().accountsList;

              const filteredValues = data.reduce((dup, current) => {
                const x = dup.find(
                  (item) => item.accountName === current.accountName
                );
                if (!x) {
                  return dup.concat([current]);
                } else {
                  return dup;
                }
              }, []);
              setAccounts(filteredValues);
            }
          }
        );
      };

      loadAccounts();
    }
  }, [open, listClient, props]);

  if (accounts.length !== 0) {
    const selectedAccountName = accounts
      .filter(function(v) {
        return v.accountName === props.value ? v : '';
      })
      .map(function(accountName) {
        return accountName;
      });

    return (
      <Autocomplete
        freeSolo={true}
        autoHighlight={true}
        clearOnEscape={true}
        id={props.id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) =>
          option.accountName === value.accountName
        }
        getOptionLabel={(option) => option.accountName}
        options={options}
        loading={loading}
        onChange={props.setNewValue}
        onInputChange={props.setNewValue}
        defaultValue={props.value ? selectedAccountName[0] : null}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(ev) => {
              if (ev.target.value !== '' || ev.target.value !== null) {
                accountNameOnChange(ev.target.value);
              }
            }}
            onClick={(ev) => {
              if (ev.target.value !== '' || ev.target.value !== null) {
                accountNameOnChange(ev.target.value);
              }
            }}
            label="Account Name"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    return <div>Loading component...</div>;
  }
}
