/*ReactJS*/
import React, { useEffect, useContext, useCallback } from 'react';

/*Material UI Components*/
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { LazyLoadRequest } from '../../proto/commonpb/list_grpc_web_pb';

import { ServiceContext } from 'context/ServiceContext';

export default function ClientAccountNoSelect(props) {
  const [accounts, setAccounts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loadingInit = open && options.length === 0;
  const [loading, setLoading] = React.useState(loadingInit);
  const { listAccountServiceClient: listClient } = useContext(ServiceContext);

  const accountNoOnChange = async (key) => {
    setLoading(true);

    let listAccountReq = new LazyLoadRequest();
    listAccountReq.setLimit(100);
    listAccountReq.setKey(key);

    await listClient.lazyLoadClientAccount(listAccountReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        return;
      } else {
        let data = res.toObject().accountsList;

        const filteredValues = data.reduce((dup, current) => {
          const x = dup.find((item) => item.accountNo === current.accountNo);
          if (!x) {
            return dup.concat([current]);
          } else {
            return dup;
          }
        }, []);

        if (props.selectedCorrespondent !== undefined) {
          const filterByCorrespondent = filteredValues.filter(function(item) {
            return item.correspondent === props.selectedCorrespondent;
          });

          setOptions(filterByCorrespondent);
        } else {
          setOptions(filteredValues);
        }
      }
    });

    setLoading(false);
  };

  const loadAccounts = useCallback(async () => {
    let listAccountReq = new LazyLoadRequest();
    listAccountReq.setLimit(1000);
    listAccountReq.setKey('');

    await listClient.lazyLoadClientAccount(listAccountReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        return;
      } else {
        let data = res.toObject().accountsList;

        const filteredValues = data.reduce((dup, current) => {
          const x = dup.find((item) => item.accountNo === current.accountNo);
          if (!x) {
            return dup.concat([current]);
          } else {
            return dup;
          }
        }, []);

        setAccounts(filteredValues);
      }
    });
  }, [listClient]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      loadAccounts();
    }
  }, [open, loadAccounts]);

  if (accounts.length !== 0) {
    const selectedAccountNo = accounts
      .filter(function(v) {
        return v.accountNo === props.value ? v : '';
      })
      .map(function(accountNo) {
        return accountNo;
      });

    return (
      <Autocomplete
        freeSolo={true}
        autoHighlight={true}
        clearOnEscape={true}
        id={props.id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) =>
          option.accountNo === value.accountNo
        }
        getOptionLabel={(option) =>
          option.accountName + ' - ' + option.accountNo
        }
        options={options}
        loading={loading}
        onChange={props.setNewValue}
        onInputChange={props.allowInputChange ? props.setNewValue : null}
        defaultValue={props.value ? selectedAccountNo[0] : null}
        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required}
            onChange={(ev) => {
              if (ev.target.value !== '' || ev.target.value !== null) {
                accountNoOnChange(ev.target.value);
              }
            }}
            onClick={(ev) => {
              if (ev.target.value !== '' || ev.target.value !== null) {
                accountNoOnChange(ev.target.value);
              }
            }}
            label={props.label}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    return <div>Loading component...</div>;
  }
}
