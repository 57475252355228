import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: 'bold',
  },
  pageContainer: {
    padding: 20,
  },
  container: {
    padding: '25px 30px 25px 30px',
    backgroundColor: '#ffffff',
    border: '1px solid #e8e8e8',
    borderRadius: 5,
  },
  mt20: {
    marginTop: '20px',
  },
}));
