import moment from 'moment-timezone';

const google_date = require('../../src/google/type/date_pb.js');
const timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

export function objectToProtoDate(oDate) {
  if (!oDate) return;

  const pb = new google_date.Date();
  pb.setMonth(oDate.month);
  pb.setYear(oDate.year);
  pb.setDay(oDate.day);

  return pb;
}

export function objectToProtoTimeStamp(oTimeStamp) {
  if (!oTimeStamp) return;

  const pb = new timestamp_pb.Timestamp();
  pb.setNanos(oTimeStamp.nanos);
  pb.setSeconds(oTimeStamp.seconds);

  return pb;
}

export function protoTimeSpanObjectToString(oTimeSpan, format) {
  if (!oTimeSpan) return;

  const secs = oTimeSpan.seconds * 1000;
  const nanos = oTimeSpan.nanos / 1000000;

  return moment(new Date(secs + nanos)).format(
    format || moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
  );
}

export function stringToProtoDate(sparam) {
  if (!sparam) return;

  const pb = new google_date.Date();
  const m = moment(sparam);
  const [year, month, day] = [m.year(), m.month() + 1, m.date()];

  const parsedYear = parseInt(year);
  const parsedMonth = parseInt(month);
  const parsedDay = parseInt(day);

  if (
    Number.isInteger(parsedYear) &&
    parsedYear >= 1900 &&
    parsedYear <= 3000 &&
    Number.isInteger(parsedMonth) &&
    parsedMonth >= 1 &&
    parsedMonth <= 12 &&
    Number.isInteger(parsedDay) &&
    parsedDay >= 1 &&
    parsedDay <= 31
  ) {
    pb.setYear(parsedYear);
    pb.setMonth(parsedMonth);
    pb.setDay(parsedDay);

    return pb;
  } else {
    throw new Error(
      `Incorrect date format "${sparam}", please use format "2021-01-01" instead.`
    );
  }
}

export function stringToProtoTimeSpan(sparam) {
  if (!sparam) return;

  const pb = new timestamp_pb.Timestamp();
  pb.setNanos(0);
  pb.setSeconds(moment(sparam, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).unix());
  return pb;
}

export function stringToUTCProtoTimeSpan(sparam) {
  if (!sparam) return;

  const utcs = moment(sparam, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    .toDate()
    .toUTCString();
  const pb = new timestamp_pb.Timestamp();
  pb.setNanos(0);
  pb.setSeconds(moment(utcs).unix());

  return pb;
}

export function stringToModalDate(sparam) {
  if (!sparam || sparam === '--') return '';

  const utcs = moment(new Date(sparam))
    .toDate()
    .toUTCString();
  return moment(utcs).format('YYYY-MM-DDThh:mm:ss');
}

export function protoDateObjectToString(o, format) {
  if (!o) return;
  const { year, month, day } = o;

  return moment(new Date(year, month - 1, day, 0, 0, 0, 0)).format(
    format || 'yyyy-MM-DD'
  );
}

export function protoTimeStampObjectToString(o) {
  if (!o) return;

  const secs = o.seconds * 1000;
  const nanos = o.nanos / 1000000;

  return moment(new Date(secs + nanos)).format(
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
  );
}

export function stringToProtoTradeTimeStamp(sparam) {
  if (!sparam) return;

  return timestamp_pb.Timestamp.fromDate(
    moment.tz(sparam, 'YYYY-M-D H:m', 'America/New_York').toDate()
  );
}

export function padString(value, padchar, charCount) {
  const pad = padchar.repeat(charCount);
  const formatted = (pad + value).slice(-charCount);

  return formatted;
}
