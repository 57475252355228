/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListPositionReconRequest } from '../../../proto/reconpb/positionrecon_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Visibility as ViewIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import ContraAccountSelect from '../../../components/AutoComplete/ContraAccount';
import ContraSourceSelect from '../../../components/Dropdown/ContraSource';
import PositionReconDetailsTable from './PositionReconDetailsTable';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PositionReconTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    positionReconServiceClient: positionrecon,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = (isSearch, type) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (dateTypeValue ? '&dateType=' + dateTypeValue : '') +
      (accountValue ? '&account=' + accountValue : '') +
      (contraAccountValue ? '&contraAccount=' + contraAccountValue : '') +
      (contraSourceValue ? '&contraSource=' + contraSourceValue : '');
    window.history.pushState({}, null, pathWithParams);

    if (isSearch) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    let list = new ListPositionReconRequest();
    list.setAccount(accountValue);
    list.setSymbol(symbolValue);
    list.setContraAccount(contraAccountValue);
    list.setContraSource(contraSourceValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setDateType(dateTypeValue);

    positionrecon.listPositionRecon(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      let rows = [];

      if (type === 'show breaks only') {
        rows = res.toObject().positionReconsList.filter(function(data) {
          return parseFloat(data.diffQty) !== 0;
        });
      } else {
        rows = res.toObject().positionReconsList;
      }
      setRows(rows);
      if (isSearch) {
        notifyInfo(rows.length + ' search results.');
        setLoading(false);
      }
    });
  };

  const showBreaksOnly = (type) => {
    search(false, type);
    setIsBreakOnly(!isBreakOnly ? true : false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Position Reconciliation');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="details"
              onClick={() => {
                handleOpen(
                  rows[dataIndex].systemDate,
                  rows[dataIndex].originalCusip,
                  rows[dataIndex].account
                );
              }}
            >
              <ViewIcon />
            </IconButton>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'qty',
      label: '1115 - Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].qty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'qty2',
      label: '1200 - Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].qty2;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'account',
      label: 'Account',
    },
    {
      name: 'contraAccount',
      label: 'Contra Account',
    },
    {
      name: 'contraQty',
      label: 'Contra Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].contraQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'contraSource',
      label: 'Contra Source',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'diffQty',
      label: 'Diff Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].diffQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'reorg',
      label: 'Reorg',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'systemDate',
      label: 'Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate
            ? formatPbDate(rows[dataIndex].systemDate)
            : '--';
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const fileName =
    'PositionRecon_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 12, 3, 4, 6, 9, 11, 7, 1, 2, 5, 8, 13, 10],
    selectableRows: 'none',
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 2500, 5000],
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Position Reconciliation');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Position Reconciliation');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [dateTypeValue, setDateTypeValue] = React.useState('trade_date');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [accountValue, setAccountValue] = React.useState('');
  const [contraAccountValue, setContraAccountValue] = React.useState('');
  const [contraSourceValue, setContraSourceValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isBreakOnly, setIsBreakOnly] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      account: query.get('account'),
      systemDate: query.get('systemDate'),
      dateType: query.get('dateType'),
      contraAccount: query.get('contraAccount'),
      contraSource: query.get('contraSource'),
    };

    if (data.account) {
      setAccountValue(data.account);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.dateType) {
      setDateTypeValue(data.dateType);
    }
    if (data.contraAccount) {
      setContraAccountValue(data.contraAccount);
    }
    if (data.contraSource) {
      setContraSourceValue(data.contraSource);
    }

    setCurrentPath(path);

    //Get system date
    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.systemDate) {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });
  }, [systemDate, params]);

  const [selectedSystemDate, setSelectedSystemDate] = React.useState('');
  const [selectedOrigCusip, setSelectedOrigCusip] = React.useState('');
  const [selectedAccountNo, setSelectedAccountNo] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (systemDate, originalCusip, accountNo) => {
    setSelectedSystemDate(systemDate);
    setSelectedOrigCusip(originalCusip);
    setSelectedAccountNo(accountNo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => setDateTypeValue(e.target.value)}
                >
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountValue}
                  onChange={(e) => {
                    setAccountValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <ContraAccountSelect
                  freeSolo={true}
                  name="contraAccount"
                  label="Contra Account"
                  value={contraAccountValue}
                  onChange={(e) => {
                    setContraAccountValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="contra_source">
                  Contra Source
                </InputLabel>
                <ContraSourceSelect
                  required={false}
                  labelId="contraSource"
                  fullWidth
                  value={contraSourceValue}
                  onChange={(e) => setContraSourceValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search(true, '')}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ViewIcon />}
              onClick={() => {
                showBreaksOnly(isBreakOnly ? 'show breaks only' : 'show all');
              }}
            >
              {isBreakOnly ? 'Show Breaks Only' : 'Show All'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Position Reconciliations'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <PositionReconDetailsTable
                systemdate={selectedSystemDate}
                datetype={dateTypeValue}
                origcusip={selectedOrigCusip}
                accountno={selectedAccountNo}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
