/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  CallLog,
  ListCallLogRequest,
  ListCallLogPendingRequest,
} from '../../../proto/marginpb/calllog_grpc_web_pb';

import { ListNoteRequest } from '../../../proto/commonpb/note_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Create as EditIcon, Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatCurrency, formatPbDate } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from 'components/AutoComplete/MasterAccountNo';
import CallStatusReasonSelect from 'components/AutoComplete/CallStatusReason';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

import NoteTable from './NoteTable';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

function getCallLogNotes(note, accountId, linkId, noteType, setNoteList) {
  let list = new ListNoteRequest();
  list.setAccountId(accountId);
  list.setLinkId(linkId);
  list.setNoteType(noteType);

  note.listNote(list, {}, (err, response) => {
    if (err) {
      console.error(err);
      notifyError(err.message);
      return;
    }
    console.log(response.toObject());

    setNoteList(response.toObject().notesList);
  });
}

function saveCallLog(
  calllog,
  closeModal,
  requirementLogId,
  modalCallStatus,
  modalCallStatusReason,
  rows
) {
  if (!modalCallStatus) {
    notifyError('Call status is required.');
    return;
  }
  if (!modalCallStatusReason) {
    notifyError('Call status reason is required.');
    return;
  }

  let updateReq = new CallLog();
  updateReq.setRequirementLogId(requirementLogId);
  updateReq.setCallStatus(modalCallStatus);
  updateReq.setCallStatusReason(modalCallStatusReason);
  calllog.updateCallLog(updateReq, {}, (err) => {
    if (err) {
      console.log(err);
      notifyError(err.message);
      return;
    } else {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].requirementLogId === requirementLogId) {
          rows[i].callStatus = modalCallStatus;
          rows[i].callStatusReason = modalCallStatusReason;
        }
      }
      notifySuccess('Call Log has been updated.');
      closeModal();
    }
  });
}

export default function CallLogTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    callLogServiceClient: calllog,
    noteServiceClient: note,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);
  const [noteList, setNoteList] = useState();

  useEffect(() => {
    if (noteList) {
      setOpen(true);
    }
  }, [noteList]);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (tradeDateValue ? '&tradeDate=' + tradeDateValue : '') +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListCallLogRequest();
    list.setCorrespondent(correspondentValue);
    list.setMasterAccountNo(masterAccountNoValue);
    list.setAccountName(accountNameValue);
    if (tradeDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = tradeDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setTradeDate(gglSystemDate);
    }

    calllog.listCallLog(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().callLogsList.map((data) => ({
        requirementLogId: data.requirementLogId,
        tradeDate: formatPbDate(data.tradeDate),
        dueDate: formatPbDate(data.dueDate),
        accountId: data.accountId,
        correspondent: data.correspondent,
        masterAccountNo: data.masterAccountNo,
        accountName: data.accountName,
        callType: data.callType,
        callReq: formatCurrency(data.callReq),
        callAmt: formatCurrency(data.callAmt),
        callStatus: data.callStatus,
        callStatusReason: data.callStatusReason,
        callStatusReasonCount: data.callStatusReasonCount,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate
          ? moment(new Date(data.modifiedDate.seconds * 1000)).format(
              'MM/DD/YYYY'
            )
          : '',
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const showAllPending = () => {
    let list = new ListCallLogPendingRequest();

    calllog.listCallLogPending(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().callLogsList.map((data) => ({
        requirementLogId: data.requirementLogId,
        tradeDate: formatPbDate(data.tradeDate),
        dueDate: formatPbDate(data.dueDate),
        accountId: data.accountId,
        correspondent: data.correspondent,
        masterAccountNo: data.masterAccountNo,
        accountName: data.accountName,
        callType: data.callType,
        callReq: formatCurrency(data.callReq),
        callAmt: formatCurrency(data.callAmt),
        callStatus: data.callStatus,
        callStatusReason: data.callStatusReason,
        callStatusReasonCount: data.callStatusReasonCount,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate
          ? moment(new Date(data.modifiedDate.seconds * 1000)).format(
              'MM/DD/YYYY'
            )
          : '',
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Call Log');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="Edit" arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[dataIndex])}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'callAmt',
      label: 'Call Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].callAmt.replace('$', '');
          const f = rows[dataIndex].callAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'callReq',
      label: 'Call Requirement',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].callReq.replace('$', '');
          const f = rows[dataIndex].callReq;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'callStatus',
      label: 'Call Status',
    },
    {
      name: 'callStatusReason',
      label: 'Call Status Reason',
    },
    {
      name: 'callStatusReasonCount',
      label: 'Call Status Reason Count',
      options: {
        setCellProps: () => ({
          style: { align: 'right' },
        }),
      },
    },
    {
      name: 'callType',
      label: 'Call Type',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'dueDate',
      label: 'Due Date',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'modifiedBy',
      label: 'Modified By',
    },
    {
      name: 'modifiedDate',
      label: 'Modified Date',
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
    },
  ];

  const fileName =
    'CallLog_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 13, 9, 8, 10, 1, 7, 3, 2, 4, 5, 6, 11, 12],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Call Log');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Call Log');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['callAmt', 'callReq']}
          columnsWithQty={[]}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [tradeDateValue, setTradeDateValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');

  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      tradeDate: query.get('tradeDate'),
    };

    if (data.tradeDate) {
      setTradeDateValue(data.tradeDate);
    }
    if (data.correspondent && data.correspondent !== 'none') {
      setCorrespondentValue(data.correspondent);
    }
    if (data.masterAccountNo && data.masterAccountNo !== 'none') {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountName && data.accountName !== 'none') {
      setAccountNameValue(data.accountName);
    }
    if (!data.tradeDate) {
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setTradeDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  const [modalAccountId, setModalAccountId] = React.useState('');
  const [modalRequirementLogId, setModalRequirementLogId] = React.useState('');
  const [modalAccountNameValue, setModalAccountNameValue] = React.useState('');
  const [
    modalMasterAccountNoValue,
    setModalMasterAccountNoValue,
  ] = React.useState('');
  const [modalCallTypeValue, setModalCallTypeValue] = React.useState('');

  const [modalCallStatusValue, setModalCallStatusValue] = React.useState('');
  const [
    modalCallStatusReasonValue,
    setModalCallStatusReasonValue,
  ] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Call Log');

  const handleOpen = (rowData) => {
    console.log('click');
    setModalAccountId(rowData.accountId);
    setModalRequirementLogId(rowData.requirementLogId);
    setModalAccountNameValue(rowData.accountName);
    setModalMasterAccountNoValue(rowData.masterAccountNo);
    setModalCallTypeValue(rowData.callType);
    setModalCallStatusValue(rowData.callStatus);
    setModalCallStatusReasonValue(rowData.callStatusReason);

    setModalTitleValue('Edit');

    getCallLogNotes(
      note,
      rowData.accountId,
      rowData.requirementLogId,
      'call_logs',
      setNoteList
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Trade Date"
                  type="date"
                  value={tradeDateValue}
                  onChange={(e) => setTradeDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => showAllPending()}
            >
              Show All Pending
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Call Logs'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalLg}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Call Log - [{modalMasterAccountNoValue}] -{' '}
              {modalAccountNameValue} [{modalCallTypeValue}]
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Call Status *</InputLabel>
                    <Select
                      fullWidth
                      value={modalCallStatusValue}
                      onChange={(e) => setModalCallStatusValue(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="pending">pending</MenuItem>
                      <MenuItem value="satisfied">satisfied</MenuItem>
                      <MenuItem value="overdue">overdue</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <CallStatusReasonSelect
                      id="callStatusReason"
                      labelid="callStatusReason"
                      label="Call Status Reason"
                      value={modalCallStatusReasonValue}
                      onChange={(e) =>
                        setModalCallStatusReasonValue(e.target.value)
                      }
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalCallStatusReasonValue(
                            newValue.code ? newValue.code : newValue
                          );
                        } else {
                          setModalCallStatusReasonValue('');
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <NoteTable
                    accountid={modalAccountId}
                    linkid={modalRequirementLogId}
                    notelist={noteList}
                    style={{ paddingBottom: 0 }}
                  />
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveCallLog(
                          calllog,
                          handleClose,
                          modalRequirementLogId,
                          modalCallStatusValue,
                          modalCallStatusReasonValue,
                          rows
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
