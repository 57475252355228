/*ReactJS*/
import React from 'react';

/*Material UI Components*/
import { Select, MenuItem } from '@material-ui/core';

class AccountDesignatorSelect extends React.Component {
  render() {
    return (
      <Select
        name={this.props.name}
        required
        displayEmpty
        fullWidth
        value={this.props.value}
        onChange={this.props.onChange}
        inputProps={this.props.inputProps}
      >
        <MenuItem value="">
          <em>Blank</em>
        </MenuItem>
        <MenuItem value="customer">Customer</MenuItem>
        <MenuItem value="gl">Gen. Ledger</MenuItem>
        <MenuItem value="house_hold_member_affiliated">
          House Hold Member & Affiliated
        </MenuItem>
        <MenuItem value="memo_account">Memo Account</MenuItem>
        <MenuItem value="omnibus">Omnibus</MenuItem>
        <MenuItem value="pab">PAB</MenuItem>
        <MenuItem value="paib">PAIB</MenuItem>
        <MenuItem value="share_holders">Share Holders</MenuItem>
        <MenuItem value="prop">Prop</MenuItem>
        <MenuItem value="deposit">Deposit</MenuItem>
        <MenuItem value="acats_wash">ACATS Wash Account</MenuItem>
        <MenuItem value="firm">Firm Account</MenuItem>
      </Select>
    );
  }
}

export default AccountDesignatorSelect;
