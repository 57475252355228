/*Service*/
import {
  Owner,
  DeleteOwnerRequest,
} from '../../../proto/accountpb/owner_grpc_web_pb';

/*ReactJS*/
import React, { useState, useContext } from 'react';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Mask*/
import InputMask from 'react-input-mask';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Countries Dropdown Component*/
import CountriesSelect from '../../../components/AutoComplete/Countries';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Re-render component
===========================================================================================*/
function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}
/*=========================================================================================
End of re-render component
===========================================================================================*/

export default function OwnerTable({
  accountid,
  ownerlist,
  generalTaxCountry,
}) {
  const rows = ownerlist;

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();

  const reRender = RenderComponent(); //Re-renders component when something change in component state
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { ownerServiceClient: owner } = useContext(ServiceContext);

  /*=========================================================================================
Add and Update owner function
===========================================================================================*/
  function saveOwner(
    reRender,
    isAdd,
    closeModal,
    ownerId,
    accountId,
    ownerType,
    firstName,
    lastName,
    taxCountry,
    taxId,
    taxIdType
  ) {
    if (!ownerType) {
      notifyError('Owner type is required.');
      return;
    }
    if (!firstName) {
      notifyError('First name is required.');
      return;
    }
    if (!lastName) {
      notifyError('Last name is required.');
      return;
    }
    if (!taxCountry) {
      notifyError('Taxcountry is required.');
      return;
    }
    if (!taxIdType && taxCountry === 'United States of America') {
      notifyError('Tax ID type is required.');
      return;
    }

    if (isAdd) {
      let addReq = new Owner();
      addReq.setAccountId(accountId);
      addReq.setOwnerType(ownerType);
      addReq.setFirstName(firstName);
      addReq.setLastName(lastName);
      addReq.setTaxCountry(taxCountry);
      addReq.setTaxId(taxId);
      addReq.setTaxIdType(taxIdType);

      owner.createOwner(addReq, {}, (err, res) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }
        rows.push(res.toObject().owner);
        notifySuccess('New owner has been added.');
        reRender();
        closeModal();
      });
    } else {
      let updateReq = new Owner();
      updateReq.setOwnerId(ownerId);
      updateReq.setAccountId(accountId);
      updateReq.setOwnerType(ownerType);
      updateReq.setFirstName(firstName);
      updateReq.setLastName(lastName);
      updateReq.setTaxCountry(taxCountry);
      updateReq.setTaxId(taxId);
      updateReq.setTaxIdType(taxIdType);

      owner.updateOwner(updateReq, {}, (err) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].ownerId === ownerId) {
            rows[i].ownerType = ownerType;
            rows[i].firstName = firstName;
            rows[i].lastName = lastName;
            rows[i].taxCountry = taxCountry;
            rows[i].taxId = taxId;
            rows[i].taxIdType = taxIdType;
          }
        }
        notifySuccess('Owner has been updated.');
        reRender();
        closeModal();
      });
    }
  }
  /*=========================================================================================
End of Add and Update owner function
===========================================================================================*/

  /*=========================================================================================
Delete owner function: Multiple
===========================================================================================*/
  function removeOwner(reRender, ownerIds, handleClick, confirm) {
    if (ownerIds.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        ownerIds.length +
        ' Owner(s) in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        ownerIds.forEach(removeFunction);

        function removeFunction(item) {
          let deleteReq = new DeleteOwnerRequest();
          deleteReq.setOwnerId(item);

          owner.deleteOwner(deleteReq, {}, (err) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
              return;
            }
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].ownerId === item) {
                rows.splice(c, 1);
              }
            }
            reRender();
          });
        }

        handleClick('', '', true);
        notifySuccess(ownerIds.length + ' Owner has been deleted');
      })
      .catch(() => {
        /* ... */
      });
  }
  /*=========================================================================================
End of delete client account function: Multiple
===========================================================================================*/

  /*=========================================================================================
Delete owner function: Single
===========================================================================================*/
  function removeOwnerSingle(reRender, ownerId, firstName, lastName, confirm) {
    confirm({
      description:
        "You are about to delete owner '" +
        firstName +
        ' ' +
        lastName +
        "' in your system. Please confirm your action.",
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeleteOwnerRequest();
        deleteReq.setOwnerId(ownerId);

        owner.deleteOwner(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
            return;
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].ownerId === ownerId) {
              rows.splice(c, 1);
            }
          }
          reRender();
        });
        notifySuccess('owner has been deleted');
      })
      .catch(() => {
        // showToastMessage("error", "System error.");
      });
  }
  /*=========================================================================================
End of delete owner function: Single
===========================================================================================*/

  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const [isVisibleOwnerType, setIsVisibleOwnerType] = React.useState(true);
  const [isVisibleFirstName, setIsVisibleFirstName] = React.useState(true);
  const [isVisibleLastName, setIsVisibleLastName] = React.useState(true);
  const [isVisibleTaxcountry, setIsVisibleTaxcountry] = React.useState(true);
  const [isVisibleTaxId, setIsVisibleTaxId] = React.useState(true);
  const [isVisibleTaxIdType, setIsVisibleTaxIdType] = React.useState(true);

  const columns = [
    {
      id: '1',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '2',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '3',
      name: 'ownerType',
      label: 'Owner Type',
    },
    {
      id: '4',
      name: 'firstName',
      label: 'First Name',
    },
    {
      id: '5',
      name: 'lastName',
      label: 'Last Name',
    },
    {
      id: '6',
      name: 'taxCountry',
      label: 'Tax Country',
    },
    {
      id: '7',
      name: 'taxId',
      label: 'Tax ID',
    },
    {
      id: '8',
      name: 'taxIdType',
      label: 'Tax ID Type',
    },
  ];

  const handleClick = (event, ownerId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(ownerId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, ownerId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
      setRowID([]);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].ownerId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rows[rowIndex].ownerId}
        selected={isItemSelected}
      >
        {isVisibleOwnerType ||
          isVisibleFirstName ||
          isVisibleLastName ||
          isVisibleTaxcountry ||
          isVisibleTaxId ||
          isVisibleTaxIdType ? (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => handleClick(event, rows[rowIndex].ownerId)}
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        ) : null}
        {isVisibleOwnerType ||
          isVisibleFirstName ||
          isVisibleLastName ||
          isVisibleTaxcountry ||
          isVisibleTaxId ||
          isVisibleTaxIdType ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="delete"
              onClick={() => {
                removeOwnerSingle(
                  reRender,
                  rows[rowIndex].ownerId,
                  rows[rowIndex].firstName,
                  rows[rowIndex].lastName,
                  confirm
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleOwnerType ||
          isVisibleFirstName ||
          isVisibleLastName ||
          isVisibleTaxcountry ||
          isVisibleTaxId ||
          isVisibleTaxIdType ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="edit"
              onClick={() => handleOpen(rows[rowIndex], false)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleOwnerType ? (
          <TableCell align="left">{rows[rowIndex].ownerType}</TableCell>
        ) : null}
        {isVisibleFirstName ? (
          <TableCell align="left">{rows[rowIndex].firstName}</TableCell>
        ) : null}
        {isVisibleLastName ? (
          <TableCell align="left">{rows[rowIndex].lastName}</TableCell>
        ) : null}
        {isVisibleTaxcountry ? (
          <TableCell align="left">{rows[rowIndex].taxCountry}</TableCell>
        ) : null}
        {isVisibleTaxId ? (
          <TableCell align="left">{rows[rowIndex].taxId}</TableCell>
        ) : null}
        {isVisibleTaxIdType ? (
          <TableCell align="left">{rows[rowIndex].taxIdType}</TableCell>
        ) : null}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    if (column === 'ownerType') {
      if (action === 'remove') {
        setIsVisibleOwnerType(false);
      } else {
        setIsVisibleOwnerType(true);
      }
    }

    if (column === 'firstName') {
      if (action === 'remove') {
        setIsVisibleFirstName(false);
      } else {
        setIsVisibleFirstName(true);
      }
    }

    if (column === 'lastName') {
      if (action === 'remove') {
        setIsVisibleLastName(false);
      } else {
        setIsVisibleLastName(true);
      }
    }

    if (column === 'taxCountry') {
      if (action === 'remove') {
        setIsVisibleTaxcountry(false);
      } else {
        setIsVisibleTaxcountry(true);
      }
    }

    if (column === 'taxId') {
      if (action === 'remove') {
        setIsVisibleTaxId(false);
      } else {
        setIsVisibleTaxId(true);
      }
    }

    if (column === 'taxIdType') {
      if (action === 'remove') {
        setIsVisibleTaxIdType(false);
      } else {
        setIsVisibleTaxIdType(true);
      }
    }
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/

  /*========================================================================================= 
  Owner modal
  ===========================================================================================*/
  const [ownerId, setOwnerId] = React.useState('');
  const [maskValue, setMaskValue] = React.useState('999999999');
  const [modalOwnerTypeValue, setModalOwnerTypeValue] = React.useState('');
  const [modalFirstNameValue, setModalFirstNameValue] = React.useState('');
  const [modalLastNameValue, setModalLastNameValue] = React.useState('');
  const [modalTaxcountryValue, setModalTaxcountryValue] = React.useState('');
  const [modalTaxIdValue, setModalTaxIdValue] = React.useState('');
  const [modalTaxIdTypeValue, setModalTaxIdTypeValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalOwnerTypeValue('primary');
      setModalFirstNameValue('');
      setModalLastNameValue('');
      setModalTaxcountryValue(generalTaxCountry); // set owner tax country by general taxt country by default
      setModalTaxIdValue('');
      setModalTaxIdTypeValue('');

      setMaskValue('999999999');
      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setOwnerId(rowData.ownerId);
      setModalOwnerTypeValue(rowData.ownerType);
      setModalFirstNameValue(rowData.firstName);
      setModalLastNameValue(rowData.lastName);
      setModalTaxcountryValue(rowData.taxCountry);
      setModalTaxIdValue(rowData.taxId);
      setModalTaxIdTypeValue(rowData.taxIdType);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*========================================================================================= 
  End of owner modal
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<DeleteIcon />}
              onClick={() => {
                removeOwner(reRender, rowID, handleClick, confirm);
              }}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Owners'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Owner
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Owner Type *</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalOwnerTypeValue}
                      onChange={(e) => setModalOwnerTypeValue(e.target.value)}
                    >
                      <MenuItem value="primary">Primary</MenuItem>
                      <MenuItem value="secondary">Secondary</MenuItem>
                      <MenuItem value="joint">Joint</MenuItem>
                      <MenuItem value="beneficiary">Beneficiary</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required
                      type="text"
                      label="First Name"
                      fullWidth={true}
                      value={modalFirstNameValue}
                      onChange={(e) => setModalFirstNameValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      required
                      type="text"
                      label="Last Name"
                      fullWidth={true}
                      value={modalLastNameValue}
                      onChange={(e) => setModalLastNameValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <CountriesSelect
                      labelid="taxcountry"
                      label="Tax Country"
                      value={modalTaxcountryValue}
                      onChange={(e) => setModalTaxcountryValue(e.target.value)}
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalTaxcountryValue(newValue.code);
                        } else {
                          setModalTaxcountryValue('');
                          return;
                        }
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputMask
                      mask={maskValue}
                      formatChars={{ "9": "[0-9]", "*": "[ -~]" }}
                      value={modalTaxIdValue}
                      onChange={(e) => setModalTaxIdValue(e.target.value)}
                    >
                      {() => (
                        <TextField
                          id="taxID"
                          label="Tax ID"
                          type="text"
                          name="taxID"
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </InputMask>
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Tax ID Type</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalTaxIdTypeValue}
                      onChange={(e) => {
                        setModalTaxIdTypeValue(e.target.value);

                        if (e.target.value === 'ssn') {
                          setMaskValue('999-99-9999');
                        } else if (e.target.value === 'tin') {
                          setMaskValue('99-9999999');
                        } else if (e.target.value === '') {
                          setMaskValue('*'.repeat(40));
                        }
                      }}
                    >
                      {modalTaxcountryValue !== 'US' ? (
                        <MenuItem value="">Blank</MenuItem>
                      ) : null}
                      <MenuItem value="ssn">SSN</MenuItem>
                      <MenuItem value="tin">TIN</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveOwner(
                          reRender,
                          modalAddAction,
                          handleClose,
                          ownerId,
                          accountid,
                          modalOwnerTypeValue,
                          modalFirstNameValue,
                          modalLastNameValue,
                          modalTaxcountryValue,
                          modalTaxIdValue,
                          modalTaxIdTypeValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
