/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListInventoryReportRequest } from '../../../proto/reportpb/inventoryreport_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function InventoryReportTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const [dateLabel, setDateLabel] = useState('Date');

  const {
    profileServiceClient: systemDate,
    inventoryReportServiceClient: inventoryreport,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (dateTypeValue ? '&dateType=' + dateTypeValue : '') +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListInventoryReportRequest();
    list.setSymbol(symbolValue);
    list.setAccountNo(accountNoValue);
    list.setDateType(dateTypeValue);
    setDateLabel(dateTypeValue);
    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      list.setFromDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      list.setToDate(gglToDate);
    }

    inventoryreport.listInventoryReport(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().inventoryReportsList.map((data) => ({
        systemDate: formatPbDate(data.systemDate),
        symbol: data.symbol,
        accountNo: data.accountNo,
        qty: data.qty,
        costBasis: formatCurrency(data.costBasis),
        marketValue: formatCurrency(data.marketValue),
        unrealized: formatCurrency(data.unrealized),
        closingPrice: formatCurrency(data.closingPrice),
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Inventory Report');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'costBasis',
      label: 'Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'systemDate',
      label:
        dateLabel === 'system_date'
          ? 'System Date'
          : dateLabel === 'settle_date'
          ? 'Settle Date'
          : 'Trade Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate ? rows[dataIndex].systemDate : '--';
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'unrealized',
      label: 'Unrealized',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
  ];

  const fileName =
    'InventoryReport_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [4, 0, 5, 3, 1, 2, 6, 7],
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Inventory Report');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Inventory Report');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['costBasis', 'marketValue', 'unrealized']}
          columnsWithQty={['qty']}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [dateTypeValue, setDateTypeValue] = React.useState('trade_date');
  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      dateType: query.get('dateType'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
      accountNo: query.get('accountNo'),
      symbol: query.get('symbol'),
    };

    if (data.dateType) {
      setDateTypeValue(data.dateType);
      setDateLabel(data.dateType);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }
    if (!data.fromDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          return;
        }
        setFromDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
        setToDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
      });
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => setDateTypeValue(e.target.value)}
                >
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                  <MenuItem value="system_date">System Date</MenuItem>
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Inventory Report'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
