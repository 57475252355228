/*Service*/
import {
  TaxRate,
  DeleteTaxRateRequest,
  ListTaxRateRequest,
} from '../../../proto/irspb/taxrate_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Checkbox,
  IconButton,
  Button,
  Box,
  TextField,
  Modal,
  Backdrop,
  Fade,
  InputLabel,
  Tooltip,
} from '@material-ui/core';

/*Material UI Icons*/
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Create as EditIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom component*/
import SearchButton from '../../../components/Button/Search';
import CountriesSelect from '../../../components/AutoComplete/Countries';

import { countries } from './../../../components/AutoComplete/AutoCompleteData';

/*Moment JS*/
import moment from 'moment-timezone';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

function saveTaxRate(
  taxRateClient,
  rows,
  isAdd,
  closeModal,
  countrycode,
  rate,
  taxRateID
) {
  if (!countrycode) {
    notifyError('Country is a required field.');
    return;
  }

  if (!rate) {
    notifyError('Rate is a required field.');
    return;
  }

  if (isAdd) {
    let req = new TaxRate();
    req.setCountryCode(countrycode);
    req.setRate(rate);

    countries.forEach((c) => {
      if (c.code === countrycode) {
        req.setCountry(c.codeDescription);
      }
    });

    taxRateClient.createTaxRate(req, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      rows.push(res.toObject().taxRate);
      notifySuccess('tax rate has been added.');
      closeModal();
    });
  } else {
    let req = new TaxRate();
    console.log(taxRateID);
    req.setId(taxRateID);
    req.setCountryCode(countrycode);
    req.setRate(rate);

    countries.forEach((c) => {
      if (c.code === countrycode) {
        req.setCountry(c.codeDescription);
      }
    });

    taxRateClient.updateTaxRate(req, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      let taxRate = res.toObject().taxRate;
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].id === taxRateID) {
          rows[i].countryCode = taxRate.countryCode;
          rows[i].country = taxRate.country;
          rows[i].rate = taxRate.rate;
          break;
        }
      }
      notifySuccess('Tax rate has been updated.');
      closeModal();
    });
  }
}

function removeTaxRate(
  taxRateClient,
  rows,
  setRows,
  ids,
  handleClick,
  confirm
) {
  if (ids.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      ids.length +
      ' Tax Rate(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      ids.forEach(removeFunction);

      function removeFunction(item) {
        let req = new DeleteTaxRateRequest();
        req.setTaxRateId(item);

        taxRateClient.deleteTaxRate(req, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }

          let updatedData = [];

          for (let c = 0; c < rows.length; c++) {
            if (rows[c].id === item) {
              rows.splice(rows[c], 1);
            } else {
              updatedData.push(rows[c]);
            }
          }
          setRows(updatedData);
        });
      }

      handleClick('', '', true);
      notifySuccess(ids.length + ' tax rate(s) has been deleted');
    })
    .catch((error) => {
      /* ... */
      console.log(error);
    });
}

function removeTaxRateSingle(
  taxRateClient,
  rows,
  setRows,
  taxRateID,
  countrycode,
  country,
  rate,
  confirm
) {
  if (!country) {
    country = '';
  }

  confirm({
    description:
      "You are about to delete tax rate '" +
      country +
      ': ' +
      rate +
      "' in your system. Please confirm your action.",
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let req = new DeleteTaxRateRequest();
      req.setTaxRateId(taxRateID);

      taxRateClient.deleteTaxRate(req, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }

        let updatedData = [];

        for (let c = 0; c < rows.length; c++) {
          if (rows[c].countryCode === countrycode) {
            rows.splice(rows[c], 1);
          } else {
            updatedData.push(rows[c]);
          }
        }
        setRows(updatedData);
      });

      notifySuccess('Tax rate has been deleted');
    })
    .catch((error) => {
      // showToastMessage("error", "System error.");
      console.log(error);
    });
}

export default function TaxRateTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const confirm = useConfirm();

  const {
    taxRateServiceClient: taxRateClient,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (countryValue ? '&country=' + countryValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListTaxRateRequest();
    list.setCountry(countryValue);

    taxRateClient.listTaxRate(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().taxRateList;
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Tax Rate');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const [rowID, setRowID] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const handleClick = (event, taxRateId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(taxRateId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, taxRateId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      console.log(newSelected);
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (taxRateId) => selected.indexOf(taxRateId) !== -1;

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          const isItemSelected = isSelected(rows[dataIndex].id);
          const labelId = `enhanced-table-checkbox-${dataIndex}`;

          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCellNone}>
                <Checkbox
                  onClick={(event) => handleClick(event, rows[dataIndex].id)}
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </div>
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    removeTaxRateSingle(
                      taxRateClient,
                      rows,
                      setRows,
                      rows[dataIndex].id,
                      rows[dataIndex].countryCode,
                      rows[dataIndex].country,
                      rows[dataIndex].rate,
                      confirm
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex], false)}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'country',
      label: 'Country',
    },
    {
      name: 'countryCode',
      label: 'Country Code',
    },
    {
      name: 'rate',
      label: 'Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
  ];

  const fileName =
    'TaxRate_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 2, 1, 3],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Tax Rate');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Tax Rate');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [countryValue, setCountryValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      country: query.get('country'),
    };

    if (data.country) {
      setCountryValue(data.country);
    }

    setCurrentPath(path);
  }, [params]);

  const [modalTaxRateID, setModalTaxRateID] = React.useState('');
  const [modalCountryCode, setModalCountryCode] = React.useState('');
  const [modalRate, setModalRate] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalCountryCode('');
      setModalRate('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setModalTaxRateID(rowData.id);
      setModalCountryCode(rowData.countryCode);
      setModalRate(rowData.rate);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1}>
                <InputLabel id="country" shrink>
                  Country
                </InputLabel>
                <CountriesSelect
                  labelid="country"
                  value={countryValue}
                  onchange={(e) => setCountryValue(e.target.value)}
                  setNewValue={(event, newValue) => {
                    if (newValue) {
                      setCountryValue(newValue.code);
                    } else {
                      setCountryValue('');
                      return;
                    }
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<DeleteIcon />}
              onClick={() => {
                removeTaxRate(
                  taxRateClient,
                  rows,
                  setRows,
                  rowID,
                  handleClick,
                  confirm
                );
              }}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Tax Rate'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        className={classes.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              className={classes.textBold}
              variant="h4"
              gutterBottom
            >
              {modalTitleValue} Tax Rate
            </Typography>
            <Box id="transition-modal-description">
              <form className={classes.modalForm} noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div
                    className={classes.grdCell1}
                    style={{ marginRight: 30, marginTop: 16 }}
                  >
                    <InputLabel id="country" shrink>
                      Country
                    </InputLabel>
                    <CountriesSelect
                      labelid="country"
                      value={modalCountryCode}
                      onchange={(e) => setModalCountryCode(e.target.value)}
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalCountryCode(newValue.code);
                        } else {
                          setModalCountryCode('');
                          return;
                        }
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      required
                      label="Rate"
                      placeholder="Rate"
                      fullWidth={true}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      value={modalRate}
                      onChange={(e) => {
                        setModalRate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveTaxRate(
                          taxRateClient,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalCountryCode,
                          modalRate,
                          modalTaxRateID
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
