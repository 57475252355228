import React, { useState, useEffect, useContext } from 'react';
import {
  stringToProtoDate,
  stringToProtoTradeTimeStamp,
} from '../../../services/ConvertService';
import {
  Transaction,
  ReadTransactionRequest,
  TransactionFee,
  GetFeeRequest,
  ListFeesRequest,
} from '../../../proto/trnspb/transaction_grpc_web_pb';
import SelectCurrency from 'components/AutoComplete/Currency';

import {
  Typography,
  Box,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Button,
  AccordionDetails,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { some } from 'lodash';
import {
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';
import { AssignmentReturn as ImportIcon } from '@material-ui/icons';
/*styles */
import useStyles from '../styles';

/*Moment JS*/
import moment from 'moment-timezone';

/*data*/
import { trnsOptions, sideOptions, entrySubTypeOptions } from '../MockData';

/*Custom components*/
import SelectAccount from '../../../components/AutoComplete/SelectAccount';
import SelectSymbol from '../../../components/AutoComplete/SelectSymbol';
import SelectEntrySubType from '../../../components/AutoComplete/SelectEntrySubType';
import TransactionUploadModal from './TransactionUploadModal';
import VendorSelect from '../../../components/AutoComplete/Vendor';
import { ServiceContext } from 'context/ServiceContext';
import { ReadPendingTrnsRequest } from '../../../proto/trnspb/pendingtrns_grpc_web_pb';

import FeesComponent from './Fees';

import { meta } from 'services/TransactionService';
import {
  stringToProtoTransactionMetadata,
  protoTransactionMetadataToString,
} from 'services/TransactionMetadata';
import { useProfile } from 'context/ProfileContext';
import { MetadataModal } from './MetadataModal';

function saveTransaction(
  transactionClient,
  params,
  trnsId,
  screen,
  cancelAndCorrect,
  pending,
  actionType,
  setMessage
) {
  let req = new Transaction();

  if (screen === 'Trade') {
    if (!params.entryType) {
      notifyError('Entry Type is required.');
      return;
    }
    if (!params.accountNumber) {
      notifyError('Account No. is required.');
      return;
    }
    if (!params.contraAccount) {
      notifyError('Contra Account is required.');
      return;
    }
    if (!params.side) {
      notifyError('Side is required.');
      return;
    }
    if (!params.symbol) {
      notifyError('Symbol is required.');
      return;
    }
    if (!params.qty) {
      notifyError('Quantity is required.');
      return;
    }
    if (!params.price) {
      notifyError('Price is required.');
      return;
    }
    if (!params.tradeDate) {
      notifyError('Trade Date is required.');
      return;
    }

    req.setEntryType(params.entryType);
    req.setEntrySubType(params.entrySubType);
    req.setCorrespondent(params.correspondent);
    req.setAccountNo(params.accountNumber);
    req.setContraCorrespondent(params.contraCorrespondent);
    req.setContraAccountNo(params.contraAccount);
    req.setSide(params.side);
    req.setSymbol(params.symbol);
    req.setCusip(params.cusip);
    req.setQty(params.qty);
    req.setPrice(params.price);
    req.setGrossAmt(params.grossAmount);
    req.setNetAmt(params.netAmount);
    req.setTradeAt(stringToProtoTradeTimeStamp(params.tradeDate));
    req.setSettleDate(stringToProtoDate(params.settleDate));
    req.setDescription(params.description);
    req.setExecutionId(params.executionId);
    req.setExternalId(params.externalId);
    req.setExecutingVenue(params.executingVenue);
    req.setOrderId(params.orderId);
    req.setLeavesQty(params.leavesQty);
    req.setFees(params.fees);
    req.setContraAccountDesignator(params.contraAccountDesignator);
    req.setRefId(params.refId);

    let metadata = null;
    if (params.metadata) {
      const metadata = validateMetadata(params.metadata);
      if (!metadata) return;
      if (metadata.getLocalFees()) {
        notifyError(
          'Please specify Local Fees in the field instead of free format'
        );
        return;
      }
      req.setMetadata(metadata);
    }

    if (params.contraSymbol && params.contraSymbol !== 'USD') {
      req.setContraSymbol(
        params.contraSymbol.startsWith('$')
          ? params.contraSymbol
          : '$' + params.contraSymbol
      );

      if (!params.swapRate) {
        notifyError('Swap Rate is required.');
        return;
      }

      if (
        params.swapFeeBpsAlpa.includes('.') ||
        params.swapFeeBpsAlpa.includes('-')
      ) {
        notifyError('Swap Fee BPS should be positive integer.');
        return;
      }
      if (
        params.swapFeeBpsCorr.includes('.') ||
        params.swapFeeBpsCorr.includes('-')
      ) {
        notifyError('Swap Fee BPS should be positive integer.');
        return;
      }

      if (!metadata && params.localFees) {
        metadata = validateMetadata(
          '{"local_fees":"' + params.localFees + '"}'
        );
        if (!metadata) return;
      } else if (params.localFees) {
        metadata.setLocalFees(params.localFees);
      }

      req.setSwapRate(params.swapRate);
      req.setSwapRateAt(stringToProtoTradeTimeStamp(params.swapRateAt));
      req.setLocalGrossAmt(params.localGrossAmt);
      req.setLocalNetAmt(params.localNetAmt);
      req.setSwapFeeBpsAlpa(params.swapFeeBpsAlpa);
      req.setSwapFeeBpsCorr(params.swapFeeBpsCorr);
      req.setMetadata(metadata);
    }

    let trnsFees = [];
    params.feeList.forEach((item) => {
      let trnsFee = new TransactionFee();
      trnsFee.setFeeType(item.feeType);
      trnsFee.setFee(item.fee);
      trnsFees.push(trnsFee);
    });

    req.setTransactionFeesList(trnsFees);
  }

  if (screen === 'Cash Movement') {
    if (!params.entryType) {
      notifyError('Entry Type is required.');
      return;
    }
    if (!params.accountNumber) {
      notifyError('Account No. is required.');
      return;
    }
    if (!params.contraAccount) {
      notifyError('Contra Account is required.');
      return;
    }
    if (!params.netAmount && !params.localNetAmt) {
      notifyError('Net Amount or Local Net Amount is required.');
      return;
    }

    req.setEntryType(params.entryType);
    req.setEntrySubType(params.entrySubType);
    req.setCorrespondent(params.correspondent);
    req.setAccountNo(params.accountNumber);
    req.setContraCorrespondent(params.contraCorrespondent);
    req.setContraAccountNo(params.contraAccount);
    req.setNetAmt(params.netAmount);
    req.setSettleDate(stringToProtoDate(params.settleDate));
    req.setDescription(params.description);
    req.setExternalId(params.externalId);
    req.setExecutionId(params.executionId);
    req.setVendor(params.vendor);
    req.setRefId(params.refId);
    let metadata = null;
    if (params.metadata) {
      metadata = validateMetadata(params.metadata);
      if (!metadata) return;
      req.setMetadata(metadata);
    }

    if (params.contraSymbol && params.contraSymbol !== 'USD') {
      req.setContraSymbol(
        params.contraSymbol.startsWith('$')
          ? params.contraSymbol
          : '$' + params.contraSymbol
      );

      if (!params.swapRate) {
        notifyError('Swap Rate is required.');
        return;
      }

      req.setSwapRate(params.swapRate);
      req.setSwapRateAt(stringToProtoTradeTimeStamp(params.swapRateAt));
      req.setLocalNetAmt(params.localNetAmt);
    }

    if (params.entryType === 'INVPNL') {
      if (!params.symbol) {
        notifyError('Symbol is required.');
        return;
      }
      req.setSymbol(params.symbol);
      req.setCusip(params.cusip);
    }
  }

  if (screen === 'Cash and/or Position Movement') {
    if (!params.entryType) {
      notifyError('Entry Type is required.');
      return;
    }
    if (!params.accountNumber) {
      notifyError('Account No. is required.');
      return;
    }
    if (!params.contraAccount) {
      notifyError('Contra Account is required.');
      return;
    }
    if (params.entryType === 'CFEE') {
      if (params.symbol) {
        params.netAmount = null;
      }
      if (
        (!params.symbol && !params.netAmount) ||
        (params.symbol && params.netAmount)
      ) {
        notifyError('Cannot accept both Net Amount and Symbol filled at once.');
        return;
      }
      if (params.symbol && (!params.price || !params.qty)) {
        notifyError('Price and Qty are required for the Symbol');
        return;
      }
      if (params.netAmount && (params.price || params.qty)) {
        notifyError(
          'Price or Qty should not be given when Net Amount is given'
        );
        return;
      }
    } else {
      if (!params.symbol) {
        notifyError('Symbol is required.');
        return;
      }
      if (!params.netAmount && !params.localNetAmt) {
        notifyError('Net Amount or Local Net Amount is required.');
        return;
      }
    }
    // if (!params.qty && !params.netAmount) {
    //   if (!params.qty) {
    //     notifyError('Enter either QTY or Net Amount.');
    //     return;
    //   }
    //   if (!params.netAmount) {
    //     notifyError('Enter either QTY or Net Amount.');
    //     return;
    //   }
    // }
    // if (!params.price) {
    //   notifyError('Price is required.');
    //   return;
    // }

    req.setEntryType(params.entryType);
    req.setEntrySubType(params.entrySubType);
    req.setCorrespondent(params.correspondent);
    req.setAccountNo(params.accountNumber);
    req.setContraCorrespondent(params.contraCorrespondent);
    req.setContraAccountNo(params.contraAccount);
    req.setSymbol(params.symbol);
    req.setCusip(params.cusip);
    req.setQty(params.qty);
    req.setPrice(params.price);
    req.setNetAmt(params.netAmount);
    req.setSettleDate(stringToProtoDate(params.settleDate));
    req.setHoldDate(stringToProtoDate(params.holdDate));
    req.setDescription(params.description);
    req.setContraAccountDesignator(params.contraAccountDesignator);
    req.setRefId(params.refId);
    let metadata = null;
    if (params.metadata) {
      metadata = validateMetadata(params.metadata);
      if (!metadata) return;
      req.setMetadata(metadata);
    }

    if (params.contraSymbol && params.contraSymbol !== 'USD') {
      req.setContraSymbol(
        params.contraSymbol.startsWith('$')
          ? params.contraSymbol
          : '$' + params.contraSymbol
      );

      if (!params.swapRate) {
        notifyError('Swap Rate is required.');
        return;
      }

      req.setSwapRate(params.swapRate);
      req.setSwapRateAt(stringToProtoTradeTimeStamp(params.swapRateAt));
      req.setLocalNetAmt(params.localNetAmt);
    }
  }

  if (screen === 'Position Movement') {
    if (!params.entryType) {
      notifyError('Entry Type is required.');
      return;
    }
    if (!params.accountNumber) {
      notifyError('Account No. is required.');
      return;
    }
    if (!params.contraAccount) {
      notifyError('Contra Account is required.');
      return;
    }
    if (!params.symbol) {
      notifyError('Symbol is required.');
      return;
    }
    if (!params.qty) {
      notifyError('Quantity is required.');
      return;
    }
    if (!params.price && params.entryType !== 'MMSWP') {
      notifyError('Price is required.');
      return;
    }

    req.setEntryType(params.entryType);
    req.setEntrySubType(params.entrySubType);
    req.setCorrespondent(params.correspondent);
    req.setAccountNo(params.accountNumber);
    req.setContraCorrespondent(params.contraCorrespondent);
    req.setContraAccountNo(params.contraAccount);
    req.setSymbol(params.symbol);
    req.setCusip(params.cusip);
    req.setQty(params.qty);
    req.setPrice(params.price);
    req.setSettleDate(stringToProtoDate(params.settleDate));
    req.setHoldDate(stringToProtoDate(params.holdDate));
    req.setDescription(params.description);
    req.setContraAccountDesignator(params.contraAccountDesignator);
    req.setRefId(params.refId);

    if (params.contraSymbol && params.contraSymbol !== 'USD') {
      req.setContraSymbol(
        params.contraSymbol.startsWith('$')
          ? params.contraSymbol
          : '$' + params.contraSymbol
      );

      if (!params.swapRate) {
        notifyError('Swap Rate is required.');
        return;
      }

      req.setSwapRate(params.swapRate);
      req.setSwapRateAt(stringToProtoTradeTimeStamp(params.swapRateAt));
    }
    if (params.metadata) {
      const metadata = validateMetadata(params.metadata);
      if (!metadata) return;
      req.setMetadata(metadata);
    }
  }

  try {
    req = handleJournalPositionCash(screen, params, req);
  } catch (err) {
    notifyError(err.message);
    return;
  }

  if (pending && actionType !== 'process-pending') {
    return new Promise((resolve, reject) => {
      transactionClient.createPendingTransaction(req, meta, (err, res) => {
        if (err) {
          notifyError(err.message);
          reject(err);
          return;
        }
        if (res.toObject().status === 'pending') {
          notifySuccess('Pending Transaction has been added.');
        } else {
          notifyError(
            res
              .toObject()
              .status.charAt(0)
              .toUpperCase() +
              res.toObject().status.slice(1) +
              '. ' +
              res.toObject().errorMessage
          );
        }
        resolve();
      });
    });
  } else {
    if (actionType === 'process-pending') {
      req.setTrnsId(trnsId);
      return new Promise((resolve, reject) => {
        transactionClient.processPendingTransaction(req, meta, (err, res) => {
          if (err) {
            notifyError(err.message);
            reject(err);
            return;
          }
          if (res.toObject().errorMessage === '') {
            setMessage({
              severity: 'success',
              message: 'Pending transaction has been processed.',
            });
            notifySuccess('Pending transaction has been processed.');
          } else {
            notifyError(
              res
                .toObject()
                .status.charAt(0)
                .toUpperCase() +
                res.toObject().status.slice(1) +
                '. ' +
                res.toObject().errorMessage
            );
          }
          resolve();
        });
      });
    } else if (cancelAndCorrect) {
      req.setTrnsId(trnsId);
      return new Promise((resolve, reject) => {
        transactionClient.correctTransaction(req, meta, (err, res) => {
          if (err) {
            notifyError(err.message);
            reject(err);
            return;
          }
          if (['deleted', 'rejected'].includes(res.toObject().status)) {
            // "<status>. <error-message>"
            // e.g., "Rejected. Invalid Symbol"
            notifyError(
              res
                .toObject()
                .status.charAt(0)
                .toUpperCase() +
                res.toObject().status.slice(1) +
                '. ' +
                res.toObject().errorMessage
            );
          } else {
            notifySuccess('Transaction has been corrected.');
          }
          resolve();
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        transactionClient.createTransaction(req, meta, (err, res) => {
          if (err) {
            notifyError(err.message);
            reject(err);
            return;
          }
          if (res.toObject().status === 'executed') {
            notifySuccess('Transaction has been added.');
          } else if (res.toObject().status === 'split') {
            notifySuccess(res.toObject().status);
          } else {
            notifyError(
              res
                .toObject()
                .status.charAt(0)
                .toUpperCase() +
                res.toObject().status.slice(1) +
                '. ' +
                res.toObject().errorMessage
            );
          }
          resolve();
        });
      });
    }
  }
}

function validateMetadata(metadataStr) {
  if (metadataStr) {
    try {
      return stringToProtoTransactionMetadata(metadataStr);
    } catch (e) {
      notifyError(e.message);
      return;
    }
  }
}

function handleJournalPositionCash(screen, params, req) {
  if (screen === 'Journal Position and Cash') {
    if (!params.entryType) {
      throw new Error('Entry Type is required.');
    }
    if (!params.accountNumber) {
      throw new Error('Account No. is required.');
    }
    if (!params.contraAccount) {
      throw new Error('Contra Account is required.');
    }
    if (!params.symbol) {
      throw new Error('Symbol is required.');
    }
    if (!params.qty) {
      throw new Error('Quantity is required.');
    }
    if (!params.netAmount) {
      throw new Error('Net Amount is required.');
    }

    req.setEntryType(params.entryType);
    req.setCorrespondent(params.correspondent);
    req.setAccountNo(params.accountNumber);
    req.setContraCorrespondent(params.contraCorrespondent);
    req.setContraAccountNo(params.contraAccount);
    req.setSymbol(params.symbol);
    req.setCusip(params.cusip);
    req.setQty(params.qty);
    req.setPrice(params.price);
    req.setNetAmt(params.netAmount);
    req.setTradeAt(stringToProtoTradeTimeStamp(params.tradeDate));
    req.setSettleDate(stringToProtoDate(params.settleDate));
    req.setDescription(params.description);
    req.setExecutionId(params.executionId);
    req.setExternalId(params.externalId);
    req.setExecutingVenue(params.executingVenue);
    req.setOrderId(params.orderId);
    req.setLeavesQty(params.leavesQty);
    req.setContraAccountDesignator(params.contraAccountDesignator);
    req.setRefId(params.refId);
  }
  return req;
}

function keyRandomizer() {
  return Math.random()
    .toString(36)
    .substring(7);
}

export default function TransactionForm({ trnsid, accountid, actionType }) {
  const profile = useProfile();
  const classes = useStyles();
  const [isCancelAndCorrect, setIsCancelAndCorrect] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [screen, setScreen] = useState(1);
  const [screenDescription, setScreenDescription] = useState('Trade');
  const [transactionValue, setTransactionValue] = useState('TRD');
  const [entrySubTypeValue, setEntrySubTypeValue] = useState('');
  const [sideValue, setSideValue] = useState('');
  const [correspondentValue, setCorrespondentValue] = useState('');
  const [accountNumberValue, setAccountNumberValue] = useState('');
  const [contraCorrespondentValue, setContraCorrespondentValue] = useState('');
  const [contraAccountValue, setContraAccountValue] = useState('');
  const [symbolValue, setSymbolValue] = useState('');
  const [cusipValue, setCusipValue] = useState('');
  const [qtyValue, setQtyValue] = useState('');
  const [priceValue, setPriceValue] = useState('');
  const [grossAmountValue, setGrossAmountValue] = useState('');
  const [netAmountValue, setNetAmountValue] = useState('');
  const [tradeDateValue, setTradeDateValue] = useState(
    profile.systemDate.format('YYYY-MM-DDTh:mm')
  );
  const [settleDateValue, setSettleDateValue] = useState(
    profile.settleDate.format('YYYY-MM-DD')
  );
  const [descriptionValue, setDescriptionValue] = useState('');
  const [executionIdValue, setExecutionIdValue] = useState('');
  const [externalIdValue, setExternalIdValue] = useState('');
  const [executingVenueValue, setExecutingVenueValue] = useState('');
  const [vendorValue, setVendorValue] = useState('');
  const [orderIdValue, setOrderIdValue] = useState('');
  const [leavesQtyValue, setLeavesQtyValue] = useState('');
  const [
    contraAccountDesignatorValue,
    setContraAccountDesignatorValue,
  ] = useState('');
  const [refIdValue, setRefIdValue] = useState('');
  const [holdDateValue, setHoldDateValue] = useState('');
  const [metadataValue, setMetadataValue] = useState('');
  const [contraSymbolValue, setContraSymbolValue] = useState('');
  const [swapRateValue, setSwapRateValue] = useState('');
  const [swapRateAtValue, setSwapRateAtValue] = useState('');
  const [localGrossAmtValue, setLocalGrossAmtValue] = useState('');
  const [localNetAmtValue, setLocalNetAmtValue] = useState('');
  const [swapFeeBpsAlpaValue, setSwapFeeBpsAlpaValue] = useState('');
  const [swapFeeBpsCorrValue, setSwapFeeBpsCorrValue] = useState('');
  const [localFeesValue, setLocalFeesValue] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);
  const [usdFieldsDisabled, setUsdFieldsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    severity: 'success',
    message: '',
  });

  const [feesValue, setFeesValue] = useState(0);
  const [feeListValue, setFeeListValue] = useState([
    { feeType: 'TAF Fee', fee: '0' },
    { feeType: 'REG Fee', fee: '0' },
  ]);
  const [openImport, setOpenImport] = React.useState(false);

  const handleUploadClose = async (data, isEdit) => {
    setOpenImport(false);
  };

  const {
    transactionServiceClient: transactionClient,
    pendingTrnsServiceClient: pendingtrnsClient,
  } = useContext(ServiceContext);

  useEffect(() => {
    if (actionType !== 'process-pending') {
      setMessage({
        severity: 'success',
        message: '',
      });
    }
  }, [actionType]);

  useEffect(() => {
    let total = 0;
    feeListValue.forEach((item) => {
      total += parseFloat(item.fee);
    });
    setFeesValue(total);
  }, [feeListValue]);

  useEffect(() => {
    if (sideValue) {
      calculateRegFee(grossAmountValue);
      calculateTaf(qtyValue);
    } // eslint-disable-next-line
  }, [sideValue]);

  useEffect(() => {
    let req = new ListFeesRequest();

    transactionClient.listFees(req, meta, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }

      let newArr = feeListValue.map((item) => {
        return item;
      });

      res.toObject().feesList.forEach((fee) => {
        newArr.push({ feeType: fee.feeType, fee: '0' });
      });
      setFeeListValue(newArr);
    }); // eslint-disable-next-line
  }, []);

  const inputs = [
    {
      name: 'correspondent',
      type: 'autocomplete-correspondent',
      label: 'Correspondent',
      required: false,
    },
    {
      name: 'account_number',
      type: 'autocomplete-accountno',
      label: 'Account No',
      required: true,
    },
    {
      name: 'vendor',
      type: 'autocomplete-vendor',
      label: 'Vendor',
      required: true,
    },
    {
      name: 'contra_correspondent',
      type: 'autocomplete-contra-correspondent',
      label: 'Contra Correspondent',
      required: false,
    },
    {
      name: 'symbol',
      type: 'autocomplete-symbol',
      label: 'Symbol',
      required: true,
    },
    {
      name: 'cusip',
      type: 'autocomplete-cusip',
      label: 'Cusip',
      required: true,
    },
    {
      name: 'contra_account',
      type: 'autocomplete-contraaccount',
      label: 'Contra Account',
      required: true,
    },
    {
      name: 'side',
      type: 'select',
      label: 'Side',
      required: true,
    },
    {
      name: 'qty',
      type: 'number',
      label: 'QTY',
      required: false,
    },
    {
      name: 'price',
      type: 'number',
      label: 'Price',
      required: false,
    },
    {
      name: 'gross_amount',
      type: 'number',
      label: 'Gross Amount',
      required: false,
      disabled:
        isDisabled ||
        (usdFieldsDisabled && (contraSymbolValue || 'USD') !== 'USD'),
    },
    {
      name: 'fees',
      type: 'multiple-fees',
      label: 'Fees',
      required: false,
    },
    {
      name: 'net_amount',
      type: 'number',
      label: 'Net Amount',
      required: false,
      disabled:
        isDisabled ||
        (usdFieldsDisabled && (contraSymbolValue || 'USD') !== 'USD'),
    },
    {
      name: 'trade_date',
      type: 'time',
      label: 'Trade Date (ET)',
      required: true,
    },
    {
      name: 'settle_date',
      type: 'date',
      label: 'Settle Date',
      required: false,
    },
    {
      name: 'description',
      type: 'text',
      label: 'Description',
      required: false,
    },
    {
      name: 'entry_type',
      type: 'select',
      label: 'Entry Type',
      required: true,
    },
    {
      name: 'entry_sub_type',
      type: 'autocomplete-entry-sub-type',
      label: 'Entry Sub Type',
      required: false,
    },
    {
      name: 'execution_id',
      type: 'text',
      label: 'Execution ID',
      required: false,
    },
    {
      name: 'external_id',
      type: 'text',
      label: 'External ID',
      required: false,
    },
    {
      name: 'executing_venue',
      type: 'text',
      label: 'Executing Venue',
      required: false,
    },
    {
      name: 'order_id',
      type: 'text',
      label: 'Order ID',
      required: false,
    },
    {
      name: 'leaves_qty',
      type: 'number',
      label: 'Leaves Qty',
      required: false,
    },
    {
      name: 'contra_account_designator',
      type: 'custom-select',
      label: 'Contra Account Designator',
      required: false,
    },
    {
      name: 'ref_id',
      type: 'text',
      label: 'Ref ID',
      required: false,
    },
    {
      name: 'hold_date',
      type: 'date',
      label: 'Hold Date',
      required: false,
    },
    {
      name: 'metadata',
      type: 'text',
      label: 'Metadata',
      required: false,
    },
    {
      name: 'contra_symbol',
      type: 'autocomplete-contra-symbol',
      label: 'Currency',
      required: false,
    },
    {
      name: 'swap_rate',
      type: 'number',
      label: 'Swap Rate',
      required: true,
    },
    {
      name: 'swap_rate_at',
      type: 'time',
      label: 'Swap Rate At',
      required: false,
    },
    {
      name: 'local_gross_amt',
      type: 'number',
      label: 'Local Gross Amount',
      required: false,
      disabled: isDisabled,
    },
    {
      name: 'local_net_amt',
      type: 'number',
      label: 'Local Net Amount (' + contraSymbolValue + ')',
      required: false,
      disabled: isDisabled,
    },
    {
      name: 'swap_fee_bps_alpa',
      type: 'number',
      label: 'Swap Fee BPS Alpaca',
      required: false,
    },
    {
      name: 'swap_fee_bps_corr',
      type: 'number',
      label: 'Swap Fee BPS Correspondent',
      required: false,
    },
    {
      name: 'local_fees',
      type: 'number',
      label: 'Local Fees (' + contraSymbolValue + ') - Subledger Only',
      required: false,
    },
  ];

  useEffect(() => {
    if (isPending) {
      handleSubmit();
      setIsPending(false);
    } // eslint-disable-next-line
  }, [isPending]);

  useEffect(() => {
    //set default to velox if selected entry type is TRD
    if (transactionValue === 'TRD') {
      setContraAccountValue('1115');
    }

    // Disable USD net_amt input for JNLC, CSD and CSW as the amount will
    // be kept as non-USD til trade happened, and local_net_amt is more
    // important before that.
    if (
      transactionValue === 'JNLC' ||
      transactionValue === 'CSD' ||
      transactionValue === 'CSW'
    ) {
      setUsdFieldsDisabled(true);
    } else {
      setUsdFieldsDisabled(false);
    }

    //disabled gross amount and net amount if selected screen is Trade
    if (screenDescription === 'Trade') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    if (trnsid) {
      let readReq;

      if (actionType === 'process-pending') {
        readReq = new ReadPendingTrnsRequest();
        readReq.setStageId(trnsid);
      } else {
        setIsCancelAndCorrect(true);

        readReq = new ReadTransactionRequest();

        readReq.setTrnsId(trnsid);
        readReq.setAccountId(accountid);
      }

      setLoading(true);
      (actionType === 'process-pending'
        ? pendingtrnsClient
        : transactionClient
      ).readTransaction(readReq, {}, (err, res) => {
        console.log(err);
        if (err) {
          notifyError(err.message);
          setMessage({
            severity: 'error',
            message: err.message,
          });
          setLoading(false);
          return;
        }

        const trns = res.toObject();
        if (
          trns.pendingTransaction &&
          trns.pendingTransaction.status === 'correct'
        ) {
          setMessage({
            severity: 'success',
            message: 'Transaction is already corrected.',
          });
          //notifySuccess('Transaction is already corrected.');
        }

        if (err) {
          notifyError(err.message);
          return;
        }
        let data =
          actionType === 'process-pending'
            ? trns.pendingTransaction
            : trns.transaction;

        const targetScreen = trnsOptions.find(
          (o) => o.value === data.entryType
        );
        if (!targetScreen) {
          notifyError(`unknown entry type ${data.entryType}`);
          return;
        } else {
          setScreen(targetScreen.screen_no);
          setScreenDescription(targetScreen.screen);
        }
        setTransactionValue(data.entryType);
        setEntrySubTypeValue(data.entrySubType);
        setCorrespondentValue(data.correspondent);
        setAccountNumberValue(data.accountNo);
        setContraCorrespondentValue(data.contraCorrespondent);
        setContraAccountValue(data.contraAccountNo);
        setExecutionIdValue(data.executionId);
        setExternalIdValue(data.externalId);
        setSideValue(data.side);
        setSymbolValue(data.symbol);
        setCusipValue(data.cusip);
        setQtyValue(data.qty);
        setPriceValue(data.price);
        setGrossAmountValue(data.grossAmt);
        setFeesValue(data.fees);
        setNetAmountValue(data.netAmt);
        setTradeDateValue(
          data.tradeAt
            ? moment(new Date(data.tradeAt.seconds * 1000))
                .toISOString()
                .substring(0, 16)
            : ''
        );
        setSettleDateValue(
          data.settleDate
            ? moment(
                new Date(
                  data.settleDate.year,
                  data.settleDate.month - 1,
                  data.settleDate.day,
                  0,
                  0,
                  0,
                  0
                )
              ).format('yyyy-MM-DD')
            : ''
        );
        setHoldDateValue(
          data.holdDate
            ? moment(
                new Date(
                  data.holdDate.year,
                  data.holdDate.month - 1,
                  data.holdDate.day,
                  0,
                  0,
                  0,
                  0
                )
              ).format('yyyy-MM-DD')
            : ''
        );
        setDescriptionValue(data.description);
        setExecutingVenueValue(data.executingVenue);
        setVendorValue(data.vendorValue);
        setOrderIdValue(data.orderId);
        setLeavesQtyValue(data.leavesQty);
        setContraAccountDesignatorValue(data.contraAccountDesignator);
        setRefIdValue(data.refId);
        let resTrns =
          actionType === 'process-pending'
            ? res.getPendingTransaction()
            : res.getTransaction();
        let fmtedMetadata = protoTransactionMetadataToString(
          resTrns.getMetadata()
        );
        setMetadataValue(fmtedMetadata);
        setContraSymbolValue(data.contraSymbol);
        setSwapRateValue(data.swapRate);
        setSwapRateAtValue(
          data.swapRateAt
            ? moment(new Date(data.swapRateAt.seconds * 1000))
                .toISOString()
                .substring(0, 16)
            : ''
        );
        setLocalGrossAmtValue(data.localGrossAmt);
        setLocalNetAmtValue(data.localNetAmt);

        // cleaning up usd value when non-USD for special cash movements
        if (
          transactionValue === 'JNLC' ||
          transactionValue === 'CSD' ||
          transactionValue === 'CSW'
        ) {
          setNetAmountValue(
            (contraSymbolValue || 'USD') === 'USD' ? data.netAmt : ''
          );
        }

        setLoading(false);
      });
    } else {
      setIsCancelAndCorrect(false);
    } // eslint-disable-next-line
  }, [
    transactionClient,
    trnsid,
    accountid,
    transactionValue,
    screenDescription,
    actionType,
  ]);

  const handleOnChangeType = (event) => {
    const selectedValue = event.target.value;
    const isTrns = some(trnsOptions, ['value', selectedValue]);
    const isEntrySubType = some(entrySubTypeOptions, ['value', selectedValue]);
    let targetValue;

    if (isTrns) {
      /***Note Refactor Me *******/
      targetValue = trnsOptions.find((o) => o.value === event.target.value);
      setScreen(targetValue.screen_no);
      setScreenDescription(targetValue.screen);
      setTransactionValue(selectedValue);
      setEntrySubTypeValue('');
      setSideValue('');
      setCorrespondentValue('');
      setAccountNumberValue('');
      setContraCorrespondentValue('');
      setContraAccountValue('');
      setSymbolValue('');
      setCusipValue('');
      setDescriptionValue('');
      setQtyValue('');
      setPriceValue('');
      setGrossAmountValue('');
      setFeesValue('');
      setNetAmountValue('');
      setTradeDateValue('');
      setSettleDateValue('');
      setHoldDateValue('');
      setExecutionIdValue('');
      setExternalIdValue('');
      setExecutingVenueValue('');
      setVendorValue('');
      setOrderIdValue('');
      setLeavesQtyValue('');
      setContraAccountDesignatorValue('');
      setRefIdValue('');
      setMetadataValue('');
      setContraSymbolValue('');
      setSwapRateValue('');
      setSwapRateAtValue('');
      setLocalGrossAmtValue('');
      setLocalNetAmtValue('');
      setLocalFeesValue('');
      setSwapFeeBpsAlpaValue('');
      setSwapFeeBpsCorrValue('');
      /***Note: Clean Me up*******/
      document.getElementById('transaction-form').reset();
    } else if (isEntrySubType) {
      setEntrySubTypeValue(selectedValue);
    } else {
      setSideValue(selectedValue);
    }

    if (selectedValue === 'TRD') {
      setContraAccountValue('1115');
    }
  };

  const handleSubmit = async () => {
    setWaiting(true);
    if (screenDescription === 'Trade') {
      await saveTransaction(
        transactionClient,
        {
          entryType: transactionValue,
          entrySubType: entrySubTypeValue,
          correspondent: correspondentValue,
          accountNumber: accountNumberValue,
          contraCorrespondent: contraCorrespondentValue,
          contraAccount: contraAccountValue,
          side: sideValue,
          symbol: symbolValue,
          cusip: cusipValue,
          qty: qtyValue,
          price: priceValue,
          grossAmount: grossAmountValue,
          fees: feesValue,
          netAmount: netAmountValue,
          tradeDate: tradeDateValue,
          settleDate: settleDateValue,
          description: descriptionValue,
          executionId: executionIdValue,
          externalId: externalIdValue,
          executingVenue: executingVenueValue,
          orderId: orderIdValue,
          leavesQty: leavesQtyValue,
          feeList: feeListValue,
          contraAccountDesignator: contraAccountDesignatorValue,
          refId: refIdValue,
          metadata: metadataValue,
          contraSymbol: contraSymbolValue,
          swapRate: swapRateValue,
          swapRateAt: swapRateAtValue,
          localGrossAmt: grossAmountValue ? null : localGrossAmtValue,
          localNetAmt: netAmountValue ? null : localNetAmtValue,
          swapFeeBpsAlpa: swapFeeBpsAlpaValue,
          swapFeeBpsCorr: swapFeeBpsCorrValue,
          localFees: localFeesValue,
        },
        trnsid,
        screenDescription,
        isCancelAndCorrect,
        isPending,
        actionType,
        setMessage
      );
    }

    if (screenDescription === 'Cash Movement') {
      await saveTransaction(
        transactionClient,
        {
          entryType: transactionValue,
          entrySubType: entrySubTypeValue,
          correspondent: correspondentValue,
          accountNumber: accountNumberValue,
          contraCorrespondent: contraCorrespondentValue,
          contraAccount: contraAccountValue,
          netAmount: netAmountValue,
          settleDate: settleDateValue,
          description: descriptionValue,
          executionId: executionIdValue,
          externalId: externalIdValue,
          vendor: vendorValue,
          symbol: symbolValue,
          cusip: cusipValue,
          refId: refIdValue,
          metadata: metadataValue,
          contraSymbol: contraSymbolValue,
          swapRate: swapRateValue,
          swapRateAt: swapRateAtValue,
          localNetAmt: netAmountValue ? null : localNetAmtValue,
        },
        trnsid,
        screenDescription,
        isCancelAndCorrect,
        isPending,
        actionType,
        setMessage
      );
    }

    if (screenDescription === 'Cash and/or Position Movement') {
      await saveTransaction(
        transactionClient,
        {
          entryType: transactionValue,
          entrySubType: entrySubTypeValue,
          correspondent: correspondentValue,
          accountNumber: accountNumberValue,
          contraCorrespondent: contraCorrespondentValue,
          contraAccount: contraAccountValue,
          symbol: symbolValue,
          cusip: cusipValue,
          qty: qtyValue,
          price: priceValue,
          netAmount: netAmountValue,
          settleDate: settleDateValue,
          holdDate: holdDateValue,
          description: descriptionValue,
          refId: refIdValue,
          metadata: metadataValue,
          contraSymbol: contraSymbolValue,
          swapRate: swapRateValue,
          swapRateAt: swapRateAtValue,
          localNetAmt: netAmountValue ? null : localNetAmtValue,
        },
        trnsid,
        screenDescription,
        isCancelAndCorrect,
        isPending,
        actionType,
        setMessage
      );
    }

    if (screenDescription === 'Position Movement') {
      await saveTransaction(
        transactionClient,
        {
          entryType: transactionValue,
          entrySubType: entrySubTypeValue,
          correspondent: correspondentValue,
          accountNumber: accountNumberValue,
          contraCorrespondent: contraCorrespondentValue,
          contraAccount: contraAccountValue,
          symbol: symbolValue,
          cusip: cusipValue,
          qty: qtyValue,
          price: priceValue,
          settleDate: settleDateValue,
          holdDate: holdDateValue,
          description: descriptionValue,
          contraAccountDesignator: contraAccountDesignatorValue,
          refId: refIdValue,
          metadata: metadataValue,
          contraSymbol: contraSymbolValue,
          swapRate: swapRateValue,
          swapRateAt: swapRateAtValue,
        },
        trnsid,
        screenDescription,
        isCancelAndCorrect,
        isPending,
        actionType,
        setMessage
      );
    }

    if (screenDescription === 'Journal Position and Cash') {
      await saveTransaction(
        transactionClient,
        {
          entryType: transactionValue,
          correspondent: correspondentValue,
          accountNumber: accountNumberValue,
          contraCorrespondent: contraCorrespondentValue,
          contraAccount: contraAccountValue,
          symbol: symbolValue,
          cusip: cusipValue,
          qty: qtyValue,
          price: priceValue,
          netAmount: netAmountValue,
          tradeDate: tradeDateValue,
          settleDate: settleDateValue,
          description: descriptionValue,
          executionId: executionIdValue,
          externalId: externalIdValue,
          executingVenue: executingVenueValue,
          orderId: orderIdValue,
          leavesQty: leavesQtyValue,
          contraAccountDesignator: contraAccountDesignatorValue,
          refId: refIdValue,
          metadata: metadataValue,
        },
        trnsid,
        screenDescription,
        isCancelAndCorrect,
        isPending,
        actionType,
        setMessage
      );
    }
    setWaiting(false);
  };

  function getTransactionForm(elements, screenNo) {
    const renderRowWrapper = elements.map((itemsRow) =>
      itemsRow !== null ? (
        <div
          key={itemsRow}
          className={
            itemsRow.length > 2 ? classes.grdRow : classes.grdRowInlineFlex
          }
        >
          {getFormFields(itemsRow, screenNo)}{' '}
        </div>
      ) : null
    );

    if (loading) {
      return <Typography variant="h5">Loading transaction...</Typography>;
    }

    return (
      <Box className={classes.container}>
        <form id="transaction-form">
          <div className={classes.containerTitle}>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1}>
                <Typography variant="h4" className={classes.textBold}>
                  {screenDescription}
                </Typography>
              </div>
              {actionType !== 'process-pending' && (
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<ImportIcon />}
                    onClick={() => {
                      setOpenImport(true);
                    }}
                  >
                    Import
                  </Button>
                </div>
              )}
            </div>
            <br />
            {renderRowWrapper}
          </div>
          <div className={classes.grdRow}>
            <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
              {message.message ? (
                <Alert severity={message.severity}>{message.message}</Alert>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={
                    waiting && !isPending ? (
                      <CircularProgress
                        style={{ color: '#ffffff', height: 20, width: 20 }}
                      />
                    ) : (
                      <span></span>
                    )
                  }
                  onClick={() => (waiting ? {} : handleSubmit())}
                  disabled={waiting}
                >
                  {waiting && !isPending
                    ? 'Processing'
                    : actionType === 'process-pending'
                    ? 'Process'
                    : 'Save'}
                </Button>
              )}
            </div>
            {actionType !== 'process-pending' && (
              <div className={classes.grdCell1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={
                    waiting && isPending ? (
                      <CircularProgress
                        style={{ color: '#ffffff', height: 20, width: 20 }}
                      />
                    ) : (
                      <span></span>
                    )
                  }
                  onClick={() => setIsPending(true)}
                  disabled={waiting}
                >
                  {waiting && isPending ? 'Processing' : 'Save to Pending'}
                </Button>
              </div>
            )}
          </div>
        </form>
        {openImport && (
          <TransactionUploadModal
            onClose={handleUploadClose}
            open={openImport}
          ></TransactionUploadModal>
        )}
      </Box>
    );
  }

  function onTextInput(e, name) {
    const value = e.target.value;

    if (name === 'correspondent') setCorrespondentValue(value);
    if (name === 'account_number') setAccountNumberValue(value);
    if (name === 'contra_correspondent') setContraCorrespondentValue(value);
    if (name === 'contra_account') setContraAccountValue(value);
    if (name === 'symbol') setSymbolValue(value);
    if (name === 'cusip') setCusipValue(value);
    if (name === 'description') setDescriptionValue(value);
    if (name === 'execution_id') setExecutionIdValue(value);
    if (name === 'external_id') setExternalIdValue(value);
    if (name === 'executing_venue') setExecutingVenueValue(value);
    if (name === 'order_id') setOrderIdValue(value);
    if (name === 'vendor') setVendorValue(value);
    if (name === 'ref_id') setRefIdValue(value);
    if (name === 'metadata') setMetadataValue(value);
    if (name === 'contra_symbol') setContraSymbolValue(value);
  }

  function calculateTaf(qty) {
    if (
      sideValue &&
      (sideValue === 'sell' || sideValue === 'sell_short') &&
      qty !== 0
    ) {
      let req = new GetFeeRequest();
      req.setValue(qty !== '' ? qty : '0');

      transactionClient.getTafFee(req, meta, (err, res) => {
        if (err) {
          notifyError(err.message);
          return;
        }

        let val = qty !== '0' && qty !== '' ? res.toObject().fee : '0';
        updateFeeValue('taf', val);
      });
    } else {
      updateFeeValue('taf', '0');
    }
  }

  function calculateRegFee(grossAmt) {
    if (
      sideValue &&
      (sideValue === 'sell' || sideValue === 'sell_short') &&
      grossAmt !== 0
    ) {
      let req = new GetFeeRequest();
      req.setValue(grossAmt !== '' ? grossAmt : '0');

      transactionClient.getRegFee(req, meta, (err, res) => {
        if (err) {
          notifyError(err.message);
          return;
        }

        let val =
          grossAmt !== '0' && grossAmt !== '' ? res.toObject().fee : '0';
        updateFeeValue('reg', val);
      });
    } else {
      updateFeeValue('reg', '0');
    }
  }

  function onNumberInput(e, name) {
    const value = e.target.value;
    if (name === 'qty') {
      setQtyValue(value);
      calculateTaf(value);
    }
    if (name === 'price') setPriceValue(value);
    if (name === 'gross_amount') {
      setGrossAmountValue(value);
      calculateRegFee(value);
    }
    if (name === 'fees') setFeesValue(value);
    if (name === 'net_amount') setNetAmountValue(value);
    if (name === 'leaves_qty') setLeavesQtyValue(value);
    if (name === 'swap_rate') setSwapRateValue(value);
    if (name === 'local_gross_amt') setLocalGrossAmtValue(value);
    if (name === 'local_net_amt') setLocalNetAmtValue(value);
    if (name === 'swap_fee_bps_alpa') setSwapFeeBpsAlpaValue(value);
    if (name === 'swap_fee_bps_corr') setSwapFeeBpsCorrValue(value);
    if (name === 'local_fees') setLocalFeesValue(value);
  }

  function onDateInput(e, name) {
    const value = e.target.value;
    if (name === 'settle_date') setSettleDateValue(value);
    if (name === 'hold_date') setHoldDateValue(value);
  }

  function onTimeInput(e, name) {
    const value = e.target.value;
    if (name === 'trade_date') setTradeDateValue(value);
    if (name === 'swap_rate_at') setSwapRateAtValue(value);
  }

  function getFormFields(row, screenNo) {
    let generatedRow = [];
    let count = 0;
    const screen = 'screen' + screenNo;
    row.forEach((inputField) => {
      count++;
      const rowKey = screen + count;
      const inputObject = inputs.find((o) => o.name === inputField);
      generatedRow.push(getFormField(inputObject, rowKey, row.length));
    });
    return generatedRow;
  }

  function updateFeeValue(feeType, v) {
    if (parseFloat(v) < 0) {
      return false;
    }

    let newArr = feeListValue.map((item) => {
      if (feeType === item.feeType) {
        return { ...item, fee: v };
      } else {
        return item;
      }
    });

    setFeeListValue(newArr);
  }

  function getValue(name) {
    if (name === 'correspondent') return correspondentValue;
    if (name === 'account_number') return accountNumberValue;
    if (name === 'contra_correspondent') return contraCorrespondentValue;
    if (name === 'contra_account') return contraAccountValue;
    if (name === 'symbol') return symbolValue;
    if (name === 'cusip') return cusipValue;
    if (name === 'description') return descriptionValue;
    if (name === 'qty') return qtyValue;
    if (name === 'price') return priceValue;
    if (name === 'gross_amount') return grossAmountValue;
    if (name === 'fees') return feesValue;
    if (name === 'net_amount') return netAmountValue;
    if (name === 'trade_date') return tradeDateValue;
    if (name === 'settle_date') return settleDateValue;
    if (name === 'execution_id') return executionIdValue;
    if (name === 'external_id') return externalIdValue;
    if (name === 'executing_venue') return executingVenueValue;
    if (name === 'order_id') return orderIdValue;
    if (name === 'leaves_qty') return leavesQtyValue;
    if (name === 'vendor') return vendorValue;
    if (name === 'contra_account_designator')
      return contraAccountDesignatorValue;
    if (name === 'ref_id') return refIdValue;
    if (name === 'hold_date') return holdDateValue;
    if (name === 'metadata') return metadataValue;
    if (name === 'contra_symbol') return contraSymbolValue;
    if (name === 'swap_rate') return swapRateValue;
    if (name === 'swap_rate_at') return swapRateAtValue;
    if (name === 'local_gross_amt') return localGrossAmtValue;
    if (name === 'local_net_amt') return localNetAmtValue;
    if (name === 'swap_fee_bps_alpa') return swapFeeBpsAlpaValue;
    if (name === 'swap_fee_bps_corr') return swapFeeBpsCorrValue;
    if (name === 'local_fees') return localFeesValue;
  }

  function getFormField(inputObject, rowKey, rowCount) {
    if (!inputObject) return;

    const { name, type, label, required, disabled } = inputObject;
    const isMetadata = name === 'metadata';
    let field = '';

    switch (type) {
      case 'text':
        field = (
          <>
            <TextField
              required={required}
              label={label}
              key={name}
              fullWidth
              onInput={(e) => onTextInput(e, name)}
              value={getValue(name) || ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {isMetadata && (
              <MetadataModal md={getValue(name)} setFormMd={onTextInput} />
            )}
          </>
        );
        break;
      case 'number':
        field = (
          <TextField
            required={required}
            disabled={disabled}
            label={label}
            key={name}
            type="number"
            fullWidth
            value={getValue(name) || ''}
            InputLabelProps={{ shrink: true }}
            onInput={(e) => onNumberInput(e, name)}
          />
        );
        break;
      case 'date':
        field = (
          <TextField
            required={required}
            fullWidth
            label={label}
            key={name}
            type="date"
            onInput={(e) => onDateInput(e, name)}
            value={getValue(name) || ''}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
        break;
      case 'time':
        field = (
          <TextField
            required={required}
            fullWidth
            label={label}
            key={name}
            type="datetime-local"
            onInput={(e) => onTimeInput(e, name)}
            value={getValue(name) || ''}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
        break;
      case 'select':
        {
          let options = [];
          let value = '';

          if (name === 'entry_type') {
            options = trnsOptions;
            value = transactionValue;
          }

          if (name === 'entry_sub_type') {
            options = entrySubTypeOptions;
            value = entrySubTypeValue;
          }

          if (name === 'side') {
            options = sideOptions;
            value = sideValue;
          }

          field = (
            <>
              <InputLabel shrink id={name}>
                {label + (required ? ' *' : '')}
              </InputLabel>
              <Select
                displayEmpty
                fullWidth
                key={name}
                onChange={handleOnChangeType}
                value={value || ''}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {options.map(function(item) {
                  let val = '';
                  if (
                    name === 'entry_type' ||
                    name === 'entry_sub_type' ||
                    name === 'side'
                  )
                    val = item.value;
                  if (name === 'correspondent') val = item.correspondent;
                  if (name === 'account_number') val = item.accountNo;
                  if (name === 'contra_correspondent')
                    val = item.contraCorrespondent;

                  if (!val) return '';

                  return (
                    <MenuItem key={keyRandomizer()} value={val || ''}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          );
        }
        break;
      case 'autocomplete-entry-sub-type':
        field = (
          <SelectEntrySubType
            freeSolo={true}
            type="entry_sub_type"
            name={name}
            label={label}
            value={getValue(name) || ''}
            onChange={(e) => {
              setEntrySubTypeValue(e.currentTarget.value);
            }}
          />
        );
        break;
      case 'autocomplete-correspondent':
        field = (
          <SelectAccount
            freeSolo={true}
            type="correspondent"
            name={name}
            label={label}
            value={getValue(name) || ''}
            onChange={(e) => {
              setCorrespondentValue(e.currentTarget.value);
            }}
          />
        );
        break;
      case 'autocomplete-contra-correspondent':
        field = (
          <SelectAccount
            freeSolo={true}
            type="correspondent"
            name={name}
            label={label}
            value={getValue(name) || ''}
            onChange={(e) => {
              setContraCorrespondentValue(e.currentTarget.value);
            }}
          />
        );
        break;
      case 'autocomplete-accountno':
        field = (
          <SelectAccount
            freeSolo={true}
            type="accountNo"
            name={name}
            label={label}
            value={getValue(name) || ''}
            showAccountName={true}
            onChange={(e) => {
              setAccountNumberValue(e.currentTarget.value);
            }}
            selectedCorrespondent={correspondentValue ? correspondentValue : ''}
          />
        );
        break;
      case 'autocomplete-contraaccount':
        field = (
          <SelectAccount
            freeSolo={true}
            type="accountNo"
            name={name}
            label={label}
            value={getValue(name) || ''}
            showAccountName={true}
            onChange={(e) => {
              setContraAccountValue(e.currentTarget.value);
            }}
            selectedCorrespondent={
              contraCorrespondentValue ? contraCorrespondentValue : ''
            }
          />
        );
        break;
      case 'autocomplete-vendor':
        field = (
          <VendorSelect
            required={required}
            labelid={name}
            label={label}
            value={getValue(name) || ''}
            onChange={(e) => setVendorValue(e.target.value)}
            setNewValue={(event, newValue) => {
              if (newValue) {
                setVendorValue(newValue.code);
              } else {
                setVendorValue('');
                return;
              }
            }}
          />
        );
        break;
      case 'autocomplete-symbol':
        field = (
          <SelectSymbol
            freeSolo={true}
            required={required}
            labelid={name}
            label={label}
            type="symbol"
            value={getValue(name) || ''}
            onChange={(e) => setSymbolValue(e.target.value)}
            date={
              tradeDateValue
                ? moment(Date.parse(tradeDateValue)).format('yyyy-MM-DD')
                : ''
            }
          />
        );
        break;
      case 'autocomplete-cusip':
        field = (
          <SelectSymbol
            freeSolo={true}
            required={required}
            labelid={name}
            label={label}
            value={getValue(name) || ''}
            type="cusip"
            onChange={(e) => setCusipValue(e.target.value)}
            selectedSymbol={symbolValue ? symbolValue : ''}
            date={
              tradeDateValue
                ? moment(Date.parse(tradeDateValue)).format('yyyy-MM-DD')
                : ''
            }
          />
        );
        break;
      case 'multiple-fees':
        field = (
          <>
            {/* <InputLabel shrink id={name}>
                {label + (required ? ' *' : '')}
            </InputLabel> */}
            <FeesComponent value={feesValue}>
              {feeListValue.map((fee) => (
                <AccordionDetails className={classes.details} key={fee.feeType}>
                  <TextField
                    fullWidth
                    labelid={fee.feeType}
                    label={fee.feeType}
                    value={fee.fee}
                    onBlur={(e) => {
                      if (e.target.value === '') {
                        updateFeeValue(fee.feeType, 0);
                      }
                    }}
                    onChange={(e) => {
                      updateFeeValue(fee.feeType, e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </AccordionDetails>
              ))}
            </FeesComponent>
          </>
        );
        break;
      case 'custom-select':
        field = (
          <div>
            <InputLabel shrink>{label}</InputLabel>
            <Select
              fullWidth
              value={getValue(name) || ''}
              onChange={(e) => setContraAccountDesignatorValue(e.target.value)}
            >
              <MenuItem value="">
                <em>Blank</em>
              </MenuItem>
              <MenuItem value="2">Long</MenuItem>
              <MenuItem value="3">Short</MenuItem>
            </Select>
          </div>
        );
        break;
      case 'autocomplete-contra-symbol':
        field = (
          <SelectCurrency
            freeSolo={false}
            name={name}
            label={label}
            required={required}
            value={getValue(name) || ''}
            onChange={(e) => {
              setContraSymbolValue(e.currentTarget.value);
              setGrossAmountValue('');
              setNetAmountValue('');
            }}
          />
        );
        break;
      default:
        field = <></>;
    }

    if (
      name === 'swap_rate' ||
      name === 'swap_rate_at' ||
      name === 'local_gross_amt' ||
      name === 'local_net_amt' ||
      name === 'swap_fee_bps_alpa' ||
      name === 'swap_fee_bps_corr' ||
      name === 'local_fees'
    ) {
      // Note: this will create a empty cell as place holder
      field = (contraSymbolValue || 'USD') === 'USD' ? <></> : field;
    }

    const autoWidth = rowCount > 2 ? '100%' : '300px';
    return (
      <div
        className={classes.grdCell1}
        key={rowKey}
        style={{ marginRight: 10, width: autoWidth }}
      >
        {' '}
        {field}{' '}
      </div>
    );
  }

  function renderForm() {
    const tradeElements = [
      [
        'entry_type',
        'correspondent',
        'account_number',
        'contra_correspondent',
        'contra_account',
        'side',
      ],
      ['symbol', 'cusip', 'qty', 'price', 'trade_date', 'settle_date'],
      [
        'gross_amount',
        (contraSymbolValue || 'USD') === 'USD' ? 'fees' : 'local_fees',
        'net_amount',
      ],
      ['description', 'external_id', 'execution_id', 'executing_venue'],
      ['order_id', 'leaves_qty', 'contra_account_designator', 'ref_id'],
      ['metadata', 'contra_symbol', 'swap_rate', 'swap_rate_at'],

      (contraSymbolValue || 'USD') === 'USD'
        ? null
        : ['swap_fee_bps_alpa', 'swap_fee_bps_corr'],
    ];

    const isSubtypeUSD = (subtype) => {
      if (subtype === 'REG' || subtype === 'TAF' || subtype === 'JIT') {
        return true;
      }
      return (contraSymbolValue || 'USD') === 'USD';
    };
    const cashElements = [
      [
        'entry_type',
        'entry_sub_type',
        'correspondent',
        'account_number',
        'contra_correspondent',
      ],
      [
        'contra_account',
        isSubtypeUSD(entrySubTypeValue) ? 'net_amount' : 'local_net_amt',
        'settle_date',
        'description',
      ],
      [
        'external_id',
        'execution_id',
        'vendor',
        transactionValue === 'INVPNL' ? 'symbol' : null,
        transactionValue === 'INVPNL' ? 'cusip' : null,
        'ref_id',
      ],
      ['metadata', 'contra_symbol', 'swap_rate', 'swap_rate_at'],
    ];

    const positionElements = [
      [
        'entry_type',
        'entry_sub_type',
        'correspondent',
        'account_number',
        'contra_account',
        'contra_correspondent',
      ],
      [
        'symbol',
        'cusip',
        'qty',
        transactionValue !== 'MMSWP' ? 'price' : null,
        'settle_date',
        'hold_date',
      ],
      ['description', 'contra_account_designator', 'ref_id'],
      ['metadata', 'contra_symbol', 'swap_rate', 'swap_rate_at'],
    ];

    const cashPositionElements = [
      [
        'entry_type',
        'entry_sub_type',
        'correspondent',
        'account_number',
        'contra_correspondent',
      ],
      ['contra_account', 'symbol', 'cusip', 'qty', 'price'],
      [
        'net_amount',
        'settle_date',
        'hold_date',
        'description',
        'contra_account_designator',
      ],
      ['ref_id', 'metadata', 'contra_symbol', 'swap_rate', 'swap_rate_at'],
    ];

    const journalCashPositionElements = [
      [
        'entry_type',
        'correspondent',
        'account_number',
        'contra_correspondent',
        'contra_account',
      ],
      [
        'symbol',
        'cusip',
        'qty',
        'net_amount',
        'price',
        'trade_date',
        'settle_date',
      ],
      ['description', 'external_id', 'execution_id', 'executing_venue'],
      ['order_id', 'leaves_qty', 'contra_account_designator', 'ref_id'],
      ['metadata'],
    ];

    let elements = [];
    if (screen === 1) elements = tradeElements;
    if (screen === 2) elements = cashElements;
    if (screen === 3) elements = positionElements;
    if (screen === 4) elements = cashPositionElements;
    if (screen === 5) elements = journalCashPositionElements;

    return getTransactionForm(elements, screen);
  }

  return <>{renderForm()}</>;
}
