// source: bankpb/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.bankpb.Account', null, global);
goog.exportSymbol('proto.bankpb.CreateAccountResponse', null, global);
goog.exportSymbol('proto.bankpb.ListAccountRequest', null, global);
goog.exportSymbol('proto.bankpb.ListAccountResponse', null, global);
goog.exportSymbol('proto.bankpb.ReadAccountRequest', null, global);
goog.exportSymbol('proto.bankpb.ReadAccountResponse', null, global);
goog.exportSymbol('proto.bankpb.UpdateAccountRequest', null, global);
goog.exportSymbol('proto.bankpb.UpdateAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.Account.displayName = 'proto.bankpb.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.UpdateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.UpdateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.UpdateAccountRequest.displayName = 'proto.bankpb.UpdateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.CreateAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.CreateAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.CreateAccountResponse.displayName = 'proto.bankpb.CreateAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.UpdateAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.UpdateAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.UpdateAccountResponse.displayName = 'proto.bankpb.UpdateAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ReadAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ReadAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ReadAccountRequest.displayName = 'proto.bankpb.ReadAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ReadAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ReadAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ReadAccountResponse.displayName = 'proto.bankpb.ReadAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ListAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListAccountRequest.displayName = 'proto.bankpb.ListAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bankpb.ListAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.bankpb.ListAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListAccountResponse.displayName = 'proto.bankpb.ListAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
bankId: jspb.Message.getFieldWithDefault(msg, 1, ""),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
bankOwnerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
bankName: jspb.Message.getFieldWithDefault(msg, 6, ""),
achRoutingNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
wireRoutingNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
bankAccountNo: jspb.Message.getFieldWithDefault(msg, 9, ""),
bankAccountType: jspb.Message.getFieldWithDefault(msg, 10, ""),
approvedMethod: jspb.Message.getFieldWithDefault(msg, 11, ""),
paidAccessToken: jspb.Message.getFieldWithDefault(msg, 12, ""),
status: jspb.Message.getFieldWithDefault(msg, 13, ""),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
bankAddressId: jspb.Message.getFieldWithDefault(msg, 17, ""),
bankIdentifierCode: jspb.Message.getFieldWithDefault(msg, 18, ""),
achSecType: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.Account}
 */
proto.bankpb.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.Account;
  return proto.bankpb.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.Account}
 */
proto.bankpb.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankOwnerName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchRoutingNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWireRoutingNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountNo(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedMethod(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidAccessToken(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 16:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAddressId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankIdentifierCode(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchSecType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankOwnerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAchRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWireRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBankAccountNo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBankAccountType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getApprovedMethod();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPaidAccessToken();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getBankAddressId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBankIdentifierCode();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAchSecType();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string bank_id = 1;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.bankpb.Account.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.bankpb.Account.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.bankpb.Account.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_owner_name = 5;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bank_name = 6;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ach_routing_no = 7;
 * @return {string}
 */
proto.bankpb.Account.prototype.getAchRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setAchRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string wire_routing_no = 8;
 * @return {string}
 */
proto.bankpb.Account.prototype.getWireRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setWireRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string bank_account_no = 9;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string bank_account_type = 10;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string approved_method = 11;
 * @return {string}
 */
proto.bankpb.Account.prototype.getApprovedMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setApprovedMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string paid_access_token = 12;
 * @return {string}
 */
proto.bankpb.Account.prototype.getPaidAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setPaidAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string status = 13;
 * @return {string}
 */
proto.bankpb.Account.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.bankpb.Account.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.bankpb.Account} returns this
*/
proto.bankpb.Account.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.Account.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string created_by = 15;
 * @return {string}
 */
proto.bankpb.Account.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.type.Date system_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.Account.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.Account} returns this
*/
proto.bankpb.Account.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.Account.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string bank_address_id = 17;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string bank_identifier_code = 18;
 * @return {string}
 */
proto.bankpb.Account.prototype.getBankIdentifierCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setBankIdentifierCode = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string ach_sec_type = 19;
 * @return {string}
 */
proto.bankpb.Account.prototype.getAchSecType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.Account} returns this
 */
proto.bankpb.Account.prototype.setAchSecType = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.UpdateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.UpdateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.UpdateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bankId: jspb.Message.getFieldWithDefault(msg, 1, ""),
status: jspb.Message.getFieldWithDefault(msg, 2, ""),
bankAddressId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.UpdateAccountRequest}
 */
proto.bankpb.UpdateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.UpdateAccountRequest;
  return proto.bankpb.UpdateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.UpdateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.UpdateAccountRequest}
 */
proto.bankpb.UpdateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAddressId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.UpdateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.UpdateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.UpdateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankAddressId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string bank_id = 1;
 * @return {string}
 */
proto.bankpb.UpdateAccountRequest.prototype.getBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.UpdateAccountRequest} returns this
 */
proto.bankpb.UpdateAccountRequest.prototype.setBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.bankpb.UpdateAccountRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.UpdateAccountRequest} returns this
 */
proto.bankpb.UpdateAccountRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bank_address_id = 3;
 * @return {string}
 */
proto.bankpb.UpdateAccountRequest.prototype.getBankAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.UpdateAccountRequest} returns this
 */
proto.bankpb.UpdateAccountRequest.prototype.setBankAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.CreateAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.CreateAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.CreateAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.CreateAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankAccount: (f = msg.getBankAccount()) && proto.bankpb.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.CreateAccountResponse}
 */
proto.bankpb.CreateAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.CreateAccountResponse;
  return proto.bankpb.CreateAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.CreateAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.CreateAccountResponse}
 */
proto.bankpb.CreateAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.Account;
      reader.readMessage(value,proto.bankpb.Account.deserializeBinaryFromReader);
      msg.setBankAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.CreateAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.CreateAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.CreateAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.CreateAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional Account bank_account = 1;
 * @return {?proto.bankpb.Account}
 */
proto.bankpb.CreateAccountResponse.prototype.getBankAccount = function() {
  return /** @type{?proto.bankpb.Account} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.Account, 1));
};


/**
 * @param {?proto.bankpb.Account|undefined} value
 * @return {!proto.bankpb.CreateAccountResponse} returns this
*/
proto.bankpb.CreateAccountResponse.prototype.setBankAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.CreateAccountResponse} returns this
 */
proto.bankpb.CreateAccountResponse.prototype.clearBankAccount = function() {
  return this.setBankAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.CreateAccountResponse.prototype.hasBankAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.UpdateAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.UpdateAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.UpdateAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankAccount: (f = msg.getBankAccount()) && proto.bankpb.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.UpdateAccountResponse}
 */
proto.bankpb.UpdateAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.UpdateAccountResponse;
  return proto.bankpb.UpdateAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.UpdateAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.UpdateAccountResponse}
 */
proto.bankpb.UpdateAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.Account;
      reader.readMessage(value,proto.bankpb.Account.deserializeBinaryFromReader);
      msg.setBankAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.UpdateAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.UpdateAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.UpdateAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional Account bank_account = 1;
 * @return {?proto.bankpb.Account}
 */
proto.bankpb.UpdateAccountResponse.prototype.getBankAccount = function() {
  return /** @type{?proto.bankpb.Account} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.Account, 1));
};


/**
 * @param {?proto.bankpb.Account|undefined} value
 * @return {!proto.bankpb.UpdateAccountResponse} returns this
*/
proto.bankpb.UpdateAccountResponse.prototype.setBankAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.UpdateAccountResponse} returns this
 */
proto.bankpb.UpdateAccountResponse.prototype.clearBankAccount = function() {
  return this.setBankAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.UpdateAccountResponse.prototype.hasBankAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ReadAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ReadAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ReadAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bankId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ReadAccountRequest}
 */
proto.bankpb.ReadAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ReadAccountRequest;
  return proto.bankpb.ReadAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ReadAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ReadAccountRequest}
 */
proto.bankpb.ReadAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ReadAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ReadAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ReadAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string bank_id = 1;
 * @return {string}
 */
proto.bankpb.ReadAccountRequest.prototype.getBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ReadAccountRequest} returns this
 */
proto.bankpb.ReadAccountRequest.prototype.setBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ReadAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ReadAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ReadAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankAccount: (f = msg.getBankAccount()) && proto.bankpb.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ReadAccountResponse}
 */
proto.bankpb.ReadAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ReadAccountResponse;
  return proto.bankpb.ReadAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ReadAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ReadAccountResponse}
 */
proto.bankpb.ReadAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.Account;
      reader.readMessage(value,proto.bankpb.Account.deserializeBinaryFromReader);
      msg.setBankAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ReadAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ReadAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ReadAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional Account bank_account = 1;
 * @return {?proto.bankpb.Account}
 */
proto.bankpb.ReadAccountResponse.prototype.getBankAccount = function() {
  return /** @type{?proto.bankpb.Account} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.Account, 1));
};


/**
 * @param {?proto.bankpb.Account|undefined} value
 * @return {!proto.bankpb.ReadAccountResponse} returns this
*/
proto.bankpb.ReadAccountResponse.prototype.setBankAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.ReadAccountResponse} returns this
 */
proto.bankpb.ReadAccountResponse.prototype.clearBankAccount = function() {
  return this.setBankAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.ReadAccountResponse.prototype.hasBankAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
bankOwnerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
bankName: jspb.Message.getFieldWithDefault(msg, 5, ""),
achRoutingNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
bankAccountNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
status: jspb.Message.getFieldWithDefault(msg, 8, ""),
fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListAccountRequest}
 */
proto.bankpb.ListAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListAccountRequest;
  return proto.bankpb.ListAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListAccountRequest}
 */
proto.bankpb.ListAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankOwnerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchRoutingNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBankOwnerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAchRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBankAccountNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string master_account_no = 2;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bank_owner_name = 4;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getBankOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setBankOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_name = 5;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ach_routing_no = 6;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getAchRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setAchRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bank_account_no = 7;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getBankAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setBankAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.bankpb.ListAccountRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.type.Date from_system_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.ListAccountRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
*/
proto.bankpb.ListAccountRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.ListAccountRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.type.Date to_system_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.ListAccountRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.ListAccountRequest} returns this
*/
proto.bankpb.ListAccountRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.ListAccountRequest} returns this
 */
proto.bankpb.ListAccountRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.ListAccountRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bankpb.ListAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bankAccountsList: jspb.Message.toObjectList(msg.getBankAccountsList(),
    proto.bankpb.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListAccountResponse}
 */
proto.bankpb.ListAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListAccountResponse;
  return proto.bankpb.ListAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListAccountResponse}
 */
proto.bankpb.ListAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.Account;
      reader.readMessage(value,proto.bankpb.Account.deserializeBinaryFromReader);
      msg.addBankAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.bankpb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Account bank_accounts = 1;
 * @return {!Array<!proto.bankpb.Account>}
 */
proto.bankpb.ListAccountResponse.prototype.getBankAccountsList = function() {
  return /** @type{!Array<!proto.bankpb.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bankpb.Account, 1));
};


/**
 * @param {!Array<!proto.bankpb.Account>} value
 * @return {!proto.bankpb.ListAccountResponse} returns this
*/
proto.bankpb.ListAccountResponse.prototype.setBankAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.bankpb.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bankpb.Account}
 */
proto.bankpb.ListAccountResponse.prototype.addBankAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.bankpb.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bankpb.ListAccountResponse} returns this
 */
proto.bankpb.ListAccountResponse.prototype.clearBankAccountsList = function() {
  return this.setBankAccountsList([]);
};


goog.object.extend(exports, proto.bankpb);
