// source: assetpb/securitymaster.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.assetpb.AdditionalDeliverables', null, global);
goog.exportSymbol('proto.assetpb.ListSecurityMasterMultipleRequest', null, global);
goog.exportSymbol('proto.assetpb.ListSecurityMasterMultipleResponse', null, global);
goog.exportSymbol('proto.assetpb.ListSecurityMasterRequest', null, global);
goog.exportSymbol('proto.assetpb.ListSecurityMasterResponse', null, global);
goog.exportSymbol('proto.assetpb.SecurityMaster', null, global);
goog.exportSymbol('proto.assetpb.SecurityMasterFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.SecurityMaster = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assetpb.SecurityMaster, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.SecurityMaster.displayName = 'proto.assetpb.SecurityMaster';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.AdditionalDeliverables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assetpb.AdditionalDeliverables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.AdditionalDeliverables.displayName = 'proto.assetpb.AdditionalDeliverables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.SecurityMasterFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assetpb.SecurityMasterFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.SecurityMasterFilter.displayName = 'proto.assetpb.SecurityMasterFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.ListSecurityMasterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assetpb.ListSecurityMasterRequest.repeatedFields_, null);
};
goog.inherits(proto.assetpb.ListSecurityMasterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.ListSecurityMasterRequest.displayName = 'proto.assetpb.ListSecurityMasterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.ListSecurityMasterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assetpb.ListSecurityMasterResponse.repeatedFields_, null);
};
goog.inherits(proto.assetpb.ListSecurityMasterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.ListSecurityMasterResponse.displayName = 'proto.assetpb.ListSecurityMasterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.ListSecurityMasterMultipleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assetpb.ListSecurityMasterMultipleRequest.repeatedFields_, null);
};
goog.inherits(proto.assetpb.ListSecurityMasterMultipleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.ListSecurityMasterMultipleRequest.displayName = 'proto.assetpb.ListSecurityMasterMultipleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assetpb.ListSecurityMasterMultipleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assetpb.ListSecurityMasterMultipleResponse.repeatedFields_, null);
};
goog.inherits(proto.assetpb.ListSecurityMasterMultipleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assetpb.ListSecurityMasterMultipleResponse.displayName = 'proto.assetpb.ListSecurityMasterMultipleResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.SecurityMaster.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.SecurityMaster.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.SecurityMaster} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.SecurityMaster.toObject = function(includeInstance, msg) {
  var f, obj = {
securityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
originalCusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
symbolDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
marginable: jspb.Message.getFieldWithDefault(msg, 6, ""),
securityType: jspb.Message.getFieldWithDefault(msg, 7, ""),
secSubType: jspb.Message.getFieldWithDefault(msg, 8, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 9, ""),
whenIssued: jspb.Message.getFieldWithDefault(msg, 10, ""),
exchangeCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
sicCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
dtcEligible: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
leverageFactor: jspb.Message.getFieldWithDefault(msg, 14, 0),
createdBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
issuerTypeCode: jspb.Message.getFieldWithDefault(msg, 18, ""),
assetCategory: jspb.Message.getFieldWithDefault(msg, 19, ""),
startDate: (f = msg.getStartDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
endDate: (f = msg.getEndDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
multiplier: jspb.Message.getFieldWithDefault(msg, 22, ""),
additionalDeliverables: (f = msg.getAdditionalDeliverables()) && proto.assetpb.AdditionalDeliverables.toObject(includeInstance, f),
underlyingSymbol: jspb.Message.getFieldWithDefault(msg, 24, ""),
size: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.SecurityMaster}
 */
proto.assetpb.SecurityMaster.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.SecurityMaster;
  return proto.assetpb.SecurityMaster.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.SecurityMaster} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.SecurityMaster}
 */
proto.assetpb.SecurityMaster.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginable(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecSubType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhenIssued(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSicCode(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDtcEligible(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeverageFactor(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerTypeCode(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetCategory(value);
      break;
    case 20:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 21:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setMultiplier(value);
      break;
    case 23:
      var value = new proto.assetpb.AdditionalDeliverables;
      reader.readMessage(value,proto.assetpb.AdditionalDeliverables.deserializeBinaryFromReader);
      msg.setAdditionalDeliverables(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingSymbol(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.SecurityMaster.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.SecurityMaster.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.SecurityMaster} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.SecurityMaster.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMarginable();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSecurityType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSecSubType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getWhenIssued();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExchangeCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSicCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDtcEligible();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLeverageFactor();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIssuerTypeCode();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAssetCategory();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getMultiplier();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAdditionalDeliverables();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.assetpb.AdditionalDeliverables.serializeBinaryToWriter
    );
  }
  f = message.getUnderlyingSymbol();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSize();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string security_id = 1;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string original_cusip = 2;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cusip = 4;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol_description = 5;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string marginable = 6;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getMarginable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setMarginable = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string security_type = 7;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSecurityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSecurityType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sec_sub_type = 8;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSecSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSecSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string asset_type = 9;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string when_issued = 10;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getWhenIssued = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setWhenIssued = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string exchange_code = 11;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getExchangeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setExchangeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string sic_code = 12;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSicCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSicCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool dtc_eligible = 13;
 * @return {boolean}
 */
proto.assetpb.SecurityMaster.prototype.getDtcEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setDtcEligible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 leverage_factor = 14;
 * @return {number}
 */
proto.assetpb.SecurityMaster.prototype.getLeverageFactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setLeverageFactor = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string created_by = 16;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.assetpb.SecurityMaster.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.assetpb.SecurityMaster} returns this
*/
proto.assetpb.SecurityMaster.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assetpb.SecurityMaster.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string issuer_type_code = 18;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getIssuerTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setIssuerTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string asset_category = 19;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getAssetCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setAssetCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.type.Date start_date = 20;
 * @return {?proto.google.type.Date}
 */
proto.assetpb.SecurityMaster.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 20));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.assetpb.SecurityMaster} returns this
*/
proto.assetpb.SecurityMaster.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assetpb.SecurityMaster.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.type.Date end_date = 21;
 * @return {?proto.google.type.Date}
 */
proto.assetpb.SecurityMaster.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 21));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.assetpb.SecurityMaster} returns this
*/
proto.assetpb.SecurityMaster.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assetpb.SecurityMaster.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string multiplier = 22;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getMultiplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional AdditionalDeliverables additional_deliverables = 23;
 * @return {?proto.assetpb.AdditionalDeliverables}
 */
proto.assetpb.SecurityMaster.prototype.getAdditionalDeliverables = function() {
  return /** @type{?proto.assetpb.AdditionalDeliverables} */ (
    jspb.Message.getWrapperField(this, proto.assetpb.AdditionalDeliverables, 23));
};


/**
 * @param {?proto.assetpb.AdditionalDeliverables|undefined} value
 * @return {!proto.assetpb.SecurityMaster} returns this
*/
proto.assetpb.SecurityMaster.prototype.setAdditionalDeliverables = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.clearAdditionalDeliverables = function() {
  return this.setAdditionalDeliverables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assetpb.SecurityMaster.prototype.hasAdditionalDeliverables = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string underlying_symbol = 24;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getUnderlyingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setUnderlyingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string size = 25;
 * @return {string}
 */
proto.assetpb.SecurityMaster.prototype.getSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMaster} returns this
 */
proto.assetpb.SecurityMaster.prototype.setSize = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.AdditionalDeliverables.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.AdditionalDeliverables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.AdditionalDeliverables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.AdditionalDeliverables.toObject = function(includeInstance, msg) {
  var f, obj = {
symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
amount: jspb.Message.getFieldWithDefault(msg, 2, ""),
allocationPercentage: jspb.Message.getFieldWithDefault(msg, 3, ""),
settlementType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.AdditionalDeliverables}
 */
proto.assetpb.AdditionalDeliverables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.AdditionalDeliverables;
  return proto.assetpb.AdditionalDeliverables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.AdditionalDeliverables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.AdditionalDeliverables}
 */
proto.assetpb.AdditionalDeliverables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllocationPercentage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.AdditionalDeliverables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.AdditionalDeliverables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.AdditionalDeliverables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.AdditionalDeliverables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllocationPercentage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSettlementType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.assetpb.AdditionalDeliverables.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.AdditionalDeliverables} returns this
 */
proto.assetpb.AdditionalDeliverables.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string amount = 2;
 * @return {string}
 */
proto.assetpb.AdditionalDeliverables.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.AdditionalDeliverables} returns this
 */
proto.assetpb.AdditionalDeliverables.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string allocation_percentage = 3;
 * @return {string}
 */
proto.assetpb.AdditionalDeliverables.prototype.getAllocationPercentage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.AdditionalDeliverables} returns this
 */
proto.assetpb.AdditionalDeliverables.prototype.setAllocationPercentage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string settlement_type = 4;
 * @return {string}
 */
proto.assetpb.AdditionalDeliverables.prototype.getSettlementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.AdditionalDeliverables} returns this
 */
proto.assetpb.AdditionalDeliverables.prototype.setSettlementType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.SecurityMasterFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.SecurityMasterFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.SecurityMasterFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.SecurityMasterFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
originalCusip: jspb.Message.getFieldWithDefault(msg, 3, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 4, ""),
asof: (f = msg.getAsof()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.SecurityMasterFilter}
 */
proto.assetpb.SecurityMasterFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.SecurityMasterFilter;
  return proto.assetpb.SecurityMasterFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.SecurityMasterFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.SecurityMasterFilter}
 */
proto.assetpb.SecurityMasterFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.SecurityMasterFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.SecurityMasterFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.SecurityMasterFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.SecurityMasterFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.assetpb.SecurityMasterFilter.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMasterFilter} returns this
 */
proto.assetpb.SecurityMasterFilter.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cusip = 2;
 * @return {string}
 */
proto.assetpb.SecurityMasterFilter.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMasterFilter} returns this
 */
proto.assetpb.SecurityMasterFilter.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string original_cusip = 3;
 * @return {string}
 */
proto.assetpb.SecurityMasterFilter.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMasterFilter} returns this
 */
proto.assetpb.SecurityMasterFilter.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string asset_type = 4;
 * @return {string}
 */
proto.assetpb.SecurityMasterFilter.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.SecurityMasterFilter} returns this
 */
proto.assetpb.SecurityMasterFilter.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date asof = 5;
 * @return {?proto.google.type.Date}
 */
proto.assetpb.SecurityMasterFilter.prototype.getAsof = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.assetpb.SecurityMasterFilter} returns this
*/
proto.assetpb.SecurityMasterFilter.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assetpb.SecurityMasterFilter} returns this
 */
proto.assetpb.SecurityMasterFilter.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assetpb.SecurityMasterFilter.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assetpb.ListSecurityMasterRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.ListSecurityMasterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.ListSecurityMasterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
asof: (f = msg.getAsof()) && google_type_date_pb.Date.toObject(includeInstance, f),
originalCusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
assetTypesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.ListSecurityMasterRequest}
 */
proto.assetpb.ListSecurityMasterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.ListSecurityMasterRequest;
  return proto.assetpb.ListSecurityMasterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.ListSecurityMasterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.ListSecurityMasterRequest}
 */
proto.assetpb.ListSecurityMasterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setAsof(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.ListSecurityMasterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.ListSecurityMasterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAsof();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cusip = 2;
 * @return {string}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Date asof = 3;
 * @return {?proto.google.type.Date}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.getAsof = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
*/
proto.assetpb.ListSecurityMasterRequest.prototype.setAsof = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.clearAsof = function() {
  return this.setAsof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.hasAsof = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string original_cusip = 4;
 * @return {string}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string asset_types = 5;
 * @return {!Array<string>}
 */
proto.assetpb.ListSecurityMasterRequest.prototype.getAssetTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.setAssetTypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.addAssetTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assetpb.ListSecurityMasterRequest} returns this
 */
proto.assetpb.ListSecurityMasterRequest.prototype.clearAssetTypesList = function() {
  return this.setAssetTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assetpb.ListSecurityMasterResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.ListSecurityMasterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.ListSecurityMasterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.ListSecurityMasterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
securityMastersList: jspb.Message.toObjectList(msg.getSecurityMastersList(),
    proto.assetpb.SecurityMaster.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.ListSecurityMasterResponse}
 */
proto.assetpb.ListSecurityMasterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.ListSecurityMasterResponse;
  return proto.assetpb.ListSecurityMasterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.ListSecurityMasterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.ListSecurityMasterResponse}
 */
proto.assetpb.ListSecurityMasterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assetpb.SecurityMaster;
      reader.readMessage(value,proto.assetpb.SecurityMaster.deserializeBinaryFromReader);
      msg.addSecurityMasters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.ListSecurityMasterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.ListSecurityMasterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.ListSecurityMasterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityMastersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.assetpb.SecurityMaster.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SecurityMaster security_masters = 1;
 * @return {!Array<!proto.assetpb.SecurityMaster>}
 */
proto.assetpb.ListSecurityMasterResponse.prototype.getSecurityMastersList = function() {
  return /** @type{!Array<!proto.assetpb.SecurityMaster>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assetpb.SecurityMaster, 1));
};


/**
 * @param {!Array<!proto.assetpb.SecurityMaster>} value
 * @return {!proto.assetpb.ListSecurityMasterResponse} returns this
*/
proto.assetpb.ListSecurityMasterResponse.prototype.setSecurityMastersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.assetpb.SecurityMaster=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assetpb.SecurityMaster}
 */
proto.assetpb.ListSecurityMasterResponse.prototype.addSecurityMasters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.assetpb.SecurityMaster, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assetpb.ListSecurityMasterResponse} returns this
 */
proto.assetpb.ListSecurityMasterResponse.prototype.clearSecurityMastersList = function() {
  return this.setSecurityMastersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assetpb.ListSecurityMasterMultipleRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.ListSecurityMasterMultipleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.ListSecurityMasterMultipleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.ListSecurityMasterMultipleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterMultipleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.assetpb.SecurityMasterFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.ListSecurityMasterMultipleRequest}
 */
proto.assetpb.ListSecurityMasterMultipleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.ListSecurityMasterMultipleRequest;
  return proto.assetpb.ListSecurityMasterMultipleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.ListSecurityMasterMultipleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.ListSecurityMasterMultipleRequest}
 */
proto.assetpb.ListSecurityMasterMultipleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assetpb.SecurityMasterFilter;
      reader.readMessage(value,proto.assetpb.SecurityMasterFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.ListSecurityMasterMultipleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.ListSecurityMasterMultipleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.ListSecurityMasterMultipleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterMultipleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.assetpb.SecurityMasterFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SecurityMasterFilter filters = 1;
 * @return {!Array<!proto.assetpb.SecurityMasterFilter>}
 */
proto.assetpb.ListSecurityMasterMultipleRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.assetpb.SecurityMasterFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assetpb.SecurityMasterFilter, 1));
};


/**
 * @param {!Array<!proto.assetpb.SecurityMasterFilter>} value
 * @return {!proto.assetpb.ListSecurityMasterMultipleRequest} returns this
*/
proto.assetpb.ListSecurityMasterMultipleRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.assetpb.SecurityMasterFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assetpb.SecurityMasterFilter}
 */
proto.assetpb.ListSecurityMasterMultipleRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.assetpb.SecurityMasterFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assetpb.ListSecurityMasterMultipleRequest} returns this
 */
proto.assetpb.ListSecurityMasterMultipleRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assetpb.ListSecurityMasterMultipleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assetpb.ListSecurityMasterMultipleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.assetpb.ListSecurityMasterMultipleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assetpb.ListSecurityMasterMultipleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterMultipleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
securityMastersList: jspb.Message.toObjectList(msg.getSecurityMastersList(),
    proto.assetpb.SecurityMaster.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assetpb.ListSecurityMasterMultipleResponse}
 */
proto.assetpb.ListSecurityMasterMultipleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assetpb.ListSecurityMasterMultipleResponse;
  return proto.assetpb.ListSecurityMasterMultipleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assetpb.ListSecurityMasterMultipleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assetpb.ListSecurityMasterMultipleResponse}
 */
proto.assetpb.ListSecurityMasterMultipleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assetpb.SecurityMaster;
      reader.readMessage(value,proto.assetpb.SecurityMaster.deserializeBinaryFromReader);
      msg.addSecurityMasters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assetpb.ListSecurityMasterMultipleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assetpb.ListSecurityMasterMultipleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assetpb.ListSecurityMasterMultipleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assetpb.ListSecurityMasterMultipleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityMastersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.assetpb.SecurityMaster.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SecurityMaster security_masters = 1;
 * @return {!Array<!proto.assetpb.SecurityMaster>}
 */
proto.assetpb.ListSecurityMasterMultipleResponse.prototype.getSecurityMastersList = function() {
  return /** @type{!Array<!proto.assetpb.SecurityMaster>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assetpb.SecurityMaster, 1));
};


/**
 * @param {!Array<!proto.assetpb.SecurityMaster>} value
 * @return {!proto.assetpb.ListSecurityMasterMultipleResponse} returns this
*/
proto.assetpb.ListSecurityMasterMultipleResponse.prototype.setSecurityMastersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.assetpb.SecurityMaster=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assetpb.SecurityMaster}
 */
proto.assetpb.ListSecurityMasterMultipleResponse.prototype.addSecurityMasters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.assetpb.SecurityMaster, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assetpb.ListSecurityMasterMultipleResponse} returns this
 */
proto.assetpb.ListSecurityMasterMultipleResponse.prototype.clearSecurityMastersList = function() {
  return this.setSecurityMastersList([]);
};


goog.object.extend(exports, proto.assetpb);
