/*Service*/
import {
  SystemNumber,
  ListSystemNumberTypesRequest,
} from '../../../proto/admpb/systemnumber_grpc_web_pb';

// import {
//   ReadProfileRequest,
//   ProfileServiceClient,
// } from '../../../proto/admpb/profile_grpc_web_pb';

/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Button,
  Box,
  TextField,
  Modal,
  Backdrop,
  Fade,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

/*Material UI Icons*/
import { Add as AddIcon, Create as EditIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom component*/
import SearchButton from '../../../components/Button/Search';
import SelectSystemNumberType from '../../../components/AutoComplete/SystemNumberType';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Time Stamp Protobuf*/
const google_date = require('../../../google/type/date_pb.js');

var countDecimals = function(num) {
  let text = num.toString();
  if (text.indexOf('e-') > -1) {
    let [base, trail] = text.split('e-');
    let elen = parseInt(trail, 10);
    let idx = base.indexOf('.');
    return idx === -1 ? 0 + elen : base.length - idx - 1 + elen;
  }
  let index = text.indexOf('.');
  return index === -1 ? 0 : text.length - index - 1;
};
/*=========================================================================================
Add and Update system code function
===========================================================================================*/
function saveSystemNumber(
  systemNumberClient,
  rows,
  isAdd,
  closeModal,
  systemNumberID,
  type,
  subType,
  fromDate,
  toDate,
  rate
) {
  if (isAdd) {
    if (!type) {
      notifyError('Type is a required field.');
      return;
    }
    if (!subType) {
      notifyError('Sub type is a required field.');
      return;
    }
    if (!fromDate) {
      notifyError('From date is a required field.');
      return;
    }
    if (!toDate) {
      notifyError('To date is a required field.');
      return;
    }
    if (!rate) {
      notifyError('Rate is a required field.');
      return;
    }

    let req = new SystemNumber();
    req.setType(type);
    req.setSubType(subType);
    req.setRate(rate);

    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      req.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      req.setToDate(gglToDate);
    }

    systemNumberClient.createSystemNumber(req, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }

      let data = res.toObject().systemNumber;

      let newData = {
        fromDate: formatPbDate(data.fromDate),
        rate: formatCurrency(data.rate),
        subType: data.subType,
        systemNumberId: data.systemNumberId,
        toDate: formatPbDate(data.toDate),
        type: data.type,
      };

      rows.push(newData);
      notifySuccess('System number has been added.');
      closeModal();
    });
  } else {
    let req = new SystemNumber();

    req.setSystemNumberId(systemNumberID);
    req.setType(type);
    req.setSubType(subType);
    req.setRate(rate);
    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      req.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      req.setToDate(gglToDate);
    }

    systemNumberClient.updateSystemNumber(req, {}, (err) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].systemNumberId === systemNumberID) {
          rows[i].type = type;
          rows[i].subType = subType;
          rows[i].fromDate = moment(new Date(fromDate)).format('MM/DD/YYYY');
          rows[i].toDate = moment(new Date(toDate)).format('MM/DD/YYYY');
          rows[i].rate = formatCurrency(rate);
        }
      }
      notifySuccess('System number has been updated.');
      closeModal();
    });
  }
}
/*=========================================================================================
End of add and update system code function
===========================================================================================*/

export default function SystemNumberTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { systemNumberServiceClient: systemNumberClient } = useContext(
    ServiceContext
  );

  /*START OF PAGING LOGIC ===================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  const [systemNumberTypes, setSystemNumberTypes] = useState([]);

  const getSystemNumberTypes = () => {
    let listTypeReq = new ListSystemNumberTypesRequest();
    systemNumberClient.listSystemNumberTypes(listTypeReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      } else {
        setSystemNumberTypes(res.array[0]);
      }
    });
  };

  useEffect(() => {
    getSystemNumberTypes();
  }, []);

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          fromDate:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          rate:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          subType:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          toDate:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          type:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (key === 'rate') {
                item[key] = item[key].includes('$')
                  ? item[key]
                  : formatCurrency(item[key]);
              }
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          fromDate:
            typeof data.fromDate === 'object'
              ? formatPbDate(data.fromDate)
              : data.fromDate,
          rate: data.rate.includes('$')
            ? data.rate
            : formatCurrency(data.rate, '', countDecimals(data.rate)),
          subType: data.subType,
          systemNumberId: data.systemNumberId,
          toDate:
            typeof data.toDate === 'object'
              ? formatPbDate(data.toDate)
              : data.toDate,
          type: data.type,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          fromDate:
            typeof data.fromDate === 'object'
              ? formatPbDate(data.fromDate)
              : data.fromDate,
          rate: data.rate.includes('$')
            ? data.rate
            : formatCurrency(data.rate, '', countDecimals(data.rate)),
          subType: data.subType,
          systemNumberId: data.systemNumberId,
          toDate:
            typeof data.toDate === 'object'
              ? formatPbDate(data.toDate)
              : data.toDate,
          type: data.type,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**search function to get balances ========================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath + '?search=true' + (typeValue ? '&type=' + typeValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let list = new SystemNumber();
      list.setType(typeValue);

      systemNumberClient.listSystemNumber(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().systemNumbersList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**search function end */

  /**PAGING LOGIC END ======================================================*/

  /*=========================================================================================
  Table Configuration
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'fromDate',
      label: 'From Date',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '2',
      name: 'rate',
      label: 'Rate',
      visibleState: useState(true),
      align: 'right',
      order: 4,
    },
    {
      id: '3',
      name: 'subType',
      label: 'Sub Type',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '4',
      name: 'toDate',
      label: 'To Date',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '5',
      name: 'type',
      label: 'Type',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow tabIndex={-1} key={rowIndex}>
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCellNone}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'SystemNumber_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [4, 2, 0, 3, 1],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  // const [fromDateValue, setFromDateValue] = React.useState('');
  // const [toDateValue, setToDateValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      type: query.get('type'),
    };

    if (data.type) {
      setTypeValue(data.type);
    }

    setCurrentPath(path);

    //Get default system date
    // let profileReq = new ReadProfileRequest();
    // systemDate.readProfile(profileReq, {}, (err, res) => {
    //   if (err) {
    //     console.log(err);
    //     return;
    //   } else {
    //     setToDateValue(
    //       moment(
    //         new Date(formatPbDate(res.toObject().profile.systemDate))
    //       ).format('yyyy-MM-DD')
    //     );
    //     setFromDateValue(
    //       moment(
    //         new Date(formatPbDate(res.toObject().profile.systemDate))
    //       ).format('yyyy-MM-DD')
    //     );
    //   }
    // });
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [
    modalSystemNumberIDValue,
    setModalSystemNumberIDValue,
  ] = React.useState('');
  const [modalTypeValue, setModalTypeValue] = React.useState('');
  const [modalSubTypeValue, setModalSubTypeValue] = React.useState('');
  const [modalFromDateValue, setModalFromDateValue] = React.useState('');
  const [modalToDateValue, setModalToDateValue] = React.useState('');
  const [modalRateValue, setModalRateValue] = React.useState('');
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalTypeValue('');
      setModalSubTypeValue('');
      setModalFromDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalToDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalRateValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setModalSystemNumberIDValue(rowData.systemNumberId);
      setModalTypeValue(rowData.type);
      setModalSubTypeValue(rowData.subType);
      setModalFromDateValue(
        rowData.fromDate
          ? moment(new Date(rowData.fromDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalToDateValue(
        rowData.toDate
          ? moment(new Date(rowData.toDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalRateValue(rowData.rate.replace('$', '').replace(',', ''));

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              {/* <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div> */}
              <div className={classes.grdCell1}>
                <SelectSystemNumberType
                  freeSolo={false}
                  name="type"
                  label="Type"
                  style={{ width: 300 }}
                  fullWidth={true}
                  value={typeValue}
                  onChange={(e) => {
                    setTypeValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'System Numbers'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        className={classes.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              className={classes.textBold}
              variant="h4"
              gutterBottom
            >
              {modalTitleValue} System Number
            </Typography>
            <Box mt={5}>
              <form className={classes.modalForm} noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Type</InputLabel>
                    <Select
                      required
                      fullWidth={true}
                      value={modalTypeValue}
                      onChange={(e) => setModalTypeValue(e.target.value)}
                    >
                      {systemNumberTypes.map((i) => (
                        <MenuItem value={i[0]} key={i[0]}>
                          {i[0]}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Sub Type</InputLabel>
                    <Select
                      required
                      fullWidth={true}
                      value={modalSubTypeValue}
                      onChange={(e) => setModalSubTypeValue(e.target.value)}
                    >
                      {modalTypeValue &&
                        systemNumberTypes
                          .filter((i) => i[0] === modalTypeValue)[0][1]
                          .map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      label="From Date"
                      type="date"
                      value={modalFromDateValue}
                      onChange={(e) => setModalFromDateValue(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      label="To Date"
                      type="date"
                      value={modalToDateValue}
                      onChange={(e) => setModalToDateValue(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Rate"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modalRateValue}
                      onChange={(e) => setModalRateValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveSystemNumber(
                          systemNumberClient,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalSystemNumberIDValue,
                          modalTypeValue,
                          modalSubTypeValue,
                          modalFromDateValue,
                          modalToDateValue,
                          modalRateValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
