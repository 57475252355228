/*ReactJS*/
import React, { useState } from 'react';

/*Custom components*/
import TabPanel from 'components/TabPanel/TabPanel.js';
import UsersTable from './Components/UsersTable.js';

/*Material UI Components*/
import { Box, Typography, Breadcrumbs, Tab, Tabs } from '@material-ui/core';

/*Style*/
import useStyles from '../../styles.js';
import PermissionsTable from './Components/PermissionsTable.js';

export default function ClearingPermissions() {
  const { pageContainer, textBold, tabTradesHeader } = useStyles();
  const [value, setValue] = useState(0);
  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  });

  return (
    <React.Fragment>
      <div className={pageContainer}>
        <Box>
          <Typography
            variant="h2"
            className={textBold}
            align="left"
            gutterBottom={true}
          >
            Clearing
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <span color="inherit">Clearing</span>
            <span color="inherit">Permissions</span>
            <Typography color="primary">
              {value ? 'Permissions' : 'Users'}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box className={tabTradesHeader}>
          <Tabs
            value={value}
            onChange={onChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="scrollable auto tabs"
            centered
          >
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Permissions" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UsersTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PermissionsTable />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
