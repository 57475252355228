import { useHistory } from 'react-router-dom';

export function useSsrRouter() {
  const history = useHistory();

  const path = '/app/report/self-service-reporting';

  return {
    goToCreateReportPage() {
      history.push(path + '?type=create-report');
    },
    goToUpdateReportPage(reportId) {
      history.push(path + '?type=update-report&report-id=' + reportId);
    },
    goToViewReportPage(reportId) {
      history.push(path + '?type=view-report&report-id=' + reportId);
    },
    goToViewConfigPage(reportId, configId) {
      history.push(
        path +
          '?type=view-config&report-id=' +
          reportId +
          '&config-id=' +
          configId
      );
    },
    goToEditExecutionPage(executionId) {
      history.push(path + '?type=edit-execution&execution-id=' + executionId);
    },
    goToViewExecutionPage(executionId) {
      history.push(path + '?type=view-execution&execution-id=' + executionId);
    },
    goToOverviewPage() {
      history.push(path);
    },
  };
}
