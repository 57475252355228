/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListPositionDetailRequest } from '../../../proto/reportpb/position_grpc_web_pb';
import { CancelTransactionRequest } from '../../../proto/trnspb/transaction_grpc_web_pb';

/*Toast Notification*/
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import { IconButton, Tooltip } from '@material-ui/core';

/*Material UI Icons*/
import {
  CompareArrowsRounded as CancelAndCorrectIcon,
  Block as CancelIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { formatPbDate, formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

export default function PositionDetailsTable({ accountno, originalcusip }) {
  const classes = useStyles();
  const confirm = useConfirm();

  const {
    listServiceClient: position,
    transactionServiceClient: transaction,
  } = useContext(ServiceContext);

  const search = () => {
    let list = new ListPositionDetailRequest();
    list.setAccountNo(accountno);
    list.setOriginalCusip(originalcusip);

    position.listPositionDetail(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err);
        return;
      }

      const rows = res.toObject().positionDetailsList.map((data) => ({
        accountNo: data.accountNo,
        contraAccountNo: data.contraAccountNo,
        entryType: data.entryType,
        tradeDate: formatPbDate(data.tradeDate),
        symbol: data.symbol,
        qty: data.qty,
        settleDate: formatPbDate(data.settleDate),
        netAmt: formatCurrency(data.netAmt),
        price: formatCurrency(data.price),
        fees: formatCurrency(data.fees),
        systemDate: formatPbDate(data.systemDate),
        accountId: data.accountId,
        trnsId: data.trnsId,
        status: data.status,
      }));
      setRows(rows);
    });
  };

  const cancelTransaction = (transaction, trnsId, confirm) => {
    confirm({
      description: 'You are about to cancel this transaction, please confirm.',
      confirmationText: 'Yes, Cancel Transaction',
      cancellationText: 'No',
    })
      .then(() => {
        let cancelReq = new CancelTransactionRequest();
        cancelReq.setTrnsId(trnsId);
        transaction.cancelTransaction(cancelReq, {}, (err) => {
          if (err) {
            console.error(err);
            notifyError(err.message);
            return;
          }
          search();
          notifySuccess('Transaction has been canceled.');
        });
      })
      .catch(() => {
        /* ... */
      });
  };

  const cancelAndCorrectTransaction = (accountId, trnsId) => {
    const paramPath =
      '/app/trns/cancel-and-correct/' + trnsId + '/' + accountId;

    window.open(paramPath, '_blank');
  };

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCell1}>
                <Tooltip title="Cancel" arrow>
                  <div>
                    <IconButton
                      aria-label="cancel"
                      disabled={
                        rows[dataIndex].status === 'canceled' ? true : false
                      }
                      onClick={() => {
                        cancelTransaction(
                          transaction,
                          rows[dataIndex].trnsId,
                          confirm
                        );
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
              <div className={classes.grdCell1}>
                <Tooltip title="Correct" arrow>
                  <div>
                    <IconButton
                      aria-label="cancelcorrect"
                      disabled={
                        rows[dataIndex].status === 'canceled' ? true : false
                      }
                      onClick={() => {
                        cancelAndCorrectTransaction(
                          rows[dataIndex].accountId,
                          rows[dataIndex].trnsId
                        );
                      }}
                    >
                      <CancelAndCorrectIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate ? rows[dataIndex].systemDate : '--';
        },
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'contraAccountNo',
      label: 'Contra Account No',
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeDate ? rows[dataIndex].tradeDate : '--';
        },
      },
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settleDate ? rows[dataIndex].settleDate : '--';
        },
      },
    },
    {
      name: 'entryType',
      label: 'Entry Type',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].qty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'netAmt',
      label: 'Net Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].netAmt.replace('$', '');
          const f = rows[dataIndex].netAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].price.replace('$', '');
          const f = rows[dataIndex].price;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'fees',
      label: 'Fees',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].fees.replace('$', '');
          const f = rows[dataIndex].fees;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    filterType: 'select',
    download: true,
    filter: true,
    search: true,
    print: false,
    sort: true,
    viewColumns: true,
    selectableRows: false,
    selectableRowsHeader: false,
    rowsPerPage: 10,
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  useEffect(() => {
    if (accountno) {
      search();
    }
    // eslint-disable-next-line
  }, [accountno]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={'Position Details'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
