/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  CreateAccountMappingRequest,
  UpdateAccountMappingRequest,
  ListAccountMappingRequest,
  DeleteAccountMappingRequest,
} from '../../../proto/reconpb/accountmapping_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  IconButton,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom Component*/
import SearchButton from '../../../components/Button/Search';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
//import ContraAccountSelect from '../../../components/AutoComplete/ContraAccount';
import ContraSourceSelect from '../../../components/Dropdown/ContraSource';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Add and Update Account Mapping function
===========================================================================================*/
function saveAccountMapping(
  accountmapping,
  rows,
  isAdd,
  closeModal,
  account,
  report,
  contraAccount,
  contraSource,
  accountMappingId
) {
  if (!account) {
    notifyError('Account is required.');
    return;
  }
  if (!report) {
    notifyError('Report is required.');
    return;
  }
  if (!contraAccount) {
    notifyError('Contra Account is required.');
    return;
  }
  if (!contraSource) {
    notifyError('Contra Source is required.');
    return;
  }

  if (isAdd) {
    let addReq = new CreateAccountMappingRequest();
    addReq.setAccount(account);
    addReq.setReport(report);
    addReq.setContraAccount(contraAccount);
    addReq.setContraSource(contraSource);

    accountmapping.createAccountMapping(addReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        notifyError(err.message);
      } else {
        rows.push(res.toObject().accountMapping);
        notifySuccess('New Recon Mapping has been added.');
        closeModal();
      }
    });
  } else {
    let updateReq = new UpdateAccountMappingRequest();
    updateReq.setAccountMappingId(accountMappingId);
    updateReq.setAccount(account);
    updateReq.setReport(report);
    updateReq.setContraAccount(contraAccount);
    updateReq.setContraSource(contraSource);

    accountmapping.updateAccountMapping(updateReq, {}, (err) => {
      if (err) {
        notifyError(err.message);
        return;
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].accountMappingId === accountMappingId) {
            rows[i].account = account;
            rows[i].report = report;
            rows[i].contraAccount = contraAccount;
            rows[i].contraSource = contraSource;
          }
        }
        notifySuccess('Recon Mapping has been updated.');
        closeModal();
      }
    });
  }
}
/*=========================================================================================
End of Add and Update Account Mapping function
===========================================================================================*/

/*=========================================================================================
Delete Account Mapping function: Single
===========================================================================================*/
function removeAccountMappingSingle(
  accountmapping,
  rows,
  setRows,
  accountMappingId,
  account,
  confirm
) {
  confirm({
    description:
      "You are about to delete Recon Mapping '" +
      account +
      "' in your system. Please confirm your action.",
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let deleteReq = new DeleteAccountMappingRequest();
      deleteReq.setAccountMappingId(accountMappingId);

      accountmapping.deleteAccountMapping(deleteReq, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.messsage);
          return;
        }

        let updatedData = [];

        for (let c = 0; c < rows.length; c++) {
          if (rows[c].accountMappingId === accountMappingId) {
            rows.splice(rows[c], 1);
          } else {
            updatedData.push(rows[c]);
          }
        }
        setRows(updatedData);
      });
      notifySuccess('Recon Mapping has been deleted');
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}
/*=========================================================================================
End of delete Account Mapping function: Single
===========================================================================================*/

/*=========================================================================================
Delete Account Mapping function: Multiple
===========================================================================================*/
function removeAccountMapping(
  accountmapping,
  rows,
  setRows,
  accountMappingIds,
  handleClick,
  confirm
) {
  if (accountMappingIds.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      accountMappingIds.length +
      ' Recon Mapping(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      accountMappingIds.forEach(removeFunction);

      function removeFunction(item) {
        let deleteReq = new DeleteAccountMappingRequest();
        deleteReq.setAccountMappingId(item);

        accountmapping.deleteAccountMapping(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }

          let updatedData = [];

          for (let c = 0; c < rows.length; c++) {
            if (rows[c].accountMappingId === item) {
              rows.splice(rows[c], 1);
            } else {
              updatedData.push(rows[c]);
            }
          }
          setRows(updatedData);
        });
      }
      handleClick('', '', true);
      notifySuccess(
        accountMappingIds.length + ' Recon Mappings has been deleted'
      );
    })
    .catch(() => {
      /* ... */
    });
}
/*=========================================================================================
End of delete Account Mapping function: Multiple
===========================================================================================*/

export default function ReconMappingTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { accountMappingServiceClient: accountmapping } = useContext(
    ServiceContext
  );

  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          account:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          contraAccount:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          contrSource:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          report:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          account: data.account,
          report: data.report,
          contraAccount: data.contraAccount,
          contraSource: data.contraSource,
          accountMappingId: data.accountMappingId,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          account: data.account,
          report: data.report,
          contraAccount: data.contraAccount,
          contraSource: data.contraSource,
          accountMappingId: data.accountMappingId,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**Search Account Mapping function ==================================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (accountValue ? '&account=' + accountValue : '') +
        (reportValue ? '&report=' + reportValue : '') +
        (contraAccountValue ? '&contraAccount=' + contraAccountValue : '') +
        (contraSourceValue ? '&contraSource=' + contraSourceValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let list = new ListAccountMappingRequest();
      list.setAccount(accountValue);
      list.setReport(reportValue);
      list.setContraAccount(contraAccountValue);
      list.setContraSource(contraSourceValue);

      accountmapping.listAccountMapping(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().accountMappingsList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**Search Account Mapping function end ==============================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      id: '1',
      name: 'account',
      label: 'Account',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '2',
      name: 'contraAccount',
      label: 'Contra Account',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '3',
      name: 'contraSource',
      label: 'Contra Source',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '4',
      name: 'report',
      label: 'Report',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, accountMappingId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(accountMappingId);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, accountMappingId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };
  const isSelected = (accountMappingId) =>
    selected.indexOf(accountMappingId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].accountMappingId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={rowIndex}
        selected={isItemSelected}
        aria-label={labelId}
      >
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCell1}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].accountMappingId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  removeAccountMappingSingle(
                    accountmapping,
                    rows,
                    setRows,
                    rows[rowIndex].accountMappingId,
                    rows[rowIndex].account,
                    confirm
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => (v.options.empty ? false : v.options.display))
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'ReconMapping_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    downloadOptions: {
      filename: fileName,
    },
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 3, 1, 2],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [accountValue, setAccountValue] = React.useState('');
  const [reportValue, setReportValue] = React.useState('');
  const [contraAccountValue, setContraAccountValue] = React.useState('');
  const [contraSourceValue, setContraSourceValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      account: query.get('account'),
      report: query.get('report'),
      contraAccount: query.get('contraAccount'),
      contraSource: query.get('contraSource'),
    };

    if (data.account) {
      setAccountValue(data.account);
    }
    if (data.report) {
      setReportValue(data.report);
    }
    if (data.contraAccount) {
      setContraAccountValue(data.contraAccount);
    }
    if (data.contraSource) {
      setContraSourceValue(data.contraSource);
    }

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalAccountValue, setModalAccountValue] = React.useState('');
  const [modalReportValue, setModalReportValue] = React.useState('');
  const [modalContraAccountValue, setModalContraAccountValue] = React.useState(
    ''
  );
  const [modalContraSourceValue, setModalContraSourceValue] = React.useState(
    ''
  );
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const [accountMappingId, setAccountMappingId] = React.useState('');
  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalAccountValue('');
      setModalReportValue('');
      setModalContraAccountValue('');
      setModalContraSourceValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setModalAccountValue(rowData.account);
      setModalReportValue(rowData.report);
      setModalContraAccountValue(rowData.contraAccount);
      setModalContraSourceValue(rowData.contraSource);

      setModalAddAction(false);
      setModalTitleValue('Edit');
      setAccountMappingId(rowData.accountMappingId);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="account"
                  label="Account"
                  value={accountValue}
                  onChange={(e) => {
                    setAccountValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Report"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={reportValue}
                  onChange={(e) => setReportValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={true}
                  name="contraAccount"
                  label="Contra Account"
                  value={contraAccountValue}
                  onChange={(e) => setContraAccountValue(e.currentTarget.value)}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="contra_source">
                  Contra Source
                </InputLabel>
                <ContraSourceSelect
                  required={false}
                  labelId="contraSource"
                  fullWidth
                  value={contraSourceValue}
                  onChange={(e) => setContraSourceValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeAccountMapping(
                  accountmapping,
                  rows,
                  setRows,
                  rowID,
                  handleClick,
                  confirm
                );
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Recon Mapping'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Recon Mapping
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <AccountNoSelect
                      required={true}
                      freeSolo={false}
                      name="account"
                      label="Account"
                      value={modalAccountValue}
                      onChange={(e) => {
                        setModalAccountValue(e.currentTarget.value);
                      }}
                      selectedCorrespondent={''}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink id="report">
                      Report *
                    </InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalReportValue}
                      onChange={(e) => setModalReportValue(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="balance">Balance</MenuItem>
                      <MenuItem value="position">Position</MenuItem>
                      <MenuItem value="execution">Execution</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required={true}
                      label="Contra Account"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalContraAccountValue}
                      onChange={(e) =>
                        setModalContraAccountValue(e.target.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink id="contra_source">
                      Contra Source *
                    </InputLabel>
                    <ContraSourceSelect
                      required={false}
                      labelId="contraSource"
                      fullWidth
                      value={modalContraSourceValue}
                      onChange={(e) =>
                        setModalContraSourceValue(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveAccountMapping(
                          accountmapping,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalAccountValue,
                          modalReportValue,
                          modalContraAccountValue,
                          modalContraSourceValue,
                          accountMappingId
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
