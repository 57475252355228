/*Service*/
import {
  Address,
  DeleteAddressRequest,
} from '../../../proto/accountpb/address_grpc_web_pb';

/*ReactJS*/
import React, { useState, useContext } from 'react';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Country Dropdown Component*/
import CountriesSelect from '../../../components/AutoComplete/Countries';
import StateSelect from './../../../components/Dropdown/State';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Re-render component
===========================================================================================*/
function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}
/*=========================================================================================
End of re-render component
===========================================================================================*/

function AddressTable({ accountid, addresslist }) {
  const rows = addresslist;

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  const reRender = RenderComponent(); //Re-renders component when something change in component state
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { addressServiceClient: address } = useContext(ServiceContext);

  /*=========================================================================================
  Add and Update address function
  ===========================================================================================*/
  function saveAddress(
    rows,
    isAdd,
    closeModal,
    addressId,
    accountId,
    addressType,
    address1,
    address2,
    address3,
    city,
    state,
    zip,
    country,
    reRender
  ) {
    if (!addressType) {
      notifyError('Address type is required');
      return;
    }
    if (!address1) {
      notifyError('Address 1 is required');
      return;
    }
    if (!city) {
      notifyError('City is required');
      return;
    }
    if (!country) {
      notifyError('Country is required');
      return;
    }
    if (
      !state &&
      (country === 'United States of America' || country === 'Canada')
    ) {
      notifyError('State is required');
      return;
    }

    if (isAdd) {
      let addReq = new Address();
      addReq.setAccountId(accountId);
      addReq.setAddressType(addressType);
      addReq.setAddress1(address1);
      addReq.setAddress2(address2);
      addReq.setAddress3(address3);
      addReq.setCity(city);
      addReq.setState(state);
      addReq.setZip(zip);
      addReq.setCountry(country);

      address.createAddress(addReq, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          return;
        }
        console.log(res.toObject());
        rows.push(res.toObject().address);
        reRender();
        notifySuccess('New address has been added.');
        closeModal();
      });
    } else {
      let updateReq = new Address();
      updateReq.setAddressId(addressId);
      updateReq.setAccountId(accountId);
      updateReq.setAddressType(addressType);
      updateReq.setAddress1(address1);
      updateReq.setAddress2(address2);
      updateReq.setAddress3(address3);
      updateReq.setCity(city);
      updateReq.setState(state);
      updateReq.setZip(zip);
      updateReq.setCountry(country);

      address.updateAddress(updateReq, {}, (err) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].addressId === addressId) {
            rows[i].addressType = addressType;
            rows[i].address1 = address1;
            rows[i].address2 = address2;
            rows[i].address3 = address3;
            rows[i].city = city;
            rows[i].state = state;
            rows[i].zip = zip;
            rows[i].country = country;
          }
        }
        reRender();
        notifySuccess('Address has been updated.');
        closeModal();
      });
    }
  }
  /*=========================================================================================
  End of Add and Update address function
  ===========================================================================================*/

  /*=========================================================================================
Delete address function: Multiple
===========================================================================================*/
  function removeAddress(rows, addressIds, handleClick, confirm, reRender) {
    if (addressIds.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        addressIds.length +
        ' Address(s) in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        addressIds.forEach(removeFunction);
        function removeFunction(item) {
          let deleteReq = new DeleteAddressRequest();
          deleteReq.setAddressId(item);

          address.deleteAddress(deleteReq, {}, (err) => {
            if (err) {
              console.error(err);
              notifyError(err.message);
              return;
            }
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].addressId === item) {
                rows.splice(c, 1);
              }
            }
            reRender();
          });
        }
        handleClick('', '', true);
        notifySuccess(addressIds.length + ' Address has been deleted');
      })
      .catch(() => {
        /* ... */
      });
  }
  /*=========================================================================================
End of delete address function: Multiple
===========================================================================================*/

  /*=========================================================================================
Delete address function: Single
===========================================================================================*/
  function removeAddressSingle(
    rows,
    addressId,
    addressType,
    address1,
    confirm,
    reRender
  ) {
    confirm({
      description:
        "You are about to delete Address '" +
        addressType +
        ': ' +
        address1 +
        "' in your system. Please confirm your action.",
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeleteAddressRequest();
        deleteReq.setAddressId(addressId);
        console.log(addressId);

        address.deleteAddress(deleteReq, {}, (err) => {
          if (err) {
            console.error(err);
            notifyError(err.message);
            return;
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].addressId === addressId) {
              rows.splice(c, 1);
            }
          }
          reRender();
        });
        notifySuccess('Address has been deleted');
      })
      .catch(() => {
        // showToastMessage("error", "System error.");
      });
  }
  /*=========================================================================================
End of delete address function: Single
===========================================================================================*/

  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      id: '1',
      name: 'addressType',
      label: 'Address Type',
      visibleState: useState(true),
    },
    {
      id: '2',
      name: 'address1',
      label: 'Address 1',
      visibleState: useState(true),
    },
    {
      id: '3',
      name: 'address2',
      label: 'Address 2',
      visibleState: useState(true),
    },
    {
      id: '4',
      name: 'address3',
      label: 'Address 3',
      visibleState: useState(true),
    },
    {
      id: '5',
      name: 'city',
      label: 'City',
      visibleState: useState(true),
    },
    {
      id: '6',
      name: 'state',
      label: 'State',
      visibleState: useState(true),
    },
    {
      id: '7',
      name: 'zip',
      label: 'Zip',
      visibleState: useState(true),
    },
    {
      id: '8',
      name: 'country',
      label: 'Country',
      visibleState: useState(true),
    },
  ];

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, addressId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(addressId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, addressId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
      setRowID([]);
    }
  };

  const isSelected = (addressId) => selected.indexOf(addressId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].addressId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rows[rowIndex].addressId}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCellNone}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].addressId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCellNone}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  removeAddressSingle(
                    rows,
                    rows[rowIndex].addressId,
                    rows[rowIndex].addressType,
                    rows[rowIndex].address1,
                    confirm,
                    reRender
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.grdCellNone}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {columns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/

  /*========================================================================================= 
  Address modal
  ===========================================================================================*/
  const [addressId, setAddressId] = React.useState('');
  const [modalAddressTypeValue, setModalAddressTypeValue] = React.useState('');
  const [modalAddress1Value, setModalAddress1Value] = React.useState('');
  const [modalAddress2Value, setModalAddress2Value] = React.useState('');
  const [modalAddress3Value, setModalAddress3Value] = React.useState('');
  const [modalCityValue, setModalCityValue] = React.useState('');
  const [modalStateValue, setModalStateValue] = React.useState('');
  const [modalZipValue, setModalZipValue] = React.useState('');
  const [modalCountryValue, setModalCountryValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalAddressTypeValue('primary');
      setModalAddress1Value('');
      setModalAddress2Value('');
      setModalAddress3Value('');
      setModalCityValue('');
      setModalStateValue('CA');
      setModalZipValue('');
      setModalCountryValue('US');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setAddressId(rowData.addressId);
      setModalAddressTypeValue(rowData.addressType);
      setModalAddress1Value(rowData.address1);
      setModalAddress2Value(rowData.address2);
      setModalAddress3Value(rowData.address3);
      setModalCityValue(rowData.city);
      setModalStateValue(rowData.state);
      setModalZipValue(rowData.zip);
      setModalCountryValue(rowData.country);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*========================================================================================= 
  End of address modal
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<DeleteIcon />}
              onClick={() => {
                removeAddress(rows, rowID, handleClick, confirm, reRender);
              }}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Address'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <div className={classes.modalHeader}>
              <Typography
                id="transition-modal-title"
                variant="h4"
                className={classes.textBold}
                gutterBottom
              >
                {modalTitleValue} Address
              </Typography>
            </div>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="addressType" shrink>
                      Address Type *
                    </InputLabel>
                    <Select
                      labelId="addressType"
                      displayEmpty
                      fullWidth
                      value={modalAddressTypeValue}
                      onChange={(e) => setModalAddressTypeValue(e.target.value)}
                    >
                      <MenuItem value="primary">Primary</MenuItem>
                      <MenuItem value="mailing">Mailing</MenuItem>
                      <MenuItem value="po_box">P.O. Box</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Address 1"
                      required
                      fullWidth={true}
                      value={modalAddress1Value}
                      onChange={(e) => setModalAddress1Value(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Address 2"
                      fullWidth={true}
                      value={modalAddress2Value}
                      onChange={(e) => setModalAddress2Value(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Address 3"
                      fullWidth={true}
                      value={modalAddress3Value}
                      onChange={(e) => setModalAddress3Value(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="City"
                      required
                      fullWidth={true}
                      value={modalCityValue}
                      onChange={(e) => setModalCityValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>State</InputLabel>
                    <StateSelect
                      value={modalStateValue}
                      onChange={(e) => setModalStateValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Zip"
                      fullWidth={true}
                      value={modalZipValue}
                      onChange={(e) => setModalZipValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <CountriesSelect
                      labelid="country"
                      label="Country"
                      value={modalCountryValue}
                      onChange={(e) => setModalCountryValue(e.target.value)}
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalCountryValue(newValue.code);
                        } else {
                          setModalCountryValue('');
                          return;
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveAddress(
                          rows,
                          modalAddAction,
                          handleClose,
                          addressId,
                          accountid,
                          modalAddressTypeValue,
                          modalAddress1Value,
                          modalAddress2Value,
                          modalAddress3Value,
                          modalCityValue,
                          modalStateValue,
                          modalZipValue,
                          modalCountryValue,
                          reRender
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { AddressTable };
