import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Dedupe, ExtraErrorData } from '@sentry/integrations';

export const init = () => {
  const { SENTRY_ENVIRONMENT: environment } = window.env;
  const { REACT_APP_VERSION: version, REACT_APP_SENTRY_DSN: dsn } = process.env;
  if (environment && environment !== 'DEV') {
    Sentry.init({
      dsn: dsn,
      release: version,
      environment: environment,
      integrations: [
        new Integrations.BrowserTracing(),
        new Dedupe(),
        new ExtraErrorData(),
      ],
      maxBreadcrumbs: 20,
    });

    // custom console.error to send sentry exception events
    (function() {
      let _error = console.error;
      // captures all browser console errors
      console.error = function(err) {
        if (typeof err === 'object' && err?.code && err?.message) {
          // in case of api response error, stringify the error response
          Sentry.captureException(new Error(JSON.stringify(err)));
        } else {
          Sentry.captureException(err);
        }
        _error.apply(console, arguments);
      };
    })();
  }
};
