/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: reportpb/ssr.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var usrpb_administrator_pb = require('../usrpb/administrator_pb.js')

var utilspb_pagination_pb = require('../utilspb/pagination_pb.js')
const proto = {};
proto.reportpb = require('./ssr_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.SsrServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.SsrServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListSsrReportRequest,
 *   !proto.reportpb.ListSsrReportResponse>}
 */
const methodDescriptor_SsrService_ListSsrReport = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ListSsrReport',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListSsrReportRequest,
  proto.reportpb.ListSsrReportResponse,
  /**
   * @param {!proto.reportpb.ListSsrReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListSsrReportResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListSsrReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListSsrReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.listSsrReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrReport,
      callback);
};


/**
 * @param {!proto.reportpb.ListSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListSsrReportResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.listSsrReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListSsrConfigRequest,
 *   !proto.reportpb.ListSsrConfigResponse>}
 */
const methodDescriptor_SsrService_ListSsrConfig = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ListSsrConfig',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListSsrConfigRequest,
  proto.reportpb.ListSsrConfigResponse,
  /**
   * @param {!proto.reportpb.ListSsrConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListSsrConfigResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListSsrConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListSsrConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListSsrConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.listSsrConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrConfig',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrConfig,
      callback);
};


/**
 * @param {!proto.reportpb.ListSsrConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListSsrConfigResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.listSsrConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrConfig',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListSsrExecutionRequest,
 *   !proto.reportpb.ListSsrExecutionResponse>}
 */
const methodDescriptor_SsrService_ListSsrExecution = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ListSsrExecution',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListSsrExecutionRequest,
  proto.reportpb.ListSsrExecutionResponse,
  /**
   * @param {!proto.reportpb.ListSsrExecutionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListSsrExecutionResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListSsrExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListSsrExecutionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListSsrExecutionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.listSsrExecution =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrExecution',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrExecution,
      callback);
};


/**
 * @param {!proto.reportpb.ListSsrExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListSsrExecutionResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.listSsrExecution =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrExecution',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrExecution);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListSsrRowRequest,
 *   !proto.reportpb.ListSsrRowResponse>}
 */
const methodDescriptor_SsrService_ListSsrRow = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ListSsrRow',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListSsrRowRequest,
  proto.reportpb.ListSsrRowResponse,
  /**
   * @param {!proto.reportpb.ListSsrRowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListSsrRowResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListSsrRowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListSsrRowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListSsrRowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.listSsrRow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrRow',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrRow,
      callback);
};


/**
 * @param {!proto.reportpb.ListSsrRowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListSsrRowResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.listSsrRow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrRow',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrRow);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListSsrErrorRequest,
 *   !proto.reportpb.ListSsrErrorResponse>}
 */
const methodDescriptor_SsrService_ListSsrError = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ListSsrError',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListSsrErrorRequest,
  proto.reportpb.ListSsrErrorResponse,
  /**
   * @param {!proto.reportpb.ListSsrErrorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListSsrErrorResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListSsrErrorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListSsrErrorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListSsrErrorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.listSsrError =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrError',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrError,
      callback);
};


/**
 * @param {!proto.reportpb.ListSsrErrorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListSsrErrorResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.listSsrError =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ListSsrError',
      request,
      metadata || {},
      methodDescriptor_SsrService_ListSsrError);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ExecuteSsrQueryRequest,
 *   !proto.reportpb.SsrFileChunkResponse>}
 */
const methodDescriptor_SsrService_ExecuteSsrQuery = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ExecuteSsrQuery',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.reportpb.ExecuteSsrQueryRequest,
  proto.reportpb.SsrFileChunkResponse,
  /**
   * @param {!proto.reportpb.ExecuteSsrQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.SsrFileChunkResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ExecuteSsrQueryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.SsrFileChunkResponse>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.executeSsrQuery =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.SsrService/ExecuteSsrQuery',
      request,
      metadata || {},
      methodDescriptor_SsrService_ExecuteSsrQuery);
};


/**
 * @param {!proto.reportpb.ExecuteSsrQueryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.SsrFileChunkResponse>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServicePromiseClient.prototype.executeSsrQuery =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.SsrService/ExecuteSsrQuery',
      request,
      metadata || {},
      methodDescriptor_SsrService_ExecuteSsrQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.CreateSsrReportRequest,
 *   !proto.reportpb.CreateSsrReportResponse>}
 */
const methodDescriptor_SsrService_CreateSsrReport = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/CreateSsrReport',
  grpc.web.MethodType.UNARY,
  proto.reportpb.CreateSsrReportRequest,
  proto.reportpb.CreateSsrReportResponse,
  /**
   * @param {!proto.reportpb.CreateSsrReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.CreateSsrReportResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.CreateSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.CreateSsrReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.CreateSsrReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.createSsrReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/CreateSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_CreateSsrReport,
      callback);
};


/**
 * @param {!proto.reportpb.CreateSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.CreateSsrReportResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.createSsrReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/CreateSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_CreateSsrReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.UpdateSsrReportRequest,
 *   !proto.reportpb.UpdateSsrReportResponse>}
 */
const methodDescriptor_SsrService_UpdateSsrReport = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/UpdateSsrReport',
  grpc.web.MethodType.UNARY,
  proto.reportpb.UpdateSsrReportRequest,
  proto.reportpb.UpdateSsrReportResponse,
  /**
   * @param {!proto.reportpb.UpdateSsrReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.UpdateSsrReportResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.UpdateSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.UpdateSsrReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.UpdateSsrReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.updateSsrReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/UpdateSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_UpdateSsrReport,
      callback);
};


/**
 * @param {!proto.reportpb.UpdateSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.UpdateSsrReportResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.updateSsrReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/UpdateSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_UpdateSsrReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.TriggerSsrReportRequest,
 *   !proto.reportpb.TriggerSsrReportResponse>}
 */
const methodDescriptor_SsrService_TriggerSsrReport = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/TriggerSsrReport',
  grpc.web.MethodType.UNARY,
  proto.reportpb.TriggerSsrReportRequest,
  proto.reportpb.TriggerSsrReportResponse,
  /**
   * @param {!proto.reportpb.TriggerSsrReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.TriggerSsrReportResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.TriggerSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.TriggerSsrReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.TriggerSsrReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.triggerSsrReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/TriggerSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_TriggerSsrReport,
      callback);
};


/**
 * @param {!proto.reportpb.TriggerSsrReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.TriggerSsrReportResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.triggerSsrReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/TriggerSsrReport',
      request,
      metadata || {},
      methodDescriptor_SsrService_TriggerSsrReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.UpdateSsrExecutionRequest,
 *   !proto.reportpb.UpdateSsrExecutionResponse>}
 */
const methodDescriptor_SsrService_UpdateSsrExecution = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/UpdateSsrExecution',
  grpc.web.MethodType.UNARY,
  proto.reportpb.UpdateSsrExecutionRequest,
  proto.reportpb.UpdateSsrExecutionResponse,
  /**
   * @param {!proto.reportpb.UpdateSsrExecutionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.UpdateSsrExecutionResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.UpdateSsrExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.UpdateSsrExecutionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.UpdateSsrExecutionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.updateSsrExecution =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/UpdateSsrExecution',
      request,
      metadata || {},
      methodDescriptor_SsrService_UpdateSsrExecution,
      callback);
};


/**
 * @param {!proto.reportpb.UpdateSsrExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.UpdateSsrExecutionResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.updateSsrExecution =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/UpdateSsrExecution',
      request,
      metadata || {},
      methodDescriptor_SsrService_UpdateSsrExecution);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ReviewSsrRowRequest,
 *   !proto.reportpb.ReviewSsrRowResponse>}
 */
const methodDescriptor_SsrService_ReviewSsrRow = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ReviewSsrRow',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ReviewSsrRowRequest,
  proto.reportpb.ReviewSsrRowResponse,
  /**
   * @param {!proto.reportpb.ReviewSsrRowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ReviewSsrRowResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ReviewSsrRowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ReviewSsrRowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ReviewSsrRowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.reviewSsrRow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ReviewSsrRow',
      request,
      metadata || {},
      methodDescriptor_SsrService_ReviewSsrRow,
      callback);
};


/**
 * @param {!proto.reportpb.ReviewSsrRowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ReviewSsrRowResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.reviewSsrRow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ReviewSsrRow',
      request,
      metadata || {},
      methodDescriptor_SsrService_ReviewSsrRow);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ClearSsrReviewRequest,
 *   !proto.reportpb.ClearSsrReviewResponse>}
 */
const methodDescriptor_SsrService_ClearSsrReview = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/ClearSsrReview',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ClearSsrReviewRequest,
  proto.reportpb.ClearSsrReviewResponse,
  /**
   * @param {!proto.reportpb.ClearSsrReviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ClearSsrReviewResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ClearSsrReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ClearSsrReviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ClearSsrReviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.clearSsrReview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/ClearSsrReview',
      request,
      metadata || {},
      methodDescriptor_SsrService_ClearSsrReview,
      callback);
};


/**
 * @param {!proto.reportpb.ClearSsrReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ClearSsrReviewResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.clearSsrReview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/ClearSsrReview',
      request,
      metadata || {},
      methodDescriptor_SsrService_ClearSsrReview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.GenerateReviewedFileRequest,
 *   !proto.reportpb.SsrFileChunkResponse>}
 */
const methodDescriptor_SsrService_GenerateReviewedFile = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/GenerateReviewedFile',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.reportpb.GenerateReviewedFileRequest,
  proto.reportpb.SsrFileChunkResponse,
  /**
   * @param {!proto.reportpb.GenerateReviewedFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.SsrFileChunkResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.GenerateReviewedFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.SsrFileChunkResponse>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.generateReviewedFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.SsrService/GenerateReviewedFile',
      request,
      metadata || {},
      methodDescriptor_SsrService_GenerateReviewedFile);
};


/**
 * @param {!proto.reportpb.GenerateReviewedFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.SsrFileChunkResponse>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServicePromiseClient.prototype.generateReviewedFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.SsrService/GenerateReviewedFile',
      request,
      metadata || {},
      methodDescriptor_SsrService_GenerateReviewedFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.UploadReviewedFileRequest,
 *   !proto.reportpb.UploadReviewedFileResponse>}
 */
const methodDescriptor_SsrService_UploadReviewedFile = new grpc.web.MethodDescriptor(
  '/reportpb.SsrService/UploadReviewedFile',
  grpc.web.MethodType.UNARY,
  proto.reportpb.UploadReviewedFileRequest,
  proto.reportpb.UploadReviewedFileResponse,
  /**
   * @param {!proto.reportpb.UploadReviewedFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.UploadReviewedFileResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.UploadReviewedFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.UploadReviewedFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.UploadReviewedFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.SsrServiceClient.prototype.uploadReviewedFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.SsrService/UploadReviewedFile',
      request,
      metadata || {},
      methodDescriptor_SsrService_UploadReviewedFile,
      callback);
};


/**
 * @param {!proto.reportpb.UploadReviewedFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.UploadReviewedFileResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.SsrServicePromiseClient.prototype.uploadReviewedFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.SsrService/UploadReviewedFile',
      request,
      metadata || {},
      methodDescriptor_SsrService_UploadReviewedFile);
};


module.exports = proto.reportpb;

