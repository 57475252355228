import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createSelector } from 'reselect'; //memoization
import { LazyLoadRequest } from '../../proto/commonpb/list_grpc_web_pb';
import { ServiceContext } from 'context/ServiceContext';

//memoization -  cached result when the same inputs occur again
const lazyCurrency = createSelector(
  (service, value) => (async () => await lazyCurrencyPromise(service, value))(),
  (returnValue) => returnValue
);

async function lazyCurrencyPromise(service, value) {
  return new Promise((resolve, reject) => {
    let req = new LazyLoadRequest();
    req.setLimit(100);
    req.setKey(value);

    service.lazyLoadCurrency(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const data = response
          .toObject()
          .currenciesList.filter((value) => {
            return value.currency ? value : null;
          })
          .map((v) => v.currency);

        resolve([...new Set(data)]);
      }
    });
  });
}

export default function SelectCurrency(props) {
  const { listAccountServiceClient: service } = useContext(ServiceContext);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value);
      return;
    }

    if (options.find((o) => o === value)) {
      setPropsValue(value);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setInputValue(value);
    setPropsValue(value);
    getOptions(value);
  };

  //get list from backend
  const getOptions = async (value, editInputValue) => {
    const list = await lazyCurrency(service, value);

    setOptions(list.filter((v) => v));
    //editInputValue to setup the visible value of autocomplete for query string
    if (editInputValue && list.includes(value)) {
      setInputValue(props.value);
    }
  };

  React.useEffect(() => {
    if (open) {
      getOptions(props.value);
    } else if (props.value && !options.length && !inputValue) {
      const editInputValue = true;
      getOptions(props.value, editInputValue);
    }
    // eslint-disable-next-line
  }, [open, props.value]);

  return (
    <Autocomplete
      key={props.clear}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? v : '';
        setPropsValue(value);
        setInputValue(value);
      }}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => option}
      inputValue={inputValue}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          label={props.label}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
