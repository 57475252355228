import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import jwtDecode from 'jwt-decode';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';
import { ListAdministratorRequest } from 'proto/usrpb/administrator_pb';
import callbackToPromise from './callbackToPromise';

// provide admin info:
//   - idMap is provided to map between admin id and admin name for UI view
//   - sortedList is provided for form selection
//   - loginAdmin is provided to get current login user info
export function useAdministrator() {
  const { administratorServiceClient } = useContext(ServiceContext);
  const { googleAccount } = useUserState();

  return useQuery(
    'listAdministrator',
    async () => {
      const response = await callbackToPromise((cb) =>
        administratorServiceClient.listAdministrator(
          new ListAdministratorRequest(),
          {},
          cb
        )
      );

      const admins = response.toObject().administratorsList;

      const idMap = {};

      for (const admin of admins) {
        idMap[admin.id] = admin;
      }

      // sort by name
      const sortedList = admins
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));

      const { email } = jwtDecode(googleAccount?.credential);

      const loginAdmin = admins.find((admin) => admin.email === email);

      return { idMap, sortedList, loginAdmin };
    },
    {
      // cache result for 15 mins
      staleTime: 15 * 60 * 1000,
    }
  );
}

// take admin query result and an id, return admin user name
// return "" if not found
export function convertIdToName(adminQuery, id) {
  if (adminQuery?.data?.idMap) {
    return adminQuery.data.idMap[id]?.name ?? '';
  }
  return '';
}

// usage: useMutationWithLoginAdmin(async (variables, loginAdmin) => { ... }, options)
export function useMutationWithLoginAdmin(fn, options) {
  const admin = useAdministrator();

  return useMutation(async (variables) => {
    // if data is not available, raise error
    const loginAdminId = admin?.data?.loginAdmin?.id;
    if (!loginAdminId) {
      throw new Error('Cannot find login admin id');
    }

    return await fn(variables, admin.data.loginAdmin);
  }, options);
}
