/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListRequirementRequest } from '../../../proto/marginpb/requirement_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Visibility as ViewIcon, Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

/*Formatter*/
import moment from 'moment/moment.js';
import { formatPbDate, formatCurrency } from 'lib/fmt';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function MarginRequirementTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    requirementServiceClient: marginRequirement,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (tradeDateValue ? '&tradeDate=' + tradeDateValue : '') +
      (exchangeCallValue ? '&exchangeCall=' + exchangeCallValue : '') +
      (fedCallValue ? '&fedCall=' + fedCallValue : '') +
      (houseCallValue ? '&houseCall=' + houseCallValue : '') +
      (bpCallValue ? '&bpCall=' + bpCallValue : '') +
      (minBalanceCallValue ? '&minBalanceCall=' + minBalanceCallValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let listReq = new ListRequirementRequest();

    if (tradeDateValue) {
      let gglTradeDate = new google_date.Date();
      let [y, m, d] = tradeDateValue.split('-');
      gglTradeDate.setYear(y);
      gglTradeDate.setMonth(m);
      gglTradeDate.setDay(d);
      listReq.setTradeDate(gglTradeDate);
    }

    listReq.setCorrespondent(correspondentValue);
    listReq.setMasterAccountNo(masterAccountNoValue);
    listReq.setAccountName(accountNameValue);
    listReq.setExchangeCall(exchangeCallValue);
    listReq.setFedCall(fedCallValue);
    listReq.setHouseCall(houseCallValue);
    listReq.setBpCall(bpCallValue);
    listReq.setMinBalanceCall(minBalanceCallValue);

    marginRequirement.listRequirement(listReq, {}, (err, res) => {
      if (err) {
        console.log(err.message);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().requirementsList.map((data) => ({
        accountId: data.accountId,
        accountName: data.accountName,
        bpCall: formatCurrency(data.bpCall),
        cashBalance: formatCurrency(data.cashBalance),
        correspondent: data.correspondent,
        equity: formatCurrency(data.equity),
        exchangeCall: formatCurrency(data.exchangeCall),
        exchangeReq: formatCurrency(data.exchangeReq),
        fedCall: formatCurrency(data.fedCall),
        fedReq: formatCurrency(data.fedReq),
        houseCall: formatCurrency(data.houseCall),
        houseReq: formatCurrency(data.houseReq),
        longMarketValue: formatCurrency(data.longMarketValue),
        marginType: data.marginType,
        marketValue: formatCurrency(data.marketValue),
        masterAccountNo: data.masterAccountNo,
        minBalanceCall: formatCurrency(data.minBalanceCall),
        openingBp: formatCurrency(data.openingBp),
        shortMarketValue: formatCurrency(data.shortMarketValue),
        sma: formatCurrency(data.sma),
        tradeDate: formatPbDate(data.tradeDate),
        buyingPower: data.buyingPower,
        multiplier: data.multiplier,
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Margin Requirement');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          const paramPath =
            '/margin-interest/margin-requirement-details/' +
            tradeDateValue +
            '/' +
            rows[dataIndex].masterAccountNo;

          const openNewWindow = () => {
            window.open(paramPath, '_blank');
          };

          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  openNewWindow();
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'bpCall',
      label: 'Bp Call',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].bpCall;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'buyingPower',
      label: 'Buying Power',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'cashBalance',
      label: 'Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].cashBalance;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'equity',
      label: 'Equity',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].equity;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'exchangeCall',
      label: 'Exchange Call',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].exchangeCall;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'exchangeReq',
      label: 'Exhange Req',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].exchangeReq;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'fedCall',
      label: 'Fed Call',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].fedCall;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'fedReq',
      label: 'Fed Req',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].fedReq;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'houseCall',
      label: 'House Call',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].houseCall;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'houseReq',
      label: 'House Req',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].houseReq;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'longMarketValue',
      label: 'Long Market Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].longMarketValue;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].marketValue;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'minBalanceCall',
      label: 'Min Balance Call',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].minBalanceCall;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'multiplier',
      label: 'Multiplier',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'openingBp',
      label: 'Opening BP',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].openingBp;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'shortMarketValue',
      label: 'Short Market Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].shortMarketValue;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'sma',
      label: 'SMA',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].sma;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
    },
  ];

  const fileName =
    'MarginRequirement_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      21,
      5,
      15,
      1,
      4,
      13,
      19,
      6,
      14,
      18,
      8,
      7,
      10,
      9,
      12,
      11,
      2,
      16,
      20,
      3,
      17,
    ],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Margin Requirement');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Margin Requirement');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={[
            'cashBalance',
            'longMarketValue',
            'shortMarketValue',
            'equity',
            'marketValue',
            'openingBp',
            'exchangeReq',
            'exchangeCall',
            'fedReq',
            'fedCall',
            'houseReq',
            'houseCall',
            'bpCall',
            'minBalance',
            'sma',
          ]}
          columnsWithQty={[]}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [tradeDateValue, setTradeDateValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [exchangeCallValue, setExchangeCallValue] = React.useState(false);
  const [fedCallValue, setFedCallValue] = React.useState(false);
  const [houseCallValue, setHouseCallValue] = React.useState(false);
  const [bpCallValue, setBpCallValue] = React.useState(false);
  const [minBalanceCallValue, setMinBalanceCallValue] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      tradeDate: query.get('tradeDate'),
      exchangeCall: query.get('exchangeCall'),
      fedCall: query.get('fedCall'),
      houseCall: query.get('houseCall'),
      bpCall: query.get('bpCall'),
      minBalanceCall: query.get('minBalanceCall'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.exchangeCall) {
      setExchangeCallValue(Boolean(data.exchangeCall));
    }
    if (data.fedCall) {
      setFedCallValue(Boolean(data.fedCall));
    }
    if (data.houseCall) {
      setHouseCallValue(Boolean(data.houseCall));
    }
    if (data.bpCall) {
      setBpCallValue(Boolean(data.bpCall));
    }
    if (data.minBalanceCall) {
      setMinBalanceCallValue(Boolean(data.minBalanceCall));
    }
    if (data.tradeDate) {
      setTradeDateValue(data.tradeDate);
    }
    if (!data.tradeDate) {
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setTradeDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Trade Date"
                  type="date"
                  value={tradeDateValue}
                  onChange={(e) => setTradeDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exchangeCallValue}
                      onChange={(e) => setExchangeCallValue(e.target.checked)}
                    />
                  }
                  label="Exchange Call"
                />
              </div>
              <div className={classes.grdCell1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fedCallValue}
                      onChange={(e) => setFedCallValue(e.target.checked)}
                    />
                  }
                  label="Fed Call"
                />
              </div>
              <div className={classes.grdCell1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={houseCallValue}
                      onChange={(e) => setHouseCallValue(e.target.checked)}
                    />
                  }
                  label="House Call"
                />
              </div>
              <div className={classes.grdCell1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bpCallValue}
                      onChange={(e) => setBpCallValue(e.target.checked)}
                    />
                  }
                  label="BP Call"
                />
              </div>
              <div className={classes.grdCell1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={minBalanceCallValue}
                      onChange={(e) => setMinBalanceCallValue(e.target.checked)}
                    />
                  }
                  label="Min Balance Call"
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Margin Requirement'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
