export default () => {
  let title = {
    US_SEC: 'Ledgie US Securities',
    US_CRY: 'Ledgie US Crypto',
    BS_CRY: 'Ledgie X Crypto',
    BS_SEC: 'Ledgie X Securities',
    JP_SUB_SEC: 'Ledgie JP Securities',
    CA_SUB_SEC: 'Ledgie CA Securities',
  }[window.env.LEDGIE_TYPE];
  if (!title) {
    title = 'Ledgie';
  }
  if (window.env.IS_PAPER) {
    title += ' (Paper)';
  }
  return title;
};
