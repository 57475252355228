/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListBalanceRequest } from '../../../proto/reportpb/balance_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { Pagination } from 'proto/utilspb/pagination_pb';
import {
  Transaction,
  BatchCreateTransactionRequest,
} from '../../../proto/trnspb/transaction_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Notification/Notification';

/*Material UI Table Components*/
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment/moment.js';
import { formatPbDate, formatCurrency, formatDollar } from 'lib/fmt';

/*Custom components*/
import CsvDownload from '../../../components/Table/CsvDownload';
import SelectAccount from '../../../components/AutoComplete/SelectAccount';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SearchButton from '../../../components/Button/Search';
import ServerSideTableBodyFooter from '../../../components/Table/ServerSideTableBodyFooter';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';
import BalanceModal from './BalanceModal';
import SelectCurrency from 'components/AutoComplete/Currency';
import { useProfile } from 'context/ProfileContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

/*=========================================================================================
 Main Balance Table
===========================================================================================*/
export default function BalanceTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    transactionServiceClient: transactionClient,
    balanceServiceClient: balance,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: 50,
    pageNo: 0,
    reload: false,
    sortName: '',
    sortDirection: '',
  });

  const [footerData, setFooterData] = useState({});
  const [filterList, setFilterList] = useState({});

  const search = async (dontNotifySuccess) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
    };
    paginationCopy.pageNo = dontNotifySuccess ? paginationCopy.pageNo : 0;

    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNumberValue
        ? '&masterAccountNo=' + masterAccountNumberValue
        : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (baseCurrencyValue ? '&baseCurrency=' + baseCurrencyValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let paging = new Pagination();
    paging.setPageNo(paginationCopy.pageNo);
    paging.setPageSize(paginationCopy.rowsPerPage);
    paging.setSortName(paginationCopy.sortName);
    paging.setSortDirection(paginationCopy.sortDirection);

    try {
      const allData = await getData(paging, false);
      paginationCopy.count = allData.summary.totalRows;
      setPagination(paginationCopy);
      setFooterData({
        tdCashBalance: formatDollar(allData.summary.tdCashBalance),
        tdLongMarketValue: formatDollar(allData.summary.tdLongMarketValue),
        tdShortMarketValue: formatDollar(allData.summary.tdShortMarketValue),
        tdEquity: formatDollar(allData.summary.tdEquity),
        tdAdjustedBalance: formatDollar(allData.summary.tdAdjustedBalance),
        sdCashBalance: formatDollar(allData.summary.sdCashBalance),
        sdLongMarketValue: formatDollar(allData.summary.sdLongMarketValue),
        sdShortMarketValue: formatDollar(allData.summary.sdShortMarketValue),
        sdEquity: formatDollar(allData.summary.sdEquity),
        sdAdjustedBalance: formatDollar(allData.summary.sdAdjustedBalance),
      });

      const rows = allData.list;

      setRows(rows);
      if (!dontNotifySuccess) {
        notifyInfo(allData.summary.totalRows + ' search results.');
      }
    } catch (error) {
      notifyError(error.message);
    }

    setLoading(false);
  };

  const getData = async (paging) => {
    let list = new ListBalanceRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setMasterAccountNo(masterAccountNumberValue);
    list.setAccountName(accountNameValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    if (baseCurrencyValue) {
      list.setCurrenciesList([baseCurrencyValue]);
    }
    if (paging) {
      list.setPagination(paging);
    }

    return new Promise((resolve, reject) => {
      balance.listBalance(list, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          const d = res.toObject();

          resolve({
            summary: d.summary,
            list: d.balancesList.map((data) => ({
              balanceId: data.balanceId,
              correspondent: data.correspondent,
              accountNo: data.accountNo,
              masterAccountNo: data.masterAccountNo,
              accountName: data.accountName,
              accountDesignator: data.accountDesignator,
              broker: data.broker,
              type: data.type,
              systemDate: formatPbDate(data.systemDate),
              tdCashBalance: formatCurrency(data.tdCashBalance, ''),
              tdLongMarketValue: formatCurrency(data.tdLongMarketValue, ''),
              tdShortMarketValue: formatCurrency(data.tdShortMarketValue, ''),
              tdEquity: formatCurrency(data.tdEquity, ''),
              tdAdjustedBalance: formatCurrency(data.tdAdjustedBalance, ''),
              sdCashBalance: formatCurrency(data.sdCashBalance, ''),
              sdLongMarketValue: formatCurrency(data.sdLongMarketValue, ''),
              sdShortMarketValue: formatCurrency(data.sdShortMarketValue, ''),
              sdEquity: formatCurrency(data.sdEquity, ''),
              sdAdjustedBalance: formatCurrency(data.sdAdjustedBalance, ''),
              tdNonCashBalance: formatCurrency(data.tdNonCashBalance, ''),
              sdNonCashBalance: formatCurrency(data.tdNonCashBalance, ''),
              currency: data.currency,
            })),
          });
        }
      });
    });
  };

  const handleOpenMoveBalanceModal = async (selected) => {
    setSelectedData(selected);
    setOpen(true);
  };

  const handleCloseMoveBalanceModal = async (modalData, selectedRows) => {
    if (!modalData) {
      return setOpen(false);
    }

    if (!modalData.contraAccountNo) {
      notifyError('Contra Account No is required.');
      return;
    }

    try {
      const { transactionResponsesList } = await batchCreateTransaction(
        selectedRows,
        modalData.contraAccountNo
      );

      transactionResponsesList.forEach(function(message) {
        notifySuccess(
          message.status +
            ' TRNSID: ' +
            message.trnsId +
            ' ' +
            message.errorMessage
        );
      });

      setRowsSelected([]);
      setOpen(false);
      search(true);
    } catch (error) {
      notifyError(error.message);
    }
  };

  async function batchCreateTransaction(selectedRows, contraAccountNo) {
    let batch = [];

    selectedRows.forEach(function(row) {
      const rowData = rows[row.dataIndex];
      const amt = rowData.tdCashBalance
        ? rowData.tdCashBalance.replace('$', '')
        : '0';

      let req = new Transaction();
      req.setEntryType('JNLC');
      req.setAccountNo(contraAccountNo);
      req.setNetAmt(amt.toString());
      req.setGrossAmt(amt.toString());
      req.setContraAccountNo(rowData.accountNo);

      batch.push(req);
    });

    let batchReq = new BatchCreateTransactionRequest();
    batchReq.setTransactionsList(batch);

    return new Promise((resolve, reject) => {
      transactionClient.batchCreateTransaction(batchReq, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res.toObject());
        }
      });
    });
  }

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Balance');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(false);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'sdAdjustedBalance',
      label: 'SD Adjusted Balance',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdCashBalance',
      label: 'SD Cash Balance',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdEquity',
      label: 'SD Equity',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdLongMarketValue',
      label: 'SD Long Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdShortMarketValue',
      label: 'SD Short Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate ? rows[dataIndex].systemDate : '--';
        },
      },
    },
    {
      name: 'tdAdjustedBalance',
      label: 'TD Adjusted Balance',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdCashBalance',
      label: 'TD Cash Balance',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdEquity',
      label: 'TD Equity',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdLongMarketValue',
      label: 'TD Long Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdShortMarketValue',
      label: 'TD Short Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'currency',
      label: 'Currency',
    },
    {
      name: 'tdNonCashBalance',
      label: 'TD Non-Cash Balance',
    },
    {
      name: 'sdNonCashBalance',
      label: 'SD Non-Cash Balance',
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const fileName =
    'Balance_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    serverSide: true,
    count: pagination.count,
    page: pagination.pageNo,
    rowsPerPageOptions: [50, 100, 500, 1000, 5000, 15000, 30000, 100000],
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      17,
      4,
      2,
      1,
      5,
      0,
      3,
      20,
      11,
      13,
      18,
      15,
      16,
      14,
      12,
      7,
      19,
      9,
      10,
      8,
      6,
    ],
    selectableRowsHeader: true,
    rowsPerPage: pagination.rowsPerPage,
    selectableRows: 'multiple',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      setOpenCsvDownload(true);
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Balance');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Balance');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;

      switch (action) {
        case 'changePage':
          setPagination({
            ...pagination,
            pageNo: tableState.page,
            reload: true,
          });
          break;
        case 'changeRowsPerPage':
          setPagination({
            ...pagination,
            rowsPerPage: tableState.rowsPerPage,
            reload: true,
          });
          break;
        case 'sort':
          setPagination({
            ...pagination,
            sortDirection: tableState.sortOrder.direction,
            sortName: tableState.sortOrder.name,
            reload: true,
          });
          break;
        case 'filterChange':
          {
            let filter = {};
            tableState.filterList.forEach((item, index) => {
              if (item.length) {
                filter[columns[index].name] = item[0];
              }
            });
            setFilterList(filter);
          }
          break;
        default:
      }
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <ServerSideTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          data={footerData}
          additionalCell={true}
        ></ServerSideTableBodyFooter>
      );
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div align={'left'} className={classes.grdCell1}>
            <Tooltip title="Process" arrow>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="process"
                  size="small"
                  startIcon={<UpdateIcon />}
                  onClick={() => {
                    handleOpenMoveBalanceModal(selectedRows.data);
                  }}
                >
                  Move Balance
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setRowsSelected(rowsSelected);
    },
    rowsSelected: rowsSelected,
    isRowSelectable: (dataIndex) => rows[dataIndex].tdCashBalance !== '$0.00',
  };

  const profile = useProfile();
  const [openCsvDownload, setOpenCsvDownload] = React.useState(false);
  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [
    masterAccountNumberValue,
    setMasterAccountNumberValue,
  ] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [baseCurrencyValue, setBaseCurrencyValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      systemDate: query.get('systemDate'),
    };
    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNumberValue(data.masterAccountNo);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.baseCurrency) {
      setBaseCurrencyValue(data.baseCurrency);
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (pagination.reload) {
      search(true);
    }
    // eslint-disable-next-line
  }, [
    pagination.pageNo,
    pagination.rowsPerPage,
    pagination.reload,
    pagination.sortName,
    pagination.sortDirection,
  ]);

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return;
        }
      });

      return ok;
    });

    return filtered;
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="correspondent"
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountNo"
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountName"
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  showAccountName={false}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SelectAccount
                  freeSolo={true}
                  type="masterAccountNo"
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNumberValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setMasterAccountNumberValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="account_designator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  required
                  labelId="broker"
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="type">
                  Type
                </InputLabel>
                <Select
                  required
                  labelId="type"
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ maxWidth: '10%' }}>
                <SelectCurrency
                  freeSolo={false}
                  type="baseCurrency"
                  name="baseCurrency"
                  label="Base Currency"
                  value={baseCurrencyValue}
                  onChange={(e) => {
                    setBaseCurrencyValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {openCsvDownload && (
          <CsvDownload
            getData={getData}
            filename={fileName}
            dataKey="list"
            breaks={false}
            open={openCsvDownload}
            onClose={() => {
              setOpenCsvDownload(false);
            }}
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Balance'}
            data={filterRows()}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      {open && (
        <BalanceModal
          onClose={handleCloseMoveBalanceModal}
          open={open}
          value={selectedData}
        ></BalanceModal>
      )}
    </div>
  );
}
/*=========================================================================================
 End Balance Table
===========================================================================================*/
