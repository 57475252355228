/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListSegregationBalanceRequest } from '../../../proto/reportpb/segregation_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Visibility as ViewIcon, Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency, formatPercentage } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function SegregationBalanceTable({ params, segtype }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    segregationServiceClient: segregationBalance,
    profileServiceClient: systemDate,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    setLoading(true);

    const pathWithParams =
      currentPath +
      '?search=true' +
      (segtype ? '&segType=' + segtype : '') +
      (settleDateValue ? '&settleDate=' + settleDateValue : '') +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '');
    window.history.pushState({}, null, pathWithParams);

    let listReq = new ListSegregationBalanceRequest();

    if (settleDateValue) {
      let gglSettleDate = new google_date.Date();
      let [y, m, d] = settleDateValue.split('-');
      gglSettleDate.setYear(y);
      gglSettleDate.setMonth(m);
      gglSettleDate.setDay(d);
      listReq.setSettleDate(gglSettleDate);
    }

    listReq.setCorrespondent(correspondentValue);
    listReq.setMasterAccountNo(masterAccountNoValue);
    listReq.setAccountName(accountNameValue);
    listReq.setBroker(brokerValue);

    segregationBalance.listSegregationBalance(listReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().segregationBalancesList.map((data) => ({
        settleDate: formatPbDate(data.settleDate),
        segregationId: data.segregationId,
        correspondent: data.correspondent,
        masterAccountNo: data.masterAccountNo,
        accountName: data.accountName,
        broker: data.broker,
        cashBalance: formatCurrency(data.cashBalance),
        shortMarketValue: formatCurrency(data.shortMarketValue),
        adjustedBalance: formatCurrency(data.adjustedBalance),
        loanRate: formatPercentage(data.loanRate),
        loanValue: formatCurrency(data.loanValue),
        longMarketValue: formatCurrency(data.longMarketValue),
        lockup: formatCurrency(data.lockup),
        segAmt: formatCurrency(data.segAmt),
        excessMarginAmt: formatCurrency(data.excessMarginAmt),
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Segregation Balance');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          const openNewWindow = (settleDate, masterAccountNo) => {
            const paramPath =
              '/segregation/segregation-lockup/' +
              moment(new Date(settleDate)).format('YYYY-MM-DD') +
              '/' +
              masterAccountNo +
              '/' +
              'Account';

            window.open(paramPath, '_blank');
          };

          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  openNewWindow(
                    rows[dataIndex].settleDate,
                    rows[dataIndex].masterAccountNo
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'adjustedBalance',
      label: 'Adjusted Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].adjustedBalance.replace('$', '');
          const f = rows[dataIndex].adjustedBalance;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'cashBalance',
      label: 'Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].cashBalance.replace('$', '');
          const f = rows[dataIndex].cashBalance;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'excessMarginAmt',
      label: 'Excess Margin Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].excessMarginAmt.replace('$', '');
          const f = rows[dataIndex].excessMarginAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'loanRate',
      label: 'Loan Rate',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].loanRate.replace('%', '');
          const f = rows[dataIndex].loanRate;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'loanValue',
      label: 'Loan Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].loanValue.replace('$', '');
          const f = rows[dataIndex].loanValue;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'lockup',
      label: 'Lockup',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].lockup.replace('$', '');
          const f = rows[dataIndex].lockup;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'longMarketValue',
      label: 'Long Market Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].longMarketValue.replace('$', '');
          const f = rows[dataIndex].longMarketValue;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'segAmt',
      label: 'Seg Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].segAmt.replace('$', '');
          const f = rows[dataIndex].segAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
    },
    {
      name: 'shortMarketValue',
      label: 'Short Market Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].shortMarketValue.replace('$', '');
          const f = rows[dataIndex].shortMarketValue;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
  ];

  const fileName =
    'SegregationBalance_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 13, 5, 11, 1, 3, 4, 14, 2, 7, 8, 10, 9, 12, 6],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Segregation Balance');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Segregation Balance');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={[
            'cashBalance',
            'shortMarketValue',
            'adjustedBalance',
            'loanValue',
            'longMarketValue',
            'lockup',
            'segAmt',
            'excessMarginAmt',
          ]}
          columnsWithQty={['loanRate']}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [settleDateValue, setSettleDateValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [brokerValue, setBrokerValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      broker: query.get('broker'),
      settleDate: query.get('settleDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.settleDate) {
      let settleDateParam = new google_date.Date();
      let [y, m, d] = data.settleDate.split('-');
      settleDateParam.setYear(y);
      settleDateParam.setMonth(m);
      settleDateParam.setDay(d);
      setSettleDateValue(data.settleDate);
    }

    if (!data.settleDate) {
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSettleDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
          console.log(formatPbDate(res.toObject().profile.previousDate));
        }
      });
    }

    setCurrentPath(path);
  }, [params, systemDate]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Settle Date"
                  type="date"
                  value={settleDateValue}
                  onChange={(e) => setSettleDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  labelId="broker"
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Segregation Balance'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
