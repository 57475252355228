/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  UserGuide,
  DeleteUserGuideRequest,
} from '../../../proto/admpb/userguide_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Box,
  TextField,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Icons*/
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Create as EditIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';
import MenuSelect from '../../../components/AutoComplete/Menu';
//import LinkToSelect from '../../../components/AutoComplete/LinkTo';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

/*=========================================================================================
Add and Update system code function
===========================================================================================*/
function saveUserGuide(
  userGuide,
  rows,
  isAdd,
  closeModal,
  userGuideId,
  //linkId,
  //title,
  pageName,
  pagePath,
  //linkPath,
  content,
  setLoading
) {
  setLoading(true);
  if (isAdd) {
    // if (!title) {
    //   notifyError('Title is a required field.');
    //   return;
    // }
    if (!pageName) {
      notifyError('Page name is a required field.');
      return;
    }
    // if (!linkPath) {
    //   notifyError('Link to is a required field.');
    //   return;
    // }
    if (!content) {
      notifyError('Content is a required field.');
      return;
    }

    // let newLinkPath = '';

    // if (linkPath.includes(pageName)) {
    //   newLinkPath = linkPath + '/' + title;
    // } else {
    //   newLinkPath = linkPath + '/' + pageName + '/' + title;
    // }

    let req = new UserGuide();
    //req.setTitle(title);
    req.setPageName(pageName);
    req.setPagePath(pagePath);
    //req.setLinkId('');
    req.setContent(content);
    req.setType('User Guide');
    req.setModifiedBy('Logged User');

    let gglModifiedDate = new google_date.Date();
    let [y, m, d] = moment(new Date())
      .format('yyyy-MM-DD')
      .split('-');
    gglModifiedDate.setYear(y);
    gglModifiedDate.setMonth(m);
    gglModifiedDate.setDay(d);
    req.setModifiedDate(gglModifiedDate);

    userGuide.createUserGuide(req, {}, (err, res) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }

      let data = res.toObject().userGuide;

      let newData = {
        content: data.content,
        linkId: data.linkId,
        modifiedBy: data.modifiedBy,
        modifiedDate: formatPbDate(data.modifiedDate),
        pageName: data.pageName,
        pagePath: data.pagePath,
        tags: data.tags,
        title: data.title,
        type: data.type,
        userGuideId: data.userGuideId,
      };

      rows.push(newData);
      notifySuccess('User guide has been created.');
      setLoading(false);
      closeModal();
    });
  } else {
    //let newLinkPath = linkPath + '/' + pageName;

    let req = new UserGuide();
    req.setUserGuideId(userGuideId);
    //req.setTitle(title);
    req.setPageName(pageName);
    req.setPagePath(pagePath);
    req.setContent(content);
    req.setType('User Guide');
    req.setModifiedBy('Logged User');

    let gglModifiedDate = new google_date.Date();
    let [y, m, d] = moment(new Date())
      .format('yyyy-MM-DD')
      .split('-');
    gglModifiedDate.setYear(y);
    gglModifiedDate.setMonth(m);
    gglModifiedDate.setDay(d);
    req.setModifiedDate(gglModifiedDate);

    userGuide.updateUserGuide(req, {}, (err) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].userGuideId === userGuideId) {
            rows[i].content = content;
            rows[i].modifiedBy = 'Logged User';
            rows[i].modifiedDate = moment(new Date()).format('MM/DD/YYYY');
            rows[i].pageName = pageName;
            rows[i].pagePath = pagePath;
            rows[i].type = 'User Guide';
            rows[i].userGuideId = userGuideId;
          }
        }
        notifySuccess('User guide has been updated.');
        setLoading(false);
        closeModal();
      }
    });
  }
}
/*=========================================================================================
End of add and update system code function
===========================================================================================*/

/*=========================================================================================
Delete system code function: Single
===========================================================================================*/
function removeUserGuideSingle(
  userGuide,
  rows,
  setRows,
  userGuideID,
  pageName,
  confirm
) {
  confirm({
    description:
      'You are about to delete user guide ' +
      ' of ' +
      pageName +
      ' page in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let req = new DeleteUserGuideRequest();
      req.setUserGuideId(userGuideID);

      userGuide.deleteUserGuide(req, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }

        let updatedData = [];

        for (let c = 0; c < rows.length; c++) {
          if (rows[c].userGuideId === userGuideID) {
            rows.splice(rows[c], 1);
          } else {
            updatedData.push(rows[c]);
          }
        }
        setRows(updatedData);
        notifySuccess('User Guide has been deleted');
      });
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}
/*=========================================================================================
End of delete system code function: Single
===========================================================================================*/

/*=========================================================================================
Delete Margin Rate function: Multiple
===========================================================================================*/
function removeUserGuide(
  userGuide,
  setRows,
  rows,
  userGuideIds,
  handleClick,
  confirm
) {
  if (userGuideIds.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      userGuideIds.length +
      ' User Guide(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let updatedData = [];

      userGuideIds.forEach(removeFunction);

      function removeFunction(item) {
        let deleteReq = new DeleteUserGuideRequest();
        deleteReq.setUserGuideId(item);

        userGuide.deleteUserGuide(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
            return;
          }

          for (let c = 0; c < rows.length; c++) {
            if (rows[c].userGuideId === item) {
              rows.splice(rows[c], 1);
            } else {
              updatedData.push(rows[c]);
            }
          }
          setRows(updatedData);
        });
      }
      handleClick('', '', true);
      notifySuccess(userGuideIds.length + ' User Guide has been deleted');
    })
    .catch(() => {
      /* ... */
    });
}
/*=========================================================================================
End of delete client account function: Multiple
===========================================================================================*/

export default function UserGuideTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { userGuideServiceClient: userGuide } = useContext(ServiceContext);

  /*START OF PAGING LOGIC ===================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        if (a[sortField] < b[sortField]) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (a[sortField] > b[sortField]) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          content:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          modifiedBy:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          modifiedDate:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          pageName:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          // pagePath:
          //   columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          // title:
          //   columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      const rows = pageData.map((data) => ({
        userGuideId: data.userGuideId,
        title: data.title,
        content: data.content,
        modifiedBy: data.modifiedBy,
        tags: data.tags,
        type: data.type,
        pageName: data.pageName,
        pagePath: data.pagePath,
        linkId: data.linkId,
        modifiedDate:
          typeof data.modifiedDate === 'object'
            ? formatPbDate(data.modifiedDate)
            : data.modifiedDate,
      }));

      setRows(rows);
      setDownloadableRows(fullData);

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**search function to get balances ========================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (contentValue ? '&title=' + contentValue : '') +
        (pageNameValue ? '&pageName=' + pageNameValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let list = new UserGuide();
      list.setContent(contentValue);
      list.setPagePath(pagePathValue);

      userGuide.listUserGuide(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }
        let fullData = res.toObject().userGuidesList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**search function end */

  /**PAGING LOGIC END ======================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const [rowID, setRowID] = React.useState('');
  const [selected, setSelected] = React.useState([]);

  const columns = [
    {
      id: '1',
      name: 'content',
      label: 'Content',
      visibleState: useState(false),
      align: 'left',
      order: 1,
    },
    {
      id: '2',
      name: 'modifiedBy',
      label: 'Modified By',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '3',
      name: 'modifiedDate',
      label: 'Modified Date',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '4',
      name: 'pageName',
      label: 'Page Name',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    // {
    //   id: '5',
    //   name: 'pagePath',
    //   label: 'Page Path',
    //   visibleState: useState(true),
    //   align: 'left',
    //   order: 5,
    // },
    // {
    //   id: '6',
    //   name: 'title',
    //   label: 'Title',
    //   visibleState: useState(true),
    //   align: 'left',
    //   order: 1,
    // },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, userGuideID, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(userGuideID);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, userGuideID);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (userGuideID) => selected.indexOf(userGuideID) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].userGuideId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow tabIndex={-1} key={rowIndex} aria-checked={isItemSelected}>
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCell1}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].userGuideId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  removeUserGuideSingle(
                    userGuide,
                    rows,
                    setRows,
                    rows[rowIndex].userGuideId,
                    rows[rowIndex].pageName,
                    confirm
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => (v.options.empty ? false : v.options.display))
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'UserGuide_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [3, 1, 2],
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [contentValue, setContentValue] = React.useState('');
  const [pageNameValue, setPageNameValue] = React.useState('');
  const [pagePathValue, setPagePathValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      content: query.get('content'),
      pageName: query.get('pageName'),
    };

    if (data.content) {
      setContentValue(data.content);
    }
    if (data.pageName) {
      setPageNameValue(data.pageName);
    }

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalUserGuideIDValue, setModalUserGuideIDValue] = React.useState('');
  // const [
  //   modalUserGuideTitleValue,
  //   setModalUserGuideTitleValue,
  // ] = React.useState('');
  const [modalPageValue, setModalPageValue] = React.useState('');
  const [modalPagePathValue, setModalPagePathValue] = React.useState('');
  // const [modalLinkIdValue, setModalLinkIdValue] = React.useState('');
  const [modalContentValue, setModalContentValue] = useState('');
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const complex = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['removeFormat'],
    ['fontColor', 'hiliteColor'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'table'],
    ['link', 'image'],
    ['showBlocks', 'preview', 'fullScreen'],
  ];

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      //setModalUserGuideTitleValue('');
      setModalPageValue('');
      setModalPagePathValue('');
      // setModalLinkPathValue('');
      // setModalLinkIdValue('');
      setModalContentValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setModalUserGuideIDValue(rowData.userGuideId);
      //setModalUserGuideTitleValue(rowData.title);
      setModalPageValue(rowData.pageName);
      setModalPagePathValue(rowData.pagePath);
      //setModalLinkPathValue(rowData.pagePath);
      setModalContentValue(rowData.content);
      //setModalLinkIdValue(rowData.linkId);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setModalContentValue('');
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MenuSelect
                  labelid="pageName"
                  label="Page Name"
                  value={pageNameValue}
                  onChange={(e) => setPageNameValue(e.target.value)}
                  setNewValue={(event, newValue) => {
                    if (newValue) {
                      setPageNameValue(newValue.label);
                      setPagePathValue(newValue.page);
                    } else {
                      setPageNameValue('');
                      return;
                    }
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  label="Content"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={contentValue}
                  onChange={(e) => setContentValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeUserGuide(
                  userGuide,
                  setRows,
                  rows,
                  rowID,
                  handleClick,
                  confirm
                );
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'User Guides'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        className={classes.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalFull}>
            <Typography
              id="transition-modal-title"
              className={classes.textBold}
              variant="h4"
              gutterBottom
            >
              {modalTitleValue} User Guide
            </Typography>
            <Box id="transition-modal-description" mt={5}>
              <form className={classes.modalForm} data-testid="form">
                <div className={classes.grdRow}>
                  {/* <div
                    className={classes.grdCell1}
                    style={{ marginRight: 20 }}
                  >
                    <TextField
                      required
                      label="Header title"
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modalUserGuideTitleValue}
                      onChange={(e) =>
                        setModalUserGuideTitleValue(e.target.value)
                      }
                    />
                  </div> */}
                  <div className={classes.grdCell1}>
                    <MenuSelect
                      required
                      labelid="pageName"
                      label="Select the appropriate page for this content"
                      value={modalPageValue}
                      onChange={(e) => setModalPageValue(e.target.value)}
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalPageValue(newValue.label);
                          setModalPagePathValue(newValue.page);
                        } else {
                          setModalPageValue('');
                          return;
                        }
                      }}
                    />
                  </div>
                </div>
                {/* <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <LinkToSelect
                      required
                      labelid="linkTo"
                      label="Select where you want to link this content to"
                      value={modalLinkPathValue}
                      onChange={(e) =>
                        setModalLinkPathValue(e.target.value)
                      }
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalLinkPathValue(newValue.pagePath);
                          setModalLinkIdValue(newValue.userGuideId);
                        } else {
                          setModalLinkPathValue('');
                          setModalLinkIdValue('');
                          return;
                        }
                      }}
                    />
                  </div>
                </div> */}
                <div className={classes.grdRow}>
                  {/* <ReactQuill
                    style={{ width: '100%' }}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={modalContentValue}
                    onChange={setModalContentValue}
                  /> */}
                  <SunEditor
                    showToolbar={true}
                    setOptions={{
                      height: '100%',
                      buttonList: complex,
                    }}
                    setContents={modalContentValue}
                    onChange={setModalContentValue}
                  />
                </div>
                <div style={{ marginTop: 75 }} className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      classes={{ disabled: classes.disabledButton }}
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={loading}
                      startIcon={
                        loading ? (
                          <CircularProgress
                            style={{ color: '#ffffff', height: 20, width: 20 }}
                          />
                        ) : null
                      }
                      onClick={() => {
                        saveUserGuide(
                          userGuide,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalUserGuideIDValue,
                          // modalLinkIdValue,
                          // modalTitleValue,
                          modalPageValue,
                          modalPagePathValue,
                          //modalLinkPathValue,
                          modalContentValue,
                          setLoading
                        );
                      }}
                    >
                      {loading ? 'Saving...' : 'Save'}
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
