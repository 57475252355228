/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

export async function downloadDailyTradeConfirmation(
  service,
  tradeDate,
  accountId
) {
  return new Promise((resolve, reject) => {
    let error = new Error(
      'Deprecated, use the new admin UI to download trade confirmations.'
    );
    notifyError(error);
    reject(error);
  });
}
