/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListAnnouncementRequest } from '../../../proto/reorgpb/announcement_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { Box, TextField, Tooltip, IconButton } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatPercentage } from 'lib/fmt';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function AnnouncementTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    announcementServiceClient: announcement,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListAnnouncementRequest();

    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);

      list.setFromSystemDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);

      list.setToSystemDate(gglToDate);
    }
    list.setSymbol(symbolValue);

    announcement.listAnnouncement(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().announcementsList.map((data) => ({
        caType: data.caType,
        textNumber: data.textNumber,
        targetSymbol: data.targetSymbol,
        initiatingSymbol: data.initiatingSymbol,
        oldRate: formatPercentage(data.oldRate),
        newRate: formatPercentage(data.newRate),
        systemDate: formatPbDate(data.systemDate),
        effectiveDate: formatPbDate(data.effectiveDate),
        expirationDate: formatPbDate(data.expirationDate),
        recordDate: formatPbDate(data.recordDate),
        payableDate: formatPbDate(data.payableDate),
        processDate: formatPbDate(data.processDate),
        positionDate: formatPbDate(data.positionDate),
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Announcement');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'caType',
      label: 'CA Type',
    },
    {
      name: 'textNumber',
      label: 'Text Number',
    },
    {
      name: 'targetSymbol',
      label: 'Target Symbol',
    },
    {
      name: 'initiatingSymbol',
      label: 'Initiating Symbol',
    },
    {
      name: 'oldRate',
      label: 'Old Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'newRate',
      label: 'New Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'effectiveDate',
      label: 'Effective Date',
    },
    {
      name: 'expirationDate',
      label: 'Expiration Date',
    },
    {
      name: 'recordDate',
      label: 'Record Date',
    },
    {
      name: 'payableDate',
      label: 'Payable Date',
    },
    {
      name: 'processDate',
      label: 'Process Date',
    },
    {
      name: 'positionDate',
      label: 'Position Date',
    },
  ];

  const fileName =
    'Announcement_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Announcement');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Announcement');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [fromDateValue, setFromDateValue] = useState('');
  const [toDateValue, setToDateValue] = useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      symbol: query.get('symbol'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    //Get system date
    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.fromDate) {
          setFromDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
        if (!data.toDate) {
          setToDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Process Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Process Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer} style={{ marginTop: 26 }}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Announcement'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
