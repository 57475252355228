/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListPositionRequest } from '../../../proto/compliancepb/assettransfer_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
  Button,
} from '@material-ui/core';

/*Icons*/
import {
  Visibility as ViewIcon,
  Close as CloseIcon,
  Create as EditIcon,
} from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment/moment.js';
import { formatPbDate } from 'lib/fmt';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';
import PositionDetailsTable from './PositionDetailsTable';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';
import AssetTransferModal from './AssetTransferModal';
import PositionQtyModal from './PositionQtyModal';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PositionTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    tableSettingsServiceClient: tableSettings,
    assetTransferServiceClient: assetTransferClient,
  } = useContext(ServiceContext);

  const search = (noSearchNotification) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNumberValue
        ? '&masterAccountNo=' + masterAccountNumberValue
        : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListPositionRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setMasterAccountNo(masterAccountNumberValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);

    assetTransferClient.listPosition(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        return;
      }

      const rows = res.toObject().positionsList.map((data) => ({
        positionId: data.positionId,
        originalCusip: data.originalCusip,
        correspondent: data.correspondent,
        accountNo: data.accountNo,
        masterAccountNo: data.masterAccountNo,
        accountName: data.accountName,
        symbol: data.symbol,
        cusip: data.cusip,
        symbolDescription: data.symbolDescription,
        positionQty: data.positionQty,
        partialQty: data.partialQty,
        costBasis: data.costBasis,
        partialCostBasis: data.partialCostBasis,
        status: data.status,
      }));

      setRows(rows);
      if (!noSearchNotification) notifyInfo(rows.length + ' search results.');
      setLoading(false);
      setRowsSelected([]);
    });
  };

  const handleOpenGeneratePartialModal = async (selected, isTransfer) => {
    let selectedRows = [];
    selected.forEach(function(row) {
      selectedRows.push(rows[row.dataIndex]);
    });

    setTransfer(isTransfer);
    setSelectedData(selectedRows);
    setOpenGenerateModal(true);
  };

  const handleCloseGeneratePartialModal = (isExecuteSearch) => {
    if (isExecuteSearch) {
      setRowsSelected([]);
      search(true);
    }
    setIsDisabled(true);
    setOpenGenerateModal(false);
  };

  const handleOpenUpdateModal = async (row) => {
    let selectedRows = [];
    selectedRows.push(row);

    setModalPosQty(row.positionQty);
    setModalCostBasis(row.costBasis);

    setSelectedData(selectedRows);
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = (isExecuteSearch) => {
    if (isExecuteSearch) {
      setRowsSelected([]);
      search(true);
    }
    setIsDisabled(true);
    setOpenUpdateModal(false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Position');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [openGenerateModal, setOpenGenerateModal] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(false);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCellNone}>
                <Tooltip title="View Details" arrow>
                  <IconButton
                    aria-label="details"
                    onClick={() => {
                      handleOpen(
                        rows[dataIndex].accountNo,
                        rows[dataIndex].originalCusip
                      );
                    }}
                  >
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.grdCellNone}>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleOpenUpdateModal(rows[dataIndex])}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      //1
      name: 'accountName',
      label: 'Account Name',
    },
    {
      //2
      name: 'accountNo',
      label: 'Account No',
    },
    {
      //3
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      //4
      name: 'cusip',
      label: 'Cusip',
    },
    {
      //5
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      //6
      name: 'positionQty',
      label: 'Position Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      //7
      name: 'partialQty',
      label: 'Partial Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      //8
      name: 'symbol',
      label: 'Symbol',
    },
    {
      //9
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      //10
      name: 'costBasis',
      label: 'Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      //11
      name: 'partialCostBasis',
      label: 'Partial Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      //12
      name: 'status',
      label: 'Status',
    },
  ];

  const fileName =
    'Position_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 3, 2, 1, 5, 8, 4, 9, 6, 7, 10, 11, 12],
    selectableRowsHeader: true,
    selectableRows: 'multiple',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Position');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Position');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['costBasis', 'partialCostBasis']}
          columnsWithQty={['positionQty', 'partialQty']}
          rows={rows}
          additionalCell={true}
        ></CustomTableBodyFooter>
      );
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div align={'left'} className={classes.grdCell1}>
            <Tooltip title="Transfer" arrow style={{ display: 'none' }}>
              <div>
                <Button
                  id="btnTransfer"
                  variant="contained"
                  color="primary"
                  aria-label="process"
                  size="small"
                  startIcon={<UpdateIcon />}
                  onClick={() => {
                    handleOpenGeneratePartialModal(selectedRows.data, true);
                  }}
                >
                  Transfer Asset Transfer
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      if (rowsSelected.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      setRowsSelected(rowsSelected);
    },
    rowsSelected: rowsSelected,
    isRowSelectable: (dataIndex) =>
      !(
        rows[dataIndex].tdShortQty === '0' && rows[dataIndex].tdLongQty === '0'
      ),
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [
    masterAccountNumberValue,
    setMasterAccountNumberValue,
  ] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [cusipValue, setCusipValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
      systemDate: query.get('systemDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNumberValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.cusip) {
      setCusipValue(data.cusip);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, [systemDate, params]);

  const [selectedAccountNo, setSelectedAccountNo] = React.useState('');
  const [selectedOriginalCusip, setSelectedOriginalCusip] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [isTransfer, setTransfer] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [modalPosQty, setModalPosQty] = useState('0.00');
  const [modalCostBasis, setModalCostBasis] = useState('0.00');

  const handleOpen = (accountNo, originalCusip) => {
    setSelectedAccountNo(accountNo);
    setSelectedOriginalCusip(originalCusip);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={true}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNumberValue}
                  onChange={(e) => {
                    setMasterAccountNumberValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="accountDesignator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  required
                  labelId="broker"
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="type">
                  Type
                </InputLabel>
                <Select
                  required
                  labelId="type"
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Cusip"
                  type="text"
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              disabled={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<UpdateIcon />}
              onClick={() => {
                document.getElementById('btnTransfer').click();
              }}
            >
              Transfer
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Position'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <PositionDetailsTable
                accountno={selectedAccountNo}
                originalcusip={selectedOriginalCusip}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
      {openGenerateModal && (
        <AssetTransferModal
          onClose={handleCloseGeneratePartialModal}
          open={openGenerateModal}
          value={selectedData}
          transfer={isTransfer}
        ></AssetTransferModal>
      )}
      {openUpdateModal && (
        <PositionQtyModal
          onClose={handleCloseUpdateModal}
          open={openUpdateModal}
          value={selectedData}
          posqty={modalPosQty}
          costbasis={modalCostBasis}
        ></PositionQtyModal>
      )}
    </div>
  );
}
