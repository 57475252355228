/*Service*/
import { ListClientRequest } from '../../../proto/accountpb/client_grpc_web_pb';
import { TransferInput } from '../../../proto/compliancepb/transferinput_grpc_web_pb';

/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Toast Notification*/
import {
  //notifySuccess,
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Box,
  MenuItem,
  InputLabel,
  Select,
  Modal,
  Backdrop,
  Fade,
  Typography,
  TextField,
} from '@material-ui/core';

/*Material UI Icons*/
import { CompareArrowsRounded as CancelAndCorrectIcon } from '@material-ui/icons';

/*Material UI Confirm*/
//import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

import download from '../../../services/DownloadService.js';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Country Dropdown Component*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';

/*Google Time Stamp Protobuf*/
//const google_date = require('../../../google/type/date_pb.js');

function generateTI(
  accountIds,
  submittingParticipantNumber,
  originalReceiverNumber,
  originalDelivererNumber,
  receiverCorrespondent,
  delivererAccountNo,
  comments1,
  comments2,
  tiClient,
  handleClose
) {
  if (!accountIds) {
    notifyError('No account is selected');
    return;
  }

  if (!submittingParticipantNumber) {
    notifyError('Submitting DTCC# is required!');
    return;
  }

  if (!originalReceiverNumber) {
    notifyError('Receiver DTCC# is required!');
    return;
  }

  if (!originalDelivererNumber) {
    notifyError('Deliverer DTCC# is required!');
    return;
  }

  if (!receiverCorrespondent) {
    notifyError('Receiver Correspondent is required!');
    return;
  }

  if (!delivererAccountNo) {
    notifyError('Deliverer Account Number is required!');
    return;
  }

  let dlReq = new TransferInput();
  dlReq.setSubmittingParticipantNumber(submittingParticipantNumber);
  dlReq.setOriginalReceiverNumber(originalReceiverNumber);
  dlReq.setOriginalDelivererNumber(originalDelivererNumber);
  dlReq.setReceiverCorrespondent(receiverCorrespondent);
  dlReq.setAccountIdsList(accountIds);
  dlReq.setDelivererAccountNumber(delivererAccountNo);

  if (comments1) {
    dlReq.setComments1(comments1);
  }

  if (comments2) {
    dlReq.setComments2(comments2);
  }

  tiClient.createPendingTransferInput(dlReq, {}, (err, res) => {
    if (err) {
      console.error(err);
      notifyError(err.message);
      return;
    }
    notifySuccess('Accounts has been added to acats pending!');
    handleClose();
  });
}

function downloadTI(
  accountIds,
  submittingParticipantNumber,
  originalReceiverNumber,
  originalDelivererNumber,
  receiverCorrespondent,
  delivererAccountNo,
  comments1,
  comments2,
  tiClient,
  handleClose
) {
  if (!accountIds) {
    notifyError('No account is selected');
    return;
  }

  if (!submittingParticipantNumber) {
    notifyError('Submitting DTCC# is required!');
    return;
  }

  if (!originalReceiverNumber) {
    notifyError('Receiver DTCC# is required!');
    return;
  }

  if (!originalDelivererNumber) {
    notifyError('Deliverer DTCC# is required!');
    return;
  }

  if (!receiverCorrespondent) {
    notifyError('Receiver Correspondent is required!');
    return;
  }

  if (!delivererAccountNo) {
    notifyError('Deliverer Account Number is required!');
    return;
  }

  let dlReq = new TransferInput();
  dlReq.setSubmittingParticipantNumber(submittingParticipantNumber);
  dlReq.setOriginalReceiverNumber(originalReceiverNumber);
  dlReq.setOriginalDelivererNumber(originalDelivererNumber);
  dlReq.setReceiverCorrespondent(receiverCorrespondent);
  dlReq.setAccountIdsList(accountIds);
  dlReq.setDelivererAccountNumber(delivererAccountNo);

  if (comments1) {
    dlReq.setComments1(comments1);
  }

  if (comments2) {
    dlReq.setComments2(comments2);
  }

  tiClient.downloadTransferInput(dlReq, {}, (err, res) => {
    if (err) {
      notifyError(err.message);
    } else {
      download(res);
      notifySuccess('TI Download completed');
      handleClose();
    }
  });
}

function TransferInputTable({ params }) {
  /*=========================================================================================
    Component style
  ===========================================================================================*/
  const classes = useStyles();
  //const confirm = useConfirm();
  /*=========================================================================================
    End of component style
  ===========================================================================================*/
  const {
    clientServiceClient: accountClient,
    transferInputServiceClient: transferInput,
  } = useContext(ServiceContext);

  /*=========================================================================================
    Start of paging logic
  ===========================================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountDesignator:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          accountName:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          accountNo:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          activatedAt:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          broker:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          capacity:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          correspondent:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
          createdBy:
            columnFilter[7].value !== undefined ? [columnFilter[7].value] : [],
          dayTrader:
            columnFilter[8].value !== undefined ? [columnFilter[8].value] : [],
          disabledAt:
            columnFilter[9].value !== undefined ? [columnFilter[9].value] : [],
          investmentObjectiveCode:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
          investmentObjectiveDate:
            columnFilter[11].value !== undefined
              ? [columnFilter[11].value]
              : [],
          legalEntity:
            columnFilter[12].value !== undefined
              ? [columnFilter[12].value]
              : [],
          masterAccountNo:
            columnFilter[13].value !== undefined
              ? [columnFilter[13].value]
              : [],
          multiplier:
            columnFilter[14].value !== undefined
              ? [columnFilter[14].value]
              : [],
          privacyContent:
            columnFilter[15].value !== undefined
              ? [columnFilter[15].value]
              : [],
          status:
            columnFilter[16].value !== undefined
              ? [columnFilter[16].value]
              : [],
          taxCountry:
            columnFilter[17].value !== undefined
              ? [columnFilter[17].value]
              : [],
          tefra:
            columnFilter[18].value !== undefined
              ? [columnFilter[18].value]
              : [],
          w8w9:
            columnFilter[19].value !== undefined
              ? [columnFilter[19].value]
              : [],
          w8w9ReceivedDate:
            columnFilter[20].value !== undefined
              ? [columnFilter[20].value]
              : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (
                key === 'createdAt' ||
                key === 'activatedAt' ||
                key === 'disabledAt'
              ) {
                if (item[key] !== undefined) {
                  item[key] =
                    typeof item[key] === 'object'
                      ? moment(new Date(item[key].seconds * 1000)).format(
                          'MM/DD/YYYY hh:mm'
                        )
                      : item[key];
                } else {
                  item[key] = '--';
                }
              }
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          accountId: data.accountId,
          correspondent: data.correspondent,
          accountNo: data.accountNo,
          accountName: data.accountName,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          accountDesignator: data.accountDesignator,
          activatedAt: data.activatedAt
            ? typeof data.activatedAt === 'object'
              ? moment(new Date(data.activatedAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.activatedAt
            : '--',
          disabledAt: data.disabledAt
            ? typeof data.disabledAt === 'object'
              ? moment(new Date(data.disabledAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.disabledAt
            : '--',
          broker: data.broker,
          capacity: data.capacity,
          privacyContent: data.privacyContent,
          dayTrader: data.dayTrader,
          multiplier: data.multiplier,
          legalEntity: data.legalEntity,
          investmentObjectiveCode: data.investmentObjectiveCode,
          investmentObjectiveDate:
            typeof data.investmentObjectiveDate === 'object'
              ? formatPbDate(data.investmentObjectiveDate)
              : data.investmentObjectiveDate,
          taxCountry: data.taxCountry,
          tefra: data.tefra,
          w8w9: data.w8w9,
          w8w9ReceivedDate:
            typeof data.w8w9ReceivedDate === 'object'
              ? formatPbDate(data.w8w9ReceivedDate)
              : data.w8w9ReceivedDate,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          accountId: data.accountId,
          correspondent: data.correspondent,
          accountNo: data.accountNo,
          accountName: data.accountName,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          accountDesignator: data.accountDesignator,
          activatedAt: data.activatedAt
            ? typeof data.activatedAt === 'object'
              ? moment(new Date(data.activatedAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.activatedAt
            : '--',
          disabledAt: data.disabledAt
            ? typeof data.disabledAt === 'object'
              ? moment(new Date(data.disabledAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.disabledAt
            : '--',
          broker: data.broker,
          capacity: data.capacity,
          privacyContent: data.privacyContent,
          dayTrader: data.dayTrader,
          multiplier: data.multiplier,
          legalEntity: data.legalEntity,
          investmentObjectiveCode: data.investmentObjectiveCode,
          investmentObjectiveDate:
            typeof data.investmentObjectiveDate === 'object'
              ? formatPbDate(data.investmentObjectiveDate)
              : data.investmentObjectiveDate,
          taxCountry: data.taxCountry,
          tefra: data.tefra,
          w8w9: data.w8w9,
          w8w9ReceivedDate:
            typeof data.w8w9ReceivedDate === 'object'
              ? formatPbDate(data.w8w9ReceivedDate)
              : data.w8w9ReceivedDate,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (correspondentValue ? '&correspondent=' + correspondentValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (masterAccountNoValue
          ? '&masterAccountNo=' + masterAccountNoValue
          : '') +
        (accountNameValue ? '&accountName=' + accountNameValue : '') +
        (accountDesignatorValue
          ? '&accountDesignator=' + accountDesignatorValue
          : '') +
        (brokerValue ? '&broker=' + brokerValue : '') +
        (statusValue ? '&status=' + statusValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let listReq = new ListClientRequest();
      listReq.setCorrespondent(correspondentValue);
      listReq.setAccountNo(accountNoValue);
      listReq.setMasterAccountNo(masterAccountNoValue);
      listReq.setAccountName(accountNameValue);
      listReq.setAccountDesignator(accountDesignatorValue);
      listReq.setBroker(brokerValue);
      listReq.setStatus(statusValue);

      accountClient.listClient(listReq, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          return;
        }

        let fullData = res.toObject().clientsList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
    End of paging logic
  ===========================================================================================*/

  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]); // eslint-disable-next-line
  const [rowID, setRowID] = React.useState(''); 

  const columns = [
    {
      id: '1',
      name: 'accountDesignator',
      label: 'Account Designator',
      visibleState: useState(true),
      align: 'left',
      order: 5,
    },
    {
      id: '2',
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '3',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '4',
      name: 'activatedAt',
      label: 'Activated At',
      visibleState: useState(true),
      align: 'left',
      order: 6,
    },
    {
      id: '5',
      name: 'broker',
      label: 'Broker',
      visibleState: useState(true),
      align: 'left',
      order: 8,
    },
    {
      id: '6',
      name: 'capacity',
      label: 'Capacity',
      visibleState: useState(true),
      align: 'right',
      order: 9,
    },
    {
      id: '7',
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '8',
      name: 'createdBy',
      label: 'Created By',
      visibleState: useState(true),
      align: 'left',
      order: 20,
    },
    {
      id: '9',
      name: 'dayTrader',
      label: 'Day Trader',
      visibleState: useState(true),
      align: 'left',
      order: 13,
    },
    {
      id: '10',
      name: 'disabledAt',
      label: 'Disabled At',
      visibleState: useState(true),
      align: 'left',
      order: 7,
    },
    {
      id: '11',
      name: 'investmentObjectiveCode',
      label: 'Investment Objective Code',
      visibleState: useState(false),
      align: 'left',
      order: 15,
    },
    {
      id: '12',
      name: 'investmentObjectiveDate',
      label: 'Investment Objective Date',
      visibleState: useState(false),
      align: 'left',
      order: 10,
    },
    {
      id: '14',
      name: 'legalEntity',
      label: 'Legal Entry',
      visibleState: useState(false),
      align: 'left',
      order: 16,
    },
    {
      id: '15',
      name: 'masterAccountNo',
      label: 'Master Account No',
      visibleState: useState(false),
      align: 'left',
      order: 3,
    },
    {
      id: '16',
      name: 'multiplier',
      label: 'Multiplier',
      visibleState: useState(false),
      align: 'right',
      order: 14,
    },
    {
      id: '17',
      name: 'privacyContent',
      label: 'Privacy Content',
      visibleState: useState(false),
      align: 'left',
      order: 12,
    },
    {
      id: '18',
      name: 'status',
      label: 'Status',
      visibleState: useState(false),
      align: 'left',
      order: 4,
    },
    {
      id: '19',
      name: 'taxCountry',
      label: 'Tax Country',
      visibleState: useState(false),
      align: 'left',
      order: 17,
    },
    {
      id: '20',
      name: 'tefra',
      label: 'TEFRA',
      visibleState: useState(false),
      align: 'left',
      order: 19,
    },
    {
      id: '21',
      name: 'w8w9',
      label: 'W8W9',
      visibleState: useState(false),
      align: 'left',
      order: 18,
    },
    {
      id: '22',
      name: 'w8w9ReceivedDate',
      label: 'W8W9 Received Date',
      visibleState: useState(false),
      align: 'left',
      order: 11,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, accountId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(accountId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, accountId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setIsDisabled(false);
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setIsDisabled(false);
      setSelected([]);
    }
  };

  const isSelected = (accountId) => selected.indexOf(accountId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].accountId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={rows[rowIndex].accountId}
        selected={isItemSelected}
        aria-label={labelId}
      >
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCellNone}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].accountId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'ClientAccounts_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      6,
      1,
      2,
      13,
      16,
      0,
      3,
      9,
      4,
      5,
      11,
      20,
      15,
      8,
      14,
      10,
      12,
      17,
      19,
      18,
      7,
    ],
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      status: query.get('status'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.status) {
      setStatusValue(data.status);
    }

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [
    modalSubmittingParticipatnNumberValue,
    setModalSubmittingParticipatnNumberValue,
  ] = React.useState('');
  const [
    modalOriginalReceiverNumberValue,
    setModalOriginalReceiverNumberValue,
  ] = React.useState('');
  const [
    modalOriginalDelivererNumberValue,
    setModalOriginalDelivererNumberValue,
  ] = React.useState('');
  const [
    modalReceiverCorrespondentValue,
    setModalReceiverCorrespondentValue,
  ] = React.useState('');
  const [
    modalDelivererAccountNoValue,
    setModalDelivererAccountNoValue,
  ] = React.useState('');
  const [modalComments1, setModalComments1] = React.useState('');
  const [modalComments2, setModalComments2] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(true);

  const [open, setOpen] = React.useState(false);

  const handleOpen = (rowData) => {
    setModalSubmittingParticipatnNumberValue('');
    setModalOriginalReceiverNumberValue('');
    setModalOriginalDelivererNumberValue('');
    setModalReceiverCorrespondentValue('');
    setModalDelivererAccountNoValue('');
    setModalComments1('');
    setModalComments2('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="account_designator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  labelId="broker"
                  displayEmpty
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="status">
                  Status
                </InputLabel>
                <Select
                  labelId="status"
                  displayEmpty
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              disabled={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<CancelAndCorrectIcon />}
              onClick={() => {
                handleOpen();
              }}
            >
              Transfer
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Client Accounts'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalLg}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              Transfer Input (TI)
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      required
                      label="Submitting DTCC#"
                      value={modalSubmittingParticipatnNumberValue}
                      onChange={(e) =>
                        setModalSubmittingParticipatnNumberValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 4 }}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      required
                      label="Receiver DTCC#"
                      value={modalOriginalReceiverNumberValue}
                      onChange={(e) =>
                        setModalOriginalReceiverNumberValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 4 }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      required
                      label="Receiver Correspondent"
                      value={modalReceiverCorrespondentValue}
                      onChange={(e) =>
                        setModalReceiverCorrespondentValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 4 }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      required
                      label="Deliverer DTCC#"
                      value={modalOriginalDelivererNumberValue}
                      onChange={(e) =>
                        setModalOriginalDelivererNumberValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 4 }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      required
                      label="Deliverer Account Number"
                      value={modalDelivererAccountNoValue}
                      onChange={(e) =>
                        setModalDelivererAccountNoValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 20 }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ display: 'none' }}>
                    <TextField
                      fullWidth
                      label="Comments1"
                      value={modalComments1}
                      onChange={(e) => setModalComments1(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 46 }}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ display: 'none' }}>
                    <TextField
                      fullWidth
                      label="Comments2"
                      value={modalComments2}
                      onChange={(e) => setModalComments2(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 74 }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        generateTI(
                          selected,
                          modalSubmittingParticipatnNumberValue,
                          modalOriginalReceiverNumberValue,
                          modalOriginalDelivererNumberValue,
                          modalReceiverCorrespondentValue,
                          modalDelivererAccountNoValue,
                          modalComments1,
                          modalComments2,
                          transferInput,
                          handleClose
                        );
                      }}
                    >
                      Transfer
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        downloadTI(
                          selected,
                          modalSubmittingParticipatnNumberValue,
                          modalOriginalReceiverNumberValue,
                          modalOriginalDelivererNumberValue,
                          modalReceiverCorrespondentValue,
                          modalDelivererAccountNoValue,
                          modalComments1,
                          modalComments2,
                          transferInput,
                          handleClose
                        );
                      }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { TransferInputTable };
