// source: bankpb/request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var trnspb_pendingtrns_pb = require('../trnspb/pendingtrns_pb.js');
goog.object.extend(proto, trnspb_pendingtrns_pb);
goog.exportSymbol('proto.proto.CreateResponse', null, global);
goog.exportSymbol('proto.proto.ExecuteRequest', null, global);
goog.exportSymbol('proto.proto.ExecuteResponse', null, global);
goog.exportSymbol('proto.proto.GetByExternalTransferIDRequest', null, global);
goog.exportSymbol('proto.proto.GetByExternalTransferIDResponse', null, global);
goog.exportSymbol('proto.proto.IRADetails', null, global);
goog.exportSymbol('proto.proto.ListRequest', null, global);
goog.exportSymbol('proto.proto.ListResponse', null, global);
goog.exportSymbol('proto.proto.Metadata', null, global);
goog.exportSymbol('proto.proto.ReadRequest', null, global);
goog.exportSymbol('proto.proto.ReadResponse', null, global);
goog.exportSymbol('proto.proto.Request', null, global);
goog.exportSymbol('proto.proto.RequestDetail', null, global);
goog.exportSymbol('proto.proto.UpdateRequest', null, global);
goog.exportSymbol('proto.proto.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Request.displayName = 'proto.proto.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RequestDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RequestDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RequestDetail.displayName = 'proto.proto.RequestDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Metadata.displayName = 'proto.proto.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.IRADetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.IRADetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.IRADetails.displayName = 'proto.proto.IRADetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateResponse.displayName = 'proto.proto.CreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateRequest.displayName = 'proto.proto.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateResponse.displayName = 'proto.proto.UpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadRequest.displayName = 'proto.proto.ReadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadResponse.displayName = 'proto.proto.ReadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRequest.displayName = 'proto.proto.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListResponse.displayName = 'proto.proto.ListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetByExternalTransferIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetByExternalTransferIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetByExternalTransferIDRequest.displayName = 'proto.proto.GetByExternalTransferIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetByExternalTransferIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetByExternalTransferIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetByExternalTransferIDResponse.displayName = 'proto.proto.GetByExternalTransferIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExecuteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExecuteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExecuteRequest.displayName = 'proto.proto.ExecuteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExecuteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExecuteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExecuteResponse.displayName = 'proto.proto.ExecuteResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
bankId: jspb.Message.getFieldWithDefault(msg, 5, ""),
externalTransferId: jspb.Message.getFieldWithDefault(msg, 6, ""),
amount: jspb.Message.getFieldWithDefault(msg, 7, ""),
transferType: jspb.Message.getFieldWithDefault(msg, 8, ""),
status: jspb.Message.getFieldWithDefault(msg, 9, ""),
requestType: jspb.Message.getFieldWithDefault(msg, 10, ""),
createAt: (f = msg.getCreateAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
frbAmount: jspb.Message.getFieldWithDefault(msg, 14, ""),
diffAmount: jspb.Message.getFieldWithDefault(msg, 15, ""),
isInternational: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
fee: jspb.Message.getFieldWithDefault(msg, 17, ""),
instructionForCreditorAgent: jspb.Message.getFieldWithDefault(msg, 18, ""),
businessBankId: jspb.Message.getFieldWithDefault(msg, 19, ""),
contraAccountId: jspb.Message.getFieldWithDefault(msg, 20, ""),
requestedAmount: jspb.Message.getFieldWithDefault(msg, 21, ""),
feePaymentMethod: jspb.Message.getFieldWithDefault(msg, 22, ""),
description: jspb.Message.getFieldWithDefault(msg, 23, ""),
reason: jspb.Message.getFieldWithDefault(msg, 24, ""),
reasonCode: jspb.Message.getFieldWithDefault(msg, 25, ""),
traceNo: (f = jspb.Message.getField(msg, 26)) == null ? undefined : f,
alpacaTraceNo: (f = jspb.Message.getField(msg, 27)) == null ? undefined : f,
sentAt: (f = msg.getSentAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
msgId: (f = jspb.Message.getField(msg, 29)) == null ? undefined : f,
lastAcknowledgedAt: (f = msg.getLastAcknowledgedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
metadata: (f = msg.getMetadata()) && proto.proto.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Request}
 */
proto.proto.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Request;
  return proto.proto.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Request}
 */
proto.proto.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalTransferId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 13:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrbAmount(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiffAmount(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInternational(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstructionForCreditorAgent(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessBankId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedAmount(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeePaymentMethod(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCode(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceNo(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlpacaTraceNo(value);
      break;
    case 28:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSentAt(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgId(value);
      break;
    case 30:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastAcknowledgedAt(value);
      break;
    case 31:
      var value = new proto.proto.Metadata;
      reader.readMessage(value,proto.proto.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExternalTransferId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreateAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFrbAmount();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDiffAmount();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIsInternational();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getInstructionForCreditorAgent();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBusinessBankId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getContraAccountId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRequestedAmount();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getFeePaymentMethod();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getReasonCode();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeString(
      26,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getSentAt();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getLastAcknowledgedAt();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.proto.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.Request.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.proto.Request.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.proto.Request.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.proto.Request.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_id = 5;
 * @return {string}
 */
proto.proto.Request.prototype.getBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string external_transfer_id = 6;
 * @return {string}
 */
proto.proto.Request.prototype.getExternalTransferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setExternalTransferId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string amount = 7;
 * @return {string}
 */
proto.proto.Request.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string transfer_type = 8;
 * @return {string}
 */
proto.proto.Request.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.proto.Request.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string request_type = 10;
 * @return {string}
 */
proto.proto.Request.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp create_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Request.prototype.getCreateAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setCreateAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearCreateAt = function() {
  return this.setCreateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasCreateAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string created_by = 12;
 * @return {string}
 */
proto.proto.Request.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.type.Date system_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.proto.Request.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string frb_amount = 14;
 * @return {string}
 */
proto.proto.Request.prototype.getFrbAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setFrbAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string diff_amount = 15;
 * @return {string}
 */
proto.proto.Request.prototype.getDiffAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setDiffAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool is_international = 16;
 * @return {boolean}
 */
proto.proto.Request.prototype.getIsInternational = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setIsInternational = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string fee = 17;
 * @return {string}
 */
proto.proto.Request.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string instruction_for_creditor_agent = 18;
 * @return {string}
 */
proto.proto.Request.prototype.getInstructionForCreditorAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setInstructionForCreditorAgent = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string business_bank_id = 19;
 * @return {string}
 */
proto.proto.Request.prototype.getBusinessBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBusinessBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string contra_account_id = 20;
 * @return {string}
 */
proto.proto.Request.prototype.getContraAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setContraAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string requested_amount = 21;
 * @return {string}
 */
proto.proto.Request.prototype.getRequestedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setRequestedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string fee_payment_method = 22;
 * @return {string}
 */
proto.proto.Request.prototype.getFeePaymentMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setFeePaymentMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string description = 23;
 * @return {string}
 */
proto.proto.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string reason = 24;
 * @return {string}
 */
proto.proto.Request.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string reason_code = 25;
 * @return {string}
 */
proto.proto.Request.prototype.getReasonCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string trace_no = 26;
 * @return {string}
 */
proto.proto.Request.prototype.getTraceNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setTraceNo = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearTraceNo = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasTraceNo = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string alpaca_trace_no = 27;
 * @return {string}
 */
proto.proto.Request.prototype.getAlpacaTraceNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setAlpacaTraceNo = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearAlpacaTraceNo = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasAlpacaTraceNo = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.Timestamp sent_at = 28;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Request.prototype.getSentAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 28));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setSentAt = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearSentAt = function() {
  return this.setSentAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasSentAt = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string msg_id = 29;
 * @return {string}
 */
proto.proto.Request.prototype.getMsgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setMsgId = function(value) {
  return jspb.Message.setField(this, 29, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearMsgId = function() {
  return jspb.Message.setField(this, 29, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasMsgId = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.Timestamp last_acknowledged_at = 30;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Request.prototype.getLastAcknowledgedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 30));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setLastAcknowledgedAt = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearLastAcknowledgedAt = function() {
  return this.setLastAcknowledgedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasLastAcknowledgedAt = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional Metadata Metadata = 31;
 * @return {?proto.proto.Metadata}
 */
proto.proto.Request.prototype.getMetadata = function() {
  return /** @type{?proto.proto.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.proto.Metadata, 31));
};


/**
 * @param {?proto.proto.Metadata|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 31) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RequestDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RequestDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RequestDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RequestDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
bankId: jspb.Message.getFieldWithDefault(msg, 5, ""),
externalTransferId: jspb.Message.getFieldWithDefault(msg, 6, ""),
amount: jspb.Message.getFieldWithDefault(msg, 7, ""),
transferType: jspb.Message.getFieldWithDefault(msg, 8, ""),
status: jspb.Message.getFieldWithDefault(msg, 9, ""),
requestType: jspb.Message.getFieldWithDefault(msg, 10, ""),
createAt: (f = msg.getCreateAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
bankAccountType: jspb.Message.getFieldWithDefault(msg, 13, ""),
bankName: jspb.Message.getFieldWithDefault(msg, 14, ""),
achRoutingNo: jspb.Message.getFieldWithDefault(msg, 15, ""),
wireRoutingNo: jspb.Message.getFieldWithDefault(msg, 16, ""),
bankAccountNo: jspb.Message.getFieldWithDefault(msg, 17, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
frbAmount: jspb.Message.getFieldWithDefault(msg, 19, ""),
diffAmount: jspb.Message.getFieldWithDefault(msg, 20, ""),
bankOwnerName: jspb.Message.getFieldWithDefault(msg, 21, ""),
isInternational: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
fee: jspb.Message.getFieldWithDefault(msg, 23, ""),
instructionForCreditorAgent: jspb.Message.getFieldWithDefault(msg, 24, ""),
achReturnCode: jspb.Message.getFieldWithDefault(msg, 25, ""),
description: jspb.Message.getFieldWithDefault(msg, 26, ""),
reason: jspb.Message.getFieldWithDefault(msg, 27, ""),
reasonCode: jspb.Message.getFieldWithDefault(msg, 28, ""),
traceNo: (f = jspb.Message.getField(msg, 29)) == null ? undefined : f,
alpacaTraceNo: (f = jspb.Message.getField(msg, 30)) == null ? undefined : f,
sentAt: (f = msg.getSentAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
msgId: (f = jspb.Message.getField(msg, 32)) == null ? undefined : f,
lastAcknowledgedAt: (f = msg.getLastAcknowledgedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RequestDetail}
 */
proto.proto.RequestDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RequestDetail;
  return proto.proto.RequestDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RequestDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RequestDetail}
 */
proto.proto.RequestDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalTransferId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchRoutingNo(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setWireRoutingNo(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountNo(value);
      break;
    case 18:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrbAmount(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiffAmount(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankOwnerName(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInternational(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstructionForCreditorAgent(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchReturnCode(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonCode(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceNo(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlpacaTraceNo(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSentAt(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgId(value);
      break;
    case 33:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastAcknowledgedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RequestDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RequestDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RequestDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RequestDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExternalTransferId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreateAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBankAccountType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAchRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getWireRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBankAccountNo();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFrbAmount();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDiffAmount();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBankOwnerName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getIsInternational();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getInstructionForCreditorAgent();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAchReturnCode();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getReasonCode();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeString(
      29,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getSentAt();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 32));
  if (f != null) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getLastAcknowledgedAt();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_id = 5;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string external_transfer_id = 6;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getExternalTransferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setExternalTransferId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string amount = 7;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string transfer_type = 8;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string request_type = 10;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp create_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.RequestDetail.prototype.getCreateAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.RequestDetail} returns this
*/
proto.proto.RequestDetail.prototype.setCreateAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearCreateAt = function() {
  return this.setCreateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasCreateAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string created_by = 12;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string bank_account_type = 13;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getBankAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setBankAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string bank_name = 14;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ach_routing_no = 15;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getAchRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setAchRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string wire_routing_no = 16;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getWireRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setWireRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string bank_account_no = 17;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getBankAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setBankAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional google.type.Date system_date = 18;
 * @return {?proto.google.type.Date}
 */
proto.proto.RequestDetail.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 18));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.RequestDetail} returns this
*/
proto.proto.RequestDetail.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string frb_amount = 19;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getFrbAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setFrbAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string diff_amount = 20;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getDiffAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setDiffAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string bank_owner_name = 21;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getBankOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setBankOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool is_international = 22;
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.getIsInternational = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setIsInternational = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string fee = 23;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string instruction_for_creditor_agent = 24;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getInstructionForCreditorAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setInstructionForCreditorAgent = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string ach_return_code = 25;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getAchReturnCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setAchReturnCode = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string description = 26;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string reason = 27;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string reason_code = 28;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getReasonCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setReasonCode = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string trace_no = 29;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getTraceNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setTraceNo = function(value) {
  return jspb.Message.setField(this, 29, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearTraceNo = function() {
  return jspb.Message.setField(this, 29, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasTraceNo = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string alpaca_trace_no = 30;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getAlpacaTraceNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setAlpacaTraceNo = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearAlpacaTraceNo = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasAlpacaTraceNo = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.protobuf.Timestamp sent_at = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.RequestDetail.prototype.getSentAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.RequestDetail} returns this
*/
proto.proto.RequestDetail.prototype.setSentAt = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearSentAt = function() {
  return this.setSentAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasSentAt = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string msg_id = 32;
 * @return {string}
 */
proto.proto.RequestDetail.prototype.getMsgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.setMsgId = function(value) {
  return jspb.Message.setField(this, 32, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearMsgId = function() {
  return jspb.Message.setField(this, 32, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasMsgId = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.protobuf.Timestamp last_acknowledged_at = 33;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.RequestDetail.prototype.getLastAcknowledgedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 33));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.RequestDetail} returns this
*/
proto.proto.RequestDetail.prototype.setLastAcknowledgedAt = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.RequestDetail} returns this
 */
proto.proto.RequestDetail.prototype.clearLastAcknowledgedAt = function() {
  return this.setLastAcknowledgedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.RequestDetail.prototype.hasLastAcknowledgedAt = function() {
  return jspb.Message.getField(this, 33) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
ira: (f = msg.getIra()) && proto.proto.IRADetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Metadata}
 */
proto.proto.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Metadata;
  return proto.proto.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Metadata}
 */
proto.proto.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.IRADetails;
      reader.readMessage(value,proto.proto.IRADetails.deserializeBinaryFromReader);
      msg.setIra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIra();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.IRADetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional IRADetails ira = 1;
 * @return {?proto.proto.IRADetails}
 */
proto.proto.Metadata.prototype.getIra = function() {
  return /** @type{?proto.proto.IRADetails} */ (
    jspb.Message.getWrapperField(this, proto.proto.IRADetails, 1));
};


/**
 * @param {?proto.proto.IRADetails|undefined} value
 * @return {!proto.proto.Metadata} returns this
*/
proto.proto.Metadata.prototype.setIra = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Metadata} returns this
 */
proto.proto.Metadata.prototype.clearIra = function() {
  return this.setIra(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Metadata.prototype.hasIra = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.IRADetails.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.IRADetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.IRADetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IRADetails.toObject = function(includeInstance, msg) {
  var f, obj = {
taxYear: jspb.Message.getFieldWithDefault(msg, 1, ""),
federalWithholdingPct: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
stateWithholdingPct: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
federalWithholdingAmount: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
stateWithholdingAmount: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.IRADetails}
 */
proto.proto.IRADetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.IRADetails;
  return proto.proto.IRADetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.IRADetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.IRADetails}
 */
proto.proto.IRADetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFederalWithholdingPct(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateWithholdingPct(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFederalWithholdingAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateWithholdingAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.IRADetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.IRADetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.IRADetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.IRADetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string tax_year = 1;
 * @return {string}
 */
proto.proto.IRADetails.prototype.getTaxYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.setTaxYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string federal_withholding_pct = 2;
 * @return {string}
 */
proto.proto.IRADetails.prototype.getFederalWithholdingPct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.setFederalWithholdingPct = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.clearFederalWithholdingPct = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.IRADetails.prototype.hasFederalWithholdingPct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string state_withholding_pct = 3;
 * @return {string}
 */
proto.proto.IRADetails.prototype.getStateWithholdingPct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.setStateWithholdingPct = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.clearStateWithholdingPct = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.IRADetails.prototype.hasStateWithholdingPct = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string federal_withholding_amount = 4;
 * @return {string}
 */
proto.proto.IRADetails.prototype.getFederalWithholdingAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.setFederalWithholdingAmount = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.clearFederalWithholdingAmount = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.IRADetails.prototype.hasFederalWithholdingAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string state_withholding_amount = 5;
 * @return {string}
 */
proto.proto.IRADetails.prototype.getStateWithholdingAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.setStateWithholdingAmount = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.proto.IRADetails} returns this
 */
proto.proto.IRADetails.prototype.clearStateWithholdingAmount = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.IRADetails.prototype.hasStateWithholdingAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
requestDetail: (f = msg.getRequestDetail()) && proto.proto.RequestDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateResponse}
 */
proto.proto.CreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateResponse;
  return proto.proto.CreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateResponse}
 */
proto.proto.CreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RequestDetail;
      reader.readMessage(value,proto.proto.RequestDetail.deserializeBinaryFromReader);
      msg.setRequestDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.RequestDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestDetail request_detail = 1;
 * @return {?proto.proto.RequestDetail}
 */
proto.proto.CreateResponse.prototype.getRequestDetail = function() {
  return /** @type{?proto.proto.RequestDetail} */ (
    jspb.Message.getWrapperField(this, proto.proto.RequestDetail, 1));
};


/**
 * @param {?proto.proto.RequestDetail|undefined} value
 * @return {!proto.proto.CreateResponse} returns this
*/
proto.proto.CreateResponse.prototype.setRequestDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateResponse} returns this
 */
proto.proto.CreateResponse.prototype.clearRequestDetail = function() {
  return this.setRequestDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateResponse.prototype.hasRequestDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateRequest}
 */
proto.proto.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateRequest;
  return proto.proto.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateRequest}
 */
proto.proto.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.UpdateRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.proto.UpdateRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
requestDetail: (f = msg.getRequestDetail()) && proto.proto.RequestDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateResponse}
 */
proto.proto.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateResponse;
  return proto.proto.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateResponse}
 */
proto.proto.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RequestDetail;
      reader.readMessage(value,proto.proto.RequestDetail.deserializeBinaryFromReader);
      msg.setRequestDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.RequestDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestDetail request_detail = 1;
 * @return {?proto.proto.RequestDetail}
 */
proto.proto.UpdateResponse.prototype.getRequestDetail = function() {
  return /** @type{?proto.proto.RequestDetail} */ (
    jspb.Message.getWrapperField(this, proto.proto.RequestDetail, 1));
};


/**
 * @param {?proto.proto.RequestDetail|undefined} value
 * @return {!proto.proto.UpdateResponse} returns this
*/
proto.proto.UpdateResponse.prototype.setRequestDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateResponse} returns this
 */
proto.proto.UpdateResponse.prototype.clearRequestDetail = function() {
  return this.setRequestDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateResponse.prototype.hasRequestDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
requestId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadRequest}
 */
proto.proto.ReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadRequest;
  return proto.proto.ReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadRequest}
 */
proto.proto.ReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.ReadRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadRequest} returns this
 */
proto.proto.ReadRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
requestDetail: (f = msg.getRequestDetail()) && proto.proto.RequestDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadResponse}
 */
proto.proto.ReadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadResponse;
  return proto.proto.ReadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadResponse}
 */
proto.proto.ReadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RequestDetail;
      reader.readMessage(value,proto.proto.RequestDetail.deserializeBinaryFromReader);
      msg.setRequestDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.RequestDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestDetail request_detail = 1;
 * @return {?proto.proto.RequestDetail}
 */
proto.proto.ReadResponse.prototype.getRequestDetail = function() {
  return /** @type{?proto.proto.RequestDetail} */ (
    jspb.Message.getWrapperField(this, proto.proto.RequestDetail, 1));
};


/**
 * @param {?proto.proto.RequestDetail|undefined} value
 * @return {!proto.proto.ReadResponse} returns this
*/
proto.proto.ReadResponse.prototype.setRequestDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReadResponse} returns this
 */
proto.proto.ReadResponse.prototype.clearRequestDetail = function() {
  return this.setRequestDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReadResponse.prototype.hasRequestDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
transferType: jspb.Message.getFieldWithDefault(msg, 4, ""),
status: jspb.Message.getFieldWithDefault(msg, 5, ""),
requestType: jspb.Message.getFieldWithDefault(msg, 6, ""),
fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRequest}
 */
proto.proto.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRequest;
  return proto.proto.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRequest}
 */
proto.proto.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transfer_type = 4;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string request_type = 6;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.type.Date from_system_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.proto.ListRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.ListRequest} returns this
*/
proto.proto.ListRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.type.Date to_system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.proto.ListRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.ListRequest} returns this
*/
proto.proto.ListRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
requestDetailsList: jspb.Message.toObjectList(msg.getRequestDetailsList(),
    proto.proto.RequestDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListResponse}
 */
proto.proto.ListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListResponse;
  return proto.proto.ListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListResponse}
 */
proto.proto.ListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RequestDetail;
      reader.readMessage(value,proto.proto.RequestDetail.deserializeBinaryFromReader);
      msg.addRequestDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.RequestDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RequestDetail request_details = 1;
 * @return {!Array<!proto.proto.RequestDetail>}
 */
proto.proto.ListResponse.prototype.getRequestDetailsList = function() {
  return /** @type{!Array<!proto.proto.RequestDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.RequestDetail, 1));
};


/**
 * @param {!Array<!proto.proto.RequestDetail>} value
 * @return {!proto.proto.ListResponse} returns this
*/
proto.proto.ListResponse.prototype.setRequestDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.RequestDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.RequestDetail}
 */
proto.proto.ListResponse.prototype.addRequestDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.RequestDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListResponse} returns this
 */
proto.proto.ListResponse.prototype.clearRequestDetailsList = function() {
  return this.setRequestDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetByExternalTransferIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetByExternalTransferIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetByExternalTransferIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetByExternalTransferIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
externalTransferId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetByExternalTransferIDRequest}
 */
proto.proto.GetByExternalTransferIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetByExternalTransferIDRequest;
  return proto.proto.GetByExternalTransferIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetByExternalTransferIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetByExternalTransferIDRequest}
 */
proto.proto.GetByExternalTransferIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalTransferId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetByExternalTransferIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetByExternalTransferIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetByExternalTransferIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetByExternalTransferIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalTransferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string external_transfer_id = 1;
 * @return {string}
 */
proto.proto.GetByExternalTransferIDRequest.prototype.getExternalTransferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetByExternalTransferIDRequest} returns this
 */
proto.proto.GetByExternalTransferIDRequest.prototype.setExternalTransferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetByExternalTransferIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetByExternalTransferIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetByExternalTransferIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetByExternalTransferIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
requestDetail: (f = msg.getRequestDetail()) && proto.proto.RequestDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetByExternalTransferIDResponse}
 */
proto.proto.GetByExternalTransferIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetByExternalTransferIDResponse;
  return proto.proto.GetByExternalTransferIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetByExternalTransferIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetByExternalTransferIDResponse}
 */
proto.proto.GetByExternalTransferIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RequestDetail;
      reader.readMessage(value,proto.proto.RequestDetail.deserializeBinaryFromReader);
      msg.setRequestDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetByExternalTransferIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetByExternalTransferIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetByExternalTransferIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetByExternalTransferIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.RequestDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestDetail request_detail = 1;
 * @return {?proto.proto.RequestDetail}
 */
proto.proto.GetByExternalTransferIDResponse.prototype.getRequestDetail = function() {
  return /** @type{?proto.proto.RequestDetail} */ (
    jspb.Message.getWrapperField(this, proto.proto.RequestDetail, 1));
};


/**
 * @param {?proto.proto.RequestDetail|undefined} value
 * @return {!proto.proto.GetByExternalTransferIDResponse} returns this
*/
proto.proto.GetByExternalTransferIDResponse.prototype.setRequestDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetByExternalTransferIDResponse} returns this
 */
proto.proto.GetByExternalTransferIDResponse.prototype.clearRequestDetail = function() {
  return this.setRequestDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetByExternalTransferIDResponse.prototype.hasRequestDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExecuteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExecuteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExecuteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
requestId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExecuteRequest}
 */
proto.proto.ExecuteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExecuteRequest;
  return proto.proto.ExecuteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExecuteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExecuteRequest}
 */
proto.proto.ExecuteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExecuteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExecuteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExecuteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.proto.ExecuteRequest.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ExecuteRequest} returns this
 */
proto.proto.ExecuteRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExecuteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExecuteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExecuteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
pendingTrns: (f = msg.getPendingTrns()) && trnspb_pendingtrns_pb.PendingTrns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExecuteResponse}
 */
proto.proto.ExecuteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExecuteResponse;
  return proto.proto.ExecuteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExecuteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExecuteResponse}
 */
proto.proto.ExecuteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new trnspb_pendingtrns_pb.PendingTrns;
      reader.readMessage(value,trnspb_pendingtrns_pb.PendingTrns.deserializeBinaryFromReader);
      msg.setPendingTrns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExecuteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExecuteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExecuteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExecuteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingTrns();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      trnspb_pendingtrns_pb.PendingTrns.serializeBinaryToWriter
    );
  }
};


/**
 * optional trnspb.PendingTrns pending_trns = 1;
 * @return {?proto.trnspb.PendingTrns}
 */
proto.proto.ExecuteResponse.prototype.getPendingTrns = function() {
  return /** @type{?proto.trnspb.PendingTrns} */ (
    jspb.Message.getWrapperField(this, trnspb_pendingtrns_pb.PendingTrns, 1));
};


/**
 * @param {?proto.trnspb.PendingTrns|undefined} value
 * @return {!proto.proto.ExecuteResponse} returns this
*/
proto.proto.ExecuteResponse.prototype.setPendingTrns = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ExecuteResponse} returns this
 */
proto.proto.ExecuteResponse.prototype.clearPendingTrns = function() {
  return this.setPendingTrns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ExecuteResponse.prototype.hasPendingTrns = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.proto);
