import React, { useState } from 'react';

/*Service*/
import { ListServiceClient } from '../proto/reportpb/position_grpc_web_pb';

import { ListServiceClient as ListAccountServiceClient } from '../proto/commonpb/list_grpc_web_pb';

import { ProfileServiceClient } from '../proto/admpb/profile_grpc_web_pb';

import { RequestServiceClient } from '../proto/bankpb/request_grpc_web_pb.js';

import { AccountServiceClient } from '../proto/bankpb/account_grpc_web_pb.js';

import { BankAddressServiceClient } from '../proto/bankpb/address_grpc_web_pb.js';

import { PendingTrnsServiceClient } from '../proto/trnspb/pendingtrns_grpc_web_pb';

import { TransactionServiceClient } from '../proto/trnspb/transaction_grpc_web_pb';

import { ActivityServiceClient } from '../proto/reportpb/activity_grpc_web_pb';

import { BalanceServiceClient } from '../proto/reportpb/balance_grpc_web_pb';

import { BalanceReconServiceClient } from '../proto/reconpb/balancerecon_grpc_web_pb';

import { CalendarServiceClient } from '../proto/admpb/calendar_grpc_web_pb';

import { CallLogServiceClient } from '../proto/marginpb/calllog_grpc_web_pb';

import { DailyTradeConfirmServiceClient } from '../proto/reportpb/dailytradeconfirm_grpc_web_pb';

import { NoteServiceClient } from '../proto/commonpb/note_grpc_web_pb';

import { ExecutionReconServiceClient } from '../proto/reconpb/executionrecon_grpc_web_pb';

import { FeeServiceClient } from '../proto/trnspb/fee_grpc_web_pb';

import { GLBalanceServiceClient } from '../proto/reportpb/glbalance_grpc_web_pb';

import { InterestServiceClient } from '../proto/feepb/interest_grpc_web_pb';

import { RequirementServiceClient } from '../proto/marginpb/requirement_grpc_web_pb';

import { PositionReconServiceClient } from '../proto/reconpb/positionrecon_grpc_web_pb';

import { PriceServiceClient } from '../proto/assetpb/price_grpc_web_pb';

import { ProfitAndLossServiceClient } from '../proto/reportpb/profitandloss_grpc_web_pb';

import { StockBorrowServiceClient } from '../proto/reportpb/stockborrow_grpc_web_pb';

import { SystemNumberServiceClient } from '../proto/admpb/systemnumber_grpc_web_pb';

import { TrialBalanceServiceClient } from '../proto/reportpb/trialbalance_grpc_web_pb';

import {
  AdministratorServiceClient,
  AdministratorServicePromiseClient,
} from '../proto/usrpb/administrator_grpc_web_pb';

import { AddressServiceClient } from '../proto/accountpb/address_grpc_web_pb';

import { ClientServiceClient } from '../proto/accountpb/client_grpc_web_pb';

import { ContactInfoServiceClient } from '../proto/accountpb/contactinfo_grpc_web_pb';

import { AccountTypeServiceClient } from '../proto/accountpb/accounttype_grpc_web_pb';

import { OwnerServiceClient } from '../proto/accountpb/owner_grpc_web_pb';

import { ReportSetupServiceClient } from '../proto/accountpb/reportsetup_grpc_web_pb';

import { SubAccountServiceClient } from '../proto/accountpb/subaccount_grpc_web_pb';

import { EtfServiceClient } from '../proto/assetpb/etf_grpc_web_pb';

import { FeeManagementServiceClient } from '../proto/feepb/management_grpc_web_pb';

import { GeneralLedgerServiceClient } from '../proto/accountpb/generalledger_grpc_web_pb';

import { InterestRateServiceClient } from '../proto/feepb/interestrate_grpc_web_pb.js';

import { RateServiceClient } from '../proto/marginpb/rate_grpc_web_pb.js';

import { StatementServiceClient } from '../proto/reportpb/statement_grpc_web_pb';

import { AccountMappingServiceClient } from '../proto/reconpb/accountmapping_grpc_web_pb';

import { ReserveServiceClient } from '../proto/compliancepb/reserve_grpc_web_pb';

import { SecurityMasterServiceClient } from '../proto/assetpb/securitymaster_grpc_web_pb';

import { SegregationServiceClient } from '../proto/reportpb/segregation_grpc_web_pb';

import { SllServiceClient } from '../proto/assetpb/sll_grpc_web_pb';

import { SystemCodeServiceClient } from '../proto/admpb/systemcode_grpc_web_pb';

import { UserGuideServiceClient } from '../proto/admpb/userguide_grpc_web_pb';

import { FractionalShareReconServiceClient } from '../proto/reconpb/fractionalshare_grpc_web_pb';

import { SettlementFundingServiceClient } from '../proto/reportpb/settlementfunding_grpc_web_pb';

import { AnnouncementServiceClient } from '../proto/reorgpb/announcement_grpc_web_pb';

import { AccountManagementServiceClient } from '../proto/reportpb/accountmanagement_grpc_web_pb';

import { TaxRateServiceClient } from '../proto/irspb/taxrate_grpc_web_pb';

import { PreviousDateServiceClient } from '../proto/admpb/previousdate_grpc_web_pb';

import { RoleServiceClient } from '../proto/usrpb/role_grpc_web_pb';

import { FractionalSharePLServiceClient } from '../proto/reportpb/fractionalsharepl_grpc_web_pb';

import { TableSettingsServiceClient } from '../proto/admpb/tablesettings_grpc_web_pb';

import { TransferInputServiceClient } from '../proto/compliancepb/transferinput_grpc_web_pb';

import { InventoryReportServiceClient } from '../proto/reportpb/inventoryreport_grpc_web_pb';

import { TaxWithholdingDivServiceClient } from '../proto/reportpb/taxwithholdingdiv_grpc_web_pb';

import { StatementDisclosureServiceClient } from '../proto/reportpb/statementdisclosure_grpc_web_pb';

import { TemplateServiceClient } from '../proto/reportpb/template_grpc_web_pb';

import { SegregationPriorityServiceClient } from '../proto/reportpb/segregationpriority_grpc_web_pb';

import {
  SsrServiceClient,
  SsrServicePromiseClient,
} from '../proto/reportpb/ssr_grpc_web_pb';

import { AssetTransferServiceClient } from '../proto/compliancepb/assettransfer_grpc_web_pb';

import { OutgoingServiceClient } from '../proto/acatspb/outgoing_grpc_web_pb';

import { MultiCycleMROServiceClient } from '../proto/acatspb/multicycle_grpc_web_pb';

import { OutputPositionServiceClient } from '../proto/acatspb/outputposition_grpc_web_pb';

import { NoticeServiceClient } from 'proto/irspb/notice_grpc_web_pb';

import { auth } from '../lib/auth/Auth';
// context
import { useUserState, useUserDispatch } from './UserContext';

const ServiceContext = React.createContext();
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

function ServiceProvider({ children }) {
  const { googleAccount } = useUserState();
  const userDispatch = useUserDispatch();
  const [authError, setAuthError] = useState(null);

  const params = [
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth(googleAccount, authError, setAuthError, userDispatch) },
  ];

  const serviceClients = {
    listServiceClient: new ListServiceClient(...params),
    profileServiceClient: new ProfileServiceClient(...params),
    requestServiceClient: new RequestServiceClient(...params),
    listAccountServiceClient: new ListAccountServiceClient(...params),
    accountServiceClient: new AccountServiceClient(...params),
    bankAddressServiceClient: new BankAddressServiceClient(...params),
    pendingTrnsServiceClient: new PendingTrnsServiceClient(...params),
    transactionServiceClient: new TransactionServiceClient(...params),
    activityServiceClient: new ActivityServiceClient(...params),
    balanceServiceClient: new BalanceServiceClient(...params),
    balanceReconServiceClient: new BalanceReconServiceClient(...params),
    calendarServiceClient: new CalendarServiceClient(...params),
    callLogServiceClient: new CallLogServiceClient(...params),
    noteServiceClient: new NoteServiceClient(...params),
    dailyTradeConfirmServiceClient: new DailyTradeConfirmServiceClient(
      ...params
    ),
    executionReconServiceClient: new ExecutionReconServiceClient(...params),
    feeServiceClient: new FeeServiceClient(...params),
    gLBalanceServiceClient: new GLBalanceServiceClient(...params),
    interestServiceClient: new InterestServiceClient(...params),
    requirementServiceClient: new RequirementServiceClient(...params),
    positionReconServiceClient: new PositionReconServiceClient(...params),
    priceServiceClient: new PriceServiceClient(...params),
    profitAndLossServiceClient: new ProfitAndLossServiceClient(...params),
    stockBorrowServiceClient: new StockBorrowServiceClient(...params),
    systemNumberServiceClient: new SystemNumberServiceClient(...params),
    trialBalanceServiceClient: new TrialBalanceServiceClient(...params),
    administratorServiceClient: new AdministratorServiceClient(...params),
    administratorServicePromiseClient: new AdministratorServicePromiseClient(
      ...params
    ),
    addressServiceClient: new AddressServiceClient(...params),
    clientServiceClient: new ClientServiceClient(...params),
    accountTypeServiceClient: new AccountTypeServiceClient(...params),
    contactInfoServiceClient: new ContactInfoServiceClient(...params),
    ownerServiceClient: new OwnerServiceClient(...params),
    reportSetupServiceClient: new ReportSetupServiceClient(...params),
    subAccountServiceClient: new SubAccountServiceClient(...params),
    etfServiceClient: new EtfServiceClient(...params),
    feeManagementServiceClient: new FeeManagementServiceClient(...params),
    generalLedgerServiceClient: new GeneralLedgerServiceClient(...params),
    interestRateServiceClient: new InterestRateServiceClient(...params),
    rateServiceClient: new RateServiceClient(...params),
    statementServiceClient: new StatementServiceClient(...params),
    accountMappingServiceClient: new AccountMappingServiceClient(...params),
    reserveServiceClient: new ReserveServiceClient(...params),
    securityMasterServiceClient: new SecurityMasterServiceClient(...params),
    segregationServiceClient: new SegregationServiceClient(...params),
    sllServiceClient: new SllServiceClient(...params),
    systemCodeServiceClient: new SystemCodeServiceClient(...params),
    userGuideServiceClient: new UserGuideServiceClient(...params),
    fractionalShareReconServiceClient: new FractionalShareReconServiceClient(
      ...params
    ),
    settlementFundingServiceClient: new SettlementFundingServiceClient(
      ...params
    ),
    announcementServiceClient: new AnnouncementServiceClient(...params),
    accountManagementServiceClient: new AccountManagementServiceClient(
      ...params
    ),

    taxRateServiceClient: new TaxRateServiceClient(...params),
    previousDateServiceClient: new PreviousDateServiceClient(...params),
    roleServiceClient: new RoleServiceClient(...params),
    fractionalSharePLServiceClient: new FractionalSharePLServiceClient(
      ...params
    ),
    tableSettingsServiceClient: new TableSettingsServiceClient(...params),

    transferInputServiceClient: new TransferInputServiceClient(...params),
    inventoryReportServiceClient: new InventoryReportServiceClient(...params),
    taxWithholdingDivServiceClient: new TaxWithholdingDivServiceClient(
      ...params
    ),
    statementDisclosureServiceClient: new StatementDisclosureServiceClient(
      ...params
    ),
    templateServiceClient: new TemplateServiceClient(...params),
    segregationPriorityServiceClient: new SegregationPriorityServiceClient(
      ...params
    ),
    ssrServiceClient: new SsrServiceClient(...params),
    ssrServicePromiseClient: new SsrServicePromiseClient(...params),
    assetTransferServiceClient: new AssetTransferServiceClient(...params),
    outgoingServiceClient: new OutgoingServiceClient(...params),
    multiCycleMROServiceClient: new MultiCycleMROServiceClient(...params),
    outputPositionServiceClient: new OutputPositionServiceClient(...params),
    noticeServiceClient: new NoticeServiceClient(...params),
  };
  enableDevTools(Object.values(serviceClients));

  return (
    <ServiceContext.Provider
      value={{ ...{ ...serviceClients }, authError, setAuthError }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export { ServiceProvider, ServiceContext };
