import React from 'react';

import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import tableTheme from '../../../components/Table/TableStyle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const defaultDataTableOptions = {
  download: false,
  filter: false,
  print: false,
  search: false,
  viewColumns: false,
  sort: false,
  selectableRowsHeader: false,
  selectableRows: 'none',
};

// support server side fetch with pagination and filter
export function DataTable(props) {
  const rowsPerPage = props.rowsPerPage ?? 10;

  const options = {
    ...defaultDataTableOptions,
    rowsPerPage,
  };

  return (
    <MuiThemeProvider theme={tableTheme()}>
      <MUIDataTable options={options} {...props} />
    </MuiThemeProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

// <InfoTable data={ [ {name: xxx, body: xxx} ] } />
export function InfoTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {data.map((record) => {
            return (
              <TableRow key={record.name}>
                <TableCell align="right" width="30%">
                  {record.name}
                </TableCell>
                <TableCell align="left" width="70%">
                  {record.body}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
