import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  useListSsrExecution,
  useListSsrRow,
  useUpdateExecution,
  useReviewRow,
  useClearReview,
  useGenerateReviewedFile,
  useUploadReviewedFile,
} from '../Hooks/useSsrService';
import { DataTable, InfoTable } from './Table';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useSsrRouter } from '../Hooks/useRouter';
import { notifyError } from 'components/Notification/Notification';

export default function Execution({ execution }) {
  const ssrRouter = useSsrRouter();

  const handleGoToResultButton = () => {
    ssrRouter.goToViewExecutionPage(execution.id);
  };

  return (
    <div>
      <p> execution status: {execution.status}</p>

      <Button
        color="primary"
        variant="contained"
        onClick={handleGoToResultButton}
      >
        Go to result
      </Button>
    </div>
  );
}

export function ListExecutions({ reportId }) {
  const ssrRouter = useSsrRouter();
  const executions = useListSsrExecution({ reportId });

  const createGoToResultHandler = (executionId) => {
    return function() {
      ssrRouter.goToViewExecutionPage(executionId);
    };
  };

  const columns = [
    'Status',
    'Report Generated Time',
    'Report File',
    'Review Uploaded Time',
    'Review File',
    '',
  ];

  if (!executions.data) {
    return 'execution data in progress...';
  }

  const rows = executions.data.map((execution) => {
    return [
      execution.status,
      execution.reportUploadedAt?.toLocaleString() ?? 'N/A',
      execution.reportUrl ? (
        <a href={execution.reportUrl} target="_blank" rel="noopener noreferrer">
          report file link
        </a>
      ) : (
        'N/A'
      ),
      execution.reviewUploadedAt?.toLocaleString() ?? 'N/A',
      execution.reviewUrl ? (
        <a href={execution.reviewUrl} target="_blank" rel="noopener noreferrer">
          review file link
        </a>
      ) : (
        'N/A'
      ),
      // if data is cleared, hide "review" button
      execution.dataClearedAt ? null : (
        <Button
          key="review"
          color="primary"
          variant="contained"
          onClick={createGoToResultHandler(execution.id)}
        >
          Review
        </Button>
      ),
    ];
  });

  return <DataTable title="Executions" columns={columns} data={rows} />;
}

export function EditExecution({ executionId }) {
  const listExecutions = useListSsrExecution({ executionIds: [executionId] });

  if (listExecutions.isSuccess && listExecutions.data?.length === 1) {
    return <EditExecutionForm execution={listExecutions.data[0]} />;
  }

  if (listExecutions.isError) {
    return 'Error here...';
  }

  if (listExecutions.isLoading) {
    return 'Loading here...';
  }

  return 'unknonw state...';
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(4),
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

function EditExecutionForm({ execution }) {
  const classes = useStyles();
  const ssrRouter = useSsrRouter();
  const updateExecution = useUpdateExecution(execution.id);

  const formik = useFormik({
    initialValues: {
      reviewComment: execution.reviewComment,
    },
    initialTouched: {
      reviewComment: false,
    },
    onSubmit: async (values) => {
      try {
        await updateExecution.mutateAsync({
          reviewComment: values.reviewComment,
        });
        ssrRouter.goToViewExecutionPage(execution.id);
      } catch (err) {
        console.warn('failed to edit execution', err);
        if (err && err.message) {
          notifyError('failed to edit execution: ' + err.message);
        }
      }
    },
  });

  const handleCancel = () => {
    ssrRouter.goToViewExecutionPage(execution.id);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <InfoTable
          data={[
            {
              name: 'Review Comment',
              body: (
                <TextField
                  fullWidth
                  id="reviewComment"
                  name="reviewComment"
                  value={formik.values.reviewComment}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reviewComment &&
                    Boolean(formik.errors.reviewComment)
                  }
                  helperText={
                    formik.touched.reviewComment && formik.errors.reviewComment
                  }
                />
              ),
            },
          ]}
        />
        <div className={classes.buttonGroup}>
          <ButtonGroup color="primary" variant="contained" size="medium">
            <Button color="primary" variant="contained" type="submit">
              UPDATE
            </Button>

            <Button color="primary" variant="contained" onClick={handleCancel}>
              CANCEL
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function ViewExecution({ executionId }) {
  const executions = useListSsrExecution({ executionIds: [executionId] });
  const rows = useListSsrRow(executionId);

  const ssrRouter = useSsrRouter();
  const classes = useStyles2();
  const reviewRow = useReviewRow(executionId);
  const clearReviews = useClearReview(executionId);
  const generateReviewedFile = useGenerateReviewedFile(executionId);
  const uploadReviewedFile = useUploadReviewedFile(executionId);

  const [reviewChanges, setReviewChanges] = useState({});

  if (executions.data && rows.data) {
    const myExecution = executions.data.find((ex) => ex.id === executionId);

    const tableColumns = [
      '#',
      ...myExecution.headers,
      'current review',
      'new review',
    ];

    const tableRows = rows.data.map((row) => {
      const handleReviewUpdate = (event) => {
        setReviewChanges({
          ...reviewChanges,
          [row.id]: event.target.value,
        });
      };

      return [
        row?.rowNumber ?? '',
        ...row.recordsList,
        row?.reviewComment ?? '',
        <TextField
          key="new-review"
          value={reviewChanges[row.id] ?? ''}
          onChange={handleReviewUpdate}
        />,
      ];
    });

    const handleClearReviews = () => {
      clearReviews.mutate({ executionId });
      setReviewChanges({});
    };

    const handleSaveComments = () => {
      const reviews = Object.entries(reviewChanges).map(([rowId, comment]) => {
        return {
          rowId,
          comment,
        };
      });

      reviewRow.mutate({ reviews });
      setReviewChanges({});
    };

    const handleGenerateReviewFile = async () => {
      try {
        await generateReviewedFile.mutateAsync('reviewed.csv');
      } catch (err) {
        console.warn('failed to generate review file', err);
        if (err && err.message) {
          notifyError('failed to generate review file: ' + err.message);
        }
      }
    };

    const handleSendToEgnyte = async () => {
      try {
        await uploadReviewedFile.mutateAsync();
      } catch (err) {
        console.warn('failed to send to egnyte', err);
        if (err && err.message) {
          notifyError('failed to send to egnyte: ' + err.message);
        }
      }
    };

    const handleViewConfig = () => {
      ssrRouter.goToViewConfigPage(myExecution.reportId, myExecution.configId);
    };

    const handleEditExecution = () => {
      ssrRouter.goToEditExecutionPage(executionId);
    };

    return (
      <div className={classes.root}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveComments}
        >
          Save Comments
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClearReviews}
        >
          Clear All Comments
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenerateReviewFile}
        >
          Generate Review File
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSendToEgnyte}
        >
          Send to Egnyte
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleEditExecution}
        >
          Edit
        </Button>

        <InfoTable
          data={[
            {
              name: 'Config',
              body: (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleViewConfig}
                >
                  View Config
                </Button>
              ),
            },
            {
              name: 'Row Count',
              body: myExecution.rowCount,
            },
            {
              name: 'Review Count',
              body: myExecution.reviewCount,
            },
            {
              name: 'Review Comment',
              body: myExecution.reviewComment,
            },
          ]}
        />
        <DataTable
          title={''}
          data={tableRows}
          columns={tableColumns}
          rowsPerPage={100}
        />
      </div>
    );
  }

  return <div>Loading in progress...</div>;
}
