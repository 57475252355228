/*ReactJS*/
import React, { useState, useContext } from 'react';

/*NPM Packages*/
import { useForm } from 'react-hook-form';

/*Service*/
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import { notifySuccess } from 'components/Notification/Notification';

/*Material UI Table Components*/
import { Box, TextField, IconButton, Tooltip } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import SaveIcon from '@material-ui/icons/Save';

/*Custom components*/
import CsvDownload from '../../../components/Table/CsvDownload';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

export default function SummaryTable({ params }) {
  const { googleAccount } = useUserState();
  const { tableSettingsServiceClient: tableSettings } = useContext(
    ServiceContext
  );
  const classes = useStyles();

  const [showFilter, setShowFilter] = useState(true);
  const [openCsvDownload, setOpenCsvDownload] = useState(false);
  const [tableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const currDate = new Date().toISOString().slice(0, 10);

  const { register, handleSubmit } = useForm({
    defaultValues: {},
  });
  const onSubmit = (data) => console.log(data);

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Price');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
  };

  const columns = [
    {
      name: 'contracts',
      label: 'Contracts',
    },
    {
      name: 'source',
      label: 'Source',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'amount',
      label: 'Amount',
    },
    {
      name: 'qty',
      label: 'Qty',
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
    },
    {
      name: 'mpid',
      label: 'MPID',
    },
  ];

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Cusip"
                  type="text"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('cusip')}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Symbol"
                  type="text"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('symbol')}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="MPID"
                  type="text"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('mpid')}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="Trade Date"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={currDate}
                  {...register('trade-date')}
                />
              </div>
            </div>
          </form>
        )}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSubmit(onSubmit)}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {openCsvDownload && (
          <CsvDownload
            dataKey="list"
            breaks={false}
            open={openCsvDownload}
            onClose={() => {
              setOpenCsvDownload(false);
            }}
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable title={'SUMMARY'} columns={columns} options={options} />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
