/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

//Service
import { ListPriceRequest } from '../../../proto/assetpb/price_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Notification/Notification';

/*Material UI Table Components*/
import { Box, TextField, Tooltip, IconButton } from '@material-ui/core';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PriceTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    priceServiceClient: price,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListPriceRequest();
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    price.listPrice(list, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().pricesList.map((data) => ({
        systemDate: data.systemDate
          ? formatPbDate(data.systemDate)
          : data.systemDate,
        originalCusip: data.originalCusip,
        symbol: data.symbol,
        cusip: data.cusip,
        closingPrice: formatCurrency(data.closingPrice),
        prevPrice: formatCurrency(data.prevPrice),
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Price');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].closingPrice.replace('$', '');
          const f = rows[dataIndex].closingPrice;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'prevPrice',
      label: 'Prev Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].prevPrice.replace('$', '');
          const f = rows[dataIndex].prevPrice;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
  ];

  const fileName =
    'Price_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [5, 2, 4, 1, 0, 3],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Price');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Price');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [cusipValue, setCusipValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      systemDate: query.get('systemDate'),
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
    };

    if (data.cusip) {
      setCusipValue(data.cusip);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }

    setCurrentPath(path);

    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Cusip"
                  type="text"
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Price'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
/*=========================================================================================
 End Price Table
===========================================================================================*/
