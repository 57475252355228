/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

//Service
import { ListStatementRequest } from '../../../proto/reportpb/statement_grpc_web_pb';
import { downloadMonthlyStatement } from '../../../services/StatementService';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Notification/Notification';

/*Material UI Table Components*/
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  IconButton,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment-timezone';

/*Icons*/
import {
  GetAppRounded as DownloadIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import SearchButton from '../../../components/Button/Search';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

export default function MonthlyStatementTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    statementServiceClient: statement,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (monthValue ? '&month=' + monthValue : '') +
      (yearValue ? '&year=' + yearValue : '') +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListStatementRequest();
    list.setMonth(monthValue.toUpperCase());
    list.setYear(yearValue);
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setMasterAccountNo(masterAccountNoValue);

    statement.listStatement(list, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().statementsList;
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Monthly Statement');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="download"
              onClick={() => {
                downloadMonthlyStatement(
                  statement,
                  rows[dataIndex].month,
                  rows[dataIndex].year,
                  rows[dataIndex].correspondent,
                  rows[dataIndex].accountNo,
                  rows[dataIndex].accountId
                );
              }}
            >
              <DownloadIcon />
            </IconButton>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'month',
      label: 'Month',
    },
    {
      name: 'year',
      label: 'Year',
    },
  ];

  const fileName =
    'MonthlyStatement' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 5, 6, 3, 2, 1, 4],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Monthly Statement');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Monthly Statement');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [monthValue, setMonthValue] = React.useState('');
  const [yearValue, setYearValue] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      month: query.get('month'),
      year: query.get('year'),
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      accountName: query.get('accountName'),
      masterAccountNo: query.get('masterAccountNo'),
    };

    //get previous month
    const monthNames = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];

    let d = new Date();

    if (data.month) {
      setMonthValue(data.month);
    } else if (monthNames[d.getMonth() - 1] === undefined) {
      setMonthValue(monthNames[d.getMonth()]);
    } else {
      setMonthValue(monthNames[d.getMonth() - 1]);
    }
    if (data.year) {
      setYearValue(data.year);
    } else {
      setYearValue(d.getFullYear());
    }
    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }

    setCurrentPath(path);
  }, [params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 30, width: 120 }}
              >
                <InputLabel shrink>Month</InputLabel>
                <Select
                  fullWidth
                  value={monthValue}
                  onChange={(e) => setMonthValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="january">January</MenuItem>
                  <MenuItem value="february">February</MenuItem>
                  <MenuItem value="march">March</MenuItem>
                  <MenuItem value="april">April</MenuItem>
                  <MenuItem value="may">May</MenuItem>
                  <MenuItem value="june">June</MenuItem>
                  <MenuItem value="july">July</MenuItem>
                  <MenuItem value="august">August</MenuItem>
                  <MenuItem value="september">September</MenuItem>
                  <MenuItem value="october">October</MenuItem>
                  <MenuItem value="november">November</MenuItem>
                  <MenuItem value="december">December</MenuItem>
                </Select>
              </div>
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 30, width: 120 }}
              >
                <TextField
                  fullWidth
                  label="Year"
                  type="text"
                  value={yearValue}
                  onChange={(e) => setYearValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Monthly Statement'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
