// source: reportpb/profitandloss.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reportpb.ListProfitAndLossRequest', null, global);
goog.exportSymbol('proto.reportpb.ListProfitAndLossResponse', null, global);
goog.exportSymbol('proto.reportpb.ProfitAndLoss', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ProfitAndLoss = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ProfitAndLoss, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ProfitAndLoss.displayName = 'proto.reportpb.ProfitAndLoss';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListProfitAndLossRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListProfitAndLossRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListProfitAndLossRequest.displayName = 'proto.reportpb.ListProfitAndLossRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListProfitAndLossResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListProfitAndLossResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListProfitAndLossResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListProfitAndLossResponse.displayName = 'proto.reportpb.ListProfitAndLossResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ProfitAndLoss.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ProfitAndLoss.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ProfitAndLoss} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ProfitAndLoss.toObject = function(includeInstance, msg) {
  var f, obj = {
plId: jspb.Message.getFieldWithDefault(msg, 1, ""),
contraTransId: jspb.Message.getFieldWithDefault(msg, 2, ""),
plOriginalId: jspb.Message.getFieldWithDefault(msg, 3, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
buyDate: (f = msg.getBuyDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
sellDate: (f = msg.getSellDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
accountId: jspb.Message.getFieldWithDefault(msg, 7, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
originalCusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 10, ""),
entryType: jspb.Message.getFieldWithDefault(msg, 11, ""),
qty: jspb.Message.getFieldWithDefault(msg, 12, ""),
positionQty: jspb.Message.getFieldWithDefault(msg, 13, ""),
price: jspb.Message.getFieldWithDefault(msg, 14, ""),
buyQty: jspb.Message.getFieldWithDefault(msg, 15, ""),
buyPrice: jspb.Message.getFieldWithDefault(msg, 16, ""),
buyNetAmt: jspb.Message.getFieldWithDefault(msg, 17, ""),
sellQty: jspb.Message.getFieldWithDefault(msg, 18, ""),
sellPrice: jspb.Message.getFieldWithDefault(msg, 19, ""),
sellNetAmt: jspb.Message.getFieldWithDefault(msg, 20, ""),
closingPrice: jspb.Message.getFieldWithDefault(msg, 21, ""),
realized: jspb.Message.getFieldWithDefault(msg, 22, ""),
unrealized: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ProfitAndLoss}
 */
proto.reportpb.ProfitAndLoss.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ProfitAndLoss;
  return proto.reportpb.ProfitAndLoss.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ProfitAndLoss} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ProfitAndLoss}
 */
proto.reportpb.ProfitAndLoss.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraTransId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlOriginalId(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setBuyDate(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSellDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionQty(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyQty(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyPrice(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyNetAmt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellQty(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellPrice(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellNetAmt(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealized(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrealized(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ProfitAndLoss.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ProfitAndLoss.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ProfitAndLoss} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ProfitAndLoss.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContraTransId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlOriginalId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getBuyDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSellDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPositionQty();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBuyQty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBuyPrice();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBuyNetAmt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSellQty();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSellPrice();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSellNetAmt();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRealized();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getUnrealized();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string pl_id = 1;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getPlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setPlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contra_trans_id = 2;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getContraTransId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setContraTransId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pl_original_id = 3;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getPlOriginalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setPlOriginalId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.type.Date system_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ProfitAndLoss.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
*/
proto.reportpb.ProfitAndLoss.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ProfitAndLoss.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date buy_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ProfitAndLoss.prototype.getBuyDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
*/
proto.reportpb.ProfitAndLoss.prototype.setBuyDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.clearBuyDate = function() {
  return this.setBuyDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ProfitAndLoss.prototype.hasBuyDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.Date sell_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ProfitAndLoss.prototype.getSellDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
*/
proto.reportpb.ProfitAndLoss.prototype.setSellDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.clearSellDate = function() {
  return this.setSellDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ProfitAndLoss.prototype.hasSellDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string account_id = 7;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string account_no = 8;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string original_cusip = 9;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string symbol = 10;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string entry_type = 11;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string qty = 12;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string position_qty = 13;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getPositionQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setPositionQty = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string price = 14;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string buy_qty = 15;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getBuyQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setBuyQty = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string buy_price = 16;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getBuyPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setBuyPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string buy_net_amt = 17;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getBuyNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setBuyNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string sell_qty = 18;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getSellQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setSellQty = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string sell_price = 19;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getSellPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setSellPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string sell_net_amt = 20;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getSellNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setSellNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string closing_price = 21;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string realized = 22;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getRealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setRealized = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string unrealized = 23;
 * @return {string}
 */
proto.reportpb.ProfitAndLoss.prototype.getUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ProfitAndLoss} returns this
 */
proto.reportpb.ProfitAndLoss.prototype.setUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListProfitAndLossRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListProfitAndLossRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListProfitAndLossRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListProfitAndLossRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListProfitAndLossRequest}
 */
proto.reportpb.ListProfitAndLossRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListProfitAndLossRequest;
  return proto.reportpb.ListProfitAndLossRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListProfitAndLossRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListProfitAndLossRequest}
 */
proto.reportpb.ListProfitAndLossRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListProfitAndLossRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListProfitAndLossRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListProfitAndLossRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListProfitAndLossRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListProfitAndLossRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListProfitAndLossRequest} returns this
*/
proto.reportpb.ListProfitAndLossRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListProfitAndLossRequest} returns this
 */
proto.reportpb.ListProfitAndLossRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListProfitAndLossRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListProfitAndLossRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListProfitAndLossRequest} returns this
 */
proto.reportpb.ListProfitAndLossRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.reportpb.ListProfitAndLossRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListProfitAndLossRequest} returns this
 */
proto.reportpb.ListProfitAndLossRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListProfitAndLossResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListProfitAndLossResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListProfitAndLossResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListProfitAndLossResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListProfitAndLossResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
profitandlossList: jspb.Message.toObjectList(msg.getProfitandlossList(),
    proto.reportpb.ProfitAndLoss.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListProfitAndLossResponse}
 */
proto.reportpb.ListProfitAndLossResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListProfitAndLossResponse;
  return proto.reportpb.ListProfitAndLossResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListProfitAndLossResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListProfitAndLossResponse}
 */
proto.reportpb.ListProfitAndLossResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.ProfitAndLoss;
      reader.readMessage(value,proto.reportpb.ProfitAndLoss.deserializeBinaryFromReader);
      msg.addProfitandloss(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListProfitAndLossResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListProfitAndLossResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListProfitAndLossResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListProfitAndLossResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfitandlossList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.ProfitAndLoss.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProfitAndLoss ProfitAndLoss = 1;
 * @return {!Array<!proto.reportpb.ProfitAndLoss>}
 */
proto.reportpb.ListProfitAndLossResponse.prototype.getProfitandlossList = function() {
  return /** @type{!Array<!proto.reportpb.ProfitAndLoss>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.ProfitAndLoss, 1));
};


/**
 * @param {!Array<!proto.reportpb.ProfitAndLoss>} value
 * @return {!proto.reportpb.ListProfitAndLossResponse} returns this
*/
proto.reportpb.ListProfitAndLossResponse.prototype.setProfitandlossList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.ProfitAndLoss=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ProfitAndLoss}
 */
proto.reportpb.ListProfitAndLossResponse.prototype.addProfitandloss = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.ProfitAndLoss, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListProfitAndLossResponse} returns this
 */
proto.reportpb.ListProfitAndLossResponse.prototype.clearProfitandlossList = function() {
  return this.setProfitandlossList([]);
};


goog.object.extend(exports, proto.reportpb);
