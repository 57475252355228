// source: accountpb/client.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var accountpb_accounttype_pb = require('../accountpb/accounttype_pb.js');
goog.object.extend(proto, accountpb_accounttype_pb);
var accountpb_address_pb = require('../accountpb/address_pb.js');
goog.object.extend(proto, accountpb_address_pb);
var accountpb_contactinfo_pb = require('../accountpb/contactinfo_pb.js');
goog.object.extend(proto, accountpb_contactinfo_pb);
var accountpb_owner_pb = require('../accountpb/owner_pb.js');
goog.object.extend(proto, accountpb_owner_pb);
var accountpb_reportsetup_pb = require('../accountpb/reportsetup_pb.js');
goog.object.extend(proto, accountpb_reportsetup_pb);
var accountpb_subaccount_pb = require('../accountpb/subaccount_pb.js');
goog.object.extend(proto, accountpb_subaccount_pb);
var commonpb_note_pb = require('../commonpb/note_pb.js');
goog.object.extend(proto, commonpb_note_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.accountpb.Client', null, global);
goog.exportSymbol('proto.accountpb.ClientDetail', null, global);
goog.exportSymbol('proto.accountpb.ClientDetailFilter', null, global);
goog.exportSymbol('proto.accountpb.CreateClientRequest', null, global);
goog.exportSymbol('proto.accountpb.CreateClientResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteClientRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteClientResponse', null, global);
goog.exportSymbol('proto.accountpb.GetNewClientIdRequest', null, global);
goog.exportSymbol('proto.accountpb.GetNewClientIdResponse', null, global);
goog.exportSymbol('proto.accountpb.ListClientDetailRequest', null, global);
goog.exportSymbol('proto.accountpb.ListClientDetailResponse', null, global);
goog.exportSymbol('proto.accountpb.ListClientRequest', null, global);
goog.exportSymbol('proto.accountpb.ListClientResponse', null, global);
goog.exportSymbol('proto.accountpb.OmnibusConfig', null, global);
goog.exportSymbol('proto.accountpb.ReadClientRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadClientResponse', null, global);
goog.exportSymbol('proto.accountpb.StreamClientRequest', null, global);
goog.exportSymbol('proto.accountpb.UpdateClientRequest', null, global);
goog.exportSymbol('proto.accountpb.UpdateClientResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.Client.displayName = 'proto.accountpb.Client';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.OmnibusConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.OmnibusConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.OmnibusConfig.displayName = 'proto.accountpb.OmnibusConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ClientDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ClientDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ClientDetail.displayName = 'proto.accountpb.ClientDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ClientDetailFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ClientDetailFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ClientDetailFilter.displayName = 'proto.accountpb.ClientDetailFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateClientRequest.displayName = 'proto.accountpb.CreateClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateClientResponse.displayName = 'proto.accountpb.CreateClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateClientRequest.displayName = 'proto.accountpb.UpdateClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateClientResponse.displayName = 'proto.accountpb.UpdateClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadClientRequest.displayName = 'proto.accountpb.ReadClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ReadClientResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ReadClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadClientResponse.displayName = 'proto.accountpb.ReadClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetNewClientIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetNewClientIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetNewClientIdRequest.displayName = 'proto.accountpb.GetNewClientIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetNewClientIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetNewClientIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetNewClientIdResponse.displayName = 'proto.accountpb.GetNewClientIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteClientRequest.displayName = 'proto.accountpb.DeleteClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteClientResponse.displayName = 'proto.accountpb.DeleteClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientRequest.displayName = 'proto.accountpb.ListClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.StreamClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.StreamClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.StreamClientRequest.displayName = 'proto.accountpb.StreamClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListClientResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientResponse.displayName = 'proto.accountpb.ListClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListClientDetailRequest.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListClientDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientDetailRequest.displayName = 'proto.accountpb.ListClientDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListClientDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListClientDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientDetailResponse.displayName = 'proto.accountpb.ListClientDetailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
status: jspb.Message.getFieldWithDefault(msg, 6, ""),
activatedAt: (f = msg.getActivatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
disabledAt: (f = msg.getDisabledAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 9, ""),
broker: jspb.Message.getFieldWithDefault(msg, 10, ""),
capacity: jspb.Message.getFieldWithDefault(msg, 11, ""),
privacyContent: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
dayTrader: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
multiplier: jspb.Message.getFieldWithDefault(msg, 14, 0),
investmentObjectiveCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
investmentObjectiveDate: (f = msg.getInvestmentObjectiveDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
legalEntity: jspb.Message.getFieldWithDefault(msg, 17, ""),
taxCountry: jspb.Message.getFieldWithDefault(msg, 18, ""),
w8w9: jspb.Message.getFieldWithDefault(msg, 19, ""),
w8w9ReceivedDate: (f = msg.getW8w9ReceivedDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
tefra: jspb.Message.getFieldWithDefault(msg, 21, ""),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdBy: jspb.Message.getFieldWithDefault(msg, 23, ""),
isDiscretionary: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
latinAccountName: jspb.Message.getFieldWithDefault(msg, 26, ""),
baseCurrency: jspb.Message.getFieldWithDefault(msg, 27, ""),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
reportedAt: (f = msg.getReportedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
usEquityEnabled: jspb.Message.getFieldWithDefault(msg, 30, 0),
taxReportable: (f = jspb.Message.getBooleanField(msg, 31)) == null ? undefined : f,
unnetted: jspb.Message.getBooleanFieldWithDefault(msg, 32, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.Client}
 */
proto.accountpb.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.Client;
  return proto.accountpb.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.Client}
 */
proto.accountpb.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDisabledAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCapacity(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivacyContent(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDayTrader(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiplier(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentObjectiveCode(value);
      break;
    case 16:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setInvestmentObjectiveDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntity(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setW8w9(value);
      break;
    case 20:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setW8w9ReceivedDate(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setTefra(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDiscretionary(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatinAccountName(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCurrency(value);
      break;
    case 28:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 29:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReportedAt(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsEquityEnabled(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTaxReportable(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnnetted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActivatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDisabledAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCapacity();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPrivacyContent();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDayTrader();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getInvestmentObjectiveCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getInvestmentObjectiveDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getLegalEntity();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getW8w9();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getW8w9ReceivedDate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTefra();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIsDiscretionary();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getLatinAccountName();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getBaseCurrency();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReportedAt();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUsEquityEnabled();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 31));
  if (f != null) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getUnnetted();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.accountpb.Client.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.accountpb.Client.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp activated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Client.prototype.getActivatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setActivatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearActivatedAt = function() {
  return this.setActivatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasActivatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp disabled_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Client.prototype.getDisabledAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setDisabledAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearDisabledAt = function() {
  return this.setDisabledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasDisabledAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string account_designator = 9;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string broker = 10;
 * @return {string}
 */
proto.accountpb.Client.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string capacity = 11;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool privacy_content = 12;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getPrivacyContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setPrivacyContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool day_trader = 13;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getDayTrader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setDayTrader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 multiplier = 14;
 * @return {number}
 */
proto.accountpb.Client.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string investment_objective_code = 15;
 * @return {string}
 */
proto.accountpb.Client.prototype.getInvestmentObjectiveCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setInvestmentObjectiveCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.type.Date investment_objective_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.accountpb.Client.prototype.getInvestmentObjectiveDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setInvestmentObjectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearInvestmentObjectiveDate = function() {
  return this.setInvestmentObjectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasInvestmentObjectiveDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string legal_entity = 17;
 * @return {string}
 */
proto.accountpb.Client.prototype.getLegalEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setLegalEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string tax_country = 18;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string w8w9 = 19;
 * @return {string}
 */
proto.accountpb.Client.prototype.getW8w9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setW8w9 = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.type.Date w8w9_received_date = 20;
 * @return {?proto.google.type.Date}
 */
proto.accountpb.Client.prototype.getW8w9ReceivedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 20));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setW8w9ReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearW8w9ReceivedDate = function() {
  return this.setW8w9ReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasW8w9ReceivedDate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string tefra = 21;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTefra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTefra = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Client.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string created_by = 23;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool is_discretionary = 25;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getIsDiscretionary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setIsDiscretionary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional string latin_account_name = 26;
 * @return {string}
 */
proto.accountpb.Client.prototype.getLatinAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setLatinAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string base_currency = 27;
 * @return {string}
 */
proto.accountpb.Client.prototype.getBaseCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 28;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Client.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 28));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.Timestamp reported_at = 29;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Client.prototype.getReportedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 29));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setReportedAt = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearReportedAt = function() {
  return this.setReportedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasReportedAt = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional int32 us_equity_enabled = 30;
 * @return {number}
 */
proto.accountpb.Client.prototype.getUsEquityEnabled = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setUsEquityEnabled = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional bool tax_reportable = 31;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getTaxReportable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTaxReportable = function(value) {
  return jspb.Message.setField(this, 31, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearTaxReportable = function() {
  return jspb.Message.setField(this, 31, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasTaxReportable = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional bool unnetted = 32;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getUnnetted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setUnnetted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.OmnibusConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.OmnibusConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.OmnibusConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.OmnibusConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
exemptFromWithholding: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
withholdingContraAccountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.OmnibusConfig}
 */
proto.accountpb.OmnibusConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.OmnibusConfig;
  return proto.accountpb.OmnibusConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.OmnibusConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.OmnibusConfig}
 */
proto.accountpb.OmnibusConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExemptFromWithholding(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithholdingContraAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.OmnibusConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.OmnibusConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.OmnibusConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.OmnibusConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExemptFromWithholding();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getWithholdingContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool exempt_from_withholding = 1;
 * @return {boolean}
 */
proto.accountpb.OmnibusConfig.prototype.getExemptFromWithholding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.OmnibusConfig} returns this
 */
proto.accountpb.OmnibusConfig.prototype.setExemptFromWithholding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string withholding_contra_account_no = 2;
 * @return {string}
 */
proto.accountpb.OmnibusConfig.prototype.getWithholdingContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.OmnibusConfig} returns this
 */
proto.accountpb.OmnibusConfig.prototype.setWithholdingContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ClientDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ClientDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ClientDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ClientDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
omnibusConfig: (f = msg.getOmnibusConfig()) && proto.accountpb.OmnibusConfig.toObject(includeInstance, f),
state: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ClientDetail}
 */
proto.accountpb.ClientDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ClientDetail;
  return proto.accountpb.ClientDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ClientDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ClientDetail}
 */
proto.accountpb.ClientDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = new proto.accountpb.OmnibusConfig;
      reader.readMessage(value,proto.accountpb.OmnibusConfig.deserializeBinaryFromReader);
      msg.setOmnibusConfig(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ClientDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ClientDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ClientDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ClientDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getOmnibusConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.accountpb.OmnibusConfig.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.ClientDetail.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.ClientDetail} returns this
*/
proto.accountpb.ClientDetail.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ClientDetail} returns this
 */
proto.accountpb.ClientDetail.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ClientDetail.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OmnibusConfig omnibus_config = 2;
 * @return {?proto.accountpb.OmnibusConfig}
 */
proto.accountpb.ClientDetail.prototype.getOmnibusConfig = function() {
  return /** @type{?proto.accountpb.OmnibusConfig} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.OmnibusConfig, 2));
};


/**
 * @param {?proto.accountpb.OmnibusConfig|undefined} value
 * @return {!proto.accountpb.ClientDetail} returns this
*/
proto.accountpb.ClientDetail.prototype.setOmnibusConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ClientDetail} returns this
 */
proto.accountpb.ClientDetail.prototype.clearOmnibusConfig = function() {
  return this.setOmnibusConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ClientDetail.prototype.hasOmnibusConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.accountpb.ClientDetail.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetail} returns this
 */
proto.accountpb.ClientDetail.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ClientDetailFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ClientDetailFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ClientDetailFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ClientDetailFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
status: jspb.Message.getFieldWithDefault(msg, 6, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 7, ""),
broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
baseCurrency: jspb.Message.getFieldWithDefault(msg, 9, ""),
taxCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
w8w9: jspb.Message.getFieldWithDefault(msg, 11, ""),
legalEntity: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ClientDetailFilter}
 */
proto.accountpb.ClientDetailFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ClientDetailFilter;
  return proto.accountpb.ClientDetailFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ClientDetailFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ClientDetailFilter}
 */
proto.accountpb.ClientDetailFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCurrency(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setW8w9(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ClientDetailFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ClientDetailFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ClientDetailFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ClientDetailFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBaseCurrency();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getW8w9();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLegalEntity();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_designator = 7;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string base_currency = 9;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getBaseCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tax_country = 10;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string w8w9 = 11;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getW8w9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setW8w9 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string legal_entity = 12;
 * @return {string}
 */
proto.accountpb.ClientDetailFilter.prototype.getLegalEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ClientDetailFilter} returns this
 */
proto.accountpb.ClientDetailFilter.prototype.setLegalEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
clearingBroker: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateClientRequest}
 */
proto.accountpb.CreateClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateClientRequest;
  return proto.accountpb.CreateClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateClientRequest}
 */
proto.accountpb.CreateClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClearingBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getClearingBroker();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.CreateClientRequest.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.CreateClientRequest} returns this
*/
proto.accountpb.CreateClientRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateClientRequest} returns this
 */
proto.accountpb.CreateClientRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateClientRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string clearing_broker = 2;
 * @return {string}
 */
proto.accountpb.CreateClientRequest.prototype.getClearingBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.CreateClientRequest} returns this
 */
proto.accountpb.CreateClientRequest.prototype.setClearingBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateClientResponse}
 */
proto.accountpb.CreateClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateClientResponse;
  return proto.accountpb.CreateClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateClientResponse}
 */
proto.accountpb.CreateClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.CreateClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.CreateClientResponse} returns this
*/
proto.accountpb.CreateClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateClientResponse} returns this
 */
proto.accountpb.CreateClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
clearingBroker: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateClientRequest}
 */
proto.accountpb.UpdateClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateClientRequest;
  return proto.accountpb.UpdateClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateClientRequest}
 */
proto.accountpb.UpdateClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClearingBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getClearingBroker();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.UpdateClientRequest.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.UpdateClientRequest} returns this
*/
proto.accountpb.UpdateClientRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateClientRequest} returns this
 */
proto.accountpb.UpdateClientRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateClientRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string clearing_broker = 2;
 * @return {string}
 */
proto.accountpb.UpdateClientRequest.prototype.getClearingBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.UpdateClientRequest} returns this
 */
proto.accountpb.UpdateClientRequest.prototype.setClearingBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
clearingBroker: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateClientResponse}
 */
proto.accountpb.UpdateClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateClientResponse;
  return proto.accountpb.UpdateClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateClientResponse}
 */
proto.accountpb.UpdateClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClearingBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getClearingBroker();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.UpdateClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.UpdateClientResponse} returns this
*/
proto.accountpb.UpdateClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateClientResponse} returns this
 */
proto.accountpb.UpdateClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string clearing_broker = 2;
 * @return {string}
 */
proto.accountpb.UpdateClientResponse.prototype.getClearingBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.UpdateClientResponse} returns this
 */
proto.accountpb.UpdateClientResponse.prototype.setClearingBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadClientRequest}
 */
proto.accountpb.ReadClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadClientRequest;
  return proto.accountpb.ReadClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadClientRequest}
 */
proto.accountpb.ReadClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.ReadClientRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReadClientRequest} returns this
 */
proto.accountpb.ReadClientRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ReadClientResponse.repeatedFields_ = [2,3,4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
subAccountsList: jspb.Message.toObjectList(msg.getSubAccountsList(),
    accountpb_subaccount_pb.SubAccount.toObject, includeInstance),
addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    accountpb_address_pb.Address.toObject, includeInstance),
ownersList: jspb.Message.toObjectList(msg.getOwnersList(),
    accountpb_owner_pb.Owner.toObject, includeInstance),
accountTypesList: jspb.Message.toObjectList(msg.getAccountTypesList(),
    accountpb_accounttype_pb.AccountType.toObject, includeInstance),
notesList: jspb.Message.toObjectList(msg.getNotesList(),
    commonpb_note_pb.Note.toObject, includeInstance),
contactInfosList: jspb.Message.toObjectList(msg.getContactInfosList(),
    accountpb_contactinfo_pb.ContactInfo.toObject, includeInstance),
reportSetupsList: jspb.Message.toObjectList(msg.getReportSetupsList(),
    accountpb_reportsetup_pb.ReportSetup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadClientResponse}
 */
proto.accountpb.ReadClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadClientResponse;
  return proto.accountpb.ReadClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadClientResponse}
 */
proto.accountpb.ReadClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = new accountpb_subaccount_pb.SubAccount;
      reader.readMessage(value,accountpb_subaccount_pb.SubAccount.deserializeBinaryFromReader);
      msg.addSubAccounts(value);
      break;
    case 3:
      var value = new accountpb_address_pb.Address;
      reader.readMessage(value,accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 4:
      var value = new accountpb_owner_pb.Owner;
      reader.readMessage(value,accountpb_owner_pb.Owner.deserializeBinaryFromReader);
      msg.addOwners(value);
      break;
    case 5:
      var value = new accountpb_accounttype_pb.AccountType;
      reader.readMessage(value,accountpb_accounttype_pb.AccountType.deserializeBinaryFromReader);
      msg.addAccountTypes(value);
      break;
    case 6:
      var value = new commonpb_note_pb.Note;
      reader.readMessage(value,commonpb_note_pb.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 7:
      var value = new accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.addContactInfos(value);
      break;
    case 8:
      var value = new accountpb_reportsetup_pb.ReportSetup;
      reader.readMessage(value,accountpb_reportsetup_pb.ReportSetup.deserializeBinaryFromReader);
      msg.addReportSetups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getSubAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      accountpb_subaccount_pb.SubAccount.serializeBinaryToWriter
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      accountpb_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getAccountTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      accountpb_accounttype_pb.AccountType.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      commonpb_note_pb.Note.serializeBinaryToWriter
    );
  }
  f = message.getContactInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getReportSetupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      accountpb_reportsetup_pb.ReportSetup.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.ReadClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SubAccount sub_accounts = 2;
 * @return {!Array<!proto.accountpb.SubAccount>}
 */
proto.accountpb.ReadClientResponse.prototype.getSubAccountsList = function() {
  return /** @type{!Array<!proto.accountpb.SubAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, accountpb_subaccount_pb.SubAccount, 2));
};


/**
 * @param {!Array<!proto.accountpb.SubAccount>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setSubAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.accountpb.SubAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.SubAccount}
 */
proto.accountpb.ReadClientResponse.prototype.addSubAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.accountpb.SubAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearSubAccountsList = function() {
  return this.setSubAccountsList([]);
};


/**
 * repeated Address addresses = 3;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.accountpb.ReadClientResponse.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, accountpb_address_pb.Address, 3));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.ReadClientResponse.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * repeated Owner owners = 4;
 * @return {!Array<!proto.accountpb.Owner>}
 */
proto.accountpb.ReadClientResponse.prototype.getOwnersList = function() {
  return /** @type{!Array<!proto.accountpb.Owner>} */ (
    jspb.Message.getRepeatedWrapperField(this, accountpb_owner_pb.Owner, 4));
};


/**
 * @param {!Array<!proto.accountpb.Owner>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.accountpb.Owner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.ReadClientResponse.prototype.addOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.accountpb.Owner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearOwnersList = function() {
  return this.setOwnersList([]);
};


/**
 * repeated AccountType account_types = 5;
 * @return {!Array<!proto.accountpb.AccountType>}
 */
proto.accountpb.ReadClientResponse.prototype.getAccountTypesList = function() {
  return /** @type{!Array<!proto.accountpb.AccountType>} */ (
    jspb.Message.getRepeatedWrapperField(this, accountpb_accounttype_pb.AccountType, 5));
};


/**
 * @param {!Array<!proto.accountpb.AccountType>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setAccountTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.accountpb.AccountType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.AccountType}
 */
proto.accountpb.ReadClientResponse.prototype.addAccountTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.accountpb.AccountType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearAccountTypesList = function() {
  return this.setAccountTypesList([]);
};


/**
 * repeated commonpb.Note notes = 6;
 * @return {!Array<!proto.commonpb.Note>}
 */
proto.accountpb.ReadClientResponse.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.commonpb.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, commonpb_note_pb.Note, 6));
};


/**
 * @param {!Array<!proto.commonpb.Note>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.commonpb.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commonpb.Note}
 */
proto.accountpb.ReadClientResponse.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.commonpb.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * repeated ContactInfo contact_infos = 7;
 * @return {!Array<!proto.accountpb.ContactInfo>}
 */
proto.accountpb.ReadClientResponse.prototype.getContactInfosList = function() {
  return /** @type{!Array<!proto.accountpb.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, accountpb_contactinfo_pb.ContactInfo, 7));
};


/**
 * @param {!Array<!proto.accountpb.ContactInfo>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setContactInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.accountpb.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ContactInfo}
 */
proto.accountpb.ReadClientResponse.prototype.addContactInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.accountpb.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearContactInfosList = function() {
  return this.setContactInfosList([]);
};


/**
 * repeated ReportSetup report_setups = 8;
 * @return {!Array<!proto.accountpb.ReportSetup>}
 */
proto.accountpb.ReadClientResponse.prototype.getReportSetupsList = function() {
  return /** @type{!Array<!proto.accountpb.ReportSetup>} */ (
    jspb.Message.getRepeatedWrapperField(this, accountpb_reportsetup_pb.ReportSetup, 8));
};


/**
 * @param {!Array<!proto.accountpb.ReportSetup>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setReportSetupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.accountpb.ReportSetup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ReportSetup}
 */
proto.accountpb.ReadClientResponse.prototype.addReportSetups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.accountpb.ReportSetup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearReportSetupsList = function() {
  return this.setReportSetupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetNewClientIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetNewClientIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetNewClientIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetNewClientIdRequest}
 */
proto.accountpb.GetNewClientIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetNewClientIdRequest;
  return proto.accountpb.GetNewClientIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetNewClientIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetNewClientIdRequest}
 */
proto.accountpb.GetNewClientIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetNewClientIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetNewClientIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetNewClientIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetNewClientIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetNewClientIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetNewClientIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetNewClientIdResponse}
 */
proto.accountpb.GetNewClientIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetNewClientIdResponse;
  return proto.accountpb.GetNewClientIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetNewClientIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetNewClientIdResponse}
 */
proto.accountpb.GetNewClientIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetNewClientIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetNewClientIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetNewClientIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.GetNewClientIdResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GetNewClientIdResponse} returns this
 */
proto.accountpb.GetNewClientIdResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteClientRequest}
 */
proto.accountpb.DeleteClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteClientRequest;
  return proto.accountpb.DeleteClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteClientRequest}
 */
proto.accountpb.DeleteClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.DeleteClientRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteClientRequest} returns this
 */
proto.accountpb.DeleteClientRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteClientResponse}
 */
proto.accountpb.DeleteClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteClientResponse;
  return proto.accountpb.DeleteClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteClientResponse}
 */
proto.accountpb.DeleteClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.DeleteClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.DeleteClientResponse} returns this
*/
proto.accountpb.DeleteClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.DeleteClientResponse} returns this
 */
proto.accountpb.DeleteClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.DeleteClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
status: jspb.Message.getFieldWithDefault(msg, 5, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 6, ""),
broker: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientRequest}
 */
proto.accountpb.ListClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientRequest;
  return proto.accountpb.ListClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientRequest}
 */
proto.accountpb.ListClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_designator = 6;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.StreamClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.StreamClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.StreamClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.StreamClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
status: jspb.Message.getFieldWithDefault(msg, 5, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 6, ""),
broker: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.StreamClientRequest}
 */
proto.accountpb.StreamClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.StreamClientRequest;
  return proto.accountpb.StreamClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.StreamClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.StreamClientRequest}
 */
proto.accountpb.StreamClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.StreamClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.StreamClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.StreamClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.StreamClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_designator = 6;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.accountpb.StreamClientRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.StreamClientRequest} returns this
 */
proto.accountpb.StreamClientRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListClientResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.accountpb.Client.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientResponse}
 */
proto.accountpb.ListClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientResponse;
  return proto.accountpb.ListClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientResponse}
 */
proto.accountpb.ListClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Client clients = 1;
 * @return {!Array<!proto.accountpb.Client>}
 */
proto.accountpb.ListClientResponse.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.accountpb.Client>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {!Array<!proto.accountpb.Client>} value
 * @return {!proto.accountpb.ListClientResponse} returns this
*/
proto.accountpb.ListClientResponse.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Client=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Client}
 */
proto.accountpb.ListClientResponse.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Client, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListClientResponse} returns this
 */
proto.accountpb.ListClientResponse.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListClientDetailRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.accountpb.ClientDetailFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientDetailRequest}
 */
proto.accountpb.ListClientDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientDetailRequest;
  return proto.accountpb.ListClientDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientDetailRequest}
 */
proto.accountpb.ListClientDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.ClientDetailFilter;
      reader.readMessage(value,proto.accountpb.ClientDetailFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.ClientDetailFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClientDetailFilter filters = 1;
 * @return {!Array<!proto.accountpb.ClientDetailFilter>}
 */
proto.accountpb.ListClientDetailRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.accountpb.ClientDetailFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.ClientDetailFilter, 1));
};


/**
 * @param {!Array<!proto.accountpb.ClientDetailFilter>} value
 * @return {!proto.accountpb.ListClientDetailRequest} returns this
*/
proto.accountpb.ListClientDetailRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.ClientDetailFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ClientDetailFilter}
 */
proto.accountpb.ListClientDetailRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.ClientDetailFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListClientDetailRequest} returns this
 */
proto.accountpb.ListClientDetailRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListClientDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
clientDetailsList: jspb.Message.toObjectList(msg.getClientDetailsList(),
    proto.accountpb.ClientDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientDetailResponse}
 */
proto.accountpb.ListClientDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientDetailResponse;
  return proto.accountpb.ListClientDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientDetailResponse}
 */
proto.accountpb.ListClientDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.ClientDetail;
      reader.readMessage(value,proto.accountpb.ClientDetail.deserializeBinaryFromReader);
      msg.addClientDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.ClientDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClientDetail client_details = 1;
 * @return {!Array<!proto.accountpb.ClientDetail>}
 */
proto.accountpb.ListClientDetailResponse.prototype.getClientDetailsList = function() {
  return /** @type{!Array<!proto.accountpb.ClientDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.ClientDetail, 1));
};


/**
 * @param {!Array<!proto.accountpb.ClientDetail>} value
 * @return {!proto.accountpb.ListClientDetailResponse} returns this
*/
proto.accountpb.ListClientDetailResponse.prototype.setClientDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.ClientDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ClientDetail}
 */
proto.accountpb.ListClientDetailResponse.prototype.addClientDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.ClientDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListClientDetailResponse} returns this
 */
proto.accountpb.ListClientDetailResponse.prototype.clearClientDetailsList = function() {
  return this.setClientDetailsList([]);
};


goog.object.extend(exports, proto.accountpb);
