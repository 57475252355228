/*ReactJS*/
import React, { useEffect, useContext } from 'react';

/*Material UI Components*/
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

/*Service*/
import { ListBankAddressRequest } from '../../proto/bankpb/address_grpc_web_pb';
import { ServiceContext } from 'context/ServiceContext';

/*Material UI Icons*/
import { AddCircle as AddCircleIcon } from '@material-ui/icons';

export default function BankAddressSelect(props) {
  const [marginValue, setMarginValue] = React.useState('-55px');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loadingInit = open && options.length === 0;
  const [loading, setLoading] = React.useState(loadingInit);
  const { bankAddressServiceClient: bankAddress } = useContext(ServiceContext);

  const bankAddressOnChange = async (key) => {
    setLoading(true);

    let listReq = new ListBankAddressRequest();

    await bankAddress.listBankAddress(listReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        return;
      } else {
        let data = res.toObject().bankAddressesList;
        setOptions(data);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }

    if (props.disabled) {
      setMarginValue('-30px');
    } else {
      setMarginValue('-55px');
    }
  }, [open, props.disabled]);

  const selectedBankAddress = props.list
    .filter(function(v) {
      return v.bankAddressId === props.selectedvalue ? v : '';
    })
    .map(function(bankAddress) {
      return bankAddress;
    });

  if (props.list.length !== 0) {
    return (
      <Autocomplete
        autoHighlight={true}
        autoComplete={true}
        autoSelect={true}
        clearOnEscape={true}
        id={props.id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={props.disabled}
        getOptionSelected={(option, value) =>
          option.bankAddressId === value.bankAddressId
        }
        getOptionLabel={(option) =>
          option.address +
          ' ' +
          option.city +
          ' ' +
          option.zipCode +
          ', ' +
          option.state
        }
        options={options}
        loading={loading}
        onChange={props.setNewValue}
        defaultValue={props.selectedvalue ? selectedBankAddress[0] : null}
        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required}
            disabled={props.disabled}
            onChange={(ev) => {
              if (ev.target.value !== '' || ev.target.value !== null) {
                bankAddressOnChange(ev.target.value);
              }
            }}
            onClick={(ev) => {
              if (ev.target.value !== '' || ev.target.value !== null) {
                bankAddressOnChange(ev.target.value);
              }
            }}
            label={props.label}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment
                  style={{ marginRight: marginValue }}
                  position="end"
                >
                  <IconButton
                    aria-label="add bank address"
                    disabled={props.disabled}
                    onClick={props.openmodal}
                  >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    return (
      <TextField
        label="Bank Address"
        required
        disabled={true}
        fullWidth={true}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={props.disabled}
                aria-label="add bank address"
                onClick={props.openmodal}
              >
                <AddCircleIcon color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
