import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createSelector } from 'reselect'; //memoization
import { LazyLoadRequest } from '../../proto/commonpb/list_grpc_web_pb';
import { ServiceContext } from 'context/ServiceContext';
import { stringToProtoDate } from 'services/ConvertService';

//memoization -  cached result when the same inputs occur again
const lazySecurity = createSelector(
  (service, selectedSymbol, value, type, date) =>
    (async () =>
      await lazySymbolPromise(service, selectedSymbol, value, type, date))(),
  (returnValue) => returnValue
);

async function lazySymbolPromise(service, selectedSymbol, value, type, date) {
  return new Promise((resolve, reject) => {
    let req = new LazyLoadRequest();
    req.setLimit(100);
    req.setKey(value);
    req.setDate(stringToProtoDate(date));

    service.lazyLoadSecurity(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        var data = response
          .toObject()
          .securitiesList.filter((v) => {
            if (selectedSymbol) {
              return v.symbol === selectedSymbol && v[type];
            }
            return v[type];
          })
          .map((v) => v[type]);

        resolve([...new Set(data)]);
      }
    });
  });
}

export default function SelectSymbol(props) {
  const { listAccountServiceClient: service } = useContext(ServiceContext);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value);
      return;
    }

    if (options.find((o) => o === value)) {
      setPropsValue(value);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setInputValue(value);
    setPropsValue(value);
    getOptions(value);
  };

  //get list from backend
  const getOptions = async (
    value,
    selectedSymbol,
    type,
    date,
    editInputValue
  ) => {
    const list = await lazySecurity(service, selectedSymbol, value, type, date);

    setOptions(list.filter((v) => v));
    //editInputValue to setup the visible value of autocomplete for query string
    if (editInputValue && list.includes(value)) {
      setInputValue(props.value);
    }
  };

  React.useEffect(() => {
    if (open) {
      getOptions(props.value, props.selectedSymbol, props.type, props.date);
    } else if (props.value && !options.length && !inputValue) {
      const editInputValue = true;
      getOptions(
        props.value,
        props.selectedSymbol,
        props.type,
        props.date,
        editInputValue
      );
    }
    // eslint-disable-next-line
  }, [open, props.value]);

  return (
    <Autocomplete
      key={props.clear}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? v : '';
        setPropsValue(value);
        setInputValue(value);
      }}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => option}
      inputValue={inputValue}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          label={props.label}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
