/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListAccountManagementPositionDetailRequest } from '../../../proto/reportpb/accountmanagement_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PositionDetailsTable({ systemdate, accountid }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  const { accountManagementServiceClient: accountManagement } = useContext(
    ServiceContext
  );
  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].closingPrice;
          const f = formatCurrency(v);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'sdMarketValue',
      label: 'SD Market Value',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].sdMarketValue;
          const f = formatCurrency(v);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'sdQty',
      label: 'SD Qty',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].sdQty;
          return <div align="right">{v}</div>;
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'tdMarketValue',
      label: 'TD Market Value',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].tdMarketValue;
          const f = formatCurrency(v);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'tdQty',
      label: 'TD Qty',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].tdQty;
          return <div align="right">{v}</div>;
        },
      },
    },
  ];

  // const setVisibles = {};
  // columns.forEach((col) => {
  //   col.options = {
  //     display: col.visibleState[0],
  //   };
  //   setVisibles[col.name] = col.visibleState[1];
  //   return;
  // });

  const options = {
    filterType: 'select',
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    //selectableRowsHeader: false,
    columnOrder: [2, 0, 5, 6, 1, 8, 7, 4, 3],
    selectableRows: 'none',
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  useEffect(() => {
    let list = new ListAccountManagementPositionDetailRequest();

    let gglSystemDate = new google_date.Date();
    let [y, m, d] = moment(new Date(systemdate))
      .format('yyyy-MM-DD')
      .split('-');
    gglSystemDate.setYear(y);
    gglSystemDate.setMonth(m);
    gglSystemDate.setDay(d);
    list.setSystemDate(gglSystemDate);
    list.setAccountId(accountid);

    accountManagement.listAccountManagementPositionDetail(
      list,
      {},
      (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err);
          return;
        }
        setRows(res.toObject().positionDetailsList);
      }
    );
  }, [accountManagement, systemdate, accountid]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={'Position Details'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
