// convert callback style to promise style
//
// The rationale behind this is that grpc-web XxxPromiseClient has
// different implementation from regular XxxClient (callback):
//   - https://github.com/grpc/grpc-web/blob/3956560ad01b4af0a2a5c29c081f5bbd1424e85d/javascript/net/grpc/web/interceptor.js#L4
// PromiseClient uses "UnaryInterceptor"
// CallbackClient uses "StreamInterceptor"
//
// We currently only implement "StreamInterceptor" for auth to fill in "Bearer" token.
// If we want to use PromiseClient directly, we need to implement "UnaryInterceptor" to fill in "Bearer" token
//   as well, otherwise, we would get auth failure.
//
// example usage:
//     callbackToPromise(cb => {
//         administratorServiceClient.listAdministrator(new ListAdministratorRequest(), {}, cb)
//     })
export default function callbackToPromise(fn) {
  return new Promise(function(resolve, reject) {
    const cb = (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    };

    fn(cb);
  });
}
