/*ReactJS*/
import React from 'react';

/*Material UI Components*/
import { Box, Typography } from '@material-ui/core';
import { Build } from '@material-ui/icons';

/*Style*/
import useStyles from '../../styles.js';

export default function ClearingSettlement() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Build style={{ marginRight: '1rem' }} />
          <Typography variant="h2" className={classes.textBold}>
            Under Construction
          </Typography>
        </Box>
      </div>
    </React.Fragment>
  );
}
