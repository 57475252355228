import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import useStyles from '../styles';
import { ServiceContext } from 'context/ServiceContext';
import { BatchCancelTransactionRequest } from 'proto/trnspb/transaction_pb';
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

function CancelButton(props) {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={
        props.loading ? (
          <CircularProgress
            style={{ color: '#ffffff', height: 20, width: 20 }}
          />
        ) : null
      }
      onClick={props.onClick}
    >
      {props.loading
        ? `Canceling ${props.numIDs} items...`
        : 'Cancel Transactions'}
    </Button>
  );
}

export default function CancelTransactionForm() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [numIDs, setNumIDs] = useState(0);

  const textRef = useRef();

  const onCancel = () => {
    const s = textRef.current.value;
    const lines = s
      .split('\n')
      .map((o) => o.trim())
      .filter((o) => o !== '');
    if (lines.length === 0) {
      notifyError('empty input');
      return;
    }
    const req = new BatchCancelTransactionRequest();
    req.setTrnsIdsList(lines);
    setLoading(true);
    setNumIDs(lines.length);
    transactionServiceClient.batchCancelTransaction(req, {}, (err, res) => {
      setLoading(false);
      if (err) {
        notifyError(err.message);
        return;
      }
      let cancelCount = 0;
      console.log(res);
      console.log(res.toObject());
      res.toObject().transactionResponsesList.forEach((o) => {
        if (o.status === 'canceled') {
          cancelCount++;
        }
      });
      notifySuccess(`successully canceled ${cancelCount} items`);
    });
  };

  const onClear = () => {
    textRef.current.value = '';
  };

  const { transactionServiceClient } = useContext(ServiceContext);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between">
            <CancelButton
              loading={loading}
              numIDs={numIDs}
              onClick={onCancel}
            ></CancelButton>
            <Button color="secondary" onClick={onClear}>
              Clear
            </Button>
          </Box>
          <TextField
            fullWidth
            multiline={true}
            rows={20}
            label="Transaction IDs:"
            placeholder="f009f937-49b3-494d-81c0-a1f40cb96fed
b98a638f-f404-4407-9e4d-fee6747d37b7
..."
            variant="outlined"
            className={classes.mt20}
            autoFocus
            inputRef={textRef}
          />
        </Box>
      </Grid>
      <Grid item xs={6}></Grid>
    </Grid>
  );
}
