import React from 'react';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, googleAccount: action.loadout };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, googleAccount: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    googleAccount: loadGoogleAccount(),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loadGoogleAccount() {
  const data = localStorage.getItem('google.account');
  if (data) {
    return JSON.parse(data);
  }
  return null;
}

function loginUser(dispatch, googleAccount) {
  if (googleAccount) {
    googleAccount['profileObj'] = { googleId: googleAccount.credential };
    googleAccount['googleId'] = googleAccount.credential;

    dispatch({ type: 'LOGIN_SUCCESS', loadout: googleAccount });
    localStorage.setItem('google.account', JSON.stringify(googleAccount));
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    localStorage.removeItem('google.account');
  }
}

function signOut(dispatch) {
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  localStorage.removeItem('google.account');
}
