/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  Administrator,
  ListAdministratorRequest,
} from '../../../proto/usrpb/administrator_grpc_web_pb';
import { ListUserRoleRequest } from '../../../proto/usrpb/role_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Moment JS*/
import moment from 'moment-timezone';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';

function saveAdministrator(
  administrator,
  rows,
  isAdd,
  closeModal,
  email,
  name,
  role,
  id
) {
  if (!name) {
    notifyError('Name is required.');
    return;
  }

  if (!email) {
    notifyError('Email is required.');
    return;
  }

  if (!role) {
    notifyError('Role is required.');
    return;
  }

  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]*$/i.test(email)) {
    notifyError('Invalid email address.');
    return;
  }

  if (isAdd) {
    let addReq = new Administrator();
    addReq.setEmail(email);
    addReq.setName(name);
    addReq.setRole(role);

    administrator.createAdministrator(addReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      } else {
        let obj = res.toObject().administrator;
        let newAdminitrator = {
          id: obj.id,
          name: obj.name,
          email: obj.email,
          createdAt: obj.createdAt
            ? moment(new Date(obj.createdAt.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--',
          role: obj.role,
        };
        rows.push(newAdminitrator);
        notifySuccess('New Administrator has been added.');
        closeModal();
      }
    });
  } else {
    let req = new Administrator();
    req.setId(id);
    req.setEmail(email);
    req.setName(name);
    req.setRole(role);

    administrator.updateAdministrator(req, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      } else {
        //let obj = res.toObject().administrator;

        console.log(id);
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].id === id) {
            rows[i].name = name;
            rows[i].email = email;
            rows[i].role = role;
            break;
          }
        }

        notifySuccess('Administrator has been updated.');
        closeModal();
      }
    });
  }
}

function deleteAdministrator(
  administrator,
  rows,
  isAdd,
  closeModal,
  email,
  name,
  role,
  id,
  search
) {
  let req = new Administrator();
  req.setId(id);
  req.setEmail(email);
  req.setName(name);
  req.setRole(role);
  administrator.deleteAdministrator(req, {}, (err, res) => {
    if (err) {
      notifyError(err.message);
      return;
    } else {
      notifySuccess('Administrator has been removed.');
      search();
      closeModal();
    }
  });
}

function keyRandomizer() {
  return Math.random()
    .toString(36)
    .substring(7);
}

export default function AdministratorTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    administratorServiceClient: administrator,
    roleServiceClient: userRoleServiceClient,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (nameValue ? '&name=' + nameValue : '') +
      (emailValue ? '&email=' + emailValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListAdministratorRequest();
    list.setName(nameValue);
    list.setEmail(emailValue);

    administrator.listAdministrator(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().administratorsList.map((data) => ({
        id: data.id,
        name: data.name,
        email: data.email,
        createdAt: data.createdAt
          ? moment(new Date(data.createdAt.seconds * 1000)).format(
              'MM/DD/YYYY hh:mm'
            )
          : '--',
        role: data.role,
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Administrator');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="Edit" arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[dataIndex], false)}
                style={{ display: 'none' }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'createdAt',
      label: 'Created At',
    },
    {
      name: 'role',
      label: 'Role',
    },
  ];

  const fileName =
    'Administrator_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2, 3, 4],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Administrator');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Administrator');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [nameValue, setNameValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');
  const [roles, setRoles] = useState([]);
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      name: query.get('name'),
      email: query.get('email'),
    };

    if (data.name) {
      setNameValue(data.name);
    }

    if (data.email) {
      setEmailValue(data.email);
    }

    setCurrentPath(path);
  }, [params]);

  const [modalIdValue, setModalIdValue] = React.useState('');
  const [modalNameValue, setModalNameValue] = React.useState('');
  const [modalEmailValue, setModalEmailValue] = React.useState('');
  const [modalRoleValue, setModalRoleValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    loadRoles();
    if (isAdd) {
      setModalNameValue('');
      setModalEmailValue('');
      setModalRoleValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
      setOpen(true);
    } else {
      setModalIdValue(rowData.id);
      setModalNameValue(rowData.name);
      setModalEmailValue(rowData.email);
      setModalRoleValue(rowData.role);

      setModalAddAction(false);
      setModalTitleValue('Edit');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadRoles = () => {
    let req = new ListUserRoleRequest();

    userRoleServiceClient.listRoles(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        return;
      }

      setRoles(res.toObject().rolesList);
    });
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Name"
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="Email"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          {/* CLD-706: Remove add/edit/delete for Admins & Roles */}
          {/* <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div> */}
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Administrator'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Administrator
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      disabled={!modalAddAction}
                      required
                      label="Name"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalNameValue}
                      onChange={(e) => setModalNameValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      disabled={!modalAddAction}
                      required
                      label="Email"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalEmailValue}
                      onChange={(e) => setModalEmailValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink required>
                      Role
                    </InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalRoleValue}
                      onChange={(e) => {
                        setModalRoleValue(e.target.value);
                      }}
                    >
                      {roles.map(function(item) {
                        return (
                          <MenuItem
                            key={keyRandomizer()}
                            value={item.role || ''}
                          >
                            {item.role}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div className={classes.grdCell1}></div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveAdministrator(
                          administrator,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalEmailValue,
                          modalNameValue,
                          modalRoleValue,
                          modalIdValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        deleteAdministrator(
                          administrator,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalEmailValue,
                          modalNameValue,
                          modalRoleValue,
                          modalIdValue,
                          search
                        );
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
