/**
 * @fileoverview gRPC-Web generated client stub for admpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: admpb/userguide.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.admpb = require('./userguide_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admpb.UserGuideServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.admpb.UserGuideServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admpb.UserGuide,
 *   !proto.admpb.CreateUserGuideResponse>}
 */
const methodDescriptor_UserGuideService_CreateUserGuide = new grpc.web.MethodDescriptor(
  '/admpb.UserGuideService/CreateUserGuide',
  grpc.web.MethodType.UNARY,
  proto.admpb.UserGuide,
  proto.admpb.CreateUserGuideResponse,
  /**
   * @param {!proto.admpb.UserGuide} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admpb.CreateUserGuideResponse.deserializeBinary
);


/**
 * @param {!proto.admpb.UserGuide} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admpb.CreateUserGuideResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admpb.CreateUserGuideResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admpb.UserGuideServiceClient.prototype.createUserGuide =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admpb.UserGuideService/CreateUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_CreateUserGuide,
      callback);
};


/**
 * @param {!proto.admpb.UserGuide} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admpb.CreateUserGuideResponse>}
 *     Promise that resolves to the response
 */
proto.admpb.UserGuideServicePromiseClient.prototype.createUserGuide =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admpb.UserGuideService/CreateUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_CreateUserGuide);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admpb.UpdateUserGuideRequest,
 *   !proto.admpb.UpdateUserGuideResponse>}
 */
const methodDescriptor_UserGuideService_UpdateUserGuide = new grpc.web.MethodDescriptor(
  '/admpb.UserGuideService/UpdateUserGuide',
  grpc.web.MethodType.UNARY,
  proto.admpb.UpdateUserGuideRequest,
  proto.admpb.UpdateUserGuideResponse,
  /**
   * @param {!proto.admpb.UpdateUserGuideRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admpb.UpdateUserGuideResponse.deserializeBinary
);


/**
 * @param {!proto.admpb.UpdateUserGuideRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admpb.UpdateUserGuideResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admpb.UpdateUserGuideResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admpb.UserGuideServiceClient.prototype.updateUserGuide =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admpb.UserGuideService/UpdateUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_UpdateUserGuide,
      callback);
};


/**
 * @param {!proto.admpb.UpdateUserGuideRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admpb.UpdateUserGuideResponse>}
 *     Promise that resolves to the response
 */
proto.admpb.UserGuideServicePromiseClient.prototype.updateUserGuide =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admpb.UserGuideService/UpdateUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_UpdateUserGuide);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admpb.ReadUserGuideRequest,
 *   !proto.admpb.ReadUserGuideResponse>}
 */
const methodDescriptor_UserGuideService_ReadUserGuide = new grpc.web.MethodDescriptor(
  '/admpb.UserGuideService/ReadUserGuide',
  grpc.web.MethodType.UNARY,
  proto.admpb.ReadUserGuideRequest,
  proto.admpb.ReadUserGuideResponse,
  /**
   * @param {!proto.admpb.ReadUserGuideRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admpb.ReadUserGuideResponse.deserializeBinary
);


/**
 * @param {!proto.admpb.ReadUserGuideRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admpb.ReadUserGuideResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admpb.ReadUserGuideResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admpb.UserGuideServiceClient.prototype.readUserGuide =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admpb.UserGuideService/ReadUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_ReadUserGuide,
      callback);
};


/**
 * @param {!proto.admpb.ReadUserGuideRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admpb.ReadUserGuideResponse>}
 *     Promise that resolves to the response
 */
proto.admpb.UserGuideServicePromiseClient.prototype.readUserGuide =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admpb.UserGuideService/ReadUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_ReadUserGuide);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admpb.DeleteUserGuideRequest,
 *   !proto.admpb.DeleteUserGuideResponse>}
 */
const methodDescriptor_UserGuideService_DeleteUserGuide = new grpc.web.MethodDescriptor(
  '/admpb.UserGuideService/DeleteUserGuide',
  grpc.web.MethodType.UNARY,
  proto.admpb.DeleteUserGuideRequest,
  proto.admpb.DeleteUserGuideResponse,
  /**
   * @param {!proto.admpb.DeleteUserGuideRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admpb.DeleteUserGuideResponse.deserializeBinary
);


/**
 * @param {!proto.admpb.DeleteUserGuideRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admpb.DeleteUserGuideResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admpb.DeleteUserGuideResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admpb.UserGuideServiceClient.prototype.deleteUserGuide =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admpb.UserGuideService/DeleteUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_DeleteUserGuide,
      callback);
};


/**
 * @param {!proto.admpb.DeleteUserGuideRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admpb.DeleteUserGuideResponse>}
 *     Promise that resolves to the response
 */
proto.admpb.UserGuideServicePromiseClient.prototype.deleteUserGuide =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admpb.UserGuideService/DeleteUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_DeleteUserGuide);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.admpb.UserGuide,
 *   !proto.admpb.ListUserGuideResponse>}
 */
const methodDescriptor_UserGuideService_ListUserGuide = new grpc.web.MethodDescriptor(
  '/admpb.UserGuideService/ListUserGuide',
  grpc.web.MethodType.UNARY,
  proto.admpb.UserGuide,
  proto.admpb.ListUserGuideResponse,
  /**
   * @param {!proto.admpb.UserGuide} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.admpb.ListUserGuideResponse.deserializeBinary
);


/**
 * @param {!proto.admpb.UserGuide} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.admpb.ListUserGuideResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.admpb.ListUserGuideResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.admpb.UserGuideServiceClient.prototype.listUserGuide =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/admpb.UserGuideService/ListUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_ListUserGuide,
      callback);
};


/**
 * @param {!proto.admpb.UserGuide} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.admpb.ListUserGuideResponse>}
 *     Promise that resolves to the response
 */
proto.admpb.UserGuideServicePromiseClient.prototype.listUserGuide =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/admpb.UserGuideService/ListUserGuide',
      request,
      metadata || {},
      methodDescriptor_UserGuideService_ListUserGuide);
};


module.exports = proto.admpb;

