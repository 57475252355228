import React from 'react';
import { TableFooter, TableRow, TableCell } from '@material-ui/core';
import useStyles from '../../styles';

export default function ServerSideTableBodyFooter({
  columnOrder,
  columns,
  data,
  additionalCell,
}) {
  const classes = useStyles();

  const getFooterCell = (col, index) => {
    if (col.display !== 'true') return null;

    if (data[col.name] === undefined || data[col.name] === '')
      return <TableCell key={index} />;

    const bgColor = {
      background: data[col.name].includes('-') ? '#f44336' : '#4caf50',
    };
    return (
      <TableCell align="right" key={index} style={{ padding: '12px 16px' }}>
        <b style={bgColor} className={classes.totalBadge}>
          {data[col.name]}
        </b>
      </TableCell>
    );
  };

  return (
    <TableFooter>
      <TableRow>
        {additionalCell ? <TableCell key="action" /> : null}
        {columnOrder.map((order, index) => {
          const col = columns[order];

          return getFooterCell(col, order);
        })}
      </TableRow>
    </TableFooter>
  );
}
