import React from 'react';

import { useFormik } from 'formik';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useAdministrator } from '../Hooks/useAdministrator';
import {
  useCreateReport,
  useListSsrReport,
  useTriggerSsrReport,
  useUpdateReportConfig,
} from '../Hooks/useSsrService';
import QueryEditor from './QueryEditor';
import { ListExecutions } from './Execution';
import { InfoTable } from './Table';
import { ConfigView, ConfigList } from './Config';
import { ListOperationErrors } from './OperationError';
import { useSsrRouter } from '../Hooks/useRouter';
import { notifyError } from 'components/Notification/Notification';

export function UpdateReport({ reportId }) {
  const reportLists = useListSsrReport();

  if (!reportLists.data) {
    return 'fetch data in progress...';
  }

  const reports = reportLists.data.filter((report) => report.id === reportId);

  if (reports.length !== 1) {
    return 'cannot find right reports';
  }

  return <MyUpdateReport report={reports[0]} />;
}

function MyUpdateReport({ report }) {
  const updateReport = useUpdateReportConfig();
  const classes = useStyles2();
  const ssrRouter = useSsrRouter();

  const { data: admin } = useAdministrator();

  const formik = useFormik({
    initialValues: {
      reportName: report.name,
      query: report.query,
      reportOwnerId: report.ownerId,
      reportDescription: report.description,
      requireReview: report.requireReview,
      reportDirectory: report.reportDirectory,
      reviewDirectory: report.reviewDirectory,
      scheduleType: report.scheduleType,
      scheduleMeta: report.scheduleMeta,
      scheduleDescription: report.scheduleDescription,
      conditionalQuery: report.conditionalQuery,
    },
    initialTouched: {
      reportName: false,
      reportDescription: false,
    },
    onSubmit: async (values) => {
      try {
        await updateReport.mutateAsync({ ...values, reportId: report.id });
        ssrRouter.goToViewReportPage(report.id);
      } catch (err) {
        console.warn('failed to update report', err);
        if (err && err.message) {
          notifyError('failed to update report: ' + err.message);
        }
      }
    },
  });

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <InfoTable
          data={[
            {
              name: 'Report Name',
              body: (
                <TextField
                  fullWidth
                  id="reportName"
                  name="reportName"
                  value={formik.values.reportName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reportName &&
                    Boolean(formik.errors.reportName)
                  }
                />
              ),
            },
            {
              name: 'Report Description',
              body: (
                <TextField
                  fullWidth
                  id="reportDescription"
                  name="reportDescription"
                  value={formik.values.reportDescription}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reportDescription &&
                    Boolean(formik.errors.reportDescription)
                  }
                />
              ),
            },
            {
              name: 'Report Owner',
              body: (
                <Autocomplete
                  id="reportOwner"
                  options={admin?.sortedList ?? []}
                  defaultValue={admin?.idMap[formik.values.reportOwnerId]}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  onChange={(event, value) => {
                    formik.setFieldValue('reportOwnerId', value?.id);
                  }}
                />
              ),
            },
            {
              name: 'Report Directory',
              body: (
                <TextField
                  fullWidth
                  id="reportDirectory"
                  name="reportDirectory"
                  value={formik.values.reportDirectory}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reportDirectory &&
                    Boolean(formik.errors.reportDirectory)
                  }
                />
              ),
            },
            {
              name: 'Require Review',
              body: (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.requireReview}
                        onChange={formik.handleChange}
                        name="requireReview"
                      />
                    }
                  />
                </FormGroup>
              ),
            },
            {
              name: 'Review Directory',
              body: (
                <TextField
                  fullWidth
                  id="reviewDirectory"
                  name="reviewDirectory"
                  value={formik.values.reviewDirectory}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reviewDirectory &&
                    Boolean(formik.errors.reviewDirectory)
                  }
                />
              ),
            },
            {
              name: 'Schedule Type',
              body: (
                <Select
                  fullWidth
                  id="scheduleType"
                  name="scheduleType"
                  value={formik.values.scheduleType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scheduleType &&
                    Boolean(formik.errors.scheduleType)
                  }
                >
                  <MenuItem value={''}>N/A</MenuItem>
                  <MenuItem value="cron">CRON</MenuItem>
                  <MenuItem value="event">EVENT</MenuItem>
                </Select>
              ),
            },
            {
              name: 'Schedule Meta',
              body: (
                <TextField
                  fullWidth
                  id="scheduleMeta"
                  name="scheduleMeta"
                  value={formik.values.scheduleMeta}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scheduleMeta &&
                    Boolean(formik.errors.scheduleMeta)
                  }
                />
              ),
            },
            {
              name: 'Schedule Description',
              body: (
                <TextField
                  fullWidth
                  id="scheduleDescription"
                  name="scheduleDescription"
                  value={formik.values.scheduleDescription}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scheduleDescription &&
                    Boolean(formik.errors.scheduleDescription)
                  }
                />
              ),
            },
            {
              name: 'Conditional Query',
              body: (
                <QueryEditor
                  value={formik.values.conditionalQuery}
                  onChange={(value) => {
                    formik.setFieldValue('conditionalQuery', value);
                  }}
                  editable={true}
                  showFormatButton={true}
                  showExecuteButton={true}
                />
              ),
            },
            {
              name: 'Query',
              body: (
                <QueryEditor
                  value={formik.values.query}
                  onChange={(value) => {
                    formik.setFieldValue('query', value);
                  }}
                  editable={true}
                  showFormatButton={true}
                  showExecuteButton={true}
                />
              ),
            },
          ]}
        />

        <Button color="primary" variant="contained" fullWidth type="submit">
          Save Report
        </Button>
      </form>
    </div>
  );
}

const useStyles2 = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(4),
    },
  },
}));

export function CreateReport(props) {
  const createReport = useCreateReport();

  const classes = useStyles2();

  const ssrRouter = useSsrRouter();

  const formik = useFormik({
    initialValues: {
      reportName: '',
      reportOwnerId: '',
      reportDescription: '',
      requireReview: true,
      query: '',
    },
    initialTouched: {
      reportName: false,
      reportDescription: false,
    },
    onSubmit: async (values) => {
      try {
        const result = await createReport.mutateAsync(values);
        ssrRouter.goToViewReportPage(result.report.id);
      } catch (err) {
        console.warn('failed to create report', err);
        if (err && err.message) {
          notifyError('failed to create report: ' + err.message);
        }
      }
    },
  });

  const { data: admin } = useAdministrator();

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <InfoTable
          data={[
            {
              name: 'Report Name',
              body: (
                <TextField
                  fullWidth
                  id="reportName"
                  name="reportName"
                  value={formik.values.reportName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reportName &&
                    Boolean(formik.errors.reportName)
                  }
                />
              ),
            },
            {
              name: 'Report Description',
              body: (
                <TextField
                  fullWidth
                  id="reportDescription"
                  name="reportDescription"
                  value={formik.values.reportDescription}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reportDescription &&
                    Boolean(formik.errors.reportDescription)
                  }
                />
              ),
            },
            {
              name: 'Report Owner',
              body: (
                <Autocomplete
                  id="reportOwner"
                  options={admin?.sortedList ?? []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  onChange={(event, value) => {
                    console.log('autocomplete', event, value, value?.id);
                    formik.setFieldValue('reportOwnerId', value?.id);
                  }}
                />
              ),
            },
            {
              name: 'Report Directory',
              body: (
                <TextField
                  fullWidth
                  id="reportDirectory"
                  name="reportDirectory"
                  value={formik.values.reportDirectory}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reportDirectory &&
                    Boolean(formik.errors.reportDirectory)
                  }
                />
              ),
            },
            {
              name: 'Require Review',
              body: (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.requireReview}
                        onChange={formik.handleChange}
                        name="requireReview"
                      />
                    }
                  />
                </FormGroup>
              ),
            },
            {
              name: 'Review Directory',
              body: (
                <TextField
                  fullWidth
                  id="reviewDirectory"
                  name="reviewDirectory"
                  value={formik.values.reviewDirectory}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reviewDirectory &&
                    Boolean(formik.errors.reviewDirectory)
                  }
                />
              ),
            },
            {
              name: 'Schedule Type',
              body: (
                <Select
                  fullWidth
                  id="scheduleType"
                  name="scheduleType"
                  value={formik.values.scheduleType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scheduleType &&
                    Boolean(formik.errors.scheduleType)
                  }
                >
                  <MenuItem value={''}>N/A</MenuItem>
                  <MenuItem value="cron">CRON</MenuItem>
                  <MenuItem value="event">EVENT</MenuItem>
                </Select>
              ),
            },
            {
              name: 'Schedule Meta',
              body: (
                <TextField
                  fullWidth
                  id="scheduleMeta"
                  name="scheduleMeta"
                  value={formik.values.scheduleMeta}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scheduleMeta &&
                    Boolean(formik.errors.scheduleMeta)
                  }
                  helperText={
                    formik.touched.scheduleMeta && formik.errors.scheduleMeta
                  }
                />
              ),
            },
            {
              name: 'Schedule Description',
              body: (
                <TextField
                  fullWidth
                  id="scheduleDescription"
                  name="scheduleDescription"
                  value={formik.values.scheduleDescription}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scheduleDescription &&
                    Boolean(formik.errors.scheduleDescription)
                  }
                />
              ),
            },
            {
              name: 'Conditional Query',
              body: (
                <QueryEditor
                  value={formik.values.conditionalQuery}
                  onChange={(value) => {
                    formik.setFieldValue('conditionalQuery', value);
                  }}
                  editable={true}
                  showFormatButton={true}
                  showExecuteButton={true}
                />
              ),
            },
            {
              name: 'Query',
              body: (
                <QueryEditor
                  value={formik.values.query}
                  onChange={(value) => {
                    formik.setFieldValue('query', value);
                  }}
                  editable={true}
                  showFormatButton={true}
                  showExecuteButton={true}
                />
              ),
            },
          ]}
        />

        <Button color="primary" variant="contained" fullWidth type="submit">
          Save Report
        </Button>
      </form>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export function ViewReport({ reportId }) {
  const reports = useListSsrReport();
  const classes = useStyles();
  const trigger = useTriggerSsrReport(reportId);
  const ssrRouter = useSsrRouter();

  const handleBackToOverviewButton = () => {
    ssrRouter.goToOverviewPage();
  };

  const handleRunQuery = () => {
    const run = async () => {
      try {
        await trigger.mutateAsync({ reportId });
      } catch (err) {
        console.warn('failed to trigger execution', err);
        if (err && err.message) {
          notifyError('failed to trigger execution: ' + err.message);
        }
      }
    };
    run();
  };

  const handleReportUpdate = () => {
    ssrRouter.goToUpdateReportPage(reportId);
  };

  if (!reports.data) {
    return 'reports data in progress...';
  }

  const myReport = reports.data.find((report) => report.id === reportId);

  if (!myReport) {
    return 'wait report...';
  }

  return (
    <div className={classes.root}>
      <Box display="flex">
        <Button
          color="primary"
          variant="contained"
          onClick={handleBackToOverviewButton}
        >
          Back to overview
        </Button>
      </Box>

      <ConfigView reportId={reportId} configId={myReport.configId} />

      <Box display="flex" flexDirection="row-reverse">
        <Button color="primary" variant="contained" onClick={handleRunQuery}>
          Trigger Execution
        </Button>
      </Box>

      <ListExecutions reportId={reportId} />

      <Box display="flex" flexDirection="row-reverse">
        <Button
          color="primary"
          variant="contained"
          onClick={handleReportUpdate}
        >
          Update Report
        </Button>
      </Box>

      <ConfigList reportId={reportId} />

      <ListOperationErrors reportId={reportId} />
    </div>
  );
}
