/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: reportpb/position.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')

var utilspb_pagination_pb = require('../utilspb/pagination_pb.js')
const proto = {};
proto.reportpb = require('./position_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.ListServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.ListServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListPositionRequest,
 *   !proto.reportpb.ListPositionResponse>}
 */
const methodDescriptor_ListService_ListPosition = new grpc.web.MethodDescriptor(
  '/reportpb.ListService/ListPosition',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListPositionRequest,
  proto.reportpb.ListPositionResponse,
  /**
   * @param {!proto.reportpb.ListPositionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListPositionResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListPositionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListPositionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListPositionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ListServiceClient.prototype.listPosition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.ListService/ListPosition',
      request,
      metadata || {},
      methodDescriptor_ListService_ListPosition,
      callback);
};


/**
 * @param {!proto.reportpb.ListPositionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListPositionResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.ListServicePromiseClient.prototype.listPosition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.ListService/ListPosition',
      request,
      metadata || {},
      methodDescriptor_ListService_ListPosition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListPositionDetailRequest,
 *   !proto.reportpb.ListPositionDetailResponse>}
 */
const methodDescriptor_ListService_ListPositionDetail = new grpc.web.MethodDescriptor(
  '/reportpb.ListService/ListPositionDetail',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListPositionDetailRequest,
  proto.reportpb.ListPositionDetailResponse,
  /**
   * @param {!proto.reportpb.ListPositionDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListPositionDetailResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListPositionDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListPositionDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListPositionDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ListServiceClient.prototype.listPositionDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.ListService/ListPositionDetail',
      request,
      metadata || {},
      methodDescriptor_ListService_ListPositionDetail,
      callback);
};


/**
 * @param {!proto.reportpb.ListPositionDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListPositionDetailResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.ListServicePromiseClient.prototype.listPositionDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.ListService/ListPositionDetail',
      request,
      metadata || {},
      methodDescriptor_ListService_ListPositionDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListCorrespondentPositionsRequest,
 *   !proto.reportpb.ListCorrespondentPositionsResponse>}
 */
const methodDescriptor_ListService_ListCorrespondentPositions = new grpc.web.MethodDescriptor(
  '/reportpb.ListService/ListCorrespondentPositions',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListCorrespondentPositionsRequest,
  proto.reportpb.ListCorrespondentPositionsResponse,
  /**
   * @param {!proto.reportpb.ListCorrespondentPositionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListCorrespondentPositionsResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListCorrespondentPositionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListCorrespondentPositionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListCorrespondentPositionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ListServiceClient.prototype.listCorrespondentPositions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.ListService/ListCorrespondentPositions',
      request,
      metadata || {},
      methodDescriptor_ListService_ListCorrespondentPositions,
      callback);
};


/**
 * @param {!proto.reportpb.ListCorrespondentPositionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListCorrespondentPositionsResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.ListServicePromiseClient.prototype.listCorrespondentPositions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.ListService/ListCorrespondentPositions',
      request,
      metadata || {},
      methodDescriptor_ListService_ListCorrespondentPositions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.StreamPositionRequest,
 *   !proto.reportpb.Position>}
 */
const methodDescriptor_ListService_StreamPosition = new grpc.web.MethodDescriptor(
  '/reportpb.ListService/StreamPosition',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.reportpb.StreamPositionRequest,
  proto.reportpb.Position,
  /**
   * @param {!proto.reportpb.StreamPositionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.Position.deserializeBinary
);


/**
 * @param {!proto.reportpb.StreamPositionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.Position>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ListServiceClient.prototype.streamPosition =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.ListService/StreamPosition',
      request,
      metadata || {},
      methodDescriptor_ListService_StreamPosition);
};


/**
 * @param {!proto.reportpb.StreamPositionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.Position>}
 *     The XHR Node Readable Stream
 */
proto.reportpb.ListServicePromiseClient.prototype.streamPosition =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/reportpb.ListService/StreamPosition',
      request,
      metadata || {},
      methodDescriptor_ListService_StreamPosition);
};


module.exports = proto.reportpb;

