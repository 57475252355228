/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: reportpb/statementdisclosure.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.reportpb = require('./statementdisclosure_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.StatementDisclosureServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.StatementDisclosureServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListStatementDisclosureRequest,
 *   !proto.reportpb.ListStatementDisclosureResponse>}
 */
const methodDescriptor_StatementDisclosureService_ListStatementDisclosure = new grpc.web.MethodDescriptor(
  '/reportpb.StatementDisclosureService/ListStatementDisclosure',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListStatementDisclosureRequest,
  proto.reportpb.ListStatementDisclosureResponse,
  /**
   * @param {!proto.reportpb.ListStatementDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListStatementDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListStatementDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListStatementDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListStatementDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementDisclosureServiceClient.prototype.listStatementDisclosure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/ListStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_ListStatementDisclosure,
      callback);
};


/**
 * @param {!proto.reportpb.ListStatementDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListStatementDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementDisclosureServicePromiseClient.prototype.listStatementDisclosure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/ListStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_ListStatementDisclosure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ReadStatementDisclosureRequest,
 *   !proto.reportpb.ReadStatementDisclosureResponse>}
 */
const methodDescriptor_StatementDisclosureService_ReadStatementDisclosure = new grpc.web.MethodDescriptor(
  '/reportpb.StatementDisclosureService/ReadStatementDisclosure',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ReadStatementDisclosureRequest,
  proto.reportpb.ReadStatementDisclosureResponse,
  /**
   * @param {!proto.reportpb.ReadStatementDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ReadStatementDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ReadStatementDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ReadStatementDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ReadStatementDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementDisclosureServiceClient.prototype.readStatementDisclosure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/ReadStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_ReadStatementDisclosure,
      callback);
};


/**
 * @param {!proto.reportpb.ReadStatementDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ReadStatementDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementDisclosureServicePromiseClient.prototype.readStatementDisclosure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/ReadStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_ReadStatementDisclosure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.StatementDisclosure,
 *   !proto.reportpb.CreateStatementDisclosureResponse>}
 */
const methodDescriptor_StatementDisclosureService_CreateStatementDisclosure = new grpc.web.MethodDescriptor(
  '/reportpb.StatementDisclosureService/CreateStatementDisclosure',
  grpc.web.MethodType.UNARY,
  proto.reportpb.StatementDisclosure,
  proto.reportpb.CreateStatementDisclosureResponse,
  /**
   * @param {!proto.reportpb.StatementDisclosure} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.CreateStatementDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.StatementDisclosure} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.CreateStatementDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.CreateStatementDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementDisclosureServiceClient.prototype.createStatementDisclosure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/CreateStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_CreateStatementDisclosure,
      callback);
};


/**
 * @param {!proto.reportpb.StatementDisclosure} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.CreateStatementDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementDisclosureServicePromiseClient.prototype.createStatementDisclosure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/CreateStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_CreateStatementDisclosure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.StatementDisclosure,
 *   !proto.reportpb.UpdateStatementDisclosureResponse>}
 */
const methodDescriptor_StatementDisclosureService_UpdateStatementDisclosure = new grpc.web.MethodDescriptor(
  '/reportpb.StatementDisclosureService/UpdateStatementDisclosure',
  grpc.web.MethodType.UNARY,
  proto.reportpb.StatementDisclosure,
  proto.reportpb.UpdateStatementDisclosureResponse,
  /**
   * @param {!proto.reportpb.StatementDisclosure} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.UpdateStatementDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.StatementDisclosure} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.UpdateStatementDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.UpdateStatementDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementDisclosureServiceClient.prototype.updateStatementDisclosure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/UpdateStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_UpdateStatementDisclosure,
      callback);
};


/**
 * @param {!proto.reportpb.StatementDisclosure} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.UpdateStatementDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementDisclosureServicePromiseClient.prototype.updateStatementDisclosure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementDisclosureService/UpdateStatementDisclosure',
      request,
      metadata || {},
      methodDescriptor_StatementDisclosureService_UpdateStatementDisclosure);
};


module.exports = proto.reportpb;

