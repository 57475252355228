/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListStockBorrowRequest } from '../../../proto/reportpb/stockborrow_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Visibility as ViewIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import StockBorrowDetailsTable from './StockBorrowDetailsTable';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function StockBorrowTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    stockBorrowServiceClient: stockborrow,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListStockBorrowRequest();
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    stockborrow.listStockBorrow(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().stockBorrowsList.map((data) => ({
        originalCusip: data.originalCusip,
        symbol: data.symbol,
        cusip: data.cusip,
        symbolDescription: data.symbolDescription,
        settlementObligation: data.settlementObligation,
        borrowed: data.borrowed,
        loaned: data.loaned,
        freePosition: data.freePosition,
        needToReturn: data.needToReturn,
        systemDate: data.systemDate
          ? formatPbDate(data.systemDate)
          : data.systemDate,
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Stock Borrow');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton
              aria-label="details"
              onClick={() => {
                handleOpen(
                  rows[dataIndex].systemDate,
                  rows[dataIndex].originalCusip,
                  rows[dataIndex].symbol,
                  rows[dataIndex].symbolDescription,
                  rows[dataIndex].cusip
                );
              }}
            >
              <ViewIcon />
            </IconButton>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'borrowed',
      label: 'Borrowed',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].borrowed.replace('$', '');
          const f = rows[dataIndex].borrowed;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'freePosition',
      label: 'Free Position',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].freePosition.replace('$', '');
          const f = rows[dataIndex].freePosition;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'loaned',
      label: 'Loaned',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].loaned.replace('$', '');
          const f = rows[dataIndex].loaned;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'needToReturn',
      label: 'Need To Returm',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].needToReturn.replace('$', '');
          const f = rows[dataIndex].needToReturn;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'settlementObligation',
      label: 'Settlement Obligation',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].settlementObligation.replace('$', '');
          const f = rows[dataIndex].settlementObligation;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
  ];

  const fileName =
    'StockBorrow_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 10, 8, 2, 9, 7, 1, 4, 3, 5, 6],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Stock Borrow');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Stock Borrow');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [cusipValue, setCusipValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      systemDate: query.get('systemDate'),
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
    };

    if (data.cusip) {
      setCusipValue(data.cusip);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }

    setCurrentPath(path);

    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }
  }, [systemDate, params]);

  const [selectedSystemDate, setSelectedSystemDate] = React.useState('');
  const [selectedOrigCusip, setSelectedOrigCusip] = React.useState('');
  const [selectedSymbol, setSelectedSymbol] = React.useState('');
  const [
    selectedSymbolDescription,
    setSelectedSymbolDescription,
  ] = React.useState('');
  const [selectedCusip, setSelectedCusip] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (
    systemDate,
    originalCusip,
    symbol,
    symbolDescription,
    cusip
  ) => {
    setSelectedSystemDate(systemDate);
    setSelectedOrigCusip(originalCusip);
    setSelectedSymbol(symbol);
    setSelectedSymbolDescription(symbolDescription);
    setSelectedCusip(cusip);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Cusip"
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div
                className={classes.grdCell1}
                style={{ marginRight: 30 }}
              ></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Stock Borrow'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <StockBorrowDetailsTable
                systemdate={selectedSystemDate}
                origcusip={selectedOrigCusip}
                symbol={selectedSymbol}
                symboldesc={selectedSymbolDescription}
                cusip={selectedCusip}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
