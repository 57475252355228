import React from 'react';

import { InfoTable, DataTable } from './Table';
import QueryEditor from './QueryEditor';
import { useListSsrConfig } from '../Hooks/useSsrService';
import { useAdministrator } from '../Hooks/useAdministrator';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import { useSsrRouter } from '../Hooks/useRouter';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export function ViewConfig({ reportId, configId }) {
  const classes = useStyles();
  const ssrRouter = useSsrRouter();

  const handleBackToReport = () => {
    ssrRouter.goToViewReportPage(reportId);
  };

  return (
    <div className={classes.root}>
      <Box display="flex">
        <Button
          color="primary"
          variant="contained"
          onClick={handleBackToReport}
        >
          Back to Report
        </Button>
      </Box>
      <ConfigView reportId={reportId} configId={configId} />
    </div>
  );
}

export function ConfigList({ reportId }) {
  const configs = useListSsrConfig(reportId);
  const admin = useAdministrator();
  const adminIdMap = admin?.data?.idMap ?? {};

  const ssrRouter = useSsrRouter();

  if (!configs.data) {
    return 'configs data in progress...';
  }

  const columns = ['Update Time', 'Updator', ''];

  const data = configs.data.map((config) => {
    const handleButtonClick = () => {
      ssrRouter.goToViewConfigPage(reportId, config.getId());
    };

    return [
      config
        .getCreatedAt()
        ?.toDate()
        .toLocaleString(),
      adminIdMap[config.getCreatorId()]?.name,
      <Button
        key="details"
        color="primary"
        variant="contained"
        onClick={handleButtonClick}
      >
        Details
      </Button>,
    ];
  });

  return <DataTable title="History" columns={columns} data={data} />;
}

export function ConfigView({ reportId, configId }) {
  const configs = useListSsrConfig(reportId);

  if (!configs.data) {
    return 'configs data in progress...';
  }

  const filteredConfigs = configs.data.filter(
    (config) => config.getId() === configId
  );

  if (filteredConfigs.length !== 1) {
    return `Cannot find config ${configId}`;
  }

  const myConfig = filteredConfigs[0];

  return (
    <InfoTable
      data={[
        {
          name: 'Report Name',
          body: myConfig.getName(),
        },
        {
          name: 'Report Description',
          body: myConfig.getDescription(),
        },
        {
          name: 'Report Owner',
          body: myConfig.getOwnerId(),
        },
        {
          name: 'Report Directory',
          body: myConfig.getReportDirectory(),
        },
        {
          name: 'Require Review',
          body: `${myConfig.getRequireReview()}`,
        },
        {
          name: 'Review Directory',
          body: myConfig.getReviewDirectory(),
        },
        {
          name: 'Schedule Type',
          body: myConfig.getScheduleType(),
        },
        {
          name: 'Schedule Meta',
          body: myConfig.getScheduleMeta(),
        },
        {
          name: 'Schedule Description',
          body: myConfig.getScheduleDescription(),
        },
        {
          name: 'Database',
          body: 'ledgie',
        },
        {
          name: 'Conditional Query',
          body: myConfig.getConditionalQuery() ? (
            <QueryEditor
              value={myConfig.getConditionalQuery()}
              editable={false}
              showExecuteButton={true}
            ></QueryEditor>
          ) : (
            'N/A'
          ),
        },
        {
          name: 'Query',
          body: (
            <QueryEditor
              value={myConfig.getQuery()}
              editable={false}
              showExecuteButton={true}
            />
          ),
        },
      ]}
    />
  );
}
