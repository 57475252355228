// source: reconpb/balancerecon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.reconpb.BalanceRecon', null, global);
goog.exportSymbol('proto.reconpb.BalanceReconDetail', null, global);
goog.exportSymbol('proto.reconpb.ListBalanceReconDetailRequest', null, global);
goog.exportSymbol('proto.reconpb.ListBalanceReconDetailResponse', null, global);
goog.exportSymbol('proto.reconpb.ListBalanceReconRequest', null, global);
goog.exportSymbol('proto.reconpb.ListBalanceReconResponse', null, global);
goog.exportSymbol('proto.reconpb.ListBalanceReconSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.BalanceRecon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.BalanceRecon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.BalanceRecon.displayName = 'proto.reconpb.BalanceRecon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListBalanceReconRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.ListBalanceReconRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListBalanceReconRequest.displayName = 'proto.reconpb.ListBalanceReconRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListBalanceReconResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconpb.ListBalanceReconResponse.repeatedFields_, null);
};
goog.inherits(proto.reconpb.ListBalanceReconResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListBalanceReconResponse.displayName = 'proto.reconpb.ListBalanceReconResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.BalanceReconDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.BalanceReconDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.BalanceReconDetail.displayName = 'proto.reconpb.BalanceReconDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListBalanceReconDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.ListBalanceReconDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListBalanceReconDetailRequest.displayName = 'proto.reconpb.ListBalanceReconDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListBalanceReconDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconpb.ListBalanceReconDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reconpb.ListBalanceReconDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListBalanceReconDetailResponse.displayName = 'proto.reconpb.ListBalanceReconDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListBalanceReconSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.ListBalanceReconSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListBalanceReconSummary.displayName = 'proto.reconpb.ListBalanceReconSummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.BalanceRecon.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.BalanceRecon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.BalanceRecon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.BalanceRecon.toObject = function(includeInstance, msg) {
  var f, obj = {
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
account: jspb.Message.getFieldWithDefault(msg, 2, ""),
contraAccount: jspb.Message.getFieldWithDefault(msg, 3, ""),
contraSource: jspb.Message.getFieldWithDefault(msg, 4, ""),
amt: jspb.Message.getFieldWithDefault(msg, 5, ""),
contraAmt: jspb.Message.getFieldWithDefault(msg, 6, ""),
amtDiff: jspb.Message.getFieldWithDefault(msg, 7, ""),
pendingAmt: jspb.Message.getFieldWithDefault(msg, 8, ""),
dateType: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.BalanceRecon}
 */
proto.reconpb.BalanceRecon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.BalanceRecon;
  return proto.reconpb.BalanceRecon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.BalanceRecon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.BalanceRecon}
 */
proto.reconpb.BalanceRecon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAmt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmtDiff(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPendingAmt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.BalanceRecon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.BalanceRecon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.BalanceRecon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.BalanceRecon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContraAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContraSource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContraAmt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmtDiff();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPendingAmt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.BalanceRecon.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.BalanceRecon} returns this
*/
proto.reconpb.BalanceRecon.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.BalanceRecon.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contra_account = 3;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getContraAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setContraAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contra_source = 4;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getContraSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setContraSource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string amt = 5;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contra_amt = 6;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getContraAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setContraAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string amt_diff = 7;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getAmtDiff = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setAmtDiff = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string pending_amt = 8;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getPendingAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setPendingAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_type = 9;
 * @return {string}
 */
proto.reconpb.BalanceRecon.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceRecon} returns this
 */
proto.reconpb.BalanceRecon.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListBalanceReconRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListBalanceReconRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListBalanceReconRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
account: jspb.Message.getFieldWithDefault(msg, 2, ""),
contraAccount: jspb.Message.getFieldWithDefault(msg, 3, ""),
contraSource: jspb.Message.getFieldWithDefault(msg, 4, ""),
dateType: jspb.Message.getFieldWithDefault(msg, 5, ""),
breaksOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListBalanceReconRequest}
 */
proto.reconpb.ListBalanceReconRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListBalanceReconRequest;
  return proto.reconpb.ListBalanceReconRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListBalanceReconRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListBalanceReconRequest}
 */
proto.reconpb.ListBalanceReconRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBreaksOnly(value);
      break;
    case 7:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListBalanceReconRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListBalanceReconRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListBalanceReconRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContraAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContraSource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBreaksOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
*/
proto.reconpb.ListBalanceReconRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ListBalanceReconRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contra_account = 3;
 * @return {string}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getContraAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.setContraAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contra_source = 4;
 * @return {string}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getContraSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.setContraSource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_type = 5;
 * @return {string}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool breaks_only = 6;
 * @return {boolean}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getBreaksOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.setBreaksOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional utilspb.Pagination pagination = 7;
 * @return {?proto.utilspb.Pagination}
 */
proto.reconpb.ListBalanceReconRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 7));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
*/
proto.reconpb.ListBalanceReconRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ListBalanceReconRequest} returns this
 */
proto.reconpb.ListBalanceReconRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ListBalanceReconRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconpb.ListBalanceReconResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListBalanceReconResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListBalanceReconResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListBalanceReconResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
balanceReconsList: jspb.Message.toObjectList(msg.getBalanceReconsList(),
    proto.reconpb.BalanceRecon.toObject, includeInstance),
summary: (f = msg.getSummary()) && proto.reconpb.ListBalanceReconSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListBalanceReconResponse}
 */
proto.reconpb.ListBalanceReconResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListBalanceReconResponse;
  return proto.reconpb.ListBalanceReconResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListBalanceReconResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListBalanceReconResponse}
 */
proto.reconpb.ListBalanceReconResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconpb.BalanceRecon;
      reader.readMessage(value,proto.reconpb.BalanceRecon.deserializeBinaryFromReader);
      msg.addBalanceRecons(value);
      break;
    case 2:
      var value = new proto.reconpb.ListBalanceReconSummary;
      reader.readMessage(value,proto.reconpb.ListBalanceReconSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListBalanceReconResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListBalanceReconResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListBalanceReconResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalanceReconsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconpb.BalanceRecon.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconpb.ListBalanceReconSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BalanceRecon balance_recons = 1;
 * @return {!Array<!proto.reconpb.BalanceRecon>}
 */
proto.reconpb.ListBalanceReconResponse.prototype.getBalanceReconsList = function() {
  return /** @type{!Array<!proto.reconpb.BalanceRecon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconpb.BalanceRecon, 1));
};


/**
 * @param {!Array<!proto.reconpb.BalanceRecon>} value
 * @return {!proto.reconpb.ListBalanceReconResponse} returns this
*/
proto.reconpb.ListBalanceReconResponse.prototype.setBalanceReconsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconpb.BalanceRecon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconpb.BalanceRecon}
 */
proto.reconpb.ListBalanceReconResponse.prototype.addBalanceRecons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconpb.BalanceRecon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconpb.ListBalanceReconResponse} returns this
 */
proto.reconpb.ListBalanceReconResponse.prototype.clearBalanceReconsList = function() {
  return this.setBalanceReconsList([]);
};


/**
 * optional ListBalanceReconSummary summary = 2;
 * @return {?proto.reconpb.ListBalanceReconSummary}
 */
proto.reconpb.ListBalanceReconResponse.prototype.getSummary = function() {
  return /** @type{?proto.reconpb.ListBalanceReconSummary} */ (
    jspb.Message.getWrapperField(this, proto.reconpb.ListBalanceReconSummary, 2));
};


/**
 * @param {?proto.reconpb.ListBalanceReconSummary|undefined} value
 * @return {!proto.reconpb.ListBalanceReconResponse} returns this
*/
proto.reconpb.ListBalanceReconResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ListBalanceReconResponse} returns this
 */
proto.reconpb.ListBalanceReconResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ListBalanceReconResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.BalanceReconDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.BalanceReconDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.BalanceReconDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.BalanceReconDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
contraAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
entryType: jspb.Message.getFieldWithDefault(msg, 6, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 7, ""),
qty: jspb.Message.getFieldWithDefault(msg, 8, ""),
netAmt: jspb.Message.getFieldWithDefault(msg, 9, ""),
price: jspb.Message.getFieldWithDefault(msg, 10, ""),
fees: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.BalanceReconDetail}
 */
proto.reconpb.BalanceReconDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.BalanceReconDetail;
  return proto.reconpb.BalanceReconDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.BalanceReconDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.BalanceReconDetail}
 */
proto.reconpb.BalanceReconDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.BalanceReconDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.BalanceReconDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.BalanceReconDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.BalanceReconDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contra_account_no = 2;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Date system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.BalanceReconDetail.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
*/
proto.reconpb.BalanceReconDetail.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.BalanceReconDetail.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Date settle_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.BalanceReconDetail.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
*/
proto.reconpb.BalanceReconDetail.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.BalanceReconDetail.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date trade_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.BalanceReconDetail.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
*/
proto.reconpb.BalanceReconDetail.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.BalanceReconDetail.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string entry_type = 6;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string symbol = 7;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string qty = 8;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string net_amt = 9;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string price = 10;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string fees = 11;
 * @return {string}
 */
proto.reconpb.BalanceReconDetail.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.BalanceReconDetail} returns this
 */
proto.reconpb.BalanceReconDetail.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListBalanceReconDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListBalanceReconDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
dateType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListBalanceReconDetailRequest}
 */
proto.reconpb.ListBalanceReconDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListBalanceReconDetailRequest;
  return proto.reconpb.ListBalanceReconDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListBalanceReconDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListBalanceReconDetailRequest}
 */
proto.reconpb.ListBalanceReconDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListBalanceReconDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListBalanceReconDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.ListBalanceReconDetailRequest} returns this
*/
proto.reconpb.ListBalanceReconDetailRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ListBalanceReconDetailRequest} returns this
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconDetailRequest} returns this
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_type = 3;
 * @return {string}
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconDetailRequest} returns this
 */
proto.reconpb.ListBalanceReconDetailRequest.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconpb.ListBalanceReconDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListBalanceReconDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListBalanceReconDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListBalanceReconDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
balanceReconDetailsList: jspb.Message.toObjectList(msg.getBalanceReconDetailsList(),
    proto.reconpb.BalanceReconDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListBalanceReconDetailResponse}
 */
proto.reconpb.ListBalanceReconDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListBalanceReconDetailResponse;
  return proto.reconpb.ListBalanceReconDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListBalanceReconDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListBalanceReconDetailResponse}
 */
proto.reconpb.ListBalanceReconDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconpb.BalanceReconDetail;
      reader.readMessage(value,proto.reconpb.BalanceReconDetail.deserializeBinaryFromReader);
      msg.addBalanceReconDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListBalanceReconDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListBalanceReconDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListBalanceReconDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalanceReconDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconpb.BalanceReconDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BalanceReconDetail balance_recon_details = 1;
 * @return {!Array<!proto.reconpb.BalanceReconDetail>}
 */
proto.reconpb.ListBalanceReconDetailResponse.prototype.getBalanceReconDetailsList = function() {
  return /** @type{!Array<!proto.reconpb.BalanceReconDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconpb.BalanceReconDetail, 1));
};


/**
 * @param {!Array<!proto.reconpb.BalanceReconDetail>} value
 * @return {!proto.reconpb.ListBalanceReconDetailResponse} returns this
*/
proto.reconpb.ListBalanceReconDetailResponse.prototype.setBalanceReconDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconpb.BalanceReconDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconpb.BalanceReconDetail}
 */
proto.reconpb.ListBalanceReconDetailResponse.prototype.addBalanceReconDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconpb.BalanceReconDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconpb.ListBalanceReconDetailResponse} returns this
 */
proto.reconpb.ListBalanceReconDetailResponse.prototype.clearBalanceReconDetailsList = function() {
  return this.setBalanceReconDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListBalanceReconSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListBalanceReconSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListBalanceReconSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
amt: jspb.Message.getFieldWithDefault(msg, 2, ""),
contraAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
amtDiff: jspb.Message.getFieldWithDefault(msg, 4, ""),
pendingAmt: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListBalanceReconSummary}
 */
proto.reconpb.ListBalanceReconSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListBalanceReconSummary;
  return proto.reconpb.ListBalanceReconSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListBalanceReconSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListBalanceReconSummary}
 */
proto.reconpb.ListBalanceReconSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAmt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmtDiff(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPendingAmt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListBalanceReconSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListBalanceReconSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListBalanceReconSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListBalanceReconSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContraAmt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmtDiff();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPendingAmt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reconpb.ListBalanceReconSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconpb.ListBalanceReconSummary} returns this
 */
proto.reconpb.ListBalanceReconSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string amt = 2;
 * @return {string}
 */
proto.reconpb.ListBalanceReconSummary.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconSummary} returns this
 */
proto.reconpb.ListBalanceReconSummary.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contra_amt = 3;
 * @return {string}
 */
proto.reconpb.ListBalanceReconSummary.prototype.getContraAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconSummary} returns this
 */
proto.reconpb.ListBalanceReconSummary.prototype.setContraAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amt_diff = 4;
 * @return {string}
 */
proto.reconpb.ListBalanceReconSummary.prototype.getAmtDiff = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconSummary} returns this
 */
proto.reconpb.ListBalanceReconSummary.prototype.setAmtDiff = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pending_amt = 5;
 * @return {string}
 */
proto.reconpb.ListBalanceReconSummary.prototype.getPendingAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListBalanceReconSummary} returns this
 */
proto.reconpb.ListBalanceReconSummary.prototype.setPendingAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.reconpb);
