import React, { useEffect, useContext } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';

// styles
import useStyles from './styles';
import useStyles2 from '../../styles';

/*Service*/
import { UserGuide } from '../../proto/admpb/userguide_grpc_web_pb';

import {
  Typography,
  Button,
  Modal,
  Fade,
  Backdrop,
  Box,
} from '@material-ui/core';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from 'pages/dashboard';
import Notifications from 'pages/notifications';
import Maps from 'pages/maps';
import Tables from 'pages/tables';
import Icons from 'pages/icons';
import Charts from 'pages/charts';

//new pages
import Home from 'pages/Home/Home';
import SystemCode from 'pages/SystemCode/SystemCode';
import ClientAccount from 'pages/ClientAccount/ClientAccount';
import GLAccount from 'pages/GLAccount/GLAccount';
import BankAccount from 'pages/BankAccount/BankAccount';
import BankAddress from 'pages/BankAddress/BankAddress';
import ACHWire from 'pages/ACHWire/ACHWire';
import Activity from 'pages/Activity/Activity';
import Transaction from 'pages/Transaction/Transaction';
import CancelTransaction from 'pages/Transaction/CancelTransaction';
import Balance from 'pages/Balance/Balance';
import BalanceRecon from 'pages/BalanceRecon/BalanceRecon';
import Price from 'pages/Price/Price';
import SecurityMaster from 'pages/SecurityMaster/SecurityMaster';
import MarginRate from 'pages/MarginRate/MarginRate';
import MarginRequirement from 'pages/MarginRequirement/MarginRequirement';
import Position from 'pages/Position/Position';
import PositionRecon from 'pages/PositionRecon/PositionRecon';
import Segregation from 'pages/Segregation/Segregation';
import Etf from 'pages/ETF/Etf';
import ProfitAndLoss from 'pages/ProfitAndLoss/ProfitAndLoss';
import Calendar from 'pages/Calendar/Calendar';
import ReOrgAnnouncement from 'pages/ReOrgAnnouncement/ReOrgAnnouncement';
import ReserveCalc from 'pages/ReserveCalc/ReserveCalc';
import TrialBalance from 'pages/TrialBalance/TrialBalance';
import MonthlyStatement from 'pages/MonthlyStatement/MonthlyStatement';
import GLReport from 'pages/GLReport/GLReport';
import ExecutionRecon from 'pages/ExecutionRecon/ExecutionRecon';
import CallLog from 'pages/CallLog/CallLog';
import DailyTradeConfirm from 'pages/DailyTradeConfirm/DailyTradeConfirm';
import SelfServiceReporting from 'pages/SelfServiceReporting/SelfServiceReporting';
import PendingTrns from 'pages/PendingTRNS/PendingTrns';
import Sll from 'pages/SLL/Sll';
import StockBorrow from 'pages/StockBorrow/StockBorrow';
import ReconMapping from 'pages/ReconMapping/ReconMapping';
import MarginInterestCalc from 'pages/MarginInterestCalc/MarginInterestCalc';
import MarginInterestRate from 'pages/MarginInterestRate/MarginInterestRate';
import Administrator from 'pages/Administrator/Administrator';
import SystemNumber from 'pages/SystemNumber/SystemNumber';
import Fee from 'pages/Fee/Fee';
import FeeManagement from 'pages/FeeManagement/FeeManagement';
import UserGuidePage from 'pages/UserGuide/UserGuide';
import FractionalShareRecon from 'pages/FractionalShareRecon/FractionalShareRecon';
import SystemProfile from 'pages/SystemProfile/SystemProfile';
import SettlementFunding from 'pages/SettlementFunding/SettlementFunding';
import AccountManagement from 'pages/AccountManagement/AccountManagement';
import TaxRate from 'pages/TaxRate/TaxRate';
import Role from 'pages/Role/Role';
import FractionalSharePL from 'pages/FractionalSharePL/FractionalSharePL';
import TransferInput from 'pages/TransferInput/TransferInput';
import InventoryReport from 'pages/InventoryReport/InventoryReport';
import TaxWithholdingDiv from 'pages/TaxWithholdingDiv/TaxWithholdingDiv';
import MonthlyStatementDisclosure from 'pages/MonthlyStatementDisclosure/MonthlyStatementDisclosure';
import ReportTemplate from 'pages/ReportTemplate/ReportTemplate';
import SegregationPriority from 'pages/SegregationPriority/SegregationPriority';
import Partial from 'pages/AssetTransfer/AssetTransfer';
import Outgoing from 'pages/Outgoing/Outgoing';
import MultiCycleMro from 'pages/MultiCycleMRO/MultiCycleMRO';
import OutputPosition from 'pages/OutputPosition/OutputPosition';
import ClearingTrades from 'pages/ClearingTrades/ClearingTrades';
import ClearingSettlement from 'pages/ClearingSettlement/ClearingSettlement';
import ClearingPermissions from 'pages/ClearingPermissions/ClearingPermissions';

// context
import { useLayoutState } from 'context/LayoutContext';
import { ToastContainer } from 'react-toastify';
import { ServiceContext } from 'context/ServiceContext';

function Layout(props) {
  var classes = useStyles();
  var classes2 = useStyles2();

  // global
  var layoutState = useLayoutState();

  //user guide
  const { userGuideServiceClient: userGuide } = useContext(ServiceContext);

  const currentPage = props.location.pathname.split('/')[3];
  const [openModal, setOpenModal] = React.useState(false);
  const [pageName, setPageName] = React.useState('');
  const [selectedUserGuide, setSelectedUserGuide] = React.useState('');
  const [pagePath, setPagePath] = React.useState('');

  useEffect(() => {
    setSelectedUserGuide('');

    const transformCapitalize = (words) => {
      var separateWord = words.toLowerCase().split(' ');
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord
        .join(' ')
        .replace(/Ach/g, 'ACH \\')
        .replace(/Trns/gi, 'TRNS')
        .replace(/Gl/g, 'GL')
        .replace(/Etf/g, 'ETF')
        .replace(/Sll/g, 'SLL');
    };

    setPageName(
      transformCapitalize(
        props.location.pathname
          .split('/')
          .pop()
          .replace(/-/g, ' ')
      )
    );
    setPagePath(props.location.pathname.split('/').pop());
  }, [setPageName, props]);

  const handleOpen = () => {
    setTimeout(function() {
      let list = new UserGuide();
      list.setPagePath(pagePath);
      userGuide.listUserGuide(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          return;
        }
        let data = res.toObject().userGuidesList;
        setSelectedUserGuide(
          data.length > 0
            ? data[0].content
            : 'Sorry, no user guide is created for this page.'
        );
      });
    }, 500);

    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            pauseOnFocusLoss
            pauseOnHover
            className={classes.toastsContainer}
            closeButton={
              <CloseIcon className={classes.notificationCloseButton} />
            }
            toastClassName={classes.notification}
            progressClassName={classes.notificationProgress}
          />
          {currentPage !== 'user-guide' ? (
            <Button className={classes.userGuideButton} onClick={handleOpen}>
              <span className={classes.pulse}></span>
              <InfoOutlinedIcon style={{ fontSize: 22 }} />
            </Button>
          ) : null}
          <Switch>
            <Route path="/app/home" component={Home} />
            <Route
              path="/app/system-profile/calendar/:search?"
              render={(props) => (
                <Calendar truepath="/app/system-profile/calendar" {...props} />
              )}
            />
            <Route
              path="/app/system-profile/system-profile/:search?"
              render={(props) => (
                <SystemProfile
                  truepath="/app/system-profile/system-profile"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/system-profile/system-code/:search?"
              render={(props) => (
                <SystemCode
                  truepath="/app/system-profile/system-code"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/system-profile/system-number/:search?"
              render={(props) => (
                <SystemNumber
                  truepath="/app/system-profile/system-number"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/system-profile/user-guide/:search?"
              render={(props) => (
                <UserGuidePage
                  truepath="/app/system-profile/user-guide"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/user-management/administrator/:search?"
              render={(props) => (
                <Administrator
                  truepath="/app/user-management/administrator"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/user-management/role/:search?"
              render={(props) => (
                <Role truepath="/app/user-management/role" {...props} />
              )}
            />
            <Route
              path="/app/account-setup/client-account/:search?"
              render={(props) => (
                <ClientAccount
                  truepath="/app/account-setup/client-account"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/account-setup/gl-account/:search?"
              render={(props) => (
                <GLAccount
                  truepath="/app/account-setup/gl-account"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/account-setup/bank-account/:search?"
              render={(props) => (
                <BankAccount
                  truepath="/app/account-setup/bank-account"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/account-setup/bank-address/:search?"
              render={(props) => (
                <BankAddress
                  truepath="/app/account-setup/bank-address"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/account-setup/ach-wire/:search?"
              render={(props) => (
                <ACHWire truepath="/app/account-setup/ach-wire" {...props} />
              )}
            />
            <Route
              path="/app/account-setup/recon-mapping/:search?"
              render={(props) => (
                <ReconMapping
                  truepath="/app/account-setup/recon-mapping"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/segregation/segregation/:search?"
              render={(props) => (
                <Segregation
                  truepath="/app/segregation/segregation"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/segregation/priority/:search?"
              render={(props) => (
                <SegregationPriority
                  truepath="/app/segregation/priority"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/calculation/reserve-calc"
              render={(props) => (
                <ReserveCalc
                  truepath="/app/calculation/reserve-calc"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/acats/32667/transfer-input:search?"
              render={(props) => (
                <TransferInput
                  truepath="/app/acats/32667/transfer-input"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/margin-interest/margin-rate/:search?"
              render={(props) => (
                <MarginRate
                  truepath="/app/margin-interest/margin-rate"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/margin-interest/margin-requirement/:search?"
              render={(props) => (
                <MarginRequirement
                  truepath="/app/margin-interest/margin-requirement"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/margin-interest/margin-interest-calc/:search?"
              render={(props) => (
                <MarginInterestCalc
                  truepath="/app/margin-interest/margin-interest-calc"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/margin-interest/margin-interest-rate/:search?"
              render={(props) => (
                <MarginInterestRate
                  truepath="/app/margin-interest/margin-interest-rate"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/margin-interest/call-log/:search?"
              render={(props) => (
                <CallLog truepath="/app/margin-interest/call-log" {...props} />
              )}
            />
            <Route
              path="/app/cash/settlement-funding/:search?"
              render={(props) => (
                <SettlementFunding
                  truepath="/app/cash/settlement-funding"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/cash/balance/:search?"
              render={(props) => (
                <Balance truepath="/app/cash/balance" {...props} />
              )}
            />
            <Route
              path="/app/cash/reconciliation-balance/:search?"
              render={(props) => (
                <BalanceRecon
                  truepath="/app/cash/reconciliation-balance"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/cash/trial-balance/:search?"
              render={(props) => (
                <TrialBalance truepath="/app/cash/trial-balance" {...props} />
              )}
            />
            <Route
              path="/app/security-details/price/:search?"
              render={(props) => (
                <Price truepath="/app/security-details/price" {...props} />
              )}
            />
            <Route
              path="/app/security-details/security-master/:search?"
              render={(props) => (
                <SecurityMaster
                  truepath="/app/security-details/security-master"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/security-details/etf/:search?"
              render={(props) => (
                <Etf truepath="/app/security-details/etf" {...props} />
              )}
            />
            <Route
              path="/app/security-details/sll/:search?"
              render={(props) => (
                <Sll truepath="/app/security-details/sll" {...props} />
              )}
            />
            <Route
              path="/app/corp-action/announcement"
              render={(props) => (
                <ReOrgAnnouncement
                  truepath="/app/corp-action/announcement"
                  {...props}
                />
              )}
            />
            <Route path="/app/trns/enter-trns" component={Transaction} />
            <Route path="/app/trns/cancel-trns" component={CancelTransaction} />
            <Route
              path="/app/trns/activity/:search?"
              render={(props) => (
                <Activity truepath="/app/trns/activity" {...props} />
              )}
            />
            <Route
              path="/app/trns/fee/:search?"
              render={(props) => <Fee truepath="/app/trns/fee" {...props} />}
            />
            <Route
              path="/app/trns/fee-management/:search?"
              render={(props) => (
                <FeeManagement truepath="/app/trns/fee-management" {...props} />
              )}
            />
            <Route
              path="/app/trns/cancel-and-correct/:search?"
              render={(props) => (
                <Transaction
                  truepath="/app/trns/cancel-and-correct"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/trns/process-pending/:search?"
              render={(props) => (
                <Transaction truepath="/app/trns/process-pending" {...props} />
              )}
            />
            <Route
              path="/app/trns/pending-trns/:search?"
              render={(props) => (
                <PendingTrns truepath="/app/trns/pending-trns" {...props} />
              )}
            />
            <Route
              path="/app/trns/reconciliation-execution/:search?"
              render={(props) => (
                <ExecutionRecon
                  truepath="/app/trns/reconciliation-execution"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/position/position/:search?"
              render={(props) => (
                <Position truepath="/app/position/position" {...props} />
              )}
            />
            <Route
              path="/app/position/reconciliation-position/:search?"
              render={(props) => (
                <PositionRecon
                  truepath="/app/position/reconciliation-position"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/position/stock-borrow/:search?"
              render={(props) => (
                <StockBorrow truepath="/app/position/stock-borrow" {...props} />
              )}
            />
            <Route
              path="/app/position/fractional-share-recon/:search?"
              render={(props) => (
                <FractionalShareRecon
                  truepath="/app/position/fractional-share-recon"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/position/fractional-share-pl/:search?"
              render={(props) => (
                <FractionalSharePL
                  truepath="/app/position/fractional-share-pl"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/position/inventory-report/:search?"
              render={(props) => (
                <InventoryReport
                  truepath="/app/position/inventory-report"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/profit-and-loss/:search?"
              render={(props) => (
                <ProfitAndLoss
                  truepath="/app/report/profit-and-loss"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/monthly-statement/:search?"
              render={(props) => (
                <MonthlyStatement
                  truepath="/app/report/monthly-statement"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/statement-disclosure/:search?"
              render={(props) => (
                <MonthlyStatementDisclosure
                  truepath="/app/report/statement-disclosure"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/daily-trade-confirm/:search?"
              render={(props) => (
                <DailyTradeConfirm
                  truepath="/app/report/daily-trade-confirm"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/account-management/:search?"
              render={(props) => (
                <AccountManagement
                  truepath="/app/report/account-management"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/report-template/:search?"
              render={(props) => (
                <ReportTemplate
                  truepath="/app/report/report-template"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/report/self-service-reporting"
              render={(props) => (
                <SelfServiceReporting
                  truepath="/app/report/self-service-reporting"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/tax-rate/tax-rate/:search?"
              render={(props) => (
                <TaxRate truepath="/app/tax-rate/tax-rate" {...props} />
              )}
            />
            <Route
              path="/app/withholding/dividend/:search?"
              render={(props) => (
                <TaxWithholdingDiv
                  truepath="/app/withholding/dividend"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/cash/gl-report/:search?"
              render={(props) => (
                <GLReport truepath="/app/cash/gl-report" {...props} />
              )}
            />
            <Route
              path="/app/acats/transfer-input:search?"
              render={(props) => (
                <TransferInput
                  truepath="/app/acats/transfer-input"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/acats/asset-transfer:search?"
              render={(props) => (
                <Partial truepath="/app/acats/asset-transfer" {...props} />
              )}
            />
            <Route
              path="/app/acats/outgoing:search?"
              render={(props) => (
                <Outgoing truepath="/app/acats/outgoing" {...props} />
              )}
            />
            <Route
              path="/app/acats/multicycle-mro:search?"
              render={(props) => (
                <MultiCycleMro
                  truepath="/app/acats/multicycle-mro"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/acats/output-position:search?"
              render={(props) => (
                <OutputPosition
                  truepath="/app/acats/output-position"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/clearing/trades:search?"
              render={(props) => (
                <ClearingTrades truepath="/app/clearing/trades" {...props} />
              )}
            />
            <Route
              path="/app/clearing/settlement:search?"
              render={(props) => (
                <ClearingSettlement
                  truepath="/app/clearing/settlement"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/clearing/permissions:search?"
              render={(props) => (
                <ClearingPermissions
                  truepath="/app/clearing/permissions"
                  {...props}
                />
              )}
            />
            <Route path="/app/dashboard" component={Dashboard} />
            {/* <Route path="/app/typography" component={Typography} /> */}
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
        </div>
      </div>
      <Modal
        className={classes2.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes2.fadeModalFull}>
            <Typography
              id="transition-modal-title"
              className={classes2.textBold}
              variant="h4"
              gutterBottom
              style={{ textTransform: 'capitalize' }}
            >
              User Guide: {pageName}
            </Typography>
            <Box id="transition-modal-description" mt={5}>
              <div
                className={classes2.userGuidePreview}
                dangerouslySetInnerHTML={{ __html: selectedUserGuide }}
              />
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default withRouter(Layout);
