// source: reportpb/balance.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.reportpb.Balance', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceDateRangeRequest', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceRequest', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceResponse', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceSummary', null, global);
goog.exportSymbol('proto.reportpb.StreamBalanceRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Balance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Balance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Balance.displayName = 'proto.reportpb.Balance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListBalanceRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceRequest.displayName = 'proto.reportpb.ListBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceDateRangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListBalanceDateRangeRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListBalanceDateRangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceDateRangeRequest.displayName = 'proto.reportpb.ListBalanceDateRangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.StreamBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.StreamBalanceRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.StreamBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.StreamBalanceRequest.displayName = 'proto.reportpb.StreamBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListBalanceResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceResponse.displayName = 'proto.reportpb.ListBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListBalanceSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceSummary.displayName = 'proto.reportpb.ListBalanceSummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Balance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Balance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Balance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Balance.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
tdCashBalance: jspb.Message.getFieldWithDefault(msg, 9, ""),
tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
tdEquity: jspb.Message.getFieldWithDefault(msg, 12, ""),
tdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 13, ""),
sdCashBalance: jspb.Message.getFieldWithDefault(msg, 14, ""),
sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 15, ""),
sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 16, ""),
sdEquity: jspb.Message.getFieldWithDefault(msg, 17, ""),
sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 18, ""),
balanceId: jspb.Message.getFieldWithDefault(msg, 19, ""),
accountId: jspb.Message.getFieldWithDefault(msg, 20, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 21, ""),
description: jspb.Message.getFieldWithDefault(msg, 22, ""),
qty: jspb.Message.getFieldWithDefault(msg, 23, ""),
marketPrice: jspb.Message.getFieldWithDefault(msg, 24, ""),
marketValue: jspb.Message.getFieldWithDefault(msg, 25, ""),
costPrice: jspb.Message.getFieldWithDefault(msg, 26, ""),
unrealized: jspb.Message.getFieldWithDefault(msg, 27, ""),
tdCostBasis: jspb.Message.getFieldWithDefault(msg, 28, ""),
nextSdCashBalance: jspb.Message.getFieldWithDefault(msg, 29, ""),
currency: jspb.Message.getFieldWithDefault(msg, 30, ""),
tdNonCashBalance: jspb.Message.getFieldWithDefault(msg, 31, ""),
sdNonCashBalance: jspb.Message.getFieldWithDefault(msg, 32, ""),
asAt: (f = msg.getAsAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Balance}
 */
proto.reportpb.Balance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Balance;
  return proto.reportpb.Balance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Balance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Balance}
 */
proto.reportpb.Balance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCashBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEquity(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdAdjustedBalance(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdEquity(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketPrice(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostPrice(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrealized(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCostBasis(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextSdCashBalance(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdNonCashBalance(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdNonCashBalance(value);
      break;
    case 33:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Balance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Balance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Balance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Balance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTdEquity();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSdEquity();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBalanceId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getMarketPrice();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getCostPrice();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getUnrealized();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getNextSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTdNonCashBalance();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSdNonCashBalance();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAsAt();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Balance.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Balance} returns this
*/
proto.reportpb.Balance.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Balance.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string td_cash_balance = 9;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string td_long_market_value = 10;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string td_short_market_value = 11;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string td_equity = 12;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string td_adjusted_balance = 13;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string sd_cash_balance = 14;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string sd_long_market_value = 15;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string sd_short_market_value = 16;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string sd_equity = 17;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string sd_adjusted_balance = 18;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string balance_id = 19;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getBalanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setBalanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string account_id = 20;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string symbol = 21;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string description = 22;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string qty = 23;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string market_price = 24;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getMarketPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setMarketPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string market_value = 25;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string cost_price = 26;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getCostPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setCostPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string unrealized = 27;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string td_cost_basis = 28;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string next_sd_cash_balance = 29;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getNextSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setNextSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string currency = 30;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string td_non_cash_balance = 31;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdNonCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdNonCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string sd_non_cash_balance = 32;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdNonCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdNonCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional google.protobuf.Timestamp as_at = 33;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.Balance.prototype.getAsAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 33));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.Balance} returns this
*/
proto.reportpb.Balance.prototype.setAsAt = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.clearAsAt = function() {
  return this.setAsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Balance.prototype.hasAsAt = function() {
  return jspb.Message.getField(this, 33) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListBalanceRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
currenciesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceRequest}
 */
proto.reportpb.ListBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceRequest;
  return proto.reportpb.ListBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceRequest}
 */
proto.reportpb.ListBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListBalanceRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
*/
proto.reportpb.ListBalanceRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utilspb.Pagination pagination = 9;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListBalanceRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 9));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
*/
proto.reportpb.ListBalanceRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string currencies = 10;
 * @return {!Array<string>}
 */
proto.reportpb.ListBalanceRequest.prototype.getCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.addCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListBalanceDateRangeRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceDateRangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceDateRangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceDateRangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
currenciesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceDateRangeRequest}
 */
proto.reportpb.ListBalanceDateRangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceDateRangeRequest;
  return proto.reportpb.ListBalanceDateRangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceDateRangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceDateRangeRequest}
 */
proto.reportpb.ListBalanceDateRangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrencies(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceDateRangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceDateRangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceDateRangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional utilspb.Pagination pagination = 9;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 9));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
*/
proto.reportpb.ListBalanceDateRangeRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string currencies = 10;
 * @return {!Array<string>}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.addCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};


/**
 * optional google.type.Date from_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
*/
proto.reportpb.ListBalanceDateRangeRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.type.Date to_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
*/
proto.reportpb.ListBalanceDateRangeRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceDateRangeRequest} returns this
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceDateRangeRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.StreamBalanceRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.StreamBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.StreamBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.StreamBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StreamBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
currenciesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.StreamBalanceRequest}
 */
proto.reportpb.StreamBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.StreamBalanceRequest;
  return proto.reportpb.StreamBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.StreamBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.StreamBalanceRequest}
 */
proto.reportpb.StreamBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.StreamBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.StreamBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.StreamBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StreamBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.StreamBalanceRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.StreamBalanceRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
*/
proto.reportpb.StreamBalanceRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StreamBalanceRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string currencies = 9;
 * @return {!Array<string>}
 */
proto.reportpb.StreamBalanceRequest.prototype.getCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.addCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StreamBalanceRequest} returns this
 */
proto.reportpb.StreamBalanceRequest.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListBalanceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
balancesList: jspb.Message.toObjectList(msg.getBalancesList(),
    proto.reportpb.Balance.toObject, includeInstance),
summary: (f = msg.getSummary()) && proto.reportpb.ListBalanceSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceResponse}
 */
proto.reportpb.ListBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceResponse;
  return proto.reportpb.ListBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceResponse}
 */
proto.reportpb.ListBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Balance;
      reader.readMessage(value,proto.reportpb.Balance.deserializeBinaryFromReader);
      msg.addBalances(value);
      break;
    case 2:
      var value = new proto.reportpb.ListBalanceSummary;
      reader.readMessage(value,proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Balance.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListBalanceSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Balance balances = 1;
 * @return {!Array<!proto.reportpb.Balance>}
 */
proto.reportpb.ListBalanceResponse.prototype.getBalancesList = function() {
  return /** @type{!Array<!proto.reportpb.Balance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Balance, 1));
};


/**
 * @param {!Array<!proto.reportpb.Balance>} value
 * @return {!proto.reportpb.ListBalanceResponse} returns this
*/
proto.reportpb.ListBalanceResponse.prototype.setBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Balance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Balance}
 */
proto.reportpb.ListBalanceResponse.prototype.addBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Balance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListBalanceResponse} returns this
 */
proto.reportpb.ListBalanceResponse.prototype.clearBalancesList = function() {
  return this.setBalancesList([]);
};


/**
 * optional ListBalanceSummary summary = 2;
 * @return {?proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListBalanceSummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListBalanceSummary, 2));
};


/**
 * @param {?proto.reportpb.ListBalanceSummary|undefined} value
 * @return {!proto.reportpb.ListBalanceResponse} returns this
*/
proto.reportpb.ListBalanceResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceResponse} returns this
 */
proto.reportpb.ListBalanceResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
tdCashBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
tdEquity: jspb.Message.getFieldWithDefault(msg, 5, ""),
tdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 6, ""),
sdCashBalance: jspb.Message.getFieldWithDefault(msg, 7, ""),
sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
sdEquity: jspb.Message.getFieldWithDefault(msg, 10, ""),
sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 11, ""),
currency: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceSummary;
  return proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCashBalance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEquity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdAdjustedBalance(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdEquity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTdEquity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSdEquity();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reportpb.ListBalanceSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string td_cash_balance = 2;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string td_long_market_value = 3;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string td_short_market_value = 4;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string td_equity = 5;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string td_adjusted_balance = 6;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sd_cash_balance = 7;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sd_long_market_value = 8;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sd_short_market_value = 9;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sd_equity = 10;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sd_adjusted_balance = 11;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string currency = 12;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


goog.object.extend(exports, proto.reportpb);
