/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

import { ListReserveRequest } from '../../../proto/compliancepb/reserve_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Visibility as ViewIcon,
  Save as SaveIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import ReserveCalcDetailsTable from './ReserveCalcDetailsTable';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ReserveCalcTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    reserveServiceClient: reserve,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (settleDateValue ? '&settleDate=' + settleDateValue : '') +
      (codeValue ? '&code=' + codeValue : '') +
      (typeValue ? '&type=' + typeValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListReserveRequest();
    list.setType(typeValue);
    list.setCode(codeValue);

    if (settleDateValue) {
      let gglSettleDate = new google_date.Date();
      let [y, m, d] = settleDateValue.split('-');
      gglSettleDate.setYear(y);
      gglSettleDate.setMonth(m);
      gglSettleDate.setDay(d);
      list.setSettleDate(gglSettleDate);
    }

    reserve.listReserve(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().reservesList.map((data) => ({
        code: data.code,
        credit: formatCurrency(data.credit),
        debit: formatCurrency(data.debit),
        description: data.description,
        settleDate: formatPbDate(data.settleDate),
        type: data.type,
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Reserve Calculation');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  handleOpen(
                    rows[dataIndex].settleDate,
                    rows[dataIndex].type,
                    rows[dataIndex].code
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'code',
      label: 'Code',
    },
    {
      name: 'credit',
      label: 'Credit',
      options: {
        setCellProps: () => ({
          style: { align: 'right' },
        }),
      },
    },
    {
      name: 'debit',
      label: 'Debit',
      options: {
        setCellProps: () => ({
          style: { align: 'right' },
        }),
      },
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const fileName =
    'ReserveCalc_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 5, 6, 1, 4, 3, 2],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Reserve Calculation');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Reserve Calculation');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [settleDateValue, setSettleDateValue] = useState(
    moment(new Date(Date.now() - 86400000)).format('yyyy-MM-DD')
  );
  const [typeValue, setTypeValue] = useState('');
  const [codeValue, setCodeValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      settleDate: query.get('settleDate'),
      type: query.get('type'),
      code: query.get('code'),
    };

    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.code) {
      setCodeValue(data.code);
    }
    if (data.settleDate) {
      setSettleDateValue(data.settleDate);
    }

    setCurrentPath(path);
  }, [params]);

  const [selectedSettleDate, setSelectedSettleDate] = React.useState('');
  const [selectedType, setSelectedType] = React.useState('');
  const [selectedCode, setSelectedCode] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (settleDate, type, code) => {
    setSelectedSettleDate(settleDate);
    setSelectedType(type);
    setSelectedCode(code);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Settle Date"
                  type="date"
                  value={settleDateValue}
                  onChange={(e) => setSettleDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Type"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  label="Code"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={codeValue}
                  onChange={(e) => setCodeValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer} style={{ marginTop: 26 }}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Reserve Calculation'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <ReserveCalcDetailsTable
                settledate={selectedSettleDate}
                type={selectedType}
                code={selectedCode}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
