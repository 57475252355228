import { DownloadStatementRequest } from '../proto/reportpb/statement_grpc_web_pb';

import download from './DownloadService';
import { notifyError } from 'components/Notification/Notification';

export async function downloadMonthlyStatement(
  service,
  month,
  year,
  correspondent,
  accountNo,
  accountId
) {
  return new Promise((resolve, reject) => {
    const req = new DownloadStatementRequest();
    req.setMonth(month);
    req.setYear(year);
    req.setCorrespondent(correspondent);
    req.setAccountNo(accountNo);
    req.setAccountId(accountId);

    service.downloadStatement(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        download(response);
      }
    });
  });
}
