/*Service*/
import {
  ContactInfo,
  DeleteContactInfoRequest,
} from '../../../proto/accountpb/contactinfo_grpc_web_pb';

/*ReactJS*/
import React, { useState, useContext } from 'react';
import { ServiceContext } from 'context/ServiceContext';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Material Ui Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Mask*/
import InputMask from 'react-input-mask';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*=========================================================================================
  End of delete contact info function: Single
===========================================================================================*/

/*=========================================================================================
  Re-render component
===========================================================================================*/
function RenderComponent() {
  const [, setValue] = useState(1);
  return () => setValue((value) => ++value);
}
/*=========================================================================================
  End of re-render component
===========================================================================================*/

export default function ContactInfoTable({ accountid, contactinfolist }) {
  const rows = contactinfolist;

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();

  const reRender = RenderComponent(); //Re-renders component when something change in component state
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  /*=========================================================================================
  Add and Update contact info function
===========================================================================================*/
  function saveContactInfo(
    contactInformation,
    reRender,
    isAdd,
    closeModal,
    contactInfoId,
    accountId,
    contactInfoType,
    contactInfo
  ) {
    if (!contactInfoType && !contactInfo) {
      notifyError("You haven't input anything.");
      return;
    }
    if (!contactInfoType) {
      notifyError('Info type is required.');
      return;
    }
    if (!contactInfo) {
      notifyError('Contact info is required.');
      return;
    }
    if (contactInfoType === 'primary_email' || contactInfoType === 'email') {
      if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]*$/i.test(contactInfo)
      ) {
        notifyError('Invalid email address.');
        return;
      }
    }

    console.log(contactInfo);

    if (isAdd) {
      let addReq = new ContactInfo();
      addReq.setAccountId(accountId);
      addReq.setContactInfoType(contactInfoType);
      addReq.setContactInfo(contactInfo.toLowerCase());

      contactInformation.createContactInfo(addReq, {}, (err, res) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }
        let data = res.toObject().contactInfo;
        console.log(data);
        rows.push(data);
        notifySuccess('New contact info has been added.');
        reRender();
        closeModal();
      });
    } else {
      let updateReq = new ContactInfo();
      updateReq.setContactInfoId(contactInfoId);
      updateReq.setAccountId(accountId);
      updateReq.setContactInfoType(contactInfoType);
      updateReq.setContactInfo(contactInfo);

      contactInformation.updateContactInfo(updateReq, {}, (err) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        }
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].contactInfoId === contactInfoId) {
            rows[i].contactInfoType = contactInfoType;
            rows[i].contactInfo = contactInfo;
          }
        }
        notifySuccess('Contact info has been updated.');
        reRender();
        closeModal();
      });
    }
  }
  /*=========================================================================================
  End of Add and Update contact info function
===========================================================================================*/

  /*=========================================================================================
Delete contact info function: Multiple
===========================================================================================*/
  function removeContactInfo(
    contactInformation,
    reRender,
    contactInfoIds,
    handleClick,
    confirm
  ) {
    if (contactInfoIds.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        contactInfoIds.length +
        ' Contact info(s) in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        contactInfoIds.forEach(removeFunction);

        function removeFunction(item) {
          let deleteReq = new DeleteContactInfoRequest();
          deleteReq.setContactInfoId(item);

          contactInformation.deleteContactInfo(deleteReq, {}, (err) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
              return;
            }
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].contactInfoId === item) {
                rows.splice(c, 1);
              }
            }
            reRender();
          });
        }

        handleClick('', '', true);
        notifySuccess(contactInfoIds.length + ' Contact info has been deleted');
      })
      .catch(() => {
        console.log('red');
        /* ... */
      });
  }
  /*=========================================================================================
End of delete contact info function: Multiple
===========================================================================================*/

  /*=========================================================================================
  Delete contact info function: Single
===========================================================================================*/
  function removeContactInfoSingle(
    contactInformation,
    reRender,
    contactInfoId,
    contactInfo,
    confirm
  ) {
    confirm({
      description:
        "You are about to delete Contact info '" +
        contactInfo +
        "' in your system. Please confirm your action.",
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeleteContactInfoRequest();
        deleteReq.setContactInfoId(contactInfoId);

        contactInformation.deleteContactInfo(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
            return;
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].contactInfoId === contactInfoId) {
              rows.splice(c, 1);
            }
          }
          reRender();
        });
        notifySuccess('Contact info has been deleted');
      })
      .catch(() => {
        // showToastMessage("error", "System error.");
      });
  }
  /*=========================================================================================
  Table configuration
  ===========================================================================================*/

  const { contactInfoServiceClient: contactInformation } = useContext(
    ServiceContext
  );

  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const [
    isVisibleContactInfoType,
    setIsVisibleContactInfoType,
  ] = React.useState(true);
  const [isVisibleContactInfo, setIsVisibleContactInfo] = React.useState(true);

  const columns = [
    {
      id: '1',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '2',
      name: '',
      label: '',
      options: {
        display: true,
        viewColumns: false,
        empty: true,
      },
    },
    {
      id: '3',
      name: 'contactInfoType',
      label: 'Contact Info Type',
    },
    {
      id: '4',
      name: 'contactInfo',
      label: 'Contact Info',
    },
  ];

  const handleClick = (event, contactInfoId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(contactInfoId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, contactInfoId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
      setRowID([]);
    }
  };

  const isSelected = (contactInfoId) => selected.indexOf(contactInfoId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].contactInfoId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rows[rowIndex].contactInfoId}
        selected={isItemSelected}
      >
        {isVisibleContactInfoType || isVisibleContactInfo ? (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) =>
                handleClick(event, rows[rowIndex].contactInfoId)
              }
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        ) : null}
        {isVisibleContactInfoType || isVisibleContactInfo ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="delete"
              onClick={() => {
                removeContactInfoSingle(
                  contactInformation,
                  reRender,
                  rows[rowIndex].contactInfoId,
                  rows[rowIndex].contactInfo,
                  confirm
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleContactInfoType || isVisibleContactInfo ? (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="edit"
              onClick={() => handleOpen(rows[rowIndex], false)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        ) : null}
        {isVisibleContactInfoType ? (
          <TableCell align="left">{rows[rowIndex].contactInfoType}</TableCell>
        ) : null}
        {isVisibleContactInfo ? (
          <TableCell align="left">{rows[rowIndex].contactInfo}</TableCell>
        ) : null}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    if (column === 'contactInfoType') {
      if (action === 'remove') {
        setIsVisibleContactInfoType(false);
      } else {
        setIsVisibleContactInfoType(true);
      }
    }

    if (column === 'contactInfo') {
      if (action === 'remove') {
        setIsVisibleContactInfo(false);
      } else {
        setIsVisibleContactInfo(true);
      }
    }
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
  };

  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/

  /*========================================================================================= 
  Contact info modal
  ===========================================================================================*/
  const [contactInfoId, setContactInfoId] = React.useState('');
  const [maskValue, setMaskValue] = React.useState('+1 (999)  9999999');
  const [
    modalContactInfoTypeValue,
    setModalContactInfoTypeValue,
  ] = React.useState('');
  const [modalContactInfoValue, setModalContactInfoValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalContactInfoTypeValue('');
      setModalContactInfoValue('');
      setMaskValue('+1 (999)  9999999');

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setContactInfoId(rowData.contactInfoId);
      setModalContactInfoTypeValue(rowData.contactInfoType);
      setModalContactInfoValue(rowData.contactInfo);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*========================================================================================= 
  End of contact info modal
  ===========================================================================================*/

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeContactInfo(
                  contactInformation,
                  reRender,
                  rowID,
                  handleClick,
                  confirm
                );
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Contact Informations'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Contact
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="contactInfoType" shrink>
                      Contact Info Type
                    </InputLabel>
                    <Select
                      displayEmpty
                      labelId="contactInfoType"
                      fullWidth
                      value={modalContactInfoTypeValue}
                      onChange={(e) =>
                        setModalContactInfoTypeValue(e.target.value)
                      }
                    >
                      <MenuItem value="primary">Primary</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                      <MenuItem value="office">Office</MenuItem>
                      <MenuItem value="primary_email">Primary Email</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    {modalContactInfoTypeValue === 'primary' ||
                    modalContactInfoTypeValue === 'mobile' ||
                    modalContactInfoTypeValue === 'office' ? (
                      <InputMask
                        mask={maskValue}
                        value={modalContactInfoValue}
                        onChange={(e) =>
                          setModalContactInfoValue(e.target.value)
                        }
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 11);
                        }}
                      >
                        {() => (
                          <TextField
                            label="Contact Info"
                            type="text"
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </InputMask>
                    ) : (
                      <TextField
                        label="Contact Info"
                        type="email"
                        fullWidth={true}
                        value={modalContactInfoValue}
                        onChange={(e) =>
                          setModalContactInfoValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveContactInfo(
                          contactInformation,
                          reRender,
                          modalAddAction,
                          handleClose,
                          contactInfoId,
                          accountid,
                          modalContactInfoTypeValue,
                          modalContactInfoValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
