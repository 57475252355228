/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListProfitAndLossRequest } from '../../../proto/reportpb/profitandloss_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import { Box, TextField, Tooltip, IconButton } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ProfitAndLossTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    profitAndLossServiceClient: profitAndLoss,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListProfitAndLossRequest();
    list.setAccountNo(accountNoValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setSymbol(symbolValue);

    profitAndLoss.listProfitAndLoss(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().profitandlossList.map((data) => ({
        accountId: data.accountId,
        accountNo: data.accountNo,
        buyDate: formatPbDate(data.buyDate),
        buyNetAmt: formatCurrency(data.buyNetAmt),
        buyPrice: formatCurrency(data.buyPrice),
        buyQty: data.buyQty,
        closingPrice: formatCurrency(data.closingPrice),
        contraTransId: data.contraTransId,
        entryType: data.entryType,
        originalCusip: data.originalCusip,
        plId: data.plId,
        plOriginalId: data.plOriginalId,
        positionQty: data.positionQty,
        price: formatCurrency(data.price),
        qty: data.qty,
        realized: formatCurrency(data.realized),
        sellDate: formatPbDate(data.sellDate),
        sellNetAmt: formatCurrency(data.sellNetAmt),
        sellPrice: formatCurrency(data.sellPrice),
        sellQty: data.sellQty,
        symbol: data.symbol,
        systemDate: formatPbDate(data.systemDate),
        unrealized: formatCurrency(data.unrealized),
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Profit And Loss');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    return { color: v < 0 && '#f44336' };
  };

  const columns = [
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'buyDate',
      label: 'Buy Date',
    },
    {
      name: 'buyNetAmt',
      label: 'Buy Net Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].buyNetAmt.replace('$', '');
          const f = rows[dataIndex].buyNetAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'buyPrice',
      label: 'Buy Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].buyPrice.replace('$', '');
          const f = rows[dataIndex].buyPrice;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'buyQty',
      label: 'Buy Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].buyQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].closingPrice.replace('$', '');
          const f = rows[dataIndex].closingPrice;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'entryType',
      label: 'Entry Type',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'positionQty',
      label: 'Position Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].positionQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].price.replace('$', '');
          const f = rows[dataIndex].price;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].qty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'realized',
      label: 'Realized (+Gain/-Loss)',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].realized.replace('$', '');
          const f = rows[dataIndex].realized;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'sellDate',
      label: 'Sell Date',
    },
    {
      name: 'sellNetAmt',
      label: 'Sell Net Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].sellNetAmt.replace('$', '');
          const f = rows[dataIndex].sellNetAmt;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'sellPrice',
      label: 'Sell Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].sellPrice.replace('$', '');
          const f = rows[dataIndex].sellPrice;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'sellQty',
      label: 'Sell Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].sellQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'unrealized',
      label: 'Unrealized (+Gain/-Loss)',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].unrealized.replace('$', '');
          const f = rows[dataIndex].unrealized;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
  ];

  const fileName =
    'ProfitAndLoss_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      17,
      1,
      12,
      0,
      7,
      16,
      6,
      10,
      9,
      8,
      4,
      3,
      2,
      15,
      14,
      13,
      5,
      11,
      18,
    ],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Profit And Loss');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Profit And Loss');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      systemDate: query.get('systemDate'),
      accountNo: query.get('accountNo'),
      symbol: query.get('symbol'),
    };

    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }

    setCurrentPath(path);

    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          let previousDate = new Date(
            formatPbDate(res.toObject().profile.systemDate)
          );
          previousDate.setDate(previousDate.getDate() - 1);
          setSystemDateValue(
            moment(new Date(previousDate)).format('yyyy-MM-DD')
          );
        }
      });
    }
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Profit and Loss'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
