import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// components
import Layout from './Layout';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GetTitle from 'components/title';

// pages
import Error from 'pages/error';
import Login from 'pages/login';
import MarginRequirementDetails from 'pages/MarginRequirement/MarginRequirementDetails';
import SegregationLockupTable from 'pages/Segregation/Components/SegregationLockUpTable';
import Transaction from 'pages/Transaction/Transaction';

// context
import { useUserDispatch, useUserState, signOut } from 'context/UserContext';
import { ServiceContext } from 'context/ServiceContext';
import { ReadProfileRequest } from 'proto/admpb/profile_pb';
import { ProfileContext, useProfile } from 'context/ProfileContext';
import openReplayTracker from 'OpenReplay';

openReplayTracker.start();

export default function App() {
  // global
  var { googleAccount } = useUserState();
  var userDispatch = useUserDispatch();
  var { profileServiceClient } = useContext(ServiceContext);
  var { dispatch: profileDispatch } = useContext(ProfileContext);
  const { authError } = useContext(ServiceContext);
  const profile = useProfile();

  // local
  var [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = GetTitle();
    // if googleAccount is hydrated from local storage,
    // make sure the token works with API. If not, go back to unauthorized.
    // `googleAccount` is embedded in `profileServiceClient` in ServiceContext.
    if (googleAccount) {
      const req = new ReadProfileRequest();
      profileServiceClient.readProfile(req, {}, (err, res) => {
        if (err) {
          signOut(userDispatch);
        } else {
          profileDispatch({ type: 'UPDATE', profile: res });
        }
        setIsLoading(false);
      });

      // set openreplay tracker id
      const { email } = jwtDecode(googleAccount.credential);
      openReplayTracker.setUserID(email);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAccount]);

  const authenticated = () => {
    if (window.env.SKIP_GRPC_AUTHENTICATION === true) {
      return true;
    }
    if (authError || !profile) {
      return false;
    }
    return !!googleAccount;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <GoogleOAuthProvider clientId={window.env.GOOGLE_LOGIN_CLIENT_ID}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/app/home" />} />
          <Route exact path="/app" />
          {/*removed recirect in this line of code [render={() => <Redirect to="/app/home" />}] */}
          <PrivateRoute path="/app" component={Layout} />
          <PrivateRoute
            path="/margin-interest/margin-requirement-details/:tradeDate?/:masterAccountNo?"
            component={MarginRequirementDetails}
          />
          <PrivateRoute
            path="/segregation/segregation-lockup/:settleDate?/:numberOrCusip?/:type?"
            component={SegregationLockupTable}
          />
          <PrivateRoute
            path="/app/trns/transaction/cancel-and-correct/:trnsId?/:accountId?"
            component={Transaction}
          />
          <PublicRoute path="/login" component={Login} />
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (authenticated()) return React.createElement(component, props);

          if (props.location.search)
            sessionStorage.setItem(
              'search',
              props.location.search.substring(1, props.location.search.length)
            );

          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }}
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!authenticated()) return React.createElement(component, props);

          const searchQuery = sessionStorage.getItem('search');
          sessionStorage.removeItem('search');

          return (
            <Redirect
              to={{
                pathname: props.location?.state?.from?.pathname || '/',
                search: searchQuery || '',
              }}
            />
          );
        }}
      />
    );
  }
}
