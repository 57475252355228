// source: acatspb/outgoing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commonpb_file_pb = require('../commonpb/file_pb.js');
goog.object.extend(proto, commonpb_file_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.acatspb.CreatePendingResponse', null, global);
goog.exportSymbol('proto.acatspb.GetDefaultsResponse', null, global);
goog.exportSymbol('proto.acatspb.ListPositionRequest', null, global);
goog.exportSymbol('proto.acatspb.ListPositionResponse', null, global);
goog.exportSymbol('proto.acatspb.ListPositionSummary', null, global);
goog.exportSymbol('proto.acatspb.Outgoing', null, global);
goog.exportSymbol('proto.acatspb.Position', null, global);
goog.exportSymbol('proto.acatspb.UpdateQtyReponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.Outgoing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.acatspb.Outgoing.repeatedFields_, null);
};
goog.inherits(proto.acatspb.Outgoing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.Outgoing.displayName = 'proto.acatspb.Outgoing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.Position.displayName = 'proto.acatspb.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.ListPositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.ListPositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.ListPositionRequest.displayName = 'proto.acatspb.ListPositionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.ListPositionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.acatspb.ListPositionResponse.repeatedFields_, null);
};
goog.inherits(proto.acatspb.ListPositionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.ListPositionResponse.displayName = 'proto.acatspb.ListPositionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.ListPositionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.ListPositionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.ListPositionSummary.displayName = 'proto.acatspb.ListPositionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.GetDefaultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.GetDefaultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.GetDefaultsResponse.displayName = 'proto.acatspb.GetDefaultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.UpdateQtyReponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.UpdateQtyReponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.UpdateQtyReponse.displayName = 'proto.acatspb.UpdateQtyReponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.CreatePendingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.CreatePendingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.CreatePendingResponse.displayName = 'proto.acatspb.CreatePendingResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.acatspb.Outgoing.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.Outgoing.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.Outgoing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.Outgoing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.Outgoing.toObject = function(includeInstance, msg) {
  var f, obj = {
positionIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
submittingDtcc: jspb.Message.getFieldWithDefault(msg, 2, ""),
receiverDtcc: jspb.Message.getFieldWithDefault(msg, 3, ""),
delivererDtcc: jspb.Message.getFieldWithDefault(msg, 4, ""),
positionQty: jspb.Message.getFieldWithDefault(msg, 5, ""),
costBasis: jspb.Message.getFieldWithDefault(msg, 6, ""),
transferType: jspb.Message.getFieldWithDefault(msg, 7, ""),
receiverCorrespondent: jspb.Message.getFieldWithDefault(msg, 8, ""),
delivererAccountNo: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.Outgoing}
 */
proto.acatspb.Outgoing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.Outgoing;
  return proto.acatspb.Outgoing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.Outgoing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.Outgoing}
 */
proto.acatspb.Outgoing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPositionIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubmittingDtcc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverDtcc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelivererDtcc(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionQty(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostBasis(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverCorrespondent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelivererAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.Outgoing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.Outgoing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.Outgoing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.Outgoing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSubmittingDtcc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceiverDtcc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDelivererDtcc();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPositionQty();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCostBasis();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReceiverCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDelivererAccountNo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * repeated string position_ids = 1;
 * @return {!Array<string>}
 */
proto.acatspb.Outgoing.prototype.getPositionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setPositionIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.addPositionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.clearPositionIdsList = function() {
  return this.setPositionIdsList([]);
};


/**
 * optional string submitting_dtcc = 2;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getSubmittingDtcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setSubmittingDtcc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string receiver_dtcc = 3;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getReceiverDtcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setReceiverDtcc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string deliverer_dtcc = 4;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getDelivererDtcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setDelivererDtcc = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string position_qty = 5;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getPositionQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setPositionQty = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cost_basis = 6;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string transfer_type = 7;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string receiver_correspondent = 8;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getReceiverCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setReceiverCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string deliverer_account_no = 9;
 * @return {string}
 */
proto.acatspb.Outgoing.prototype.getDelivererAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Outgoing} returns this
 */
proto.acatspb.Outgoing.prototype.setDelivererAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
acatsControlNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 6, ""),
broker: jspb.Message.getFieldWithDefault(msg, 7, ""),
type: jspb.Message.getFieldWithDefault(msg, 8, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
originalCusip: jspb.Message.getFieldWithDefault(msg, 10, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 11, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 12, ""),
symbolDescription: jspb.Message.getFieldWithDefault(msg, 13, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 14, ""),
closingPrice: jspb.Message.getFieldWithDefault(msg, 15, ""),
qty: jspb.Message.getFieldWithDefault(msg, 16, ""),
marketValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
costBasis: jspb.Message.getFieldWithDefault(msg, 18, ""),
originalDelivererNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
originalReceiverNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
status: jspb.Message.getFieldWithDefault(msg, 21, ""),
positionId: jspb.Message.getFieldWithDefault(msg, 22, ""),
accountId: jspb.Message.getFieldWithDefault(msg, 23, ""),
submitterParticipantNo: jspb.Message.getFieldWithDefault(msg, 24, ""),
receiverParticipantNo: jspb.Message.getFieldWithDefault(msg, 25, ""),
delivererParticipantNo: jspb.Message.getFieldWithDefault(msg, 26, ""),
originalReceiverCorrespondent: jspb.Message.getFieldWithDefault(msg, 27, ""),
delivererAccountNo: jspb.Message.getFieldWithDefault(msg, 28, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.Position}
 */
proto.acatspb.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.Position;
  return proto.acatspb.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.Position}
 */
proto.acatspb.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcatsControlNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostBasis(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalDelivererNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverNumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionId(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubmitterParticipantNo(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverParticipantNo(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelivererParticipantNo(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCorrespondent(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelivererAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCostBasis();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOriginalDelivererNumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getOriginalReceiverNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPositionId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSubmitterParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getReceiverParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDelivererParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getOriginalReceiverCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getDelivererAccountNo();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
};


/**
 * optional string acats_control_number = 1;
 * @return {string}
 */
proto.acatspb.Position.prototype.getAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.acatspb.Position.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.acatspb.Position.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.acatspb.Position.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.acatspb.Position.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_designator = 6;
 * @return {string}
 */
proto.acatspb.Position.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.acatspb.Position.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.acatspb.Position.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.type.Date system_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.acatspb.Position.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.acatspb.Position} returns this
*/
proto.acatspb.Position.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.acatspb.Position.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string original_cusip = 10;
 * @return {string}
 */
proto.acatspb.Position.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string symbol = 11;
 * @return {string}
 */
proto.acatspb.Position.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string cusip = 12;
 * @return {string}
 */
proto.acatspb.Position.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string symbol_description = 13;
 * @return {string}
 */
proto.acatspb.Position.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string asset_type = 14;
 * @return {string}
 */
proto.acatspb.Position.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string closing_price = 15;
 * @return {string}
 */
proto.acatspb.Position.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string qty = 16;
 * @return {string}
 */
proto.acatspb.Position.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string market_value = 17;
 * @return {string}
 */
proto.acatspb.Position.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string cost_basis = 18;
 * @return {string}
 */
proto.acatspb.Position.prototype.getCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string original_deliverer_number = 19;
 * @return {string}
 */
proto.acatspb.Position.prototype.getOriginalDelivererNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setOriginalDelivererNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string original_receiver_number = 20;
 * @return {string}
 */
proto.acatspb.Position.prototype.getOriginalReceiverNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setOriginalReceiverNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string status = 21;
 * @return {string}
 */
proto.acatspb.Position.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string position_id = 22;
 * @return {string}
 */
proto.acatspb.Position.prototype.getPositionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string account_id = 23;
 * @return {string}
 */
proto.acatspb.Position.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string submitter_participant_no = 24;
 * @return {string}
 */
proto.acatspb.Position.prototype.getSubmitterParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setSubmitterParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string receiver_participant_no = 25;
 * @return {string}
 */
proto.acatspb.Position.prototype.getReceiverParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setReceiverParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string deliverer_participant_no = 26;
 * @return {string}
 */
proto.acatspb.Position.prototype.getDelivererParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setDelivererParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string original_receiver_correspondent = 27;
 * @return {string}
 */
proto.acatspb.Position.prototype.getOriginalReceiverCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setOriginalReceiverCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string deliverer_account_no = 28;
 * @return {string}
 */
proto.acatspb.Position.prototype.getDelivererAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.Position} returns this
 */
proto.acatspb.Position.prototype.setDelivererAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.ListPositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.ListPositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.ListPositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListPositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
symbol: jspb.Message.getFieldWithDefault(msg, 9, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 10, ""),
pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.ListPositionRequest}
 */
proto.acatspb.ListPositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.ListPositionRequest;
  return proto.acatspb.ListPositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.ListPositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.ListPositionRequest}
 */
proto.acatspb.ListPositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 11:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.ListPositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.ListPositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.ListPositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListPositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.acatspb.ListPositionRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
*/
proto.acatspb.ListPositionRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.acatspb.ListPositionRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string symbol = 9;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cusip = 10;
 * @return {string}
 */
proto.acatspb.ListPositionRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional utilspb.Pagination pagination = 11;
 * @return {?proto.utilspb.Pagination}
 */
proto.acatspb.ListPositionRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 11));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.acatspb.ListPositionRequest} returns this
*/
proto.acatspb.ListPositionRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.acatspb.ListPositionRequest} returns this
 */
proto.acatspb.ListPositionRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.acatspb.ListPositionRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.acatspb.ListPositionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.ListPositionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.ListPositionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.ListPositionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListPositionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.acatspb.Position.toObject, includeInstance),
summary: (f = msg.getSummary()) && proto.acatspb.ListPositionSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.ListPositionResponse}
 */
proto.acatspb.ListPositionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.ListPositionResponse;
  return proto.acatspb.ListPositionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.ListPositionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.ListPositionResponse}
 */
proto.acatspb.ListPositionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.acatspb.Position;
      reader.readMessage(value,proto.acatspb.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 2:
      var value = new proto.acatspb.ListPositionSummary;
      reader.readMessage(value,proto.acatspb.ListPositionSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.ListPositionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.ListPositionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.ListPositionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListPositionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.acatspb.Position.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.acatspb.ListPositionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Position positions = 1;
 * @return {!Array<!proto.acatspb.Position>}
 */
proto.acatspb.ListPositionResponse.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.acatspb.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.acatspb.Position, 1));
};


/**
 * @param {!Array<!proto.acatspb.Position>} value
 * @return {!proto.acatspb.ListPositionResponse} returns this
*/
proto.acatspb.ListPositionResponse.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.acatspb.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.acatspb.Position}
 */
proto.acatspb.ListPositionResponse.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.acatspb.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.acatspb.ListPositionResponse} returns this
 */
proto.acatspb.ListPositionResponse.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * optional ListPositionSummary summary = 2;
 * @return {?proto.acatspb.ListPositionSummary}
 */
proto.acatspb.ListPositionResponse.prototype.getSummary = function() {
  return /** @type{?proto.acatspb.ListPositionSummary} */ (
    jspb.Message.getWrapperField(this, proto.acatspb.ListPositionSummary, 2));
};


/**
 * @param {?proto.acatspb.ListPositionSummary|undefined} value
 * @return {!proto.acatspb.ListPositionResponse} returns this
*/
proto.acatspb.ListPositionResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.acatspb.ListPositionResponse} returns this
 */
proto.acatspb.ListPositionResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.acatspb.ListPositionResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.ListPositionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.ListPositionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.ListPositionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListPositionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.ListPositionSummary}
 */
proto.acatspb.ListPositionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.ListPositionSummary;
  return proto.acatspb.ListPositionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.ListPositionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.ListPositionSummary}
 */
proto.acatspb.ListPositionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.ListPositionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.ListPositionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.ListPositionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListPositionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.acatspb.ListPositionSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.acatspb.ListPositionSummary} returns this
 */
proto.acatspb.ListPositionSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.GetDefaultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.GetDefaultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.GetDefaultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.GetDefaultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
submittingDtcc: jspb.Message.getFieldWithDefault(msg, 1, ""),
receiverDtcc: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.GetDefaultsResponse}
 */
proto.acatspb.GetDefaultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.GetDefaultsResponse;
  return proto.acatspb.GetDefaultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.GetDefaultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.GetDefaultsResponse}
 */
proto.acatspb.GetDefaultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubmittingDtcc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiverDtcc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.GetDefaultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.GetDefaultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.GetDefaultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.GetDefaultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubmittingDtcc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReceiverDtcc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string submitting_dtcc = 1;
 * @return {string}
 */
proto.acatspb.GetDefaultsResponse.prototype.getSubmittingDtcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.GetDefaultsResponse} returns this
 */
proto.acatspb.GetDefaultsResponse.prototype.setSubmittingDtcc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string receiver_dtcc = 2;
 * @return {string}
 */
proto.acatspb.GetDefaultsResponse.prototype.getReceiverDtcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.GetDefaultsResponse} returns this
 */
proto.acatspb.GetDefaultsResponse.prototype.setReceiverDtcc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.UpdateQtyReponse.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.UpdateQtyReponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.UpdateQtyReponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.UpdateQtyReponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.UpdateQtyReponse}
 */
proto.acatspb.UpdateQtyReponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.UpdateQtyReponse;
  return proto.acatspb.UpdateQtyReponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.UpdateQtyReponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.UpdateQtyReponse}
 */
proto.acatspb.UpdateQtyReponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.UpdateQtyReponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.UpdateQtyReponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.UpdateQtyReponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.UpdateQtyReponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.CreatePendingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.CreatePendingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.CreatePendingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.CreatePendingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.CreatePendingResponse}
 */
proto.acatspb.CreatePendingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.CreatePendingResponse;
  return proto.acatspb.CreatePendingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.CreatePendingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.CreatePendingResponse}
 */
proto.acatspb.CreatePendingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.CreatePendingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.CreatePendingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.CreatePendingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.CreatePendingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.acatspb);
