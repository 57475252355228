/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListAccountManagementSegregationDetailRequest } from '../../../proto/reportpb/accountmanagement_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function SegregationDetailsTable({
  settledate,
  masteraccountno,
}) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  const { accountManagementServiceClient: accountManagement } = useContext(
    ServiceContext
  );
  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'excessMarginAmt',
      label: 'Excess Margin Amt',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].excessMarginAmt;
          const f = formatCurrency(v);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'excessMarginQty',
      label: 'Excess Margin Qty',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].excessMarginQty;
          return <div align="right">{v}</div>;
        },
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].marketValue;
          const f = formatCurrency(v);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'quantity',
      label: 'Quantity',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].quantity;
          return <div align="right">{v}</div>;
        },
      },
    },
    {
      name: 'segAmt',
      label: 'Seg Amt',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].segAmt;
          const f = formatCurrency(v);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'segQty',
      label: 'Seg Qty',
      visibleState: useState(true),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].segQty;
          return <div align="right">{v}</div>;
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
      visibleState: useState(true),
      align: 'left',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      visibleState: useState(true),
      align: 'left',
    },
  ];

  // const setVisibles = {};
  // columns.forEach((col) => {
  //   col.options = {
  //     display: col.visibleState[0],
  //   };
  //   setVisibles[col.name] = col.visibleState[1];
  //   return;
  // });

  const options = {
    filterType: 'select',
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    //selectableRowsHeader: false,
    columnOrder: [4, 0, 8, 9, 3, 6, 1, 5, 7, 2],
    selectableRows: 'none',
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  useEffect(() => {
    let list = new ListAccountManagementSegregationDetailRequest();

    let gglSettleDate = new google_date.Date();
    let [y, m, d] = moment(new Date(settledate))
      .format('yyyy-MM-DD')
      .split('-');
    gglSettleDate.setYear(y);
    gglSettleDate.setMonth(m);
    gglSettleDate.setDay(d);
    list.setSettleDate(gglSettleDate);
    list.setMasterAccountNo(masteraccountno);

    accountManagement.listAccountManagementSegregationDetail(
      list,
      {},
      (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err);
          return;
        }
        setRows(res.toObject().segregationDetailsList);
      }
    );
  }, [accountManagement, settledate, masteraccountno]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={'Segregation Details'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
