/*ReactJS*/
import React, { useState, useContext, useEffect } from 'react';
import useStyles from '../../../styles';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

import { ServiceContext } from 'context/ServiceContext';
import { AssetTransfer } from '../../../proto/compliancepb/assettransfer_grpc_web_pb';

export default function AssetTransferModal({
  onClose: handleClose,
  open: isOpen,
  value: selectedRows,
  posqty: posQty,
  costbasis: costBasis,
}) {
  const { assetTransferServiceClient: assetTransferClient } = useContext(
    ServiceContext
  );

  const classes = useStyles();
  const [modalData, setModalData] = useState({
    transferType: 'FUL',
    positionQty: posQty,
    costBasis: costBasis,
  });
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (modalData.transferType === 'FUL') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    setModalData({
      ...modalData,
      positionQty: posQty,
      costBasis: costBasis,
    });
    // eslint-disable-next-line
  }, [modalData.transferType]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  const handleGenerate = async () => {
    if (!modalData.transferType) {
      notifyError('Transfer Type is required.');
      return;
    }

    if (modalData.transferType === 'PTR') {
      if (isNaN(Number(modalData.costBasis))) {
        notifyError('Partial Cost Basis should be a number.');
        return;
      }
      if (Number(modalData.costBasis) <= 0) {
        notifyError('Partial Cost Basis should be greater than 0.');
        return;
      }

      if (!modalData.positionQty) {
        notifyError('Qty is required.');
        return;
      }
      if (isNaN(Number(modalData.positionQty))) {
        notifyError('Partial Position Quantity should be a number.');
        return;
      }
      if (Number(modalData.positionQty) <= 0) {
        notifyError('Partial Position Quantity should be greater than 0.');
        return;
      }

      if (Number(modalData.positionQty) === Number(posQty)) {
        notifyError(
          'Partial Position Quantity should not be equal to Position Quantity.'
        );
        return;
      }

      if (Number(modalData.positionQty) > Number(posQty)) {
        notifyError(
          'Partial Position Quantity should be less than Position Qty.'
        );
        return;
      }
    }

    try {
      setLoading(true);

      let position_ids = [];

      selectedRows.forEach(function(row) {
        position_ids.push(row.positionId);
      });

      let req = new AssetTransfer();
      req.setPositionIdsList(position_ids);
      req.setTransferType(modalData.transferType);
      req.setPositionQty(modalData.positionQty);
      req.setCostBasis(modalData.costBasis);

      assetTransferClient.updateAssetTransferQty(req, {}, (err, res) => {
        if (err) {
          notifyError(err.message);
        } else {
          notifySuccess('Position Qty and Cost Basis has been updated.');
          setLoading(false);
          handleClose(true);
        }
      });
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        if (!loading) {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Update Quantity and Cost Basis
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel shrink>Transfer Type</InputLabel>
                  <Select
                    fullWidth
                    name="transferType"
                    value={modalData.transferType}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Blank</em>
                    </MenuItem>
                    <MenuItem value="FUL">Full Transfer</MenuItem>
                    <MenuItem value="PTR">
                      Partial Transfer Request (Receiver)
                    </MenuItem>
                  </Select>
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    id="positionQty"
                    disabled={isDisabled}
                    name="positionQty"
                    label="Position Qty"
                    value={modalData.positionQty}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    id="costBasis"
                    disabled={isDisabled}
                    name="costBasis"
                    label="Cost Basis"
                    value={modalData.costBasis}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleGenerate}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
