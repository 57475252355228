/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListSegregationLockupRequest } from '../../../proto/reportpb/segregation_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Material UI Components*/
import { Box, IconButton, Tooltip } from '@material-ui/core';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Toast Notification*/
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from 'components/Notification/Notification';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function SegregationLockUpTable(props) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    segregationServiceClient: segregation,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const settleDate = props.match.params.settleDate;
    const numberOrCusip = props.match.params.numberOrCusip;
    const type = props.match.params.type;

    let list = new ListSegregationLockupRequest();
    let gglSettleDate = new google_date.Date();
    let [y, m, d] = settleDate.split('-');
    gglSettleDate.setYear(y);
    gglSettleDate.setMonth(m);
    gglSettleDate.setDay(d);
    list.setSettleDate(gglSettleDate);

    if (type === 'Account') {
      list.setMasterAccountNo(numberOrCusip);
    }
    if (type === 'Symbol') {
      list.setOriginalCusip(numberOrCusip);
    }

    segregation.listSegregationLockup(list, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }
      const rows = res.toObject().segregationLockupsList.map((data) => ({
        settleDate: formatPbDate(data.settleDate),
        masterAccountNo: data.masterAccountNo,
        accountName: data.accountName,
        originalCusip: data.originalCusip,
        cusip: data.cusip,
        symbol: data.symbol,
        symbolDescription: data.symbolDescription,
        quantity: data.quantity,
        closingPrice: formatCurrency(data.closingPrice),
        marketValue: formatCurrency(data.marketValue),
        previousSegQty: data.previousSegQty,
        previousMarginPaidQty: data.previousMarginPaidQty,
        previousShortPaidQty: data.previousShortPaidQty,
        segQty: data.segQty,
        marginPaidQty: data.marginPaidQty,
        shortPaidQty: data.shortPaidQty,
        segAmt: formatCurrency(data.segAmt),
        marginPaidAmt: formatCurrency(data.marginPaidAmt),
        shortPaidAmt: formatCurrency(data.shortPaidAmt),
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
    });
  }, [
    segregation,
    props.match.params.settleDate,
    props.match.params.numberOrCusip,
    props.match.params.type,
  ]);

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Segregation Lockup');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'settleDate',
      label: 'Settle Date',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'quantity',
      label: 'Quantity',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].quantity;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].closingPrice;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].marketValue;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'previousSegQty',
      label: 'Previous Seg Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].previousSegQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'previousMarginPaidQty',
      label: 'Previous Margin Paid Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].previousMarginPaidQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'previousShortPaidQty',
      label: 'Previous Short Paid Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].previousShortPaidQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'segQty',
      label: 'Seg Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].segQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'marginPaidQty',
      label: 'Margin Paid Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].marginPaidQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'shortPaidQty',
      label: 'Short Paid Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].shortPaidQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'segAmt',
      label: 'Seg Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].segAmt;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'marginPaidAmt',
      label: 'Margin Paid Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].marginPaidAmt;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'shortPaidAmt',
      label: 'Short Paid Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].shortPaidAmt;
          return <div align="right">{f}</div>;
        },
      },
    },
  ];

  const fileName =
    'SegregationLockup_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
    ],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Segregation Lockup');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Segregation Lockup');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={[
            'closingPrice',
            'marketValue',
            'segAmt',
            'marginPaidAmt',
            'shortPaidAmt',
          ]}
          columnsWithQty={[
            'quantity',
            'previousSegQty',
            'previousMarginPaidQty',
            'previousShortPaidQty',
            'segQty',
            'marginPaidQty',
            'shortPaidQty',
          ]}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <Box component="div">
          {rows && (
            <CSVLink
              id="csvDL"
              data={rows}
              filename={fileName}
              target="_blank"
            />
          )}
          <MuiThemeProvider theme={tableTheme()}>
            <MUIDataTable
              title={'Segregation Lockup'}
              data={rows}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Box>
      </div>
    </React.Fragment>
  );
}
