import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from '../../styles';
import Overview from './Components/Overview';
import { CreateReport, ViewReport, UpdateReport } from './Components/Report';
import { useLocation } from 'react-router-dom';
import { ViewConfig } from './Components/Config';
import { ViewExecution, EditExecution } from './Components/Execution';

export default function SelfServiceReporting() {
  const classes = useStyles();

  let location = useLocation();

  let searchParams = new URLSearchParams(location.search);
  const searchType = searchParams.get('type');

  let childComponent;
  switch (searchType) {
    case 'create-report':
      childComponent = <CreateReport />;
      break;

    case 'update-report':
      childComponent = (
        <UpdateReport reportId={searchParams.get('report-id')} />
      );
      break;

    case 'view-report':
      childComponent = <ViewReport reportId={searchParams.get('report-id')} />;
      break;

    case 'view-config':
      childComponent = (
        <ViewConfig
          reportId={searchParams.get('report-id')}
          configId={searchParams.get('config-id')}
        />
      );
      break;

    case 'view-execution':
      childComponent = (
        <ViewExecution executionId={searchParams.get('execution-id')} />
      );
      break;

    case 'edit-execution':
      childComponent = (
        <EditExecution executionId={searchParams.get('execution-id')} />
      );
      break;

    default:
      childComponent = <Overview />;
  }

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <Box>
          <Typography
            variant="h2"
            className={classes.textBold}
            align="left"
            gutterBottom={true}
          >
            Self Service Reporting
          </Typography>
        </Box>
        <Box mt={2}>{childComponent}</Box>
      </div>
    </React.Fragment>
  );
}
