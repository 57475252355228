/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  CreateBankAddressRequest,
  UpdateBankAddressRequest,
  ListBankAddressRequest,
  DeleteBankAddressRequest,
} from '../../../proto/bankpb/address_grpc_web_pb.js';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Checkbox,
} from '@material-ui/core';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Country Dropdown Component*/
import StateSelect from './../../../components/Dropdown/State';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';

/*=========================================================================================
Add and Update Bank Address function
===========================================================================================*/
function saveBankAddress(
  bankaddress,
  rows,
  isAdd,
  closeModal,
  bankName,
  bankRoutingNo,
  address,
  city,
  zipCode,
  state,
  status,
  bankAddressId
) {
  if (!bankName) {
    notifyError('Bank name is required.');
    return;
  }
  if (!bankRoutingNo) {
    notifyError('Bank routing no is required.');
    return;
  }

  if (isAdd) {
    let addReq = new CreateBankAddressRequest();
    addReq.setBankName(bankName);
    addReq.setBankRoutingNo(bankRoutingNo);
    addReq.setAddress(address);
    addReq.setCity(city);
    addReq.setZipCode(zipCode);
    addReq.setState(state);
    addReq.setStatus(status);

    bankaddress.createBankAddress(addReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
      } else {
        let obj = res.toObject().bankAddress;
        let newBankAddress = {
          address: obj.address,
          bankAddressId: obj.bankAddressId,
          bankName: obj.bankName,
          bankRoutingNo: obj.bankRoutingNo,
          city: obj.city,
          state: obj.state,
          status: obj.status,
          zipCode: obj.zipCode,
        };
        rows.push(newBankAddress);
        notifySuccess('New bank address has been added.');
        closeModal();
      }
    });
  } else {
    let updateReq = new UpdateBankAddressRequest();
    updateReq.setBankAddressId(bankAddressId);
    updateReq.setAddress(address);
    updateReq.setCity(city);
    updateReq.setZipCode(zipCode);
    updateReq.setState(state);
    updateReq.setStatus(status);

    bankaddress.updateBankAddress(updateReq, {}, (err) => {
      if (err) {
        notifyError(err.message);
        return;
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].bankAddressId === bankAddressId) {
            rows[i].address = address;
            rows[i].city = city;
            rows[i].zipCode = zipCode;
            rows[i].state = state;
            rows[i].status = status;
          }
        }
        notifySuccess('Bank address has been updated.');
        closeModal();
      }
    });
  }
}
/*=========================================================================================
End of Add and Update Bank Address function
===========================================================================================*/

/*=========================================================================================
Delete Bank Address function: Single
===========================================================================================*/
function removeBankAddressSingle(
  bankaddress,
  rows,
  bankAddressID,
  address,
  confirm
) {
  confirm({
    description:
      "You are about to delete Bank Address '" +
      address +
      "' in your system. Please confirm your action.",
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let deleteReq = new DeleteBankAddressRequest();
      deleteReq.setBankAddressId(bankAddressID);

      bankaddress.deleteBankAddress(deleteReq, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.messsage);
          return;
        }
        for (let c = 0; c < rows.length; c++) {
          if (rows[c].bankAddressID === bankAddressID) {
            rows[c].status = 'inactive';
          }
        }
      });
      notifySuccess('Bank address has been deactivated');
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}
/*=========================================================================================
End of delete Bank Address function: Single
===========================================================================================*/

/*=========================================================================================
Delete Bank Address function: Multiple
===========================================================================================*/
function removeBankAddress(
  bankaddress,
  rows,
  bankAddressIDs,
  handleClick,
  confirm
) {
  if (bankAddressIDs.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      bankAddressIDs.length +
      ' Bank Address(es) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      bankAddressIDs.forEach(removeFunction);

      function removeFunction(item) {
        let deleteReq = new DeleteBankAddressRequest();
        deleteReq.setBankAddressId(item);

        bankaddress.deleteBankAddress(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].bankAddressId === item) {
              rows[c].status = 'inactive';
            }
          }
        });
      }
      handleClick('', '', true);
      notifySuccess(
        bankAddressIDs.length + ' Bank address has bee deactivated'
      );
    })
    .catch(() => {
      /* ... */
    });
}
/*=========================================================================================
End of delete Bank Address function: Multiple
===========================================================================================*/

export default function BankAddressTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { bankAddressServiceClient: bankaddress } = useContext(ServiceContext);

  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          address:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          bankName:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          bankRoutingNo:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          city:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          state:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          status:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          zipCode:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          address: data.address,
          bankAddressId: data.bankAddressId,
          bankName: data.bankName,
          bankRoutingNo: data.bankRoutingNo,
          city: data.city,
          state: data.state,
          status: data.status,
          zipCode: data.zipCode,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          address: data.address,
          bankAddressId: data.bankAddressId,
          bankName: data.bankName,
          bankRoutingNo: data.bankRoutingNo,
          city: data.city,
          state: data.state,
          status: data.status,
          zipCode: data.zipCode,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**Search Bank Address function ==================================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (bankNameValue ? '&bankName=' + bankNameValue : '') +
        (bankRoutingNoValue ? '&bankRoutingNo=' + bankRoutingNoValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let list = new ListBankAddressRequest();
      list.setBankName(bankNameValue);
      list.setBankRoutingNo(bankRoutingNoValue);

      bankaddress.listBankAddress(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().bankAddressesList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**Search Bank Address function end ==============================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      id: '1',
      name: 'address',
      label: 'Address',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '2',
      name: 'bankName',
      label: 'Bank Name',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '3',
      name: 'bankRoutingNo',
      label: 'Bank Routing No',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '4',
      name: 'city',
      label: 'City',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '5',
      name: 'state',
      label: 'State',
      visibleState: useState(true),
      align: 'left',
      order: 5,
    },
    {
      id: '6',
      name: 'status',
      label: 'Status',
      visibleState: useState(true),
      align: 'left',
      order: 6,
    },
    {
      id: '7',
      name: 'zipCode',
      label: 'Zip Code',
      visibleState: useState(true),
      align: 'left',
      order: 4,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, bankAddressId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(bankAddressId);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, bankAddressId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };
  const isSelected = (bankAddressId) => selected.indexOf(bankAddressId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].bankAddressId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={rows[rowIndex].bankAddressId}
        selected={isItemSelected}
        aria-label={labelId}
      >
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCell1}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].bankAddressId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="delete"
                disabled={rows[rowIndex].status === 'inactive' ? true : false}
                onClick={() => {
                  removeBankAddressSingle(
                    bankaddress,
                    rows,
                    rows[rowIndex].bankAddressId,
                    rows[rowIndex].address,
                    confirm
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => (v.options.empty ? false : v.options.display))
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'BankAddress_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    downloadOptions: {
      filename: fileName,
    },
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [1, 2, 0, 3, 6, 4, 5],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [bankNameValue, setBankNameValue] = React.useState('');
  const [bankRoutingNoValue, setBankRoutingNoValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      bankName: query.get('bankName'),
      bankRoutingNo: query.get('bankRoutingNo'),
    };

    if (data.bankName) {
      setBankNameValue(data.bankName);
    }
    if (data.bankRoutingNo) {
      setBankRoutingNoValue(data.bankRoutingNo);
    }

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalBankNameValue, setModalBankNameValue] = React.useState('');
  const [modalBankRoutingNoValue, setModalBankRoutingNoValue] = React.useState(
    ''
  );
  const [modalAddressValue, setModalAddressValue] = React.useState('');
  const [modalCityValue, setModalCityValue] = React.useState('');
  const [modalZipCodeValue, setModalZipCodeValue] = React.useState('');
  const [modalStateValue, setModalStateValue] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState('');

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const [bankAddressId, setBankAddressId] = React.useState('');
  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalBankNameValue('');
      setModalBankRoutingNoValue('');
      setModalAddressValue('');
      setModalCityValue('');
      setModalZipCodeValue('');
      setModalStateValue('');
      setModalStatusValue('active');

      setModalAddAction(true);
      setIsDisabled(false);
      setModalTitleValue('Add New');
    } else {
      setModalBankNameValue(rowData.bankName);
      setModalBankRoutingNoValue(rowData.bankRoutingNo);
      setModalAddressValue(rowData.address);
      setModalCityValue(rowData.city);
      setModalZipCodeValue(rowData.zipCode);
      setModalStateValue(rowData.state);
      setModalStatusValue(rowData.status);

      setModalAddAction(false);
      setIsDisabled(true);
      setModalTitleValue('Edit');
      setBankAddressId(rowData.bankAddressId);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Bank Name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={bankNameValue}
                  onChange={(e) => setBankNameValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Bank Routing No"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={bankRoutingNoValue}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setBankRoutingNoValue(e.target.value);
                    }
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.toString().slice(0, 9);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeBankAddress(
                  bankaddress,
                  rows,
                  rowID,
                  handleClick,
                  confirm
                );
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Bank Addresses'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Bank Address
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Bank Name"
                      fullWidth
                      disabled={isDisabled}
                      InputLabelProps={{ shrink: true }}
                      value={modalBankNameValue}
                      onChange={(e) => setModalBankNameValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Bank Routing No"
                      fullWidth
                      disabled={isDisabled}
                      InputLabelProps={{ shrink: true }}
                      value={modalBankRoutingNoValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setModalBankRoutingNoValue(e.target.value);
                        }
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 9);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Address"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalAddressValue}
                      onChange={(e) => setModalAddressValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="City"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalCityValue}
                      onChange={(e) => setModalCityValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Zip Code"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalZipCodeValue}
                      onChange={(e) => setModalZipCodeValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>State</InputLabel>
                    <StateSelect
                      value={modalStateValue}
                      onChange={(e) => setModalStateValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Status</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalStatusValue}
                      onChange={(e) => setModalStatusValue(e.target.value)}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveBankAddress(
                          bankaddress,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalBankNameValue,
                          modalBankRoutingNoValue,
                          modalAddressValue,
                          modalCityValue,
                          modalZipCodeValue,
                          modalStateValue,
                          modalStatusValue,
                          bankAddressId
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
