// source: accountpb/reportsetup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.accountpb.CreateReportSetupResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteReportSetupRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteReportSetupResponse', null, global);
goog.exportSymbol('proto.accountpb.ReadReportSetupRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadReportSetupResponse', null, global);
goog.exportSymbol('proto.accountpb.ReportSetup', null, global);
goog.exportSymbol('proto.accountpb.UpdateReportSetupResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReportSetup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReportSetup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReportSetup.displayName = 'proto.accountpb.ReportSetup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateReportSetupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateReportSetupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateReportSetupResponse.displayName = 'proto.accountpb.CreateReportSetupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateReportSetupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateReportSetupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateReportSetupResponse.displayName = 'proto.accountpb.UpdateReportSetupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadReportSetupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadReportSetupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadReportSetupRequest.displayName = 'proto.accountpb.ReadReportSetupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadReportSetupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadReportSetupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadReportSetupResponse.displayName = 'proto.accountpb.ReadReportSetupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteReportSetupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteReportSetupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteReportSetupRequest.displayName = 'proto.accountpb.DeleteReportSetupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteReportSetupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteReportSetupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteReportSetupResponse.displayName = 'proto.accountpb.DeleteReportSetupResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReportSetup.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReportSetup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReportSetup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReportSetup.toObject = function(includeInstance, msg) {
  var f, obj = {
setupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
setupType: jspb.Message.getFieldWithDefault(msg, 3, ""),
access: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
deliveryType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReportSetup}
 */
proto.accountpb.ReportSetup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReportSetup;
  return proto.accountpb.ReportSetup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReportSetup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReportSetup}
 */
proto.accountpb.ReportSetup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccess(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReportSetup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReportSetup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReportSetup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReportSetup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSetupType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccess();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDeliveryType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string setup_id = 1;
 * @return {string}
 */
proto.accountpb.ReportSetup.prototype.getSetupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReportSetup} returns this
 */
proto.accountpb.ReportSetup.prototype.setSetupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.accountpb.ReportSetup.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReportSetup} returns this
 */
proto.accountpb.ReportSetup.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string setup_type = 3;
 * @return {string}
 */
proto.accountpb.ReportSetup.prototype.getSetupType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReportSetup} returns this
 */
proto.accountpb.ReportSetup.prototype.setSetupType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool access = 4;
 * @return {boolean}
 */
proto.accountpb.ReportSetup.prototype.getAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.ReportSetup} returns this
 */
proto.accountpb.ReportSetup.prototype.setAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string delivery_type = 5;
 * @return {string}
 */
proto.accountpb.ReportSetup.prototype.getDeliveryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReportSetup} returns this
 */
proto.accountpb.ReportSetup.prototype.setDeliveryType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateReportSetupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateReportSetupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateReportSetupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateReportSetupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
reportSetup: (f = msg.getReportSetup()) && proto.accountpb.ReportSetup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateReportSetupResponse}
 */
proto.accountpb.CreateReportSetupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateReportSetupResponse;
  return proto.accountpb.CreateReportSetupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateReportSetupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateReportSetupResponse}
 */
proto.accountpb.CreateReportSetupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.ReportSetup;
      reader.readMessage(value,proto.accountpb.ReportSetup.deserializeBinaryFromReader);
      msg.setReportSetup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateReportSetupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateReportSetupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateReportSetupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateReportSetupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportSetup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.ReportSetup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportSetup report_setup = 1;
 * @return {?proto.accountpb.ReportSetup}
 */
proto.accountpb.CreateReportSetupResponse.prototype.getReportSetup = function() {
  return /** @type{?proto.accountpb.ReportSetup} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.ReportSetup, 1));
};


/**
 * @param {?proto.accountpb.ReportSetup|undefined} value
 * @return {!proto.accountpb.CreateReportSetupResponse} returns this
*/
proto.accountpb.CreateReportSetupResponse.prototype.setReportSetup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateReportSetupResponse} returns this
 */
proto.accountpb.CreateReportSetupResponse.prototype.clearReportSetup = function() {
  return this.setReportSetup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateReportSetupResponse.prototype.hasReportSetup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateReportSetupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateReportSetupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateReportSetupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateReportSetupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
reportSetup: (f = msg.getReportSetup()) && proto.accountpb.ReportSetup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateReportSetupResponse}
 */
proto.accountpb.UpdateReportSetupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateReportSetupResponse;
  return proto.accountpb.UpdateReportSetupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateReportSetupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateReportSetupResponse}
 */
proto.accountpb.UpdateReportSetupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.ReportSetup;
      reader.readMessage(value,proto.accountpb.ReportSetup.deserializeBinaryFromReader);
      msg.setReportSetup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateReportSetupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateReportSetupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateReportSetupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateReportSetupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportSetup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.ReportSetup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportSetup report_setup = 1;
 * @return {?proto.accountpb.ReportSetup}
 */
proto.accountpb.UpdateReportSetupResponse.prototype.getReportSetup = function() {
  return /** @type{?proto.accountpb.ReportSetup} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.ReportSetup, 1));
};


/**
 * @param {?proto.accountpb.ReportSetup|undefined} value
 * @return {!proto.accountpb.UpdateReportSetupResponse} returns this
*/
proto.accountpb.UpdateReportSetupResponse.prototype.setReportSetup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateReportSetupResponse} returns this
 */
proto.accountpb.UpdateReportSetupResponse.prototype.clearReportSetup = function() {
  return this.setReportSetup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateReportSetupResponse.prototype.hasReportSetup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadReportSetupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadReportSetupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadReportSetupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadReportSetupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
setupId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadReportSetupRequest}
 */
proto.accountpb.ReadReportSetupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadReportSetupRequest;
  return proto.accountpb.ReadReportSetupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadReportSetupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadReportSetupRequest}
 */
proto.accountpb.ReadReportSetupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadReportSetupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadReportSetupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadReportSetupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadReportSetupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string setup_id = 1;
 * @return {string}
 */
proto.accountpb.ReadReportSetupRequest.prototype.getSetupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReadReportSetupRequest} returns this
 */
proto.accountpb.ReadReportSetupRequest.prototype.setSetupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadReportSetupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadReportSetupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadReportSetupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadReportSetupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
reportSetup: (f = msg.getReportSetup()) && proto.accountpb.ReportSetup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadReportSetupResponse}
 */
proto.accountpb.ReadReportSetupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadReportSetupResponse;
  return proto.accountpb.ReadReportSetupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadReportSetupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadReportSetupResponse}
 */
proto.accountpb.ReadReportSetupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.ReportSetup;
      reader.readMessage(value,proto.accountpb.ReportSetup.deserializeBinaryFromReader);
      msg.setReportSetup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadReportSetupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadReportSetupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadReportSetupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadReportSetupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportSetup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.ReportSetup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportSetup report_setup = 1;
 * @return {?proto.accountpb.ReportSetup}
 */
proto.accountpb.ReadReportSetupResponse.prototype.getReportSetup = function() {
  return /** @type{?proto.accountpb.ReportSetup} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.ReportSetup, 1));
};


/**
 * @param {?proto.accountpb.ReportSetup|undefined} value
 * @return {!proto.accountpb.ReadReportSetupResponse} returns this
*/
proto.accountpb.ReadReportSetupResponse.prototype.setReportSetup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadReportSetupResponse} returns this
 */
proto.accountpb.ReadReportSetupResponse.prototype.clearReportSetup = function() {
  return this.setReportSetup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadReportSetupResponse.prototype.hasReportSetup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteReportSetupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteReportSetupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteReportSetupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteReportSetupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
setupId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteReportSetupRequest}
 */
proto.accountpb.DeleteReportSetupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteReportSetupRequest;
  return proto.accountpb.DeleteReportSetupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteReportSetupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteReportSetupRequest}
 */
proto.accountpb.DeleteReportSetupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteReportSetupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteReportSetupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteReportSetupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteReportSetupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string setup_id = 1;
 * @return {string}
 */
proto.accountpb.DeleteReportSetupRequest.prototype.getSetupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteReportSetupRequest} returns this
 */
proto.accountpb.DeleteReportSetupRequest.prototype.setSetupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteReportSetupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteReportSetupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteReportSetupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteReportSetupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
setupId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteReportSetupResponse}
 */
proto.accountpb.DeleteReportSetupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteReportSetupResponse;
  return proto.accountpb.DeleteReportSetupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteReportSetupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteReportSetupResponse}
 */
proto.accountpb.DeleteReportSetupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteReportSetupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteReportSetupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteReportSetupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteReportSetupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string setup_id = 1;
 * @return {string}
 */
proto.accountpb.DeleteReportSetupResponse.prototype.getSetupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteReportSetupResponse} returns this
 */
proto.accountpb.DeleteReportSetupResponse.prototype.setSetupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.accountpb);
