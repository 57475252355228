import React from 'react';
import { useListSsrError } from '../Hooks/useSsrService';
import { DataTable } from './Table';

export function ListOperationErrors({ reportId }) {
  const ssrErrors = useListSsrError(reportId);

  if (!ssrErrors.data) {
    return 'ssr errors in progress...';
  }

  const columns = ['Time', 'Type', 'Message'];

  const data = ssrErrors.data.map((ssrError) => {
    return [
      ssrError
        .getCreatedAt()
        ?.toDate()
        .toLocaleString(),
      ssrError.getType(),
      ssrError.getMessage(),
    ];
  });

  return <DataTable title="Operational Error" columns={columns} data={data} />;
}
