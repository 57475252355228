import moment from 'moment';

function formatPbDate(d) {
  if (!d) {
    return '';
  }
  return (
    `${d.month.toString().padStart(2, '0')}/` +
    `${d.day.toString().padStart(2, '0')}/` +
    `${d.year.toString().padStart(2, '0')}`
  );
}

// d is assumed a decimal string
function formatDollar(d, symbol = '$') {
  if (!d) {
    return '';
  }
  if (parseFloat(d) === 0) {
    return symbol + '0.00';
  }
  const sign = parseFloat(d) < 0 ? '-' + symbol : symbol;
  let [digit, decimal] = d.replace(/^-/, '').split('.');
  const minDecimal = parseFloat(digit) < 1 ? 4 : 2;
  if (!decimal) {
    decimal = '';
  }
  if (decimal.length < minDecimal) {
    decimal += '0'.repeat(minDecimal - decimal.length);
  }

  return `${sign}${digit}.${decimal}`;
}

function formatCurrency(
  amount,
  symbol = '$',
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' + symbol : symbol;

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();

  let j = i.length > 3 ? i.length % 3 : 0;

  const finalAmount =
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '');

  return `${finalAmount}`;
}

function formatPercentage(value) {
  return value + '%';
}

function formatSeconds(seconds) {
  if (!seconds) {
    return '';
  }
  return moment(new Date(seconds * 1000)).format('MM/DD/YYYY hh:mm:ssa');
}

export {
  formatPbDate,
  formatDollar,
  formatCurrency,
  formatPercentage,
  formatSeconds,
};
