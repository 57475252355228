/*ReactJS*/
import React, { useState, useContext } from 'react';

import {
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';

/*Service*/
import {
  batchCreateTransaction,
  batchCreatePendingTransaction,
  convertDataToTransactionModel,
  checkEntryType,
  BatchPartialError,
} from '../../../services/TransactionService';

import { readExcelData } from '../../../services/ExcelService';

/*Material UI*/
import { AttachFile, Close as CloseIcon } from '@material-ui/icons';

import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';

import TransactionTable from './TransactionTable';
import EntryTypeSelect from '../../../components/AutoComplete/EntryType';

import useStyles from '../../../styles';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';
import { useConfirm } from 'material-ui-confirm';

function BatchErrorLines(props) {
  // batchError is type-checked
  const errs = props.batchError.getErrors();
  return (
    <div>
      {errs.slice(0, 10).map((o) => (
        <p key={o.index}>
          Error: {o.errorMessage} for trns with account &quot;
          {o.accountNo} &quot;, contra &quot;{o.contraAccountNo}
          &quot;, exteranalId: &quot;{o.externalId}&quot;
        </p>
      ))}
      {errs.length > 10 ? (
        <p key={999}>and {errs.length - 10} more errors</p>
      ) : null}
    </div>
  );
}

export default function TransactionUploadModal({
  onClose: handleClose,
  open: isOpen,
}) {
  var { googleAccount } = useUserState();
  var activeUser = googleAccount.profileObj.name;

  const classes = useStyles();
  const confirm = useConfirm();

  const [rows, setRows] = useState([]);
  const [rowData, setRowData] = React.useState({});
  const [originalData, setOriginalData] = useState([]);
  const [editRecord, setEditRecord] = React.useState(false);
  const [entryType, setEntryType] = useState('');
  const [loading, setLoading] = useState({
    execute: false,
    saveToPending: false,
  });
  const [batchError, setBatchError] = useState(null);

  const listReq = [];

  const readExcel = (file) => {
    if (!file) {
      return;
    }
    setRows([]);

    readExcelData(file).then((d) => {
      let rowsCopy = [];

      d.map((item) => {
        return rowsCopy.push(convertDataToTransactionModel(item));
      });

      if (!rowsCopy.every((item) => item.error === false)) {
        notifyError(
          'Successfully load(s) ' +
            d.length +
            ' records with missing required values.'
        );
      } else {
        notifySuccess('Successfully load(s) ' + d.length + ' records.');
        setBatchError(null);
      }
      setOriginalData(rowsCopy);

      // can preview up to the first 10000 rows of an upload in the table before performance issues occurring
      const slicedArray = rowsCopy.slice(0, 10000);
      setRows(slicedArray);
    });

    return;
  };

  const { transactionServiceClient: client } = useContext(ServiceContext);

  const handleExecution = async () => {
    setLoading({ execute: true });

    try {
      const records = originalData.length;
      if (records > 0) {
        const rowsCopy = [...originalData];
        await Promise.all(rowsCopy.map(createBatchRequest));

        const res = await batchCreateTransaction(
          listReq,
          client,
          activeUser,
          true
        );
        let showSuccessMessage = true;

        if (res.length > 0) {
          res.forEach((item) => {
            if (item.errorMessage !== '') {
              notifyError(
                'Status: ' +
                  item.status.charAt(0).toUpperCase() +
                  item.status.slice(1) +
                  '. Error: ' +
                  item.errorMessage
              );
              showSuccessMessage = false;
            }
          });
        }

        if (showSuccessMessage) {
          notifySuccess(
            rowsCopy.length + ' transactions successfully executed.'
          );
          rowsCopy.splice(0, rowsCopy.length - 1);
          setRows([]);
          setBatchError(null);
        }
      }
    } catch (error) {
      console.error('Failed to execute', error);
      setBatchError(error);
    } finally {
      setLoading({ execute: false });
    }
  };

  const handlePendingExecution = async () => {
    setLoading({ saveToPending: true });

    try {
      const records = rows.length;
      if (records > 0) {
        const rowsCopy = [...rows];
        await Promise.all(rowsCopy.map(createBatchRequest));

        const res = await batchCreatePendingTransaction(
          listReq,
          client,
          activeUser
        );

        let showSuccessMessage = false;

        if (res.length > 0) {
          res.forEach((item) => {
            if (item.errorMessage === '') {
              showSuccessMessage = true;
            } else {
              notifyError(
                'Status: ' +
                  item.status.charAt(0).toUpperCase() +
                  item.status.slice(1) +
                  '. Error: ' +
                  item.errorMessage
              );
            }
          });
          if (showSuccessMessage) {
            notifySuccess(
              records + ' Pending transaction(s) has been succesfully added.'
            );
          }
          rowsCopy.splice(0, rowsCopy.length - 1);
          setRows([]);
          setBatchError(null);
        }
      }
    } catch (error) {
      console.error('Failed to save to pending', error);
      setBatchError(error);
    } finally {
      setLoading({ saveToPending: false });
    }
  };

  const createBatchRequest = async (data, key) => {
    listReq.push(data);
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].correspondent;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          rowsCopy.splice(index, 1);
        }

        setRows(rowsCopy);
        notifySuccess(messageKey + ' Entry has been removed');
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleOpen = (data) => {
    setRowData(data);
    setEditRecord(true);
  };

  const handleEditClose = async (data) => {
    if (data === undefined) {
      setEditRecord(false);
      return;
    }

    if (!data.entryType) {
      notifyError('Entry Type is required.');
      return;
    }
    if (!data.accountNo) {
      notifyError('Account No is required.');
      return;
    }
    if (!data.contraAccountNo) {
      notifyError('Contra Account is required.');
      return;
    }

    if (checkEntryType(data.entryType) === 'trd') {
      if (!data.side) {
        notifyError('Side is required.');
        return;
      }
      if (!data.tradeDate) {
        notifyError('Trade Date is required.');
        return;
      }
    }
    if (
      checkEntryType(data.entryType) === 'trd' ||
      checkEntryType(data.entryType) === 'pm' ||
      checkEntryType(data.entryType) === 'cpm'
    ) {
      if (!data.symbol) {
        notifyError('Symbol is required.');
        return;
      }
    }
    if (
      checkEntryType(data.entryType) === 'trd' ||
      checkEntryType(data.entryType) === 'pm'
    ) {
      if (!data.qty) {
        notifyError('Qty is required.');
        return;
      }
      if (!data.price) {
        notifyError('Price is required.');
        return;
      }
    }
    if (checkEntryType(data.entryType) === 'cpm') {
      if (!data.netAmt && !data.localNetAmt) {
        notifyError('Net Amt or Local Net Amt is required.');
        return;
      }
    }
    if (
      (data.contraSymbol || 'USD') !== 'USD' &&
      (checkEntryType(data.entryType) === 'trd' ||
        checkEntryType(data.entryType) === 'cm' ||
        checkEntryType(data.entryType) === 'cpm')
    ) {
      if (!data.swapRate) {
        notifyError('Swap Rate is required for non-USD transactions.');
        return;
      }
    }

    try {
      const rowsCopy = [...rows];
      const index = rows.indexOf(rowData);
      data.error = false;
      rowsCopy[index] = data;
      setRows(rowsCopy);
      notifySuccess('Record has been updated.');
    } catch (error) {
      console.error(error);
    }
    setEditRecord(false);
  };

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <div className={classes.tableModalFull}>
            <div
              className={classes.grdRow}
              style={{ margin: 0, padding: '25px 30px 25px 30px' }}
            >
              <div className={classes.grdCell1}>
                <EntryTypeSelect
                  freeSolo={false}
                  name="entryType"
                  label="Entry Type"
                  value={entryType}
                  onChange={(e) => setEntryType(e.currentTarget.value)}
                  allowInputChange={true}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 10, marginTop: 3 }}
              >
                <label htmlFor="raised-button-file">
                  <Button
                    component="span"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AttachFile />}
                  >
                    Choose File
                  </Button>
                </label>
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={(e) => {
                    readExcel(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              </div>
              <div
                className={classes.grdCellNone}
                style={{ marginTop: 3, marginRight: 10 }}
              >
                <Tooltip
                  title={
                    !rows.every((item) => item.error === false)
                      ? 'Cannot execute while there are missing required value(s)'
                      : 'Execute'
                  }
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={
                        !rows.length ||
                        loading.execute ||
                        loading.saveToPending ||
                        !rows.every((item) => item.error === false)
                      }
                      startIcon={
                        loading.execute ? (
                          <CircularProgress
                            style={{ color: '#ffffff', height: 20, width: 20 }}
                          />
                        ) : null
                      }
                      onClick={() => {
                        handleExecution();
                      }}
                    >
                      {loading.execute ? 'Executing...' : 'Execute'}
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <div className={classes.grdCellNone} style={{ marginTop: 3 }}>
                <Tooltip
                  title={
                    !rows.every((item) => item.error === false)
                      ? 'Cannot execute while there are missing required value(s)'
                      : 'Save to Pending'
                  }
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={
                        !rows.length ||
                        loading.execute ||
                        loading.saveToPending ||
                        !rows.every((item) => item.error === false)
                      }
                      startIcon={
                        loading.saveToPending ? (
                          <CircularProgress
                            style={{ color: '#ffffff', height: 20, width: 20 }}
                          />
                        ) : null
                      }
                      onClick={() => {
                        handlePendingExecution();
                      }}
                    >
                      {loading.saveToPending ? 'Saving...' : 'Save to Pending'}
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
            <p style={{ color: 'red', paddingLeft: '25px' }}>
              {batchError instanceof BatchPartialError ? (
                <BatchErrorLines batchError={batchError} />
              ) : (
                batchError?.message
              )}
            </p>
            <Box component="div">
              <TransactionTable
                entrytype={entryType}
                data={rows}
                editData={rowData}
                isEdit={editRecord}
                onEditOpen={handleOpen}
                onEditClose={handleEditClose}
                onRowDelete={handleDelete}
              ></TransactionTable>
            </Box>
            {originalData.length >= 10000 && (
              <p
                style={{
                  textAlign: 'end',
                  paddingRight: '12px',
                  paddingBottom: '12px',
                  fontStyle: 'italic',
                }}
              >
                Preview only displaying first 10,000 entries
              </p>
            )}
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
