import React from 'react';

import SaveIcon from '@material-ui/icons/Save';

import { IconButton, Tooltip } from '@material-ui/core';

export default function SaveTable({ updateTableSettings }) {
  return (
    <Tooltip title="Save Table" arrow>
      <IconButton onClick={() => updateTableSettings()}>
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );
}
