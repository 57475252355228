/**
 * @fileoverview gRPC-Web generated client stub for assetpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: assetpb/price.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.assetpb = require('./price_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.assetpb.PriceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.assetpb.PriceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.assetpb.ListPriceRequest,
 *   !proto.assetpb.ListPriceResponse>}
 */
const methodDescriptor_PriceService_ListPrice = new grpc.web.MethodDescriptor(
  '/assetpb.PriceService/ListPrice',
  grpc.web.MethodType.UNARY,
  proto.assetpb.ListPriceRequest,
  proto.assetpb.ListPriceResponse,
  /**
   * @param {!proto.assetpb.ListPriceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.assetpb.ListPriceResponse.deserializeBinary
);


/**
 * @param {!proto.assetpb.ListPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.assetpb.ListPriceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.assetpb.ListPriceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.assetpb.PriceServiceClient.prototype.listPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/assetpb.PriceService/ListPrice',
      request,
      metadata || {},
      methodDescriptor_PriceService_ListPrice,
      callback);
};


/**
 * @param {!proto.assetpb.ListPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.assetpb.ListPriceResponse>}
 *     Promise that resolves to the response
 */
proto.assetpb.PriceServicePromiseClient.prototype.listPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/assetpb.PriceService/ListPrice',
      request,
      metadata || {},
      methodDescriptor_PriceService_ListPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.assetpb.UpdateFxRatesRequest,
 *   !proto.assetpb.UpdateFxRatesResponse>}
 */
const methodDescriptor_PriceService_UpdateFxRates = new grpc.web.MethodDescriptor(
  '/assetpb.PriceService/UpdateFxRates',
  grpc.web.MethodType.UNARY,
  proto.assetpb.UpdateFxRatesRequest,
  proto.assetpb.UpdateFxRatesResponse,
  /**
   * @param {!proto.assetpb.UpdateFxRatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.assetpb.UpdateFxRatesResponse.deserializeBinary
);


/**
 * @param {!proto.assetpb.UpdateFxRatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.assetpb.UpdateFxRatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.assetpb.UpdateFxRatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.assetpb.PriceServiceClient.prototype.updateFxRates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/assetpb.PriceService/UpdateFxRates',
      request,
      metadata || {},
      methodDescriptor_PriceService_UpdateFxRates,
      callback);
};


/**
 * @param {!proto.assetpb.UpdateFxRatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.assetpb.UpdateFxRatesResponse>}
 *     Promise that resolves to the response
 */
proto.assetpb.PriceServicePromiseClient.prototype.updateFxRates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/assetpb.PriceService/UpdateFxRates',
      request,
      metadata || {},
      methodDescriptor_PriceService_UpdateFxRates);
};


module.exports = proto.assetpb;

