import React from 'react';
import {
  Typography,
  Breadcrumbs,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

/*styles */
import useStyles from '../../styles';

/*components */
import AnnouncementTable from './Components/AnnouncementTable';

export default function ProfitAndLoss(props) {
  const classes = useStyles();

  const [announcementType, setAnnouncementType] = React.useState(
    'announcement'
  );

  const announcmentTable = () => {
    if (announcementType === '') {
      return <></>;
    } else if (announcementType === 'announcement') {
      return <AnnouncementTable params={props} />;
    }
  };

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 30 }}>
            <Typography
              variant="h2"
              className={classes.textBold}
              align="left"
              gutterBottom={true}
            >
              Announcement
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <span color="inherit">Security</span>
              <span color="inherit">Corp Action</span>
              <Typography color="primary">Announcement</Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.grdCell1} style={{ alignSelf: 'flex-end' }}>
            <InputLabel shrink id="announcement">
              Announcement Type
            </InputLabel>
            <Select
              labelId="announcement"
              displayEmpty
              fullWidth
              value={announcementType}
              onChange={(e) => setAnnouncementType(e.target.value)}
            >
              <MenuItem value="announcement">Announcement</MenuItem>
              <MenuItem value="cil">Cash in Lieu</MenuItem>
              <MenuItem value="merger">Merger</MenuItem>
              <MenuItem value="spin_off">Spin-Off</MenuItem>
              <MenuItem value="split">Split</MenuItem>
              <MenuItem value="">
                <em>Blank</em>
              </MenuItem>
            </Select>
          </div>
        </div>
        <Box mt={2}>{announcmentTable}</Box>
      </div>
    </React.Fragment>
  );
}
