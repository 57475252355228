/**
 * @fileoverview gRPC-Web generated client stub for compliancepb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: compliancepb/assettransfer.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var commonpb_file_pb = require('../commonpb/file_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.compliancepb = require('./assettransfer_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.compliancepb.AssetTransferServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.compliancepb.AssetTransferServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.compliancepb.AssetTransfer,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_AssetTransferService_DonwloadTransferInput = new grpc.web.MethodDescriptor(
  '/compliancepb.AssetTransferService/DonwloadTransferInput',
  grpc.web.MethodType.UNARY,
  proto.compliancepb.AssetTransfer,
  commonpb_file_pb.File,
  /**
   * @param {!proto.compliancepb.AssetTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.compliancepb.AssetTransferServiceClient.prototype.donwloadTransferInput =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/compliancepb.AssetTransferService/DonwloadTransferInput',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_DonwloadTransferInput,
      callback);
};


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.compliancepb.AssetTransferServicePromiseClient.prototype.donwloadTransferInput =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/compliancepb.AssetTransferService/DonwloadTransferInput',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_DonwloadTransferInput);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.compliancepb.AssetTransfer,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_AssetTransferService_DownloadAssetTransfer = new grpc.web.MethodDescriptor(
  '/compliancepb.AssetTransferService/DownloadAssetTransfer',
  grpc.web.MethodType.UNARY,
  proto.compliancepb.AssetTransfer,
  commonpb_file_pb.File,
  /**
   * @param {!proto.compliancepb.AssetTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.compliancepb.AssetTransferServiceClient.prototype.downloadAssetTransfer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/compliancepb.AssetTransferService/DownloadAssetTransfer',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_DownloadAssetTransfer,
      callback);
};


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.compliancepb.AssetTransferServicePromiseClient.prototype.downloadAssetTransfer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/compliancepb.AssetTransferService/DownloadAssetTransfer',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_DownloadAssetTransfer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.compliancepb.AssetTransfer,
 *   !proto.compliancepb.CreatePendingAssetTransferResponse>}
 */
const methodDescriptor_AssetTransferService_CreatePendingAssetTransfer = new grpc.web.MethodDescriptor(
  '/compliancepb.AssetTransferService/CreatePendingAssetTransfer',
  grpc.web.MethodType.UNARY,
  proto.compliancepb.AssetTransfer,
  proto.compliancepb.CreatePendingAssetTransferResponse,
  /**
   * @param {!proto.compliancepb.AssetTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.compliancepb.CreatePendingAssetTransferResponse.deserializeBinary
);


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.compliancepb.CreatePendingAssetTransferResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.compliancepb.CreatePendingAssetTransferResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.compliancepb.AssetTransferServiceClient.prototype.createPendingAssetTransfer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/compliancepb.AssetTransferService/CreatePendingAssetTransfer',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_CreatePendingAssetTransfer,
      callback);
};


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.compliancepb.CreatePendingAssetTransferResponse>}
 *     Promise that resolves to the response
 */
proto.compliancepb.AssetTransferServicePromiseClient.prototype.createPendingAssetTransfer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/compliancepb.AssetTransferService/CreatePendingAssetTransfer',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_CreatePendingAssetTransfer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.compliancepb.AssetTransfer,
 *   !proto.compliancepb.AssetTransferDefaultsResponse>}
 */
const methodDescriptor_AssetTransferService_GetAssetTransferDefaults = new grpc.web.MethodDescriptor(
  '/compliancepb.AssetTransferService/GetAssetTransferDefaults',
  grpc.web.MethodType.UNARY,
  proto.compliancepb.AssetTransfer,
  proto.compliancepb.AssetTransferDefaultsResponse,
  /**
   * @param {!proto.compliancepb.AssetTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.compliancepb.AssetTransferDefaultsResponse.deserializeBinary
);


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.compliancepb.AssetTransferDefaultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.compliancepb.AssetTransferDefaultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.compliancepb.AssetTransferServiceClient.prototype.getAssetTransferDefaults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/compliancepb.AssetTransferService/GetAssetTransferDefaults',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_GetAssetTransferDefaults,
      callback);
};


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.compliancepb.AssetTransferDefaultsResponse>}
 *     Promise that resolves to the response
 */
proto.compliancepb.AssetTransferServicePromiseClient.prototype.getAssetTransferDefaults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/compliancepb.AssetTransferService/GetAssetTransferDefaults',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_GetAssetTransferDefaults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.compliancepb.AssetTransfer,
 *   !proto.compliancepb.UpdateAssetTransferQtyReponse>}
 */
const methodDescriptor_AssetTransferService_UpdateAssetTransferQty = new grpc.web.MethodDescriptor(
  '/compliancepb.AssetTransferService/UpdateAssetTransferQty',
  grpc.web.MethodType.UNARY,
  proto.compliancepb.AssetTransfer,
  proto.compliancepb.UpdateAssetTransferQtyReponse,
  /**
   * @param {!proto.compliancepb.AssetTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.compliancepb.UpdateAssetTransferQtyReponse.deserializeBinary
);


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.compliancepb.UpdateAssetTransferQtyReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.compliancepb.UpdateAssetTransferQtyReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.compliancepb.AssetTransferServiceClient.prototype.updateAssetTransferQty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/compliancepb.AssetTransferService/UpdateAssetTransferQty',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_UpdateAssetTransferQty,
      callback);
};


/**
 * @param {!proto.compliancepb.AssetTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.compliancepb.UpdateAssetTransferQtyReponse>}
 *     Promise that resolves to the response
 */
proto.compliancepb.AssetTransferServicePromiseClient.prototype.updateAssetTransferQty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/compliancepb.AssetTransferService/UpdateAssetTransferQty',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_UpdateAssetTransferQty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.compliancepb.ListPositionRequest,
 *   !proto.compliancepb.ListPositionResponse>}
 */
const methodDescriptor_AssetTransferService_ListPosition = new grpc.web.MethodDescriptor(
  '/compliancepb.AssetTransferService/ListPosition',
  grpc.web.MethodType.UNARY,
  proto.compliancepb.ListPositionRequest,
  proto.compliancepb.ListPositionResponse,
  /**
   * @param {!proto.compliancepb.ListPositionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.compliancepb.ListPositionResponse.deserializeBinary
);


/**
 * @param {!proto.compliancepb.ListPositionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.compliancepb.ListPositionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.compliancepb.ListPositionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.compliancepb.AssetTransferServiceClient.prototype.listPosition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/compliancepb.AssetTransferService/ListPosition',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_ListPosition,
      callback);
};


/**
 * @param {!proto.compliancepb.ListPositionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.compliancepb.ListPositionResponse>}
 *     Promise that resolves to the response
 */
proto.compliancepb.AssetTransferServicePromiseClient.prototype.listPosition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/compliancepb.AssetTransferService/ListPosition',
      request,
      metadata || {},
      methodDescriptor_AssetTransferService_ListPosition);
};


module.exports = proto.compliancepb;

