/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListPositionRequest } from '../../../proto/acatspb/outgoing_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { Pagination } from 'proto/utilspb/pagination_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
  Button,
} from '@material-ui/core';

/*Icons*/
import { Visibility as ViewIcon, Close as CloseIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment/moment.js';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';
import PositionDetailsTable from './PositionDetailsTable';
import ServerSideTableBodyFooter from '../../../components/Table/ServerSideTableBodyFooter';
import OutgoingModal from './OutgoingModal';
//import PositionQtyModal from './PositionQtyModal';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PositionTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    tableSettingsServiceClient: tableSettings,
    outgoingServiceClient: position,
  } = useContext(ServiceContext);

  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: 50,
    pageNo: 0,
    reload: false,
    sortName: '',
    sortDirection: '',
  });

  const [footerData, setFooterData] = useState({});
  const [filterList, setFilterList] = useState({});

  const search = async (dontNotifySuccess) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
    };
    paginationCopy.pageNo = dontNotifySuccess ? paginationCopy.pageNo : 0;

    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNumberValue
        ? '&masterAccountNo=' + masterAccountNumberValue
        : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let paging = new Pagination();
    paging.setPageNo(paginationCopy.pageNo);
    paging.setPageSize(paginationCopy.rowsPerPage);
    paging.setSortName(paginationCopy.sortName);
    paging.setSortDirection(paginationCopy.sortDirection);

    try {
      const allData = await getData(paging, false);
      paginationCopy.count = allData.summary.totalRows;
      setPagination(paginationCopy);
      setFooterData({});

      const rows = allData.list;

      setRows(rows);
      if (!dontNotifySuccess) {
        notifyInfo(allData.summary.totalRows + ' search results.');
      }
    } catch (error) {
      notifyError(error.message);
    }

    setLoading(false);
  };

  const getData = async (paging) => {
    let list = new ListPositionRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setMasterAccountNo(masterAccountNumberValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);
    if (paging) {
      list.setPagination(paging);
    }

    return new Promise((resolve, reject) => {
      position.listPosition(list, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          const d = res.toObject();
          resolve({
            summary: d.summary,
            list: d.positionsList.map((data) => ({
              acatsControlNumber: data.acatsControlNumber,
              correspondent: data.correspondent,
              accountNo: data.accountNo,
              masterAccountNo: data.masterAccountNo,
              accountName: data.accountName,
              accountDesignator: data.accountDesignator,
              broker: data.broker,
              type: data.type,
              systemDate: formatPbDate(data.systemDate),
              originalCusip: data.originalCusip,
              symbol: data.symbol,
              cusip: data.cusip,
              symbolDescription: data.symbolDescription,
              assetType: data.assetType,
              closingPrice: data.closingPrice,
              qty: data.qty,
              marketValue: formatCurrency(data.marketValue),
              costBasis: formatCurrency(data.costBasis),
              originalDelivererNumber: data.originalDelivererNumber,
              originalReceiverNumber: data.originalReceiverNumber,
              status: data.status,
              positionId: data.positionId,
              accountId: data.accountId,
              submitterParticipantNo: data.submitterParticipantNo,
              receiverParticipantNo: data.receiverParticipantNo,
              delivererParticipantNo: data.delivererParticipantNo,
              originalReceiverCorrespondent: data.originalReceiverCorrespondent,
              delivererAccountNo: data.delivererAccountNo,
            })),
          });
        }
      });
    });
  };

  const handleOpenGeneratePartialModal = async (selected, isTransfer) => {
    let selectedRows = [];
    selected.forEach(function(row) {
      selectedRows.push(rows[row.dataIndex]);
    });

    setTransfer(isTransfer);
    setSelectedData(selectedRows);
    setOpenGenerateModal(true);
  };

  const handleCloseGeneratePartialModal = (isExecuteSearch) => {
    if (isExecuteSearch) {
      setRowsSelected([]);
      search(true);
    }
    setIsDisabled(true);
    setOpenGenerateModal(false);
  };

  // const handleOpenUpdateModal = async (row) => {
  //   let selectedRows = [];
  //   selectedRows.push(row);

  //   setModalPosQty(row.positionQty);
  //   setModalCostBasis(row.costBasis);

  //   setSelectedData(selectedRows);
  //   setOpenUpdateModal(true);
  // };

  // const handleCloseUpdateModal = (isExecuteSearch) => {
  //   if (isExecuteSearch) {
  //     setRowsSelected([]);
  //     search(true);
  //   }
  //   setIsDisabled(true);
  //   setOpenUpdateModal(false);
  // };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Position');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [openGenerateModal, setOpenGenerateModal] = React.useState(false);
  //const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(false);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  handleOpen(
                    rows[dataIndex].accountNo,
                    rows[dataIndex].originalCusip
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'acatsControlNumber',
      label: 'ACATS Control No',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'assetType',
      label: 'Asset Type',
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'costBasis',
      label: 'Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'originalDelivererNumber',
      label: 'Original Deliverer Number',
    },
    {
      name: 'originalReceiverNumber',
      label: 'Original Receiver Number',
    },
    {
      name: 'submitterParticipantNo',
      label: 'Submitter Participant No',
    },
    {
      name: 'receiverParticipantNo',
      label: 'Receiver Participant No',
    },
    {
      name: 'delivererParticipantNo',
      label: 'Deliverer Participant No',
    },
    {
      name: 'originalReceiverCorrespondent',
      label: 'Original Receiver Correspondent',
    },
    {
      name: 'delivererAccountNo',
      label: 'Deliverer Account No',
    },
    {
      name: 'status',
      label: 'Status',
    },
  ];

  const fileName =
    'Position_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    serverSide: true,
    count: pagination.count,
    page: pagination.pageNo,
    rowsPerPageOptions: [50, 100, 500, 1000, 5000, 15000, 30000, 100000],
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
    ],
    selectableRowsHeader: true,
    selectableRows: 'multiple',
    rowsPerPage: pagination.rowsPerPage,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Position');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Position');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;

      switch (action) {
        case 'changePage':
          setPagination({
            ...pagination,
            pageNo: tableState.page,
            reload: true,
          });
          break;
        case 'changeRowsPerPage':
          setPagination({
            ...pagination,
            rowsPerPage: tableState.rowsPerPage,
            reload: true,
          });
          break;
        case 'sort':
          setPagination({
            ...pagination,
            sortDirection: tableState.sortOrder.direction,
            sortName: tableState.sortOrder.name,
            reload: true,
          });
          break;
        case 'filterChange':
          {
            let filter = {};
            tableState.filterList.forEach((item, index) => {
              if (item.length) {
                filter[columns[index].name] = item[0];
              }
            });
            setFilterList(filter);
          }
          break;
        default:
      }
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <ServerSideTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          data={footerData}
          additionalCell={true}
        ></ServerSideTableBodyFooter>
      );
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div align={'left'} className={classes.grdCell1}>
            <Tooltip title="Transfer" arrow style={{ display: 'none' }}>
              <div>
                <Button
                  id="btnTransfer"
                  variant="contained"
                  color="primary"
                  aria-label="process"
                  size="small"
                  startIcon={<UpdateIcon />}
                  onClick={() => {
                    handleOpenGeneratePartialModal(selectedRows.data, true);
                  }}
                >
                  Outgoing
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      if (rowsSelected.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      setRowsSelected(rowsSelected);
    },
    rowsSelected: rowsSelected,
    isRowSelectable: (dataIndex) =>
      !(
        rows[dataIndex].tdShortQty === '0' && rows[dataIndex].tdLongQty === '0'
      ),
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [
    masterAccountNumberValue,
    setMasterAccountNumberValue,
  ] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [cusipValue, setCusipValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
      systemDate: query.get('systemDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNumberValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.cusip) {
      setCusipValue(data.cusip);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, [systemDate, params]);

  useEffect(() => {
    if (pagination.reload) {
      search(true);
    }
    // eslint-disable-next-line
  }, [pagination.pageNo,
    pagination.rowsPerPage,
    pagination.reload,
    pagination.sortName,
    pagination.sortDirection,
  ]);

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return;
        }
      });

      return ok;
    });

    return filtered;
  };

  const [selectedAccountNo, setSelectedAccountNo] = React.useState('');
  const [selectedOriginalCusip, setSelectedOriginalCusip] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [isTransfer, setTransfer] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // const [modalPosQty, setModalPosQty] = useState('0.00');
  // const [modalCostBasis, setModalCostBasis] = useState('0.00');

  const handleOpen = (accountNo, originalCusip) => {
    setSelectedAccountNo(accountNo);
    setSelectedOriginalCusip(originalCusip);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={true}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNumberValue}
                  onChange={(e) => {
                    setMasterAccountNumberValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="accountDesignator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  required
                  labelId="broker"
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="type">
                  Type
                </InputLabel>
                <Select
                  required
                  labelId="type"
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Cusip"
                  type="text"
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              disabled={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<UpdateIcon />}
              onClick={() => {
                document.getElementById('btnTransfer').click();
              }}
            >
              Transfer
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Position'}
            data={filterRows()}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <PositionDetailsTable
                accountno={selectedAccountNo}
                originalcusip={selectedOriginalCusip}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
      {openGenerateModal && (
        <OutgoingModal
          onClose={handleCloseGeneratePartialModal}
          open={openGenerateModal}
          value={selectedData}
          transfer={isTransfer}
        ></OutgoingModal>
      )}
      {/* {openUpdateModal && (
        <PositionQtyModal
          onClose={handleCloseUpdateModal}
          open={openUpdateModal}
          value={selectedData}
          posqty={modalPosQty}
          costbasis={modalCostBasis}
        ></PositionQtyModal>
      )} */}
    </div>
  );
}
