/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListFractionalShareReconRequest } from '../../../proto/reconpb/fractionalshare_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Icons*/
import { Visibility as ViewIcon, Save as SaveIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function FractionalShareReconTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const [dateLabel, setDateLabel] = useState('system_date');

  const {
    profileServiceClient: systemDate,
    fractionalShareReconServiceClient: fractionalShareRecon,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = (isSearch, type) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (dateTypeValue ? '&dateType=' + dateTypeValue : '') +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    if (isSearch) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    let list = new ListFractionalShareReconRequest();
    list.setSymbol(symbolValue);
    list.setDateType(dateTypeValue);
    setDateLabel(dateTypeValue);
    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      list.setFromDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      list.setToDate(gglToDate);
    }

    fractionalShareRecon.listFractionalShareRecon(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      let rows = [];

      if (type === 'show breaks only') {
        rows = res.toObject().fractionalSharesList.filter(function(data) {
          return parseFloat(data.breaks) > 0;
        });
      } else {
        rows = res.toObject().fractionalSharesList;
      }
      setRows(rows);
      if (isSearch) {
        notifyInfo(rows.length + ' search results.');
        setLoading(false);
      }
    });
  };

  const showBreaksOnly = (type) => {
    search(false, type);
    setIsBreakOnly(!isBreakOnly ? true : false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Fractional Share Reconciliation');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'breaks',
      label: 'Breaks',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].breaks;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'client',
      label: 'Client',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].client;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'customerControlMargin',
      label: 'Customer Control Margin - 1115',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].customerControlMargin;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'errorAccount',
      label: 'Error Account - 1440',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].errorAccount;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'fractional',
      label: 'Fractional',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].fractional;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'fractionalProp',
      label: 'Fractional + Prop',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].fractionalProp;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'propTrade',
      label: 'Prop Trade + 1200',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].propTrade;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'propTradeOwned',
      label: 'Prop Trade Owned - 1210',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].propTradeOwned;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'propTradeOwnedPrice',
      label: 'Prop Trade Owned Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].propTradeOwnedPrice);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'date',
      label:
        dateLabel === 'system_date'
          ? 'System Date'
          : dateLabel === 'settle_date'
          ? 'Settle Date'
          : 'Trade Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].date
            ? formatPbDate(rows[dataIndex].date)
            : '--';
        },
      },
    },
    {
      name: 'tradingAccount',
      label: 'Trading Account - 201',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].tradingAccount;
          return <div align="right">{f}</div>;
        },
      },
    },
  ];

  const fileName =
    'FractionalShareRecon_' +
    moment().format('MMMM Do YYYY, h:mm:ss a') +
    '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [10, 9, 1, 4, 7, 5, 6, 2, 3, 11, 8, 0],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Fractional Share Reconciliation');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Fractional Share Reconciliation');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [dateTypeValue, setDateTypeValue] = React.useState('system_date');
  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isBreakOnly, setIsBreakOnly] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      dateType: query.get('dateType'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
      symbol: query.get('symbol'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.dateType) {
      setDateTypeValue(data.dateType);
      setDateLabel(data.dateType);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }
    if (!data.fromDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          return;
        }
        setFromDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
        setToDateValue(
          moment(
            new Date(formatPbDate(res.toObject().profile.systemDate))
          ).format('yyyy-MM-DD')
        );
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => setDateTypeValue(e.target.value)}
                >
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                  <MenuItem value="system_date">System Date</MenuItem>
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search(true, '')}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ViewIcon />}
              onClick={() => {
                showBreaksOnly(isBreakOnly ? 'show breaks only' : 'show all');
              }}
            >
              {isBreakOnly ? 'Show Breaks Only' : 'Show All'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Fractional Share Reconciliations'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
