/*ReactJS*/
import React, { useState, useContext, useEffect } from 'react';
import useStyles from '../../../styles';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

import { ServiceContext } from 'context/ServiceContext';
import { AssetTransfer } from '../../../proto/compliancepb/assettransfer_grpc_web_pb';
import download from '../../../services/DownloadService.js';

export default function AssetTransferModal({
  onClose: handleClose,
  open: isOpen,
  value: selectedRows,
  transfer: isTransfer,
}) {
  const { assetTransferServiceClient: assetTransferClient } = useContext(
    ServiceContext
  );

  const classes = useStyles();
  const [modalData, setModalData] = useState({
    transferType: 'FUL',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  useEffect(() => {
    let req = new AssetTransfer();
    assetTransferClient.getAssetTransferDefaults(req, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
      } else {
        setModalData({
          ...modalData,
          submittingDTCC: res.toObject().submittingDtcc,
          receiverDTCC: res.toObject().receiverDtcc,
        });
      }
    });
    // eslint-disable-next-line
  }, [])

  const handleGenerate = async () => {
    if (!modalData.submittingDTCC) {
      notifyError('Submitting DTCC# is required.');
      return;
    }

    if (!modalData.receiverDTCC) {
      notifyError('Receiver DTCC# is required.');
      return;
    }

    if (!modalData.delivererDTCC) {
      notifyError('Deliverer DTCC# is required.');
      return;
    }

    if (!modalData.receiverCorrespondent) {
      notifyError('Receiver Correspondent is required.');
      return;
    }

    if (!modalData.delivererAccountNo) {
      notifyError('Deliverer Account No is required.');
      return;
    }

    try {
      setLoading(true);

      let position_ids = [];

      selectedRows.forEach(function(row) {
        position_ids.push(row.positionId);
      });

      let req = new AssetTransfer();
      req.setPositionIdsList(position_ids);
      req.setSubmittingDtcc(modalData.submittingDTCC);
      req.setReceiverDtcc(modalData.receiverDTCC);
      req.setDelivererDtcc(modalData.delivererDTCC);
      req.setReceiverCorrespondent(modalData.receiverCorrespondent);
      req.setDelivererAccountNo(modalData.delivererAccountNo);

      assetTransferClient.createPendingAssetTransfer(req, {}, (err, res) => {
        if (err) {
          notifyError(err.message);
        } else {
          notifySuccess('Positions has been added to pending');
          setLoading(false);
          handleClose(true);
        }
      });
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    }
  };

  const handleDownload = async () => {
    if (!modalData.submittingDTCC) {
      notifyError('Submitting DTCC# is required.');
      return;
    }

    if (!modalData.receiverDTCC) {
      notifyError('Receiver DTCC# is required.');
      return;
    }

    if (!modalData.delivererDTCC) {
      notifyError('Deliverer DTCC# is required.');
      return;
    }

    if (!modalData.receiverCorrespondent) {
      notifyError('Receiver Correspondent is required.');
      return;
    }

    if (!modalData.delivererAccountNo) {
      notifyError('Deliverer Account No is required.');
      return;
    }

    try {
      setLoading(true);

      let position_ids = [];

      selectedRows.forEach(function(row) {
        position_ids.push(row.positionId);
      });

      let req = new AssetTransfer();
      req.setPositionIdsList(position_ids);
      req.setSubmittingDtcc(modalData.submittingDTCC);
      req.setReceiverDtcc(modalData.receiverDTCC);
      req.setDelivererDtcc(modalData.delivererDTCC);
      req.setReceiverCorrespondent(modalData.receiverCorrespondent);
      req.setDelivererAccountNo(modalData.delivererAccountNo);

      assetTransferClient.downloadAssetTransfer(req, {}, (err, res) => {
        if (err) {
          notifyError(err.message);
        } else {
          download(res);
          notifySuccess('AT Download completed');
        }
      });

      assetTransferClient.donwloadTransferInput(req, {}, (err, res) => {
        if (err) {
          notifyError(err.message);
        } else {
          download(res);
          notifySuccess('TI Download completed');
        }
      });
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        if (!loading) {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Generate Asset Transfer
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="submittingDTCC"
                    label="Submitting DTCC#"
                    value={modalData.submittingDTCC}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="receiverDTCC"
                    label="Receiver DTCC#"
                    value={modalData.receiverDTCC}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="delivererDTCC"
                    label="Deliverer DTCC#"
                    value={modalData.delivererDTCC}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    required
                    name="receiverCorrespondent"
                    label="Receiver Correspondent"
                    value={modalData.receiverCorrespondent}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    required
                    name="delivererAccountNo"
                    label="Deliverer Account No"
                    value={modalData.delivererAccountNo}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 9 }}
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleGenerate}
                  >
                    Transfer
                  </Button>
                </div>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
