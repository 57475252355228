/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  Template,
  ListTemplateRequest,
} from '../../../proto/reportpb/template_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  IconButton,
  Tooltip,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Save as SaveIcon,
  AddAPhoto as AddAPhotoIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Moment JS*/
import moment from 'moment-timezone';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import CountriesSelect from '../../../components/AutoComplete/Countries';
import StateSelect from './../../../components/Dropdown/State';
import SearchButton from '../../../components/Button/Search';

export default function ReportTemplateTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    templateServiceClient: template,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListTemplateRequest();
    list.setCorrespondent(correspondentValue);

    template.listTemplate(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      const rows = res.toObject().templatesList;

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const save = () => {
    if (!modalCorrespondentValue) {
      notifyError('Correspondent is required.');
      return;
    }
    if (!modalReportTypeValue) {
      notifyError('Report type is required.');
      return;
    }
    if (!modalPhotoUrlValue) {
      notifyError('Logo is required.');
      return;
    }
    if (!modalAddressValue) {
      notifyError('Address is required.');
      return;
    }
    if (!modalCityValue) {
      notifyError('City is required.');
      return;
    }
    if (!modalCountryValue) {
      notifyError('Country is required.');
      return;
    }

    setDisableButton(true);

    if (modalAddAction) {
      let addReq = new Template();
      addReq.setCorrespondent(modalCorrespondentValue);
      addReq.setReportType(modalReportTypeValue);
      addReq.setAddress(modalAddressValue);
      addReq.setCity(modalCityValue);
      addReq.setState(modalStateValue);
      addReq.setZip(modalZipValue);
      addReq.setCountry(modalCountryValue);
      addReq.setPhoto(modalPhotoUrlValue);

      template.createTemplate(addReq, {}, (err, res) => {
        if (err) {
          notifyError(err.message);
          return;
        } else {
          let obj = res.toObject().template;
          let newTemplate = {
            correspondent: obj.correspondent,
            reportType: obj.reportType,
            address: obj.address,
            city: obj.city,
            state: obj.state,
            zip: obj.zip,
            country: obj.country,
            templateId: obj.templateId,
            photo: obj.photo,
          };
          rows.push(newTemplate);
          notifySuccess('New report template has been added.');
          handleClose();
          setDisableButton(false);
        }
      });
    } else {
      let editReq = new Template();
      editReq.setTemplateId(modalTemplateIdValue);
      editReq.setCorrespondent(modalCorrespondentValue);
      editReq.setReportType(modalReportTypeValue);
      editReq.setAddress(modalAddressValue);
      editReq.setCity(modalCityValue);
      editReq.setState(modalStateValue);
      editReq.setZip(modalZipValue);
      editReq.setCountry(modalCountryValue);
      editReq.setPhoto(modalPhotoUrlValue);

      template.updateTemplate(editReq, {}, (err, res) => {
        if (err) {
          console.log(err);
          notifyError(err.message);
          return;
        } else {
          let obj = res.toObject().template;
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].templateId === obj.templateId) {
              rows[i].correspondent = obj.correspondent;
              rows[i].reportType = obj.reportType;
              rows[i].address = obj.address;
              rows[i].city = obj.city;
              rows[i].state = obj.state;
              rows[i].zip = obj.zip;
              rows[i].country = obj.country;
              rows[i].photo = obj.photo;
            }
          }

          notifySuccess('Report template has been updated.');
          handleClose();
          setDisableButton(false);
        }
      });
    }
  };

  const selectPhoto = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setModalPhotoUrlValue(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Report Template');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="Edit" arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[dataIndex])}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'photo',
      label: 'Logo',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return rows[dataIndex].photo ? (
            <img
              style={{ width: 100 }}
              src={rows[dataIndex].photo}
              alt="logo"
            />
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'reportType',
      label: 'Report Type',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State',
    },
    {
      name: 'zip',
      label: 'Zip',
    },
    {
      name: 'country',
      label: 'Country',
    },
  ];

  const fileName =
    'MonthlyTemplate_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Report Template');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Report Template');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }

    setCurrentPath(path);
  }, [params]);

  const [modalCorrespondentValue, setModalCorrespondentValue] = React.useState(
    ''
  );
  const [modalReportTypeValue, setModalReportTypeValue] = React.useState('');
  const [modalAddressValue, setModalAddressValue] = React.useState('');
  const [modalCityValue, setModalCityValue] = React.useState('');
  const [modalStateValue, setModalStateValue] = React.useState('');
  const [modalZipValue, setModalZipValue] = React.useState('');
  const [modalCountryValue, setModalCountryValue] = React.useState('');
  const [modalPhotoUrlValue, setModalPhotoUrlValue] = React.useState('');
  const [modalTemplateIdValue, setModalTemplateIdValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');
  const [disableButton, setDisableButton] = React.useState(false);

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalCorrespondentValue('');
      setModalReportTypeValue('');
      setModalAddressValue('');
      setModalCityValue('');
      setModalStateValue('');
      setModalZipValue('');
      setModalPhotoUrlValue('');
      setModalCountryValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
      setOpen(true);
    } else {
      setModalCorrespondentValue(rowData.correspondent);
      setModalReportTypeValue(rowData.reportType);
      setModalAddressValue(rowData.address);
      setModalCityValue(rowData.city);
      setModalStateValue(rowData.state);
      setModalZipValue(rowData.zip);
      setModalCountryValue(rowData.country);
      setModalPhotoUrlValue(rowData.photo);
      setModalTemplateIdValue(rowData.templateId);
      setModalAddAction(false);
      setModalTitleValue('Edit');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={true}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Report Template'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Report Template
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <div className={classes.logoContainer}>
                          {modalPhotoUrlValue ? (
                            <img
                              className={classes.uploadThumbnail}
                              src={modalPhotoUrlValue}
                              alt="logo"
                            />
                          ) : (
                            <label className={classes.noThumbnailLabel}>
                              Please select an Image for report logo.
                            </label>
                          )}
                        </div>
                        <input
                          style={{ display: 'none' }}
                          accept="image/*"
                          id="input-photo"
                          type="file"
                          onChange={(e) => {
                            selectPhoto(e);
                          }}
                        />
                        <label htmlFor="input-photo">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            size="large"
                            fullWidth
                            startIcon={<AddAPhotoIcon />}
                          >
                            Select Photo
                          </Button>
                        </label>
                      </div>
                    </div>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <CorrespondentSelect
                          freeSolo={true}
                          name="correspondent"
                          label="Correspondent"
                          value={modalCorrespondentValue}
                          onChange={(e) => {
                            setModalCorrespondentValue(e.currentTarget.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <InputLabel id="reportType" shrink>
                          Report Type *
                        </InputLabel>
                        <Select
                          labelId="reportType"
                          displayEmpty
                          fullWidth
                          value={modalReportTypeValue}
                          onChange={(e) =>
                            setModalReportTypeValue(e.target.value)
                          }
                        >
                          <MenuItem value="monthly_statement">
                            Monthly Statement
                          </MenuItem>
                          <MenuItem value="daily_trade_confirm">
                            Daily Trade Confirm
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className={classes.grdCell1}>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <TextField
                          label="Address"
                          required
                          fullWidth={true}
                          value={modalAddressValue}
                          onChange={(e) => setModalAddressValue(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <TextField
                          label="City"
                          required
                          fullWidth={true}
                          value={modalCityValue}
                          onChange={(e) => setModalCityValue(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <InputLabel shrink>State</InputLabel>
                        <StateSelect
                          value={modalStateValue}
                          onChange={(e) => setModalStateValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <TextField
                          label="Zip"
                          fullWidth={true}
                          value={modalZipValue}
                          onChange={(e) => setModalZipValue(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.grdRow}>
                      <div className={classes.grdCell1}>
                        <CountriesSelect
                          labelid="country"
                          label="Country"
                          value={modalCountryValue}
                          onChange={(e) => setModalCountryValue(e.target.value)}
                          setNewValue={(event, newValue) => {
                            if (newValue) {
                              setModalCountryValue(newValue.code);
                            } else {
                              setModalCountryValue('');
                              return;
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      disabled={disableButton}
                      color="primary"
                      size="large"
                      startIcon={
                        disableButton ? (
                          <CircularProgress
                            style={{ color: '#ffffff', height: 20, width: 20 }}
                          />
                        ) : (
                          ''
                        )
                      }
                      onClick={() => {
                        save();
                      }}
                    >
                      {!disableButton ? 'Save' : 'Saving...'}
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
