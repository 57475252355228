/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

//Service
import { ListTrialBalanceRequest } from '../../../proto/reportpb/trialbalance_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Notification/Notification';

/*Material UI Table Components*/
import {
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function TrialBalanceTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    trialBalanceServiceClient: trialbalance,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = (isSearch, type) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '');
    window.history.pushState({}, null, pathWithParams);

    if (isSearch) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    let list = new ListTrialBalanceRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setMasterAccountNo(masterAccountNoValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    trialbalance.listTrialBalance(list, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        setLoading(false);
        return;
      }

      let rows = [];

      if (type === 'show breaks only') {
        rows = res.toObject().trialBalancesList.filter(function(data) {
          return data.sdDebitCashBalance > 0 || data.sdCreditCashBalance > 0;
        });
      } else {
        rows = res.toObject().trialBalancesList;
      }
      setRows(rows);
      if (isSearch) {
        notifyInfo(rows.length + ' search results.');
        setLoading(false);
      }
    });
  };

  const showBreaksOnly = (type) => {
    search(false, type);
    setIsBreakOnly(!isBreakOnly ? true : false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Trial Balance');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'sdCreditCashBalance',
      label: 'SD Credit Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].sdCreditCashBalance);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'sdDebitCashBalance',
      label: 'SD Debit Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].sdDebitCashBalance);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate
            ? formatPbDate(rows[dataIndex].systemDate)
            : '--';
        },
      },
    },
    {
      name: 'tdCreditCashBalance',
      label: 'TD Credit Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].tdCreditCashBalance);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'tdDebitCashBalance',
      label: 'TD Debit Cash Balance',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].tdDebitCashBalance);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const fileName =
    'TrialBalance_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [4, 2, 1, 5, 0, 3, 11, 8, 7, 6, 10, 9],
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Trial Balance');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Trial Balance');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={[
            'sdCreditCashBalance',
            'sdDebitCashBalance',
            'tdCreditCashBalance',
            'tdDebitCashBalance',
          ]}
          columnsWithQty={[]}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isBreakOnly, setIsBreakOnly] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      systemDate: query.get('systemDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }

    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Account Designator</InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Broker</InputLabel>
                <Select
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search(true, '')}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!rows.length ? true : false}
              startIcon={
                isBreakOnly ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              onClick={() => {
                showBreaksOnly(isBreakOnly ? 'show breaks only' : 'show all');
              }}
            >
              {isBreakOnly ? 'Hide Zero Values' : 'Show Zero Values'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Trial Balance'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
