/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { Sll, ListSllRequest } from '../../../proto/assetpb/sll_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Add as AddIcon } from '@material-ui/icons';
import { Create as EditIcon } from '@material-ui/icons';
import { Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Moment JS*/
import moment from 'moment-timezone';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

function saveSLL(
  sll,
  rows,
  isAdd,
  closeModal,
  symbol,
  symbolDescription,
  market,
  statusDescription,
  statusId,
  sll_id
) {
  if (!symbol) {
    notifyError('Symbol is required.');
    return;
  }
  if (!symbolDescription) {
    notifyError('Symbol Description is required.');
    return;
  }

  if (statusId === 0) {
    notifyError('Status is required.');
    return;
  }

  if (isAdd) {
    let addReq = new Sll();
    addReq.setSllUuid('');
    addReq.setSymbol(symbol);
    addReq.setSymbolDescription(symbolDescription);
    addReq.setMarket(market);
    addReq.setStatusDescription(statusDescription);
    addReq.setStatusId(statusId);

    sll.createSll(addReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      } else {
        let obj = res.toObject().sll;
        let newSll = {
          symbol: obj.symbol,
          symbolDescription: obj.symbolDescription,
          market: obj.market,
          statusDescription: obj.statusDescription,
          statusId: obj.statusId,
        };
        rows.push(newSll);
        notifySuccess('New SLL has been added.');
        closeModal();
      }
    });
  } else {
    let editReq = new Sll();
    editReq.setSllUuid(sll_id);
    editReq.setSymbol(symbol);
    editReq.setSymbolDescription(symbolDescription);
    editReq.setMarket(market);
    editReq.setStatusDescription(statusDescription);
    editReq.setStatusId(statusId);

    sll.updateSll(editReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        notifyError(err.message);
        return;
      } else {
        let obj = res.toObject().sll;
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].symbol === obj.symbol) {
            rows[i].symbolDescription = obj.symbolDescription;
            rows[i].market = obj.market;
            rows[i].statusDescription = obj.statusDescription;
            rows[i].statusId = obj.statusId;
          }
        }

        notifySuccess('SLL has been updated.');
        closeModal();
      }
    });
  }
}

export default function SllTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    sllServiceClient: sll,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListSllRequest();
    list.setSymbol(symbolValue);

    sll.listSll(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      const rows = res.toObject().sllsList;
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('SLL');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0, width: 0 }}
            >
              <div align={'left'} className={classes.grdCellNone}>
                <Tooltip title="Edit" arrow>
                  <div>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleOpen(rows[dataIndex])}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'market',
      label: 'Market',
    },
    {
      name: 'sllUuid',
      label: 'ID',
    },
    {
      name: 'statusDescription',
      label: 'Status Description',
    },
    {
      name: 'statusId',
      label: 'Status ID',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
  ];

  const fileName = 'EFT_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 2, 5, 6, 1, 3, 4],
    selectableRowsHeader: false,
    selectableRows: false,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('SLL');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('SLL');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      symbol: query.get('symbol'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }

    setCurrentPath(path);
  }, [params]);

  const [modalSymbolValue, setModalSymbolValue] = React.useState('');
  const [
    modalSymbolDescriptionValue,
    setModalSymbolDescriptionValue,
  ] = React.useState('');
  const [modalMarketValue, setModalMarketValue] = React.useState('');
  const [
    modalStatusDescriptionValue,
    setModalStatusDescriptionValue,
  ] = React.useState('');
  const [modalStatusIdValue, setModalStatusIdValue] = React.useState('');
  const [modalSllUuidValue, setModalSllUuidValue] = React.useState('');

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalSymbolValue('');
      setModalSymbolDescriptionValue('');
      setModalMarketValue('');
      setModalStatusDescriptionValue('');
      setModalSllUuidValue('');

      setModalAddAction(true);
      setModalTitleValue('Add New');
      setOpen(true);
    } else {
      setModalSllUuidValue(rowData.sllUuid);
      setModalSymbolValue(rowData.symbol);
      setModalSymbolDescriptionValue(rowData.symbolDescription);
      setModalMarketValue(rowData.market);
      setModalStatusIdValue(rowData.statusId);
      setModalStatusDescriptionValue(rowData.statusDescription);
      setModalAddAction(false);
      setModalTitleValue('Edit');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Statuses = [
    {
      value: 0,
      desc: '',
    },
    {
      value: 1,
      desc: 'Easy to borrow',
    },
    {
      value: 2,
      desc: 'Locate required',
    },
    {
      value: 3,
      desc: 'Pre borrow',
    },
    {
      value: 4,
      desc: 'Short Sale Prohibited',
    },
  ];

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'SLL'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} SLL
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required
                      label="Symbol"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalSymbolValue}
                      onChange={(e) => setModalSymbolValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdRow}>
                    <div
                      className={classes.grdCell1}
                      style={{ marginRight: 30 }}
                    >
                      <TextField
                        label="Market"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={modalMarketValue}
                        onChange={(e) => setModalMarketValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required
                      label="Symbol Description"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalSymbolDescriptionValue}
                      onChange={(e) =>
                        setModalSymbolDescriptionValue(e.target.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Status *</InputLabel>
                    <Select
                      fullWidth
                      value={modalStatusIdValue}
                      onChange={(e) => {
                        let v = 0;
                        if (e.target.value) {
                          v = e.target.value;
                        }
                        let status = Statuses[v];
                        setModalStatusIdValue(status.value);
                        setModalStatusDescriptionValue(status.desc);
                        console.log(status.value, status.desc);
                      }}
                    >
                      <MenuItem value="0">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="1">Easy to borrow</MenuItem>
                      <MenuItem value="2">Locate required</MenuItem>
                      <MenuItem value="3">Pre borrow</MenuItem>
                      <MenuItem value="4">Short Sale Prohibited</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveSLL(
                          sll,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalSymbolValue,
                          modalSymbolDescriptionValue,
                          modalMarketValue,
                          modalStatusDescriptionValue,
                          modalStatusIdValue,
                          modalSllUuidValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
