import React, { useState } from 'react';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/sql/sql';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { format } from 'sql-formatter';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useExecuteQuery } from '../Hooks/useSsrService';

const useStyles = makeStyles((theme) => ({
  queryEditor: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBackground: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalContentBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const codeMirrorEditOptions = {
  mode: 'text/x-pgsql',
  theme: 'material',
  lineNumbers: true,
};

const codeMirrorReadOnlyOptions = {
  mode: 'text/x-pgsql',
  theme: 'material',
  lineNumbers: true,
  readOnly: 'nocursor',
};

const sqlFormatOptions = {
  language: 'postgresql',
};

// { value, onChange={(value) => xxx}, showExecuteButton, showFormatButton, editable }
export default function QueryEditor({
  value,
  onChange,
  editable = false,
  showFormatButton = false,
  showExecuteButton = false,
}) {
  const classes = useStyles();

  const [executeInProgress, setExecuteInProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    body: '',
  });

  const executeQuery = useExecuteQuery();

  const options = editable ? codeMirrorEditOptions : codeMirrorReadOnlyOptions;

  // only show format button if it's editable
  if (!editable) {
    showFormatButton = false;
  }

  const showButton = showFormatButton || showExecuteButton;

  const handleClickFormat = () => {
    const formatted = format(value, sqlFormatOptions);

    onChange(formatted);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent({
      title: '',
      body: '',
    });
  };

  const handleClickExecute = async () => {
    setExecuteInProgress(true);
    setOpenModal(true);
    setModalContent({
      title: 'Execute in progress...',
      body: (
        <div className={classes.modalContentBody}>
          <CircularProgress />
        </div>
      ),
    });

    try {
      await executeQuery.mutateAsync({ filename: 'query.csv', query: value });
      setOpenModal(false);
    } catch (err) {
      console.warn('Failed to execute query because of ', err);

      setOpenModal(true);
      setModalContent({
        title: 'Failed to execute query',
        body: err.message,
      });
    } finally {
      setExecuteInProgress(false);
    }
  };

  return (
    <div className={classes.queryEditor}>
      <CodeMirror
        value={value}
        onBeforeChange={(editor, data, value) => onChange(value)}
        options={options}
      />
      {showButton && (
        <div className={classes.buttonGroup}>
          <ButtonGroup color="primary" variant="contained" size="medium">
            {showFormatButton && (
              <Button onClick={handleClickFormat}> FORMAT </Button>
            )}
            {showExecuteButton && (
              <Button onClick={handleClickExecute} disabled={executeInProgress}>
                EXECUTE
              </Button>
            )}
          </ButtonGroup>
          <Modal
            className={classes.modal}
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="query-execute-modal-title"
            aria-describedby="query-execute-modal-description"
          >
            <div className={classes.modalBackground}>
              <h2 id="query-execute-modal-title">{modalContent.title}</h2>
              <div id="query-execute-modal-description">
                {modalContent.body}
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
