// source: reportpb/position.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.reportpb.CorrespondentPosition', null, global);
goog.exportSymbol('proto.reportpb.ListCorrespondentPositionsRequest', null, global);
goog.exportSymbol('proto.reportpb.ListCorrespondentPositionsResponse', null, global);
goog.exportSymbol('proto.reportpb.ListPositionDetailRequest', null, global);
goog.exportSymbol('proto.reportpb.ListPositionDetailResponse', null, global);
goog.exportSymbol('proto.reportpb.ListPositionRequest', null, global);
goog.exportSymbol('proto.reportpb.ListPositionResponse', null, global);
goog.exportSymbol('proto.reportpb.ListPositionSummary', null, global);
goog.exportSymbol('proto.reportpb.Position', null, global);
goog.exportSymbol('proto.reportpb.PositionDetail', null, global);
goog.exportSymbol('proto.reportpb.StreamPositionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Position.displayName = 'proto.reportpb.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListPositionRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListPositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionRequest.displayName = 'proto.reportpb.ListPositionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListPositionResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListPositionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionResponse.displayName = 'proto.reportpb.ListPositionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.PositionDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.PositionDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.PositionDetail.displayName = 'proto.reportpb.PositionDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListPositionDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionDetailRequest.displayName = 'proto.reportpb.ListPositionDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListPositionDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListPositionDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionDetailResponse.displayName = 'proto.reportpb.ListPositionDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListPositionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionSummary.displayName = 'proto.reportpb.ListPositionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListCorrespondentPositionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListCorrespondentPositionsRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListCorrespondentPositionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListCorrespondentPositionsRequest.displayName = 'proto.reportpb.ListCorrespondentPositionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.CorrespondentPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.CorrespondentPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.CorrespondentPosition.displayName = 'proto.reportpb.CorrespondentPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListCorrespondentPositionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListCorrespondentPositionsResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListCorrespondentPositionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListCorrespondentPositionsResponse.displayName = 'proto.reportpb.ListCorrespondentPositionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.StreamPositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.StreamPositionRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.StreamPositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.StreamPositionRequest.displayName = 'proto.reportpb.StreamPositionRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
originalCusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 10, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 11, ""),
symbolDescription: jspb.Message.getFieldWithDefault(msg, 12, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 13, ""),
closingPrice: jspb.Message.getFieldWithDefault(msg, 14, ""),
tdShortQty: jspb.Message.getFieldWithDefault(msg, 15, ""),
tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 16, ""),
tdLongQty: jspb.Message.getFieldWithDefault(msg, 17, ""),
tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 18, ""),
sdShortQty: jspb.Message.getFieldWithDefault(msg, 19, ""),
sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 20, ""),
sdLongQty: jspb.Message.getFieldWithDefault(msg, 21, ""),
sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 22, ""),
positionId: jspb.Message.getFieldWithDefault(msg, 23, ""),
accountId: jspb.Message.getFieldWithDefault(msg, 24, ""),
tdCostBasis: jspb.Message.getFieldWithDefault(msg, 25, ""),
qty: jspb.Message.getFieldWithDefault(msg, 26, ""),
price: jspb.Message.getFieldWithDefault(msg, 27, ""),
costPrice: jspb.Message.getFieldWithDefault(msg, 28, ""),
contraAccountDesignator: jspb.Message.getFieldWithDefault(msg, 29, ""),
marketValue: jspb.Message.getFieldWithDefault(msg, 30, ""),
unrealized: jspb.Message.getFieldWithDefault(msg, 31, ""),
currency: jspb.Message.getFieldWithDefault(msg, 36, ""),
tdEntrySwapRate: jspb.Message.getFieldWithDefault(msg, 37, ""),
asAt: (f = msg.getAsAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Position}
 */
proto.reportpb.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Position;
  return proto.reportpb.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Position}
 */
proto.reportpb.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortQty(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongQty(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortQty(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongQty(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCostBasis(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostPrice(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountDesignator(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrealized(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEntrySwapRate(value);
      break;
    case 38:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAsAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTdShortQty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTdLongQty();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSdShortQty();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSdLongQty();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPositionId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCostPrice();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getContraAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getUnrealized();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getTdEntrySwapRate();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getAsAt();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.Position.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.Position.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.Position.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Position.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Position} returns this
*/
proto.reportpb.Position.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Position.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string original_cusip = 9;
 * @return {string}
 */
proto.reportpb.Position.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string symbol = 10;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string cusip = 11;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string symbol_description = 12;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string asset_type = 13;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string closing_price = 14;
 * @return {string}
 */
proto.reportpb.Position.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string td_short_qty = 15;
 * @return {string}
 */
proto.reportpb.Position.prototype.getTdShortQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setTdShortQty = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string td_short_market_value = 16;
 * @return {string}
 */
proto.reportpb.Position.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string td_long_qty = 17;
 * @return {string}
 */
proto.reportpb.Position.prototype.getTdLongQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setTdLongQty = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string td_long_market_value = 18;
 * @return {string}
 */
proto.reportpb.Position.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string sd_short_qty = 19;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSdShortQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSdShortQty = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string sd_short_market_value = 20;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string sd_long_qty = 21;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSdLongQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSdLongQty = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string sd_long_market_value = 22;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string position_id = 23;
 * @return {string}
 */
proto.reportpb.Position.prototype.getPositionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string account_id = 24;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string td_cost_basis = 25;
 * @return {string}
 */
proto.reportpb.Position.prototype.getTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string qty = 26;
 * @return {string}
 */
proto.reportpb.Position.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string price = 27;
 * @return {string}
 */
proto.reportpb.Position.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string cost_price = 28;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCostPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCostPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string contra_account_designator = 29;
 * @return {string}
 */
proto.reportpb.Position.prototype.getContraAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setContraAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string market_value = 30;
 * @return {string}
 */
proto.reportpb.Position.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string unrealized = 31;
 * @return {string}
 */
proto.reportpb.Position.prototype.getUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string currency = 36;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string td_entry_swap_rate = 37;
 * @return {string}
 */
proto.reportpb.Position.prototype.getTdEntrySwapRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setTdEntrySwapRate = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional google.protobuf.Timestamp as_at = 38;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.Position.prototype.getAsAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 38));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.Position} returns this
*/
proto.reportpb.Position.prototype.setAsAt = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.clearAsAt = function() {
  return this.setAsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Position.prototype.hasAsAt = function() {
  return jspb.Message.getField(this, 38) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListPositionRequest.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
symbol: jspb.Message.getFieldWithDefault(msg, 9, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 10, ""),
pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
currenciesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
clearingBroker: jspb.Message.getFieldWithDefault(msg, 13, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 14, ""),
underlyingSymbol: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionRequest}
 */
proto.reportpb.ListPositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionRequest;
  return proto.reportpb.ListPositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionRequest}
 */
proto.reportpb.ListPositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 11:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrencies(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setClearingBroker(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getClearingBroker();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUnderlyingSymbol();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListPositionRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
*/
proto.reportpb.ListPositionRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string symbol = 9;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cusip = 10;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional utilspb.Pagination pagination = 11;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListPositionRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 11));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
*/
proto.reportpb.ListPositionRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated string currencies = 12;
 * @return {!Array<string>}
 */
proto.reportpb.ListPositionRequest.prototype.getCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.addCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};


/**
 * optional string clearing_broker = 13;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getClearingBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setClearingBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string asset_type = 14;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string underlying_symbol = 15;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getUnderlyingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setUnderlyingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListPositionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.reportpb.Position.toObject, includeInstance),
summary: (f = msg.getSummary()) && proto.reportpb.ListPositionSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionResponse}
 */
proto.reportpb.ListPositionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionResponse;
  return proto.reportpb.ListPositionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionResponse}
 */
proto.reportpb.ListPositionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Position;
      reader.readMessage(value,proto.reportpb.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 2:
      var value = new proto.reportpb.ListPositionSummary;
      reader.readMessage(value,proto.reportpb.ListPositionSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Position.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListPositionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Position positions = 1;
 * @return {!Array<!proto.reportpb.Position>}
 */
proto.reportpb.ListPositionResponse.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.reportpb.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Position, 1));
};


/**
 * @param {!Array<!proto.reportpb.Position>} value
 * @return {!proto.reportpb.ListPositionResponse} returns this
*/
proto.reportpb.ListPositionResponse.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Position}
 */
proto.reportpb.ListPositionResponse.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListPositionResponse} returns this
 */
proto.reportpb.ListPositionResponse.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * optional ListPositionSummary summary = 2;
 * @return {?proto.reportpb.ListPositionSummary}
 */
proto.reportpb.ListPositionResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListPositionSummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListPositionSummary, 2));
};


/**
 * @param {?proto.reportpb.ListPositionSummary|undefined} value
 * @return {!proto.reportpb.ListPositionResponse} returns this
*/
proto.reportpb.ListPositionResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionResponse} returns this
 */
proto.reportpb.ListPositionResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.PositionDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.PositionDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.PositionDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.PositionDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
contraAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
entryType: jspb.Message.getFieldWithDefault(msg, 6, ""),
symbol: jspb.Message.getFieldWithDefault(msg, 7, ""),
qty: jspb.Message.getFieldWithDefault(msg, 8, ""),
netAmt: jspb.Message.getFieldWithDefault(msg, 9, ""),
price: jspb.Message.getFieldWithDefault(msg, 10, ""),
fees: jspb.Message.getFieldWithDefault(msg, 11, ""),
accountId: jspb.Message.getFieldWithDefault(msg, 12, ""),
trnsId: jspb.Message.getFieldWithDefault(msg, 13, ""),
status: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.PositionDetail}
 */
proto.reportpb.PositionDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.PositionDetail;
  return proto.reportpb.PositionDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.PositionDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.PositionDetail}
 */
proto.reportpb.PositionDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.PositionDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.PositionDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.PositionDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.PositionDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contra_account_no = 2;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Date system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.PositionDetail.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.PositionDetail} returns this
*/
proto.reportpb.PositionDetail.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.PositionDetail.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Date settle_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.PositionDetail.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.PositionDetail} returns this
*/
proto.reportpb.PositionDetail.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.PositionDetail.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date trade_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.PositionDetail.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.PositionDetail} returns this
*/
proto.reportpb.PositionDetail.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.PositionDetail.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string entry_type = 6;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string symbol = 7;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string qty = 8;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string net_amt = 9;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string price = 10;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string fees = 11;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string account_id = 12;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string trns_id = 13;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string status = 14;
 * @return {string}
 */
proto.reportpb.PositionDetail.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.PositionDetail} returns this
 */
proto.reportpb.PositionDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
originalCusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionDetailRequest}
 */
proto.reportpb.ListPositionDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionDetailRequest;
  return proto.reportpb.ListPositionDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionDetailRequest}
 */
proto.reportpb.ListPositionDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.reportpb.ListPositionDetailRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionDetailRequest} returns this
 */
proto.reportpb.ListPositionDetailRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string original_cusip = 2;
 * @return {string}
 */
proto.reportpb.ListPositionDetailRequest.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionDetailRequest} returns this
 */
proto.reportpb.ListPositionDetailRequest.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.reportpb.ListPositionDetailRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionDetailRequest} returns this
 */
proto.reportpb.ListPositionDetailRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListPositionDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
positionDetailsList: jspb.Message.toObjectList(msg.getPositionDetailsList(),
    proto.reportpb.PositionDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionDetailResponse}
 */
proto.reportpb.ListPositionDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionDetailResponse;
  return proto.reportpb.ListPositionDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionDetailResponse}
 */
proto.reportpb.ListPositionDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.PositionDetail;
      reader.readMessage(value,proto.reportpb.PositionDetail.deserializeBinaryFromReader);
      msg.addPositionDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.PositionDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PositionDetail position_details = 1;
 * @return {!Array<!proto.reportpb.PositionDetail>}
 */
proto.reportpb.ListPositionDetailResponse.prototype.getPositionDetailsList = function() {
  return /** @type{!Array<!proto.reportpb.PositionDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.PositionDetail, 1));
};


/**
 * @param {!Array<!proto.reportpb.PositionDetail>} value
 * @return {!proto.reportpb.ListPositionDetailResponse} returns this
*/
proto.reportpb.ListPositionDetailResponse.prototype.setPositionDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.PositionDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.PositionDetail}
 */
proto.reportpb.ListPositionDetailResponse.prototype.addPositionDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.PositionDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListPositionDetailResponse} returns this
 */
proto.reportpb.ListPositionDetailResponse.prototype.clearPositionDetailsList = function() {
  return this.setPositionDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
tdLongQty: jspb.Message.getFieldWithDefault(msg, 2, ""),
tdShortQty: jspb.Message.getFieldWithDefault(msg, 3, ""),
sdLongQty: jspb.Message.getFieldWithDefault(msg, 4, ""),
sdShortQty: jspb.Message.getFieldWithDefault(msg, 5, ""),
closingPrice: jspb.Message.getFieldWithDefault(msg, 8, ""),
tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
tdCostBasis: jspb.Message.getFieldWithDefault(msg, 13, ""),
currency: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionSummary}
 */
proto.reportpb.ListPositionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionSummary;
  return proto.reportpb.ListPositionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionSummary}
 */
proto.reportpb.ListPositionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongQty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortQty(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongQty(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortQty(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCostBasis(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTdLongQty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTdShortQty();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSdLongQty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSdShortQty();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reportpb.ListPositionSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string td_long_qty = 2;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getTdLongQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTdLongQty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string td_short_qty = 3;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getTdShortQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTdShortQty = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sd_long_qty = 4;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getSdLongQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setSdLongQty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sd_short_qty = 5;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getSdShortQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setSdShortQty = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string closing_price = 8;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string td_long_market_value = 9;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string td_short_market_value = 10;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sd_long_market_value = 11;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string sd_short_market_value = 12;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string td_cost_basis = 13;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string currency = 16;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListCorrespondentPositionsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListCorrespondentPositionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListCorrespondentPositionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListCorrespondentPositionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
symbolsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
firmAccounts: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest}
 */
proto.reportpb.ListCorrespondentPositionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListCorrespondentPositionsRequest;
  return proto.reportpb.ListCorrespondentPositionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListCorrespondentPositionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest}
 */
proto.reportpb.ListCorrespondentPositionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbols(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFirmAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListCorrespondentPositionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListCorrespondentPositionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListCorrespondentPositionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFirmAccounts();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
*/
proto.reportpb.ListCorrespondentPositionsRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string symbols = 3;
 * @return {!Array<string>}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.getSymbolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.addSymbols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};


/**
 * optional bool firm_accounts = 4;
 * @return {boolean}
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.getFirmAccounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListCorrespondentPositionsRequest} returns this
 */
proto.reportpb.ListCorrespondentPositionsRequest.prototype.setFirmAccounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.CorrespondentPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.CorrespondentPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.CorrespondentPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.CorrespondentPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
longQty: jspb.Message.getFieldWithDefault(msg, 3, ""),
shortQty: jspb.Message.getFieldWithDefault(msg, 4, ""),
longMarketValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
shortMarketValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
numAccounts: jspb.Message.getFieldWithDefault(msg, 7, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 8, ""),
closingPrice: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.CorrespondentPosition}
 */
proto.reportpb.CorrespondentPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.CorrespondentPosition;
  return proto.reportpb.CorrespondentPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.CorrespondentPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.CorrespondentPosition}
 */
proto.reportpb.CorrespondentPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongQty(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortQty(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumAccounts(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.CorrespondentPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.CorrespondentPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.CorrespondentPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.CorrespondentPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongQty();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShortQty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNumAccounts();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cusip = 2;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string long_qty = 3;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getLongQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setLongQty = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string short_qty = 4;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getShortQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setShortQty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string long_market_value = 5;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string short_market_value = 6;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string num_accounts = 7;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getNumAccounts = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setNumAccounts = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string asset_type = 8;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string closing_price = 9;
 * @return {string}
 */
proto.reportpb.CorrespondentPosition.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.CorrespondentPosition} returns this
 */
proto.reportpb.CorrespondentPosition.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListCorrespondentPositionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListCorrespondentPositionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListCorrespondentPositionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListCorrespondentPositionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListCorrespondentPositionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondentPositionsList: jspb.Message.toObjectList(msg.getCorrespondentPositionsList(),
    proto.reportpb.CorrespondentPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListCorrespondentPositionsResponse}
 */
proto.reportpb.ListCorrespondentPositionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListCorrespondentPositionsResponse;
  return proto.reportpb.ListCorrespondentPositionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListCorrespondentPositionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListCorrespondentPositionsResponse}
 */
proto.reportpb.ListCorrespondentPositionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.CorrespondentPosition;
      reader.readMessage(value,proto.reportpb.CorrespondentPosition.deserializeBinaryFromReader);
      msg.addCorrespondentPositions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListCorrespondentPositionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListCorrespondentPositionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListCorrespondentPositionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListCorrespondentPositionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.CorrespondentPosition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CorrespondentPosition correspondent_positions = 1;
 * @return {!Array<!proto.reportpb.CorrespondentPosition>}
 */
proto.reportpb.ListCorrespondentPositionsResponse.prototype.getCorrespondentPositionsList = function() {
  return /** @type{!Array<!proto.reportpb.CorrespondentPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.CorrespondentPosition, 1));
};


/**
 * @param {!Array<!proto.reportpb.CorrespondentPosition>} value
 * @return {!proto.reportpb.ListCorrespondentPositionsResponse} returns this
*/
proto.reportpb.ListCorrespondentPositionsResponse.prototype.setCorrespondentPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.CorrespondentPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.CorrespondentPosition}
 */
proto.reportpb.ListCorrespondentPositionsResponse.prototype.addCorrespondentPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.CorrespondentPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListCorrespondentPositionsResponse} returns this
 */
proto.reportpb.ListCorrespondentPositionsResponse.prototype.clearCorrespondentPositionsList = function() {
  return this.setCorrespondentPositionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.StreamPositionRequest.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.StreamPositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.StreamPositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.StreamPositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StreamPositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
type: jspb.Message.getFieldWithDefault(msg, 7, ""),
systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
symbol: jspb.Message.getFieldWithDefault(msg, 9, ""),
cusip: jspb.Message.getFieldWithDefault(msg, 10, ""),
currenciesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
clearingBroker: jspb.Message.getFieldWithDefault(msg, 12, ""),
assetType: jspb.Message.getFieldWithDefault(msg, 13, ""),
underlyingSymbol: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.StreamPositionRequest}
 */
proto.reportpb.StreamPositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.StreamPositionRequest;
  return proto.reportpb.StreamPositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.StreamPositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.StreamPositionRequest}
 */
proto.reportpb.StreamPositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrencies(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setClearingBroker(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.StreamPositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.StreamPositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.StreamPositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StreamPositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getClearingBroker();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUnderlyingSymbol();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.StreamPositionRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
*/
proto.reportpb.StreamPositionRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StreamPositionRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string symbol = 9;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cusip = 10;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string currencies = 11;
 * @return {!Array<string>}
 */
proto.reportpb.StreamPositionRequest.prototype.getCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.addCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};


/**
 * optional string clearing_broker = 12;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getClearingBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setClearingBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string asset_type = 13;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string underlying_symbol = 14;
 * @return {string}
 */
proto.reportpb.StreamPositionRequest.prototype.getUnderlyingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StreamPositionRequest} returns this
 */
proto.reportpb.StreamPositionRequest.prototype.setUnderlyingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


goog.object.extend(exports, proto.reportpb);
