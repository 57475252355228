/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListExecutionReconRequest } from '../../../proto/reconpb/executionrecon_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { Box, TextField, Button, Tooltip, IconButton } from '@material-ui/core';

/*Icons*/
import { Visibility as ViewIcon, Save as SaveIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ExecutionReconTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    executionReconServiceClient: executionrecon,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = (isSearch, type) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    if (isSearch) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    let list = new ListExecutionReconRequest();
    list.setSymbol(symbolValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    executionrecon.listExecutionRecon(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      let rows = [];

      if (type === 'show breaks only') {
        rows = res.toObject().executionReconsList.filter(function(data) {
          return parseFloat(data.amtDiff) !== 0;
        });
      } else {
        rows = res.toObject().executionReconsList;
      }

      setRows(rows);
      if (isSearch) {
        notifyInfo(rows.length + ' search results.');
        setLoading(false);
      }
    });
  };

  const showBreaksOnly = (type) => {
    search(false, type);
    setIsBreakOnly(!isBreakOnly ? true : false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Execution Reconciliation');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'account',
      label: 'Account',
    },
    {
      name: 'amt',
      label: 'Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].amt);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'amtDiff',
      label: 'Amt Diff',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].amtDiff);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'contraAccount',
      label: 'Contra Account',
    },
    {
      name: 'contraAmt',
      label: 'Contra Amt',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = formatCurrency(rows[dataIndex].contraAmt);
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'contraQty',
      label: 'Contra Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].contraQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'contraSource',
      label: 'Contra Source',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'diffQty',
      label: 'Diff Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].diffQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].qty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settleDate
            ? formatPbDate(rows[dataIndex].settleDate)
            : '--';
        },
      },
    },
    {
      name: 'side',
      label: 'Side',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate
            ? formatPbDate(rows[dataIndex].systemDate)
            : '--';
        },
      },
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeDate
            ? formatPbDate(rows[dataIndex].tradeDate)
            : '--';
        },
      },
    },
  ];

  const fileName =
    'ExecutionRecon_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [14, 15, 11, 0, 3, 6, 9, 13, 7, 12, 10, 5, 8, 1, 4, 2],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Execution Reconciliation');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Execution Reconciliation');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isBreakOnly, setIsBreakOnly] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      systemDate: query.get('systemDate'),
      symbol: query.get('symbol'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.previousDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }

    setCurrentPath(path);
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search(true, '')}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ViewIcon />}
              onClick={() => {
                showBreaksOnly(isBreakOnly ? 'show breaks only' : 'show all');
              }}
            >
              {isBreakOnly ? 'Show Breaks Only' : 'Show All'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Execution Reconciliations'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
