/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListPositionReconDetailRequest } from '../../../proto/reconpb/positionrecon_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStylePositionReconciliation';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PositionReconDetailsTable({
  systemdate,
  datetype,
  origcusip,
  accountno,
}) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { positionReconServiceClient: positionrecon } = useContext(
    ServiceContext
  );
  /*=========================================================================================
  Table modifications
  ===========================================================================================*/

  const columns = [
    {
      id: '1',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(true),
      align: 'left',
      options: {
        display: true,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate ? rows[dataIndex].systemDate : '--';
        },
      },
    },
    {
      id: '2',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '3',
      name: 'contraAccountNo',
      label: 'Contra Account No',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '4',
      name: 'tradeDate',
      label: 'Trade Date',
      visibleState: useState(true),
      align: 'left',
      options: {
        display: true,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeDate ? rows[dataIndex].tradeDate : '--';
        },
      },
    },
    {
      id: '5',
      name: 'settleDate',
      label: 'Settle Date',
      visibleState: useState(true),
      align: 'left',
      options: {
        display: true,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settleDate ? rows[dataIndex].settleDate : '--';
        },
      },
    },
    {
      id: '6',
      name: 'entryType',
      label: 'Entry Type',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '7',
      name: 'symbol',
      label: 'Symbol',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '8',
      name: 'qty',
      label: 'Qty',
      visibleState: useState(true),
      align: 'right',
    },
    {
      id: '9',
      name: 'netAmt',
      label: 'Net Amt',
      visibleState: useState(true),
      align: 'right',
    },
    {
      id: '10',
      name: 'price',
      label: 'Price',
      visibleState: useState(true),
      align: 'right',
    },
    {
      id: '11',
      name: 'fees',
      label: 'Fees',
      visibleState: useState(true),
      align: 'right',
    },
    {
      id: '12',
      name: 'originalCusip',
      label: 'Original Cusip',
      align: 'left',
      options: {
        display: 'false',
      },
    },
  ];

  const options = {
    filterType: 'select',
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    responsive: 'scroll',
    selectableRows: 'none',
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  useEffect(() => {
    let list = new ListPositionReconDetailRequest();
    list.setOriginalCusip(origcusip);
    list.setAccountNo(accountno);

    if (systemdate) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = moment(new Date(formatPbDate(systemdate)))
        .format('yyyy-MM-DD')
        .split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setDateType(datetype);

    positionrecon.listPositionReconDetail(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err);
        return;
      }
      const rows = res.toObject().positionReconDetailsList.map((data) => ({
        accountNo: data.accountNo,
        contraAccountNo: data.contraAccountNo,
        entryType: data.entryType,
        tradeDate: formatPbDate(data.tradeDate),
        symbol: data.symbol,
        qty: data.qty,
        settleDate: formatPbDate(data.settleDate),
        netAmt: formatCurrency(data.netAmt),
        price: formatCurrency(data.price),
        fees: formatCurrency(data.fees),
        systemDate: formatPbDate(data.systemDate),
        originalCusip: data.originalCusip,
      }));

      setRows(rows);
    });
  }, [positionrecon, systemdate, datetype, origcusip, accountno]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={'Position Reconciliation Details'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
