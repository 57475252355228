/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListCalendarRequest } from '../../../proto/admpb/calendar_grpc_web_pb';

import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';

/*Toast Notification*/
import { notifyInfo, notifyError } from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Material UI Icons*/
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*data*/
import { holidayNameOptions } from '../MockData';

import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export function CalendarTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const {
    profileServiceClient: systemDate,
    calendarServiceClient: calendarClient,
  } = useContext(ServiceContext);

  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          dayOfTheWeek:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          holidayName:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          isCurrent:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          settle:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          systemDate:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          trade:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          trade: data.trade,
          settle: data.settle,
          dayOfTheWeek: data.dayOfTheWeek,
          holidayName: data.holidayName,
          isCurrent: data.isCurrent,
          calendarId: data.calendarId,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          trade: data.trade,
          settle: data.settle,
          dayOfTheWeek: data.dayOfTheWeek,
          holidayName: data.holidayName,
          calendarId: data.calendarId,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**Search Calendar function ==================================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (fromDateValue ? '&fromDate=' + fromDateValue : '') +
        (toDateValue ? '&toDate=' + toDateValue : '') +
        (holidayNameValue ? '&holiday=' + holidayNameValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let calendar = new ListCalendarRequest();
      console.log(fromDateValue, toDateValue);
      if (fromDateValue) {
        let gglFromDate = new google_date.Date();
        let [y, m, d] = fromDateValue.split('-');
        gglFromDate.setYear(y);
        gglFromDate.setMonth(m);
        gglFromDate.setDay(d);
        calendar.setFromDate(gglFromDate);
      }
      if (toDateValue) {
        let gglToDate = new google_date.Date();
        let [y, m, d] = toDateValue.split('-');
        gglToDate.setYear(y);
        gglToDate.setMonth(m);
        gglToDate.setDay(d);
        calendar.setToDate(gglToDate);
      }
      calendar.setHolidayName(holidayNameValue);

      calendarClient.listCalendar(calendar, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().calendarsList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**Search Calendar function end ==============================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'dayOfTheWeek',
      label: 'Day of the Week',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '2',
      name: 'holidayName',
      label: 'Holiday Name',
      visibleState: useState(true),
      align: 'left',
      order: 4,
    },
    {
      id: '3',
      name: 'isCurrent',
      label: 'Is Current',
      visibleState: useState(true),
      align: 'left',
      order: 5,
    },
    {
      id: '4',
      name: 'settle',
      label: 'Settle',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '5',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '6',
      name: 'trade',
      label: 'Trade',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }

    if (col.options === undefined) {
      col.options = {
        display: col.visibleState[0],
      };
    }

    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={rows[rowIndex].calendarId}
      >
        <TableCell padding="checkbox"></TableCell>
        {sortedColumns
          .filter((v) => (v.options.empty ? false : v.options.display))
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name] === true ? (
                <CheckCircleIcon />
              ) : (
                rows[rowIndex][col.name]
              )}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'Calendar_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [4, 5, 3, 0, 1, 2],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [holidayNameValue, setHolidayNameValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
      holiday: query.get('holiday'),
    };

    if (data.holiday) {
      setHolidayNameValue(data.holiday);
    }

    //Get system date
    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.fromDate) {
          setFromDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
        if (!data.toDate) {
          setToDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id={holidayNameValue}>
                  Holiday Name
                </InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setHolidayNameValue(e.target.value)}
                  value={holidayNameValue || ''}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  {holidayNameOptions.map(function(item) {
                    return (
                      <MenuItem key={item.id} value={item.value || ''}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Calendar'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
