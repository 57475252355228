import React, { useState, useContext } from 'react';

import useStyles from '../../../styles';
import tableTheme from '../../../components/Table/TableStyle';
import CsvDownload from '../../../components/Table/CsvDownload';
import MUIDataTable from 'mui-datatables';
import SaveTable from 'components/Table/SaveTable';

import {
  Box,
  Button,
  IconButton,
  Modal,
  Fade,
  Backdrop,
  Typography,
  TextField,
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useUserState } from 'context/UserContext';
import { notifySuccess } from 'components/Notification/Notification';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { ServiceContext } from 'context/ServiceContext';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

export default function UsersTable() {
  const {
    root,
    textBold,
    textField,
    modalBackdrop,
    centerModal,
    modalCloseIcon,
    textError,
    grdRowSpaceBetween,
    grdCellNone,
  } = useStyles();
  const { googleAccount } = useUserState();
  const { tableSettingsServiceClient: tableSettings } = useContext(
    ServiceContext
  );
  const [openCsvDownload, setOpenCsvDownload] = useState(false);
  const [tableSetting] = useState({
    load: true,
    viewColumns: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
  });

  const onSubmit = (values) => {
    reset();
    setOpenModal(false);
  };

  const updateTableSettings = () => {
    const req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Price');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 2],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: () => (
      <SaveTable updateTableSettings={updateTableSettings} />
    ),
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
  };

  const columns = [
    {
      name: 'user',
      label: 'User',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'status',
      label: 'Status',
    },
  ];

  return (
    <div className={root}>
      <Box component="div" mt={5}>
        <div className={grdRowSpaceBetween}>
          <div className={grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setModalAction(0);
                setOpenModal(true);
              }}
            >
              Add/Reactivate User
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setModalAction(1);
                setOpenModal(true);
              }}
            >
              Deactivate User
            </Button>
          </div>
        </div>
        <Box component="div" mt={2}>
          {openCsvDownload && (
            <CsvDownload
              dataKey="list"
              breaks={false}
              open={openCsvDownload}
              onClose={() => {
                setOpenCsvDownload(false);
              }}
            />
          )}
          <MuiThemeProvider theme={tableTheme()}>
            <MUIDataTable title={'USERS'} columns={columns} options={options} />
          </MuiThemeProvider>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={modalBackdrop}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={centerModal}>
              <Typography
                id="match-modal-title"
                variant="h4"
                className={textBold}
                gutterBottom
              >
                {modalAction ? 'Deactivate User' : 'Add/Reactivate User'}
              </Typography>
              <TextField
                fullWidth
                label={modalAction ? "User's Email" : "New User's Email"}
                type="text"
                className={textField}
                placeholder="Enter an @alpaca.markets email"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.email}
                {...register('email', {
                  required: { value: true, message: 'This field is required' },
                  pattern: {
                    value: /\S+@alpaca.markets/,
                    message: 'Email must end with @alpaca.markets',
                  },
                })}
              />
              {errors.email && (
                <p className={textError}>{errors.email.message}</p>
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: 20 }}
                type="submit"
              >
                {modalAction ? 'Deactivate User' : 'Add/Reactivate User'}
              </Button>
              <div className={modalCloseIcon}>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenModal(false)}
                >
                  <CloseIcon style={{ color: '#f1f1f1' }} />
                </IconButton>
              </div>
            </Box>
          </form>
        </Fade>
      </Modal>
    </div>
  );
}
