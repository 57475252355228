import React, { useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

// styles
import useStyles from './styles';
import Logo from '../../images/alpaca.png';

// components
import { Typography } from '../Wrappers/Wrappers';
import NavigationMenuSelect from '../SearchBar/SearchBar';
import { GoogleLogout } from 'react-google-login';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import GetTitle from 'components/title';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import {
  useUserDispatch,
  useUserState,
  signOut,
} from '../../context/UserContext';
import { useProfile } from 'context/ProfileContext';

export default function Header() {
  const classes = useStyles();
  const history = useHistory();

  const layoutState = useLayoutState();
  const user = useUserState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const [profileMenu, setProfileMenu] = useState(null);

  const googleSignOut = () => {
    signOut(userDispatch);
  };

  const googleLoginClientId = window.env.GOOGLE_LOGIN_CLIENT_ID;
  const userName = user?.googleAccount?.profileObj?.name || 'User';

  //search menu
  const [menuValue, setMenuValue] = React.useState('');
  const title = GetTitle();
  const profile = useProfile();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <NavLink to="/app/home">
          <img alt="alpaca" className={classes.logoImage} src={Logo}></img>
        </NavLink>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          {title}
        </Typography>
        <div className={classes.grow} />
        <div style={{ width: 300, marginRight: 50 }}>
          <NavigationMenuSelect
            labelid="menu"
            label="Navigation Menu"
            value={menuValue}
            onChange={(e) => setMenuValue(e.target.value)}
            setNewValue={(event, newValue) => {
              if (newValue) {
                setMenuValue(newValue.link);

                const currentPath = window.location.pathname;
                history.push(newValue.link ? newValue.link : currentPath);
              } else {
                setMenuValue('');
                return;
              }
            }}
          />
        </div>
        <div style={{ marginRight: 20 }}>
          <DateRangeRoundedIcon />
          <label style={{ position: 'relative', top: -5, marginLeft: 5 }}>
            {profile.systemDate.format('MM/DD/YYYY')}
          </label>
        </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {userName}
            </Typography>
          </div>
          <GoogleLogout
            clientId={googleLoginClientId}
            buttonText="Logout"
            onLogoutSuccess={googleSignOut}
            onFailure={googleSignOut}
            render={(renderProps) => (
              <MenuItem
                onClick={renderProps.onClick}
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem
                )}
              >
                <AccountIcon className={classes.profileMenuIcon} /> Sign Out
              </MenuItem>
            )}
          />
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
