/*ReactJS*/
import React, { useState } from 'react';

/*Material UI Components*/
import { Box, Typography, Breadcrumbs, Tabs, Tab } from '@material-ui/core';

/*Custom components*/
import TabPanel from 'components/TabPanel/TabPanel.js';
import SummaryTable from './Components/SummaryTable.js';

/*Style*/
import useStyles from '../../styles.js';

export default function ClearingTrades(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <Box>
          <Typography
            variant="h2"
            className={classes.textBold}
            align="left"
            gutterBottom={true}
          >
            Clearing
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <span color="inherit">Clearing</span>
            <span color="inherit">Trades</span>
            <Typography color="primary">
              {value ? 'Contracts' : 'Summary'}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box className={classes.tabTradesHeader}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="scrollable auto tabs"
            centered
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Contracts" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SummaryTable params={props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h1>Under Construction</h1>
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
