/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListStockBorrowDetailRequest } from '../../../proto/reportpb/stockborrow_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

/*Material UI Components*/
import { TableCell, TableRow } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function StockBorrowDetailsTable({
  systemdate,
  origcusip,
  symbol,
  symboldesc,
  cusip,
}) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { stockBorrowServiceClient: stockborrow } = useContext(ServiceContext);
  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'settlementObligation',
      label: 'Settlement Obligation',
      visibleState: useState(true),
    },
    {
      id: '2',
      name: 'borrowed',
      label: 'Borrowed',
      visibleState: useState(true),
    },
    {
      id: '3',
      name: 'loaned',
      label: 'Loaned',
      visibleState: useState(true),
    },
    {
      id: '4',
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
    },
    {
      id: '5',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
    },
    {
      id: '6',
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
    },
    {
      id: '7',
      name: 'masterAccountNo',
      label: 'Master Account No',
      visibleState: useState(false),
    },
    {
      id: '8',
      name: 'accountDesignator',
      label: 'Account Designator',
      visibleState: useState(true),
    },
    {
      id: '9',
      name: 'broker',
      label: 'Broker',
      visibleState: useState(false),
    },
    {
      id: '10',
      name: 'type',
      label: 'Type',
      visibleState: useState(false),
    },
  ];

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow tabIndex={-1} key={rowIndex}>
        {columns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell
              className={
                parseFloat(
                  rows[rowIndex][col.name]
                    .toString()
                    .replace('$', '')
                    .replace(',', '')
                ) < 0
                  ? classes.textColorRed
                  : ''
              }
              align={col.align || 'left'}
              key={col.name}
            >
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    selectableRowsHeader: false,
    selectableRows: 'none',
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  useEffect(() => {
    let list = new ListStockBorrowDetailRequest();
    list.setOriginalCusip(origcusip);

    if (systemdate) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = moment(new Date(systemdate))
        .format('yyyy-MM-DD')
        .split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    stockborrow.listStockBorrowDetail(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err);
        return;
      }

      const rows = res.toObject().stockBorrowDetailsList.map((data) => ({
        settlementObligation: data.settlementObligation,
        borrowed: data.borrowed,
        loaned: data.loaned,
        correspondent: data.correspondent,
        accountNo: data.accountNo,
        accountName: data.accountName,
        masterAccountNo: data.masterAccountNo,
        accountDesignator: data.accountDesignator,
        broker: data.broker,
        type: data.type,
      }));
      setRows(rows);
    });
  }, [stockborrow, systemdate, origcusip]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={
            '[' +
            symbol +
            ' - ' +
            symboldesc +
            '][' +
            cusip +
            '] - ' +
            systemdate +
            ' - Stock Borrow Details'
          }
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
